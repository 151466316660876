import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material-module'
import { CustomerListComponent} from './customer-list/customer-list.component';
//import { CustomerGridService } from '../customer-list/customer-list.service';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomerService } from './customer-service';

//import { CustomerImportComponent } from './customer-import/customer-import.component';

@NgModule({
	declarations: [
		CustomerListComponent
		, CustomerEditComponent
		//, CustomerImportComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		
		CommonModule,
		RouterModule.forChild([
			//{ path: 'customer-import', component: CustomerImportComponent },
			{ path: 'customers', component: CustomerListComponent },
			{ path: 'customer-details/:Id', component: CustomerEditComponent },
		])
	],
	providers: [
		CustomerService
	]
})
export class CustomerModule { }
