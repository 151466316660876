import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';
import { IUsersearch } from 'src/app/admin/usersearches/usersearch-model/usersearch';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationSettings } from 'src/app/shared/application-settings';
import { LabelprintingService } from '../../labelprinting-service';

const CHAT_URL = "ws://localhost:5001/chathub";
export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}


@Component({
	templateUrl: './inventory-label.component.html',
	styleUrls: ['inventory-label.component.css']
})

export class InventoryLabelComponent implements OnInit, OnDestroy, AfterViewInit {
		public page = 'Inventory Label';
		errorMessage: string;
		labelForm: UntypedFormGroup;
	
		public UserSearches: IUsersearch[];
		public pageTitle = 'PackageLabel';
		public printerList = [];
	
	
	
		public LayoutList = ['regular'];
	
		trnsfrData = {
			source: '',
			content: [],
			message: '',
			function: ''
		};
		public serverStatus = false;
		public printerAvailable = false;
		public serverStatusMessage= 'start local communications';
		public serverStatusMessageDef= 'start local communications';
		private subject: AnonymousSubject<MessageEvent>;
		public messages: Subject<Message>;
		public labelData: any;
		public labelDataStatus = false;
		public partNumber: any;
		public branchid: any;
		@ViewChild('inputFile') fileUp: ElementRef;
		fileUpload = '';
		directPrintData = '';
		public PrinterResolutionList = ['203', '300']
		recCnt = 0;
		labelArray = '';
	
		constructor(
			private authService: AuthService,
			private fb: UntypedFormBuilder,
			private labelPrintingService: LabelprintingService,
			public applicationSettings: ApplicationSettings
		) {
			this.connectServer();
		}
	
		ngOnInit(): void {
	
			let printer=localStorage.getItem('printerInvLbl');
			let layout=localStorage.getItem('layoutInvLbl');
			let resolution=localStorage.getItem('resolutionInvLbl');
	
			this.labelForm = this.fb.group({
				Id: '',
				Area: ['', Validators.required],
				Row: '',
				Rack: '',
				Shelf: '',
				Location: '',
				PrinterName: [printer, Validators.required],
				Layout: layout,
				PartNumber: '',
				PrinterResolution: resolution
			});
			this.branchid = this.authService.getBranchId();
			this.getPrinterList();
			
		}
	
		public ngAfterViewInit(): void {
		}
	
		getPrinterList() {
			this.labelPrintingService.getPrinterListData(this.branchid, 'InvLbl')
				.subscribe(val => {
					if(val.length>0){
					this.printerList = val;
					this.labelForm.patchValue({
						PrinterName: this.printerList[0].IP, 
					  });
					  this.printerAvailable = true;
					  this.serverStatusMessage = this.serverStatusMessageDef;
					}else{
						this.printerAvailable = false;
						this.serverStatusMessage = this.serverStatusMessageDef + ' Unable to find printer for Inventory Label';
					}
				});
		}
	
		ngOnDestroy(): void {
		}
	
		// tslint:disable-next-line:no-shadowed-variable
		public valueNormalizer = (text: Observable<string>) => text.map(_ => null);
	
	
		resetError(): void {
			this.errorMessage = '';
		}
	
	
		//Websocket Communication Section Start//
	
		connectServer() {
			this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
				map(
					(response: MessageEvent): Message => {
						let data = JSON.parse(response.data)
						return data;
					}
				),
				catchError(error => { throw error }),
				tap({
					error: error => console.log('Error in WebSocket:', error),
					complete: () => {
						this.serverStatus = false;
					}
				}
				)
			);
			//Response section from websocket Start//
			this.messages.subscribe(msg => {
	
				if (msg.function == "labelPrintResponse") {
					//alert(msg.message);
				}
				else {
					// this.received.push(msg);
					console.log("Response from websocket: " + msg.message);
				}
			});
			//Response section from websocket End//
		}
		public connect(url): AnonymousSubject<MessageEvent> {
			this.subject = this.create(url);
			return this.subject;
		}
	
		//Connecting websocket//
		private create(url): AnonymousSubject<MessageEvent> {
			let ws = new WebSocket(url);
			ws.onopen = openevent => {
				console.log("Successfully connected: " + url);
				this.serverStatus = true;
				this.trnsfrData = {
					source: 'labelprint client',
					content: [],
					message: 'register client',
					function: 'registerAngularClient'
				};
				this.messages.next(this.trnsfrData);
			}
	
			let observable = new Observable((obs: Observer<MessageEvent>) => {
				ws.onmessage = obs.next.bind(obs);
				ws.onerror = obs.error.bind(obs);
				ws.onclose = obs.complete.bind(obs);
				return ws.close.bind(ws);
			});
			let observer = {
				error: null,
				complete: null,
				next: (data: Object) => {  //sending messge to websocket
					console.log('Message sent to websocket: ', data);
					if (ws.readyState === WebSocket.OPEN) {
						ws.send(JSON.stringify(data));
					}
					else {
						this.serverStatus = false;
					}
				}
			};
			return new AnonymousSubject<MessageEvent>(observer, observable);
		}
		reconnect() {
			this.connectServer();
		}
		//Websocket Communication Setion End//
		fetchData() {
			if (this.labelForm.valid) {
				const obj = Object.assign({}, this.labelForm.getRawValue());
				obj.BranchId = this.branchid;
				this.labelPrintingService.fetchDataForRackLabels(obj)
					.subscribe(resp => {
						if (resp.status == true) {
							this.labelData = [];
							this.labelData = resp.data;
							this.partNumber = this.labelData[0].PartNumber;
							this.labelDataStatus = true;
						}
						else {
							this.labelData = [];
							alert(resp.msg);
						}
					}
						, (error: any) => this.errorMessage = <any>error);
	
						localStorage.setItem('printerInvLbl',obj.PrinterName);
						localStorage.setItem('layoutInvLbl',obj.Layout);
						localStorage.setItem('resolutionInvLbl',obj.PrinterResolution);
			}
		}
	
		dataForPrinting(val, printername) {
			this.trnsfrData = {
				source: 'labelprint client',
				content: [],
				message: 'Label Sent For Print',
				function: 'sendLabelForPrint'
			};
			console.log(this.labelForm.value.PrinterName);
			this.trnsfrData.content.push(val);
			this.trnsfrData.content.push(printername);
			this.trnsfrData.content.push(1);
			this.messages.next(this.trnsfrData);
		}
	
		print(): void {
			if (this.labelForm.valid) {
				this.recCnt = 0;
				const obj = Object.assign({}, this.labelForm.getRawValue());
				this.startPrinting(obj);
			}
		}
		startPrinting(obj) {
			this.labelData[this.recCnt].Layout = obj.Layout;
			this.labelData[this.recCnt].PrinterResolution = obj.PrinterResolution;
			this.labelPrintingService.saveSearch(obj, this.pageTitle);
			this.labelPrintingService.createLabel6By2(this.labelData[this.recCnt])
				.subscribe(val => {
					console.log(this.recCnt);
					this.dataForPrinting(val, obj.PrinterName);
					this.recCnt = this.recCnt + 1;
	
					if (this.recCnt < this.labelData.length) {
						// (async () => {
						// 	await new Promise(f => setTimeout(f, 1000));//add 1 sec pause between two labels
							this.startPrinting(obj);
						// })();
					}
					else {
						this.onSaveComplete();
					}
				}
					, (_: any) => this.errorMessage = 'Error while printing the label');
	
		}
	
		onSaveComplete(): void {
			this.labelData = [];
			this.labelDataStatus = false;
			this.partNumber = '';
			this.labelForm.patchValue({
				Id: '',
				Area: '',
				Row: '',
				Rack: '',
				Shelf: '',
				Location: '',
				// PrinterName: '',
				//Layout: 'regular',
				PartNumber: '',
				//PrinterResolution: '300'
			});
	
		}
	
	
		onFileSelected(event) {
			console.log('onFileSelected:');
			if (event.target.files.length > 0) {
				this.fileUpload = event.target.files[0];
			}
		}
		filePrint() {
			const formData = new FormData();
			formData.append('fileName', this.fileUpload);
			formData.append('resolution', this.labelForm.value.PrinterResolution);
			formData.append('layout', this.labelForm.value.Layout);
			console.log(formData);
			console.log(this.fileUpload);
			this.labelPrintingService.filePrintInventorylabels(formData)
				.subscribe(resp => {
					this.startAllDirectPrinting(resp);			
				}
					, (error: any) => this.errorMessage = <any>error);
		}
	
		directPrint() {
			this.labelPrintingService.directPrintLabels6by2(this.directPrintData, this.labelForm.value.PrinterResolution,this.labelForm.value.Layout, this.branchid)
				.subscribe(resp => {
					if(resp.status==true){
						this.startAllDirectPrinting(resp.data);
					}
					else{
						alert(resp.message);
					}		
				}
					, (error: any) => this.errorMessage = <any>error);
		}
		startDirectPrinting() {
			this.dataForPrinting(this.labelArray[this.recCnt], this.labelForm.value.PrinterName);
			this.recCnt = this.recCnt + 1;
			if (this.recCnt < this.labelArray.length) {
				 (async () => {
				 	await new Promise(f => setTimeout(f, 250));//add 1/4 sec pause between two labels
					this.startDirectPrinting();
				 })();
			}
			else {
				this.recCnt = 0;
				this.fileUpload = '';
				this.fileUp.nativeElement.value = '';
				this.directPrintData = '';
				alert("Labels Printed Successfully!");
			}
		}
		startAllDirectPrinting(arr) {
			this.labelArray = arr;
			this.recCnt = 0;
			this.startDirectPrinting();// recursive function for direct and file direct print
		}
	
	}
	
	