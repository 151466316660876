<div class="panel panel-primary">
    <div class="panel-heading">
        Avery Labels
    </div>
    <div class="panel-body">
        <div class='row'>
            <div class='col-md-12'>
                <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                    <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <div>
<!-- <table>
    <tr>
        <th>Labels By Product Category</th>
        <th>Labels by Product Group</th>
    </tr>
    <tr>
        <td>
            <ng-container *ngFor="let i of prodCatPdfLinkList">
                <a href="javascript:void(0)" (click)="openPdfFile(i.Tag)">{{i.Tag}}</a><br>
            </ng-container>
        </td>
        <td>
            <ng-container *ngFor="let i of prodCatPdfLinkList">
                <a href="javascript:void(0)" (click)="openPdfFile(i.Tag)">{{i.Tag}}</a><br>
            </ng-container>
        </td>
    </tr>
</table> -->
            <div class="row">
<div class="col-md-12">
    <div class="col-md-6">
        <div>Labels By Product Category</div>
        <ng-container *ngFor="let i of prodCatPdfLinkList">
            <a href="javascript:void(0)" (click)="openPdfFile(i.Tag)">{{i.Tag}}</a><br>
        </ng-container>
    </div>
    <div class="col-md-6">
        <div>Labels by Product Group</div>
        <ng-container *ngFor="let i of prodGroupPdfLinkList">
            <a href="javascript:void(0)" (click)="openPdfFile(i.PriceLine)">{{i.PriceLine}}</a><br>
        </ng-container>
    </div>
</div>
            </div>
        </div>
    </div>
</div>