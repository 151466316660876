﻿<h1>Electrical Continuity Test</h1>

<table>
    <tr>
        <td>
            Electrical Continuity Ohms
        </td>
        <td>
            <input [(ngModel)]="hoseDetail.ElectricalConOhms" (ngModelChange)="changeElectricalConOhms($event)" placeholder="" class="form-control  input-list">
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorElectricalConOhms == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>
            Electrical Continuity Result
        </td>
        <td>
            <select [(ngModel)]="hoseDetail.ElectricalConResult" class="form-control  input-list">
                <option *ngFor="let o of passFail" [value]="o.value">{{o.name}}</option>
            </select>
        </td>
    </tr>
</table>
<br>
<br />
<h5>UL330 Electrical Resistance Test</h5>
<p>The UL 330 specification is a maximum of 70,000 ohms/ft.</p>
<p>The UL330 test measures the resistance in ohms of the hose from fitting to fitting. All hoses with metal helical, grounding or braided wire should measure to less than 1 ohm/ft.</p>
<p>Putting the ohm meter across each end of a hose with a braided wire should show a very low reading and may approach zero or a small fraction.</p>



