import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { LabelpropertyconfigService } from '../labelpropertyconfig-service';
import { ILabelpropertyconfigSearch } from '../labelpropertyconfig-model/labelpropertyconfigsearch';
import { ILabelpropertyconfigs } from '../labelpropertyconfig-model/labelpropertyconfigs';

@Component({
	templateUrl: './labelpropertyconfig-list.component.html',
	styleUrls: ['./labelpropertyconfig-list.component.css']
})

export class LabelpropertyconfigListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'Header1', 'Header2','Description', 'ExpressionLike', 'ExpressionEquals', 'Order','Execute','Result'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public Header1 = '';
	public Header2 = '';
	public ExpressionLike = '';
	public ExpressionEquals = '';
	public Order = '';

	public pageTitle = 'Label Property Configs List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: LabelpropertyconfigService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='Header1')
				this.Header1 = i.Value;

			if(i.Item=='Header2')
				this.Header2 = i.Value;

			if(i.Item=='ExpressionLike')
				this.ExpressionLike = i.Value;

			if(i.Item=='ExpressionEquals')
				this.ExpressionEquals = i.Value;

			if(i.Item=='Order')
				this.Order = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: ILabelpropertyconfigSearch = this._appService.getFreshSearch();

		search.Header1 = this.Header1;
		search.Header2 = this.Header2;
		search.ExpressionLike = this.ExpressionLike;
		search.ExpressionEquals = this.ExpressionEquals;
		search.Order = this.Order;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: ILabelpropertyconfigs) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: ILabelpropertyconfigs){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.Header1 = '';
		this.Header2 = '';
		this.ExpressionLike = '';
		this.ExpressionEquals = '';
		this.Order = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/labelpropertyconfigs`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: ILabelpropertyconfigSearch = this._appService.getFreshSearch();

			search.Header1 = this.Header1;
			search.Header2 = this.Header2;
			search.ExpressionLike = this.ExpressionLike;
			search.ExpressionEquals = this.ExpressionEquals;
			search.Order = this.Order;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
	executePropertys(obj){
		this.progressStatus=true;
		this._appService.executeLabelPropertys(obj).subscribe(
			retVal => {
				this.progressStatus=false;
					const url = this.router.serializeUrl(
						this.router.createUrlTree([`/labelpropertyconfig-result`,obj.Id])
				 );
				   window.open(url, '_blank');
			},
			 (error: any) => {
				 this.errorMessage = < any > error;
			});
	}
	openResultExectePropertys(id){
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/labelpropertyconfig-result`,id])
	 );
	   window.open(url, '_blank');
	}
	// executeAllPropertys(){
	// 	this.progressStatus=true;
	// 	this._appService.executeAllLabelPropertys().subscribe(
	// 		retVal => {
	// 			this.progressStatus=false;
	// 				console.log(retVal);
	// 		},
	// 		 (error: any) => {
	// 			 this.errorMessage = < any > error;
	// 		});
	// }
	generateExcel(){
		this.progressStatus=true;
		this._appService.generateExcel().subscribe(
			obj => {
				this.progressStatus=false;
				if(obj.status==true){
					window.open(`${ this.applicationSettings.retrieveUrl()}${obj.filepath}`);
				}
			    else{
					alert(obj.message);
				}
			},
			 (error: any) => {
				 this.errorMessage = < any > error;
			});
	}
}