import { Component, OnInit } from '@angular/core';
import { LabelsmenuService } from '../labelsmenu-service';
import { BinlabelcatService } from 'src/app/binlabelcats/binlabelcat-service';
import { ApplicationSettings } from '../../shared/application-settings';

@Component({
  selector: 'app-labelnextsteps',
  templateUrl: './labelnextsteps.component.html',
  styleUrls: ['./labelnextsteps.component.css']
})
export class LabelnextstepsComponent implements OnInit {

  binLabelCatList: any;
  pLineList: any;
  menutab = '';
  submenutab2 = '';
  rackBinPLine: any;
  public progressStatus = false;
  public errorMessage = '';

  constructor(
    public applicationSettings: ApplicationSettings
    , private binlabelcatService: BinlabelcatService
    , private labelsmenuService: LabelsmenuService
  ) { }

  ngOnInit(): void {
    this.getBinLabelCatData();
  }

  getBinLabelCatData() {
    this.binlabelcatService.getBinLabelCatForList().subscribe(
      (val) => {
        this.binLabelCatList = val;
        this.pLineList = this.binLabelCatList.filter(
          (thing, i, arr) => arr.findIndex(t => t.PLine === thing.PLine) === i
        );
      },
      (error: any) => {
        this.errorMessage = <any>error;
      }
    );

  }
  mainmenufunt(value) {
    this.menutab = value;
  }
  submenutab2funt(value) {
    this.submenutab2 = value;
  }

  createRackBinLabels() {

    let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
    if (this.submenutab2 == "tb2submenu1") {
      this.progressStatus = true;
      this.labelsmenuService.createRackLabelPartNumberOnly(this.rackBinPLine).subscribe(
        (val: string) => {
          window.open(url + val, "_blank");
          console.log('pdf created:' + val)
          this.progressStatus = false;
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
    }
    else if (this.submenutab2 == "tb2submenu2") {
      this.progressStatus = true;
      this.labelsmenuService.createRackLabelCode39(this.rackBinPLine).subscribe(
        (val: string) => {
          window.open(url + val, "_blank");
          console.log('pdf created:' + val)
          this.progressStatus = false;
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
    }
    else if (this.submenutab2 == "tb2submenu3") {
      this.progressStatus = true;
      this.labelsmenuService.createRackLabelByPlineCode39(this.rackBinPLine).subscribe(
        (val: string) => {
          window.open(url + val, "_blank");
          console.log('pdf created:' + val)
          this.progressStatus = false;
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
    }
  }
  resetError(): void {
		this.errorMessage = '';
		this.progressStatus = false;
	}
}
