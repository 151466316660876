<div class="container">
    <div class='row'>
        <div class='col-md-12'>
            <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                {{ errorMessage }}
            </div>
        </div>
    </div>
    <div >
    <h2> Hydraulic Fittings Categories</h2>
    <h2> Upload File</h2>
    <div class="row">
        <div class="col-md-11">
            <form class='form-horizontal' novalidate [formGroup]='uploadfileForm'>
            <div class="col-md-10" style="margin-top: 50px;">
                <input (change)="onFileSelected($event)" type="file" formControlName="fileName">
            </div>
            <div class="col-md-10 text-danger" style="margin-top: 50px;" *ngIf="collist">
                Columns does not exists in file please add these columns: {{collist}}
            </div>
        </form>
        </div>
    </div>
</div>
</div>
<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
    </div>