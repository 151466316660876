<div class="col-md-12 nopadding form-my mb-40">
    <div class="card-form">
        <div class="form-title">{{ page }}</div>
        <form class="form-horizontal" novalidate [formGroup]="ferruleForm">
            <div class="form-body">
                <div class="col-md-12">
                    <a class='btn btn-default btn-sm' [routerLink]="['/ferrules']">
                        <span class=" glyphicon glyphicon-chevron-left"></span>Back
                    </a>

                    <div class="mt-40">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="Ferrule" class="col-md-3 control-label">Ferrule Name <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <input formControlName="Ferrule" type="text" id="Ferrule" placeholder="Ferrule Name*" name="Ferrule" required class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="InsideDiameter" class="col-md-3 control-label">Inside Diameter <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <input formControlName="InsideDiameter" type="number" id="InsideDiameter" placeholder="InsideDiameter" name="InsideDiameter"
                                        class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="WallThickness" class="col-md-3 control-label">Wall Thickness <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <input formControlName="WallThickness" type="number" id="WallThickness" placeholder="Wall Thickness" name="WallThickness"
                                        required class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Pressure" class="col-md-3 control-label">Pressure</label>
                                <div class="col-md-9">
                                    <input formControlName="Pressure" type="number" class="form-control" id="Pressure" placeholder="Pressure.." name="Pressure"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="PressureTest" class="col-md-3 control-label">Pressure Test</label>
                                <div class="col-md-9">
                                    <input formControlName="PressureTest" class="form-control" type="number" id="PressureTest" placeholder="Pressure Test.."
                                        name="PressureTest" />
                                </div>
                            </div>

                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12">

                            <div class="form-group">
                                <label for="CrimpInstructions" class="col-md-3 control-label">Crimp Instructions </label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="CrimpInstructions" type="text" id="CrimpInstructions" placeholder="Crimp Instructions"
                                        name="CrimpInstructions"></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Notes" class="col-md-3 control-label">Notes</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="Notes" type="text" id="Notes" placeholder="Notes.." name="Notes"></textarea>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="form-footer">

                <div class="col-md-12 alert alert-danger mt-20 mb-20 text-center" *ngIf='errorMessage'>
                    <strong>Error occured !</strong> {{ errorMessage }}.
                </div>

                <div class="col-md-12 alert alert-success mt-20 mb-20 text-center" *ngIf='message'>
                    {{ message }}.
                </div>

                <div class="col-md-12 buttons-row text-center">
                    <button [disabled]="!editPermission" class="btn btn-flat btn-primary" (click)="saveFerrule(true)" style="width: 150px; margin-right:10px">
                        <span class="glyphicon glyphicon-floppy-save"></span> Save & Return &nbsp;
                    </button>
                    <span>
                        <button [disabled]="!editPermission" class="btn btn-flat btn-primary" (click)="saveFerrule(false)" style="width: 150px; margin-right:10px">
                            <span class="glyphicon glyphicon-floppy-save"></span> Save & Continue &nbsp;
                        </button>
                    </span>
                    <span>
                        <button class="btn btn-flat btn-warning" (click)="cancel()" style="width: 150px; margin-right:10px">
                            <span class=""></span> Cancel &nbsp;
                        </button>
                    </span>
                    <span>
                        <button class="btn btn-flat btn-danger" (click)="deleteFerrule()" style="width: 150px; margin-right:10px" [disabled]='!editPermission||!ferruleForm.valid'>
                            <span class="glyphicon glyphicon-remove-circle"></span> Delete &nbsp;
                        </button>
                    </span>
                </div>

                <div class="col-md-12 footer-info">
                    <div class="text-left col-sm-6">
                        Updated By: <span *ngIf="ferrule" class="label label-default">{{ferrule.UpdatedBy}}</span>

                    </div>
                    <div class="text-right col-sm-6">
                        Time: <span *ngIf="ferrule" class="label label-success">{{ferrule.UpdatedDate}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>