<div class='panel panel-primary animaPlaceholder' *ngIf='document'>
	<div class='panel-heading'>
		<span *ngIf='document'>{{ page }} :{{ document.Id }}</span>
		<span class='pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='documentForm'>
			<fieldset>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Name</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='Name' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Description</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='Description' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> File Name</label>
							<div class='col-md-7'>
								<input class='form-control' type='text' formControlName='FileName' />
							</div>
							<div *ngIf="CurrentFile" class="col-md-3">	
								<button [disabled]="buttonPushed" class='btn btn-primary btn-edit-sml'
								(click)='openFile(CurrentFile)'>Open File
							</button>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Document Type</label>
							<div class='col-md-10'>
								<select class="form-control" formControlName='DocumentType'>
									<option></option>
									<option>Certificate</option>
									<option>Crimp</option>
									<option>Knowledge</option>
									<option>Procedure</option>
									<option>Training</option>
									<option>WHMIS</option>
								</select>
							</div>
						</div>
						<div class='form-group' style="display: none;">
							<label class='col-md-2 control-label'> Real File Name</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='RealFileName' />
							</div>
						</div>
						<div class="form-group" *ngIf="document.Id">
							<label class='col-md-2 control-label'> Upload File</label>
							<div class="col-md-5">								
								<input (change)="onFileSelected($event)" type="file">
							</div>
						</div>
				<div class='row' style='padding: 15px;'>
					<div class='col-md-12'>
						<span>
							<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
								(click)='saveDocument(false, false)' style='width: 110px; margin-right:10px'
								[disabled]='!documentForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
								(click)='saveDocument(true, false)' style='width: 150px; margin-right:10px'
								[disabled]='!documentForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
							</button>
							<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
								(click)='saveDocument(false, true)' style='width: 190px; margin-right:10px'
								[disabled]='!documentForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
							</button>
						</span>

						<span class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='cancel()'
								style='width: 150px; margin-right:10px' [disabled]='!documentForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='deleteDocument()'
								style='width: 150px; margin-right:10px' [disabled]='!documentForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='document'>{{document.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='document'>{{document.UpdatedDate}}</label></span>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>