<div class='panel panel-primary animaPlaceholder' *ngIf='video'>
	<div class='panel-heading'>
		<span *ngIf='video'>{{ page }} :{{ video.Id }}</span>
		<span class='pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class="col-md-6 col-sm-6 col-xs-12">
			<div id="vimeo-viewer"></div>
		</div>
        <div class="mt-40">
            <div class="col-md-12 col-sm-8 col-xs-12 text-center">
                <h3>{{ video?.Title }}</h3>
                <div id="vimeo-viewer-admin"></div>
            </div>
        </div>
		<div class='row' style='padding: 15px;'>
			<div class='col-md-12'>

				<span class='pull-right'>
					<button class='btn btn-primary' [disabled]='buttonPushed' (click)='cancel()'
						style='width: 150px; margin-right:10px' >
						Cancel &nbsp;
					</button>			
				</span>
			</div>
		</div>
	</div>
</div>
<div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>