import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { ApplicationSettings } from '../shared/application-settings';
import { FittingLookup, HoseLookup, Users, FerruleLookup } from './app-classes';

@Injectable()
export class AppPropertiesService { // TODO: This should probably be removed/put into seperate files eventually

	errorMessageInteger = 'No chars or decimals';
	path: string;
	baseUrl: string;
	staffList: Users[];
	currentUser: string;
	currentUserBranch: string;
	// localWebserverPath = 'http://localhost/LocalHydroTest/';  // production value
	localWebserverPath = 'http://localhost:57227/';  // dev value
	testLocations: any[] = [{ name: '' }];

	public listHoses: Array<HoseLookup> = [];
	public listHoseStrings: Array<string> = [];
	public listEndOne: Array<string> = [];
	public listEndTwo: Array<string> = [];
	public listFittings: Array<FittingLookup> = [];

	public listFerruleOne: Array<string> = [];
	public listFerruleTwo: Array<string> = [];
	public listFerrules: Array<FerruleLookup> = [];

	// public listCustomers: any[];
	public defaultTestMachine: string;

	constructor(
		public http: HttpClient,
		private appSettings: ApplicationSettings
		//, private completerService: CompleterService
		) {
		//this.compltrService = completerService;
		this.baseUrl = this.appSettings.retrieveUrl();
	}

	getListOfTestLocations() {
		this.http.get<any>(this.baseUrl + '/api/HydroTest/TestLocations')
			.subscribe((data => {
				this.testLocations = data;
			}),
				error => () => console.error('error getting list of locations'));
	}

	getListOfTestLocations2() {
		return this.http.get<any>(this.baseUrl + '/api/HydroTest/TestLocations');
	}

	getListOfUsers() {
		this.http.get<any>(this.baseUrl + '/api/HydroTest/ListOfUsers')
			.subscribe((data => {
				this.staffList = data;
			}),
				error => () => console.error('error getting users'));
	}
	
	getUsers(): Observable<Users[]> {
		return this.http.get<Users[]>(this.baseUrl + '/api/HydroTest/ListOfUsers')
			.pipe(
				retry(1),
				catchError(this.processError)
				);
	}
	
	getThirtyCustomers(text: string) {
		return this.http.get<any>(this.baseUrl + '/api/customers?starttext=' + text);
	}

	getCustomerList() {
		return this.http.get<any>(this.baseUrl + '/api/HydroTest/CustomersAutoComplete?search=""');
	}


	getHosesAndFittings() {
		this.resetFittings();
		this.resetHoses();

		this.http.get(this.baseUrl + '/api/AssemblyDetails/HosesAutocomplete')
			.subscribe((data => this.setHoseList(data)));

		this.http.get(this.baseUrl + '/api/AssemblyDetails/FittingsAutocomplete')
			.subscribe((data => {
				this.setFittingList(data);
			}));

		this.http.get(this.baseUrl + '/api/AssemblyDetails/FerruleAutocomplete')
			.subscribe((data => {
				//console.log('service ferrule list');
				//console.log(data);
				this.setFerruleList(data);
			}));


	}

	resetHoses() {
		this.listHoses = [];
		this.listHoseStrings = [];
	}

	resetFittings() {
		this.listFittings = [];
		this.listEndOne = [];
		this.listEndTwo = [];
	}

	resetFerrules() {
		this.listFerrules = [];
		this.listFerruleOne = [];
		this.listFerruleTwo = [];

	}

	setHoseList(data: any) {
		this.listHoses = data;

		for (const h of this.listHoses) {
			this.listHoseStrings.push(h.hoseName);
		}
	}

	setFittingList(data: any) {
		this.listFittings = data;
		for (const f of this.listFittings) {
			this.listEndOne.push(f.fittingName);
			this.listEndTwo.push(f.fittingName);
		}
	}

	setFerruleList(data: any) {
		//console.log('ferrule service::setFerruleLists');
		//console.log(data);
		this.listFerrules = data;
		for (const f of this.listFerrules) {
			this.listFerruleOne.push(f.Ferrule1);
			this.listFerruleTwo.push(f.Ferrule1);
		}
		//console.log(this.listFerruleTwo);

	}
	processError(err) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }
}

