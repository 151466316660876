import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-hr-admin',
	templateUrl: './hr-admin.component.html',
	styleUrls: ['./hr-admin.component.css']
})
export class HrAdminComponent implements OnInit {

	url: SafeResourceUrl;
	legacyUrl = '/DataEntry/HRAdmin.aspx';

	constructor(
		public sanitizer: DomSanitizer,
		private settings: ApplicationSettings) {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.retrieveLegacyAppUrl() + this.legacyUrl);
	}

	ngOnInit() {
	}

}
