import { Component, Input, OnInit } from '@angular/core';
import { IHose } from '../hose-model/hose';

@Component({
	selector: 'http-continuity'
	, templateUrl: './Continuity.html',
	styles: [`
	 .alert {
		 padding: 8px;
	 }
	`]
})
export class ContinuityComponent implements OnInit {
	@Input() hoseDetail: IHose;

	public passFail: any[];
	public errorElectricalConOhms: boolean = true;

	constructor() {
		// console.log('Continuity::constructor');
		this.passFail = [
			{ name: '', value: '' },
			{ name: 'Pass', value: 'Pass' },
			{ name: 'Fail', value: 'Fail' }
		];
	}

	ngOnInit(): void {
		// console.log('Continuity::ngOnInit');
	}
	changeElectricalConOhms(val) {
		if (val != "") {
			this.errorElectricalConOhms = this.isNormalInteger(val);
		}
		else {
			this.errorElectricalConOhms = true;
		}
	}
	isNormalInteger(str): boolean {
		var n = Math.floor(Number(str));
		return String(n) === str && n >= 0;
	}

}
