import { IHose } from './hose.model/hose';
import { ApplicationSettings } from './../../shared/application-settings';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs-compat';

import { of } from 'rxjs';

@Injectable()
export class HoseService {
	private baseUrl;

	constructor(private authHttp: HttpClient, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Hose';
	}

	getHosebyId(id: number):  Observable<IHose> {
		if (id === 0) {
			return of(this.initializeHose());
		}

		const url = `${this.baseUrl}`+'/GetHoseById?id='+`${id}`;
		return this.authHttp.get<IHose>(url)
			.catch(this.handleError);
	}
	

	saveHose(hose: IHose): Observable < any > {
		if (hose.Id === 0) {
			return this.createHose(hose);
		}
		return this.updateHose(hose);
	}

	private createHose(hose: IHose): Observable <any> {
		const url = `${this.baseUrl}`+'/CreateHose';
		return this.authHttp.post(url, hose)
			.catch (this.handleError);
	}

	private updateHose(hose: IHose): Observable <IHose> {
		return this.authHttp.put(this.baseUrl +'/UpdateHose', hose)
			.catch (this.handleError);
	}

	deleteHose(id: number): Observable < Response > {
		const url = `${this.baseUrl}`+'/DeleteHose/'+`${id}`;
		return this.authHttp.delete(url)
			.catch (this.handleError);
	}

	private handleError(error: Response): Observable <any> {
		return Observable.throw (error.json().ErrorDescription || 'Server error');
	}

	initializeHose(): IHose {
		return {
			Id: 0,

			Hose: '',
			InsideDiameter: null,
			OutsideDiameter: null,
			HydraulicInsideDiameter: 0,
			DiameterValue: '',
			Compression: null,
			ContinuityTestRequired: false,

			CrimpInstructions: '',
			Notes: '',

			Pressure: null,
			PressureTest: null,
			ProductTypeId: null,
			ProductType: '',
			Description: '',
			IsCrimp: true,

			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IHose;
	}
}
