import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, fromEvent, merge } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { IUserLogin } from '../auth/user-model/user-login';
import { GenericValidator } from '../shared/generic-validator';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef })
	formInputElements: ElementRef[];
	pageTitle = 'Login';
	loginForm: UntypedFormGroup;

	userLogin: IUserLogin;
	errorMessage = '';
	sub: any;
	auth: AuthService;
	passwordflag = false;
	resetPasswordForm: UntypedFormGroup;

	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator: GenericValidator;

	constructor(private authservice: AuthService, private fb: UntypedFormBuilder) {
		this.auth = authservice;
		this.validationMessages = {
			Email: {
				required: 'Email is required.'
			},
			Password: {
				required: 'Password is required.'
			}
		};

		this.genericValidator = new GenericValidator(this.validationMessages);
	}

	ngOnInit(): void {
		this.loginForm = this.fb.group({
			Email: ['', [Validators.required]],
			Password: ['', [Validators.required]]
		});
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$/;
		this.resetPasswordForm = this.fb.group({
			userEmail: ['', [Validators.required, Validators.pattern(emailPattern)]],

		});
	}

	ngAfterViewInit(): void {
		// Watch for the blur event from any input element on the form.
		const controlBlurs: Observable<any>[] = this.formInputElements.map(
			(formControl: ElementRef) =>
				fromEvent(formControl.nativeElement, 'blur')
		);

		// Merge the blur event observable with the valueChanges observable
		merge(this.loginForm.valueChanges, ...controlBlurs)
			.debounceTime(800)
			.subscribe(value => {
				this.displayMessage = this.genericValidator.processMessages(
					this.loginForm
				);
			});
	}

	onLogin(): void {
		if (this.loginForm.dirty && this.loginForm.valid) {
			const user = Object.assign(
				{},
				this.userLogin,
				this.loginForm.value
			);

			this.auth
				.login(user)
				.subscribe(
					userLogin => (this.userLogin = userLogin),
					error => (this.errorMessage = <any>error)
				);
		}
	}
	resetPassword() {
		this.resetPasswordForm.reset();
		this.passwordflag = true;
	}
	goToLogin() {
		this.passwordflag = false;
	}
	sendResetEmail() {
		let userEmail = this.resetPasswordForm.value.userEmail;
		this.auth
			.resetEmail(userEmail)
			.subscribe(
				(response) => (alert(response),
				this.resetPasswordForm.reset()),
				error => (this.errorMessage = <any>error)
			);
	}

}
