import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHose } from '../hose-model/hose';
@Component({
	selector: 'http-hydrostatic'
	, templateUrl: './Hydrostatic.html',
	styles: [`
	 .alert {
		 padding: 8px;
	 }
	`]
})

export class HydrostaticComponent implements OnInit {
	@Input() hoseDetail: IHose;
	@Output() updateHoseDetail: EventEmitter<IHose> = new EventEmitter<IHose>();

	public passFail: any[];
	public errorIntialFt: boolean;
	public errorIntialIn: boolean;
	public errorIntialTpFt: boolean;
	public errorIntialTpIn: boolean;
	public errorIntialReFt: boolean;
	public errorIntialReIn: boolean;
	public errorIntialPoFt: boolean;
	public errorIntialPoIn: boolean;
	public errorMessageInteger: string = "Must be a postivie integer";

	constructor() {
		// console.log('Hydrostatic::constructor');
		this.passFail = [
			{ name: '', value: '' },
			{ name: 'Pass', value: 'Pass' },
			{ name: 'Fail', value: 'Fail' }
		];
	}

	ngOnInit(): void {
		// console.log('Hydrostatic::ngOnInit');
	}

	onblur(): void {
		// console.log('Hydrostatic::onblur');
		this.updateHoseDetail.emit(this.hoseDetail);
	}
	changeInitialFt(val) {
		if (val != "") {
			this.errorIntialFt = this.isNormalInteger(val);
		}
		else {
			this.errorIntialFt = true;
		}
	}
	changeInitialIn(val) {
		if (val != "") {
			this.errorIntialIn = this.isNormalInteger(val);
		}
		else {
			this.errorIntialIn = true;
		}
	}
	changeInitialTpFt(val) {
		if (val != "") {
			this.errorIntialTpFt = this.isNormalInteger(val);
		}
		else {
			this.errorIntialTpFt = true;
		}
	}

	changeInitialTpIn(val) {
		if (val != "") {
			this.errorIntialTpIn = this.isNormalInteger(val);
		}
		else {
			this.errorIntialTpIn = true;
		}
	}
	changeIntialReFt(val) {
		if (val != "") {
			this.errorIntialReFt = this.isNormalInteger(val);
		}
		else {
			this.errorIntialReFt = true;
		}
	}
	changeIntialReIn(val) {
		if (val != "") {
			this.errorIntialReIn = this.isNormalInteger(val);
		}
		else {
			this.errorIntialReIn = true;
		}
	}
	changeIntialPoFt(val) {
		if (val != "") {
			this.errorIntialPoFt = this.isNormalInteger(val);
		}
		else {
			this.errorIntialPoFt = true;
		}
	}
	changeIntialPoIn(val) {
		if (val != "") {
			this.errorIntialPoIn = this.isNormalInteger(val);
		}
		else {
			this.errorIntialPoIn = true;
		}
	}
	isNormalInteger(str): boolean {
		var n = Math.floor(Number(str));
		return String(n) === str && n >= 0;
	}
}
