<div class="row">
    <div class="col-md-6 col-xs-12 image-holder">
        <a [routerLink]="['/welcome']" [routerLinkActive]="['active']">
            <img src="./assets/logo.png" class="img-responsive" />
        </a>
    </div>
</div>

<nav class='navbar navbar-default'>
    <div class="container-fluid">
        <div class="navbar-header">
            <button #navBarToggler (click)="toggleNav()" type="button" class="navbar-toggle" data-target="#mainNavBar">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>

        </div>

        <div id="mainNavBar" class="collapse navbar-collapse" [ngClass]="{ 'in': showNav }">
            <ul class="nav navbar-nav navbar-left">
                <li class="admin" *ngIf="auth.loggedIn()">
                    <a (click)="toggleNav()" class="admin" [routerLink]="['/welcome']">Home</a>
                </li>
            </ul>
            <div>
            
        </div>
            <ul class="nav navbar-nav navbar-right">
                <li class="admin" *ngIf="auth.loggedIn()">
                    <a [routerLink]="['/whmis-document-list']" class="admin">WHMIS</a>
                </li>
                <li class="dropdown admin" *ngIf="auth.loggedIn()">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Hydro Test
                        <span class="caret"></span>
                    </a>

                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/hydrotests']">Hydro Tests</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a [routerLink]="['/calibration']" [routerLinkActive]="['active']">Calibration</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a [routerLink]="['/new-from-crimp']" [routerLinkActive]="['active']">New From Crimp</a>
                        </li>
                    </ul>


                <li class="dropdown admin" *ngIf="auth.loggedIn()">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Training
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/training-videos']">Videos</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/training-document-list']">Documents</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin"  [routerLink] = "['/trainings']">Trainings</a>
                        </li>             
                    </ul>
                </li>
                <li class="dropdown admin" *ngIf="auth.loggedIn()">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Customer Plan
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink] = "['/customerplanrecords']">Customer Plan Records</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink] = "['/customerplanrecord-upload']">Upload Customer Plan Records</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink] = "['/customerplanrecord-export']">Export Customer Plan Records</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown admin" *ngIf="auth.loggedIn()">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Labels
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                             <a href="./images/LocalEIARSetup.msi" download>Local EIAR Communication App</a>
                        </li>

                        <li class="admin" *ngIf="auth.loggedIn()">
                            <button class='btn btn btn-link' style='width: auto;' (click)='help()'>- - - LABEL HELP - - -</button>
                       </li>
                       
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <span>- - - - </span>>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/package-label']">Stock Parts 2 X 4 (soon to be removed)</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/box-label']">Stock Parts 4 X 6 (soon to be removed)</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/non-stock']">Non Stock (soon to be removed)</a>
                        </li>
                        <!-- <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/parts']">Parts</a>
                        </li> -->
                        <!--<li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/printed-parts']">Printed parts (soon to be removed)</a>
                        </li>-->
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <span>- - - - </span>>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/new-package-label']">New Stock Parts 2 X 4</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/new-box-label']">New Stock Parts 4 X 6</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/new-non-stock']">New Non Stock</a>
                        </li>
                         <li class="admin" *ngIf="auth.loggedIn()">
                            <span>- - - - </span>>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/label4by1']">Rack Label 1 x 4</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/label6by2']">Rack Label 2 x 6</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/inventory-label']">Inventory Label</a>
                        </li>
                         <li class="admin" *ngIf="auth.loggedIn()">
                            <span>- - - - </span>>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/averylabels']">Avery Labels</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/customaverylabels']">Custom Avery Labels</a>
                        </li>    
                    </ul>
                </li>

                <li class="dropdown admin" *ngIf="auth.loggedIn()">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Part Management
                        <span class="caret"></span>
                    </a>

                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/hoses']">Hose</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/ferrules']">Ferrule</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/fittings']">Fitting</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/audit-record-view','partmanagement']" >Audit Report</a>
                        </li>
                    </ul>
                </li>
                <li class="admin" *ngIf="auth.loggedIn()">
                    <a (click)="toggleNav()" class="admin" [routerLink]="['/crimp-list']">Crimp</a>
                </li>
                <li class="dropdown admin" *ngIf="(auth.isAdmin()&&auth.loggedIn())||(auth.isAppManagement()&&auth.loggedIn())">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">App Management
                        <span class="caret"></span>
                    </a>

                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/branches']">Branch</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/customers']">Customer</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/videos']">Videos</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/documents']">Documents</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/crimps-report']">Crimp Report - WARNING, HEAVY LOAD</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/partimport']">Part Import</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/customer-import']">Customer Import</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/walkins']">Walk-in</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/walkinsfile']">Walkins File</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/customerconcern-mgmt']">Customer Concern Management</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()" >
                            <a (click)="toggleNav()" class="admin" [routerLink] = "['/branchlabelprinters']"> Printer, PLC and Devices</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" class="admin" [routerLink] = "['/lookuplists']">Lookuplists</a>
						</li>
                        <li class="admin" *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" class="admin" [routerLink] = "['/hydrotestcalibrations']">Hydrotestcalibrations</a>
						</li>
                        <li *ngIf="auth.loggedIn() && auth.isInRoles('HelpAdmin')">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/helps']">Help Creator</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/partatts']">Partatts</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/parts']">Parts</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/automations']">Automations</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/vprodinvents']">Product Inventory</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
                           <span> - </span>
                        </li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/binlabelcats']">Bin Label Categories</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/labelsmenu']">Labels Menu</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink]="['/customerlabelscreator']">Customer Label Creator</a>
                        </li> 
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/labelnextsteps']">Label Next Steps</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/productdata-menu']">Label Data Processing</a>
						</li>
                        <li *ngIf="auth.loggedIn()">
							<a (click)="toggleNav()" *ngIf="auth.loggedIn()" [routerLink] = "['/hydraulicfittingscategorie-menu']">Hydraulic Fittings Categories Menu</a>
						</li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/labelpropertyconfigs']">Label Property Configs</a>
                        </li>
                        <li *ngIf="auth.loggedIn()">
                            <span> - </span>
                         </li>
                         <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/printers-ip-list']">Printer IP List (Test API)</a>
                        </li>
                       
                    </ul>
                </li>
                <li class="dropdown admin" *ngIf="(auth.isAdmin()&&auth.loggedIn())||(auth.isAppManagement()&&auth.loggedIn())">
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Apps
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/developmenttasks']">Development Tasks</a>
                        </li>
                        <li class="admin" *ngIf="auth.loggedIn()">
                            <a (click)="toggleNav()" class="admin" [routerLink]="['/millcerts']">Mill Certs</a>
                        </li>
                    </ul>
                </li>
                <li class="admin" *ngIf="auth.loggedIn()">
                    <a (click)="toggleNav()" class="admin" [routerLink]="['/customerconcerns']">Customer Concerns</a>
                </li>

                <li class="dropdown admin" *ngIf="auth.loggedIn()">
                <!-- <li class="dropdown admin" *ngIf="auth.isAdmin()&&auth.loggedIn()"> -->
                    <a class="dropdown-toggle admin" data-toggle="dropdown" href="javascript:void(0)">Admin
                        <span class="caret"></span>
                    </a>

                    <ul class="dropdown-menu">
                        <li>
                            <a (click)="toggleNav()" [routerLink]="['/aspnetusers']" class="admin">Users</a>
                        </li>
                        <li>
                            <a (click)="toggleNav()" [routerLink]="['/roles']" class="admin">Roles</a>
                        </li>
                        <li>
							<a>--------</a>
						</li>
                    </ul>
                </li>

                <li class="admin" *ngIf="auth.loggedIn()">
                    <a class="admin" data-toggle="dropdown" href="javascript:void(0)">
                        <span class='glyphicon glyphicon-user'></span> {{ auth.getEmail() }}
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a [routerLink]="['/change-password']" [routerLinkActive]="['active']">
                                Change Password
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="admin">
                    <a (click)="toggleNav()" class="admin" *ngIf="!auth.loggedIn()" [routerLink]="['/login']">Login</a>
                    <a (click)="toggleNav()" class="admin" *ngIf="auth.loggedIn()" (click)="auth.logout()" [routerLink]="['/welcome']">Log
                        Off</a>
                </li>

            </ul>

        </div>
    </div>
</nav>