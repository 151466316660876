import { ApplicationSettings } from '../../../shared/application-settings';
//import { SpinnerService } from '../../../bill-of-lading-legacy/shared/spinner/spinner.service';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IHoseSearch } from '../hose.model/hoseSearch';
import { IUsersearch } from 'src/app/admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from 'src/app/admin/usersearches/usersearch-service';
import { retry, catchError, map } from 'rxjs/operators';
import { IHoses } from '../hose.model/hoses';

@Injectable()
export class HoseGridService {
	private baseUrl;
	pageTitle = 'Hose List';
	constructor(
		private authHttp: HttpClient,
		public userSearchService: UsersearchService,
		private settings: ApplicationSettings) {

		this.baseUrl = this.settings.retrieveUrl() + '/api/Hose/GetHoses';
	}

	public getFreshSearch() : IHoseSearch { 
		let search: IHoseSearch = {
			Hose: '',
			ProductType: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	public getRecordSet(search: IHoseSearch): Observable<IHoses> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('hose', search.Hose, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('productType', search.ProductType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('take', search.take.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('hose', search.Hose.toString())
			.set('productType', search.ProductType.toString())
			.set('sort', search.orderByString)
		};

		return this.authHttp.get<IHoses>(this.baseUrl, options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IHoses>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}


}
