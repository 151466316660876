import { Component } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { BinlabelcatService } from 'src/app/binlabelcats/binlabelcat-service';
import { LabelsmenuService } from '../labelsmenu-service';
import { PartcatService } from 'src/app/partcats/partcat-service';

@Component({
	templateUrl: 'labelsmenu.component.html',
	styleUrls: ['labelsmenu.component.css']
})

export class LabelsmenuComponent {

	public errorMessage = '';
	binLabelCatList: any;
	labelTag: any;
	vendorName: any;
	pLine: any;
	menutab = '';
	vendorsList: any;
    pLineList:any;
	rackBinPLine:any;
	public progressStatus=false;
    tagList:any;
	priceLineList:any;
	pLineXOver:any;
    pdfLinkList:any;
    prodCatFileStatus:any;
	prodGroupFileStatus:any;
	reqCntProdCat=0;
	reqCntProdGroup=0;

	constructor(public applicationSettings: ApplicationSettings
		, private binlabelcatService: BinlabelcatService
		, private labelsmenuService: LabelsmenuService
		, private partcatService:PartcatService
	) {
	}
	ngOnInit() {
		this.getBinLabelCatData();
		this.getVendorsList();
		this.getTagList();
		this.getPriceLineList();
	}

	getBinLabelCatData() {
		this.binlabelcatService.getBinLabelCatForList().subscribe(
			(val) => {
				this.binLabelCatList = val;
				this.pLineList = this.binLabelCatList.filter(
					(thing, i, arr) => arr.findIndex(t => t.PLine === thing.PLine) === i
				  );
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);

	}
	createLabelByProductCategory() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/pdflabels/';
		this.labelsmenuService.createLabelByProductCategory(this.labelTag,'one',0,false).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	getVendorsList() {
		this.labelsmenuService.getVendorsList().subscribe(
			(val) => {
				this.vendorsList = val;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	createLabelByPLXOver() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
		this.labelsmenuService.createLabelByPLXOver(this.pLineXOver,this.vendorName).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	
	mainmenufunt(value){
		this.menutab=value;
	}


	resetError(): void {
		this.errorMessage = '';
		this.progressStatus = false;
	}
	getTagList() {
		this.partcatService.getTagList().subscribe(
			(val) => {
				this.tagList=val;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);

	}
	getPriceLineList() {
		this.partcatService.getPriceLineList().subscribe(
			(val) => {
				this.priceLineList=val;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);

	}
	createLabelByProductGroup() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/pdflabels/';
		this.labelsmenuService.createLabelByProductGroup(this.pLine,'one',0,false).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	// generateAllProdCatRpt() {
	// 	this.reqCntProdCat=0;
	// 	this.getAllProdCatRpt();		
	// }
	// getAllProdCatRpt(){
	// 	this.progressStatus=true;
	// 	this.labelsmenuService.createLabelByProductCategory(this.tagList[this.reqCntProdCat].Tag).subscribe(
	// 		(val: string) => {
	// 			this.reqCntProdCat=this.reqCntProdCat+1;
	// 			this.prodCatFileStatus='';
	// 			this.prodCatFileStatus= val+ ' file is created!';
	// 			console.log(val+ ' file is created!');		
	// 			this.progressStatus=false;		
	// 			if(this.reqCntProdCat<this.tagList.length){
	// 				this.getAllProdCatRpt();
	// 			}
					
	// 		},
	// 		(error: any) => {
	// 			this.errorMessage = <any>error;
	// 		}
	// 	);
	// }
	// generateAllProdGroupRpt() {
	// 	this.reqCntProdGroup=0;
	// 	this.getAllProdGroupRpt();		
	// }

	// getAllProdGroupRpt(){
	// 	this.progressStatus=true;
	// 	this.labelsmenuService.createLabelByProductGroup(this.priceLineList[this.reqCntProdGroup].PriceLine).subscribe(
	// 		(val: string) => {
	// 			this.reqCntProdGroup=this.reqCntProdGroup+1;
	// 			this.prodGroupFileStatus='';
	// 			this.prodGroupFileStatus=val+ ' file is created!';
	// 			console.log(val+ ' file is created!');		
	// 			this.progressStatus=false;		
	// 			if(this.reqCntProdGroup<this.priceLineList.length){
	// 				this.getAllProdGroupRpt();
	// 			}
	// 		},
	// 		(error: any) => {
	// 			this.errorMessage = <any>error;
	// 		}
	// 	);
	// }

	
}