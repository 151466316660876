import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomerPlanrecord } from '../customerplanrecord-model/customerplanrecord';

import { Subscription } from 'rxjs/Subscription';
import { CustomerPlanrecordService } from '../customerplanrecord-service';
import { ICustomerPlanrecords } from '../CustomerPlanrecord-model/CustomerPlanrecords';
import { ICustomerPlanrecordSearch } from '../CustomerPlanrecord-model/CustomerPlanrecordSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	templateUrl: './customerplanrecord-edit.component.html',
	styleUrls: ['customerplanrecord-edit.component.css']
})

export class CustomerPlanrecordEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	displayedColumns: string[] = ['Id', 'CustomerPlanRecordId', 'PartNumber', 'Revision', 'CustomerPlanMonth', 'Quantity','EclipseId'];


	public page = 'Edit Customer Plan Records';
	errorMessage: string;
	customerplanrecordForm: UntypedFormGroup;
	customerplanrecord: ICustomerPlanrecord;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public CustomerPlanrecordSearch: ICustomerPlanrecordSearch;
	public ListData: ICustomerPlanrecords;
	public showNextRecordButton: boolean = true;
	public dataSource: any;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private customerplanrecordService: CustomerPlanrecordService,
	) {
	}

	ngOnInit(): void {
		this.customerplanrecordForm = this.fb.group({
			Id: '',
			FileName: '',
			Customer: '',
			Notes: '',
			FileMonth: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getCustomerPlanrecord(id);
			}
		);
	}

	public getSelectedRecordSet(): void {
		this.customerplanrecordService.getRecordSet(this.CustomerPlanrecordSearch)
			.subscribe((obj: ICustomerPlanrecords) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: ICustomerPlanrecords) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.customerplanrecord.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveCustomerPlanrecord(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.customerplanrecordForm.valid) {
			const obj = Object.assign({}, this.customerplanrecord, this.customerplanrecordForm.value);
			this.customerplanrecordService.saveCustomerPlanrecord(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.customerplanrecordForm.reset();
			this.router.navigate(['/customerplanrecords']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.customerplanrecord.Id === 0) {
			this.router.navigate(['/customerplanrecord-details', data.Id]);
		}
		else if (this.customerplanrecord.Id != 0) {
			this.getCustomerPlanrecord(this.customerplanrecord.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.customerplanrecord.Id)
				record = count;
		});

		this.getCustomerPlanrecord(this.ListData.data[record].Id)
	}

	getCustomerPlanrecord(id: number): void {
		this.customerplanrecordService.getCustomerPlanrecord(id)
			.subscribe((obj: ICustomerPlanrecord) => this.onCustomerPlanrecordRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onCustomerPlanrecordRetrieved(customerplanrecord: ICustomerPlanrecord): void {
		if (this.customerplanrecordForm) {
			this.customerplanrecordForm.reset();
		}
		this.customerplanrecord = customerplanrecord;
		if (this.customerplanrecord.Id === 0) {
			this.page = 'Add Customer Plan Records';
		} else {
			this.page = 'Edit Customer Plan Records';
			this.getCustomerPlanDetails(this.customerplanrecord.Id);
		}

		this.customerplanrecordForm.patchValue({
			Id: this.customerplanrecord.Id,
			FileName: this.customerplanrecord.FileName,
			Customer: this.customerplanrecord.Customer,
			Notes: this.customerplanrecord.Notes,
			FileMonth: this.customerplanrecord.FileMonth,
			CreatedDate: this.customerplanrecord.CreatedDate,
			CreatedBy: this.customerplanrecord.CreatedBy,
			UpdatedDate: this.customerplanrecord.UpdatedDate,
			UpdatedBy: this.customerplanrecord.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteCustomerPlanrecord(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.customerplanrecord.FileName}?`)) {
			this.customerplanrecordService.deleteCustomerPlanrecord(this.customerplanrecord.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/customerplanrecords']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Customer Plan Records List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.CustomerPlanrecordSearch = this.customerplanrecordService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'FileName')
				this.CustomerPlanrecordSearch.FileName = i.Value;

			if (i.Item == 'Customer')
				this.CustomerPlanrecordSearch.Customer = i.Value;

			if (i.Item == 'Notes')
				this.CustomerPlanrecordSearch.Notes = i.Value;

			if (i.Item == 'FileMonth')
				this.CustomerPlanrecordSearch.FileMonth = i.Value;

			if (i.Item == 'orderByString')
				this.CustomerPlanrecordSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.CustomerPlanrecordSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	getCustomerPlanDetails(Id) {
		this.customerplanrecordService.getCustomerPlanDetailsData(Id)
			.subscribe((response) => {
				this.dataSource = new MatTableDataSource(response);


			}
				, (error: any) => this.errorMessage = <any>error);
	}
}
