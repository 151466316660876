import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, FormControlName, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { IRole } from 'src/app/admin/roles/role-model/role';
import { ApplicationSettings } from '../../../shared/application-settings';
import { GenericValidator } from '../../../shared/generic-validator';
//import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { IUser } from '../user-model/user';
import { UserService } from '../user-service/user.service';
import { BrancheService } from '../../branches/branche-service';
import { IBranche } from '../../branches/Branche-model/Branche';
import { UntypedFormControl } from '@angular/forms';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'user-edit',
	templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef })
	formInputElements: ElementRef[];

	//spinner: SpinnerService;
	baseUrl: string;
	showCreateSection = false;
	editUserForm: UntypedFormGroup;
	pageTitle = 'Edit User';
	errorMessage = '';
	public branchs: IBranche[];

	user = <IUser>{};
	private sub: Subscription;
	selectedRole='';
	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator: GenericValidator;

	public branchId: number;
	// Autocomplete data
	public roleNames: string[] = [];
	public roleNamesFiltered: string[];
	
	formControlRole = new UntypedFormControl();
	urldatausers:any;
	urldatasignature:any;
	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private settings: ApplicationSettings,
		//private spinnerservice: SpinnerService,
		private userService: UserService,
		private branchService: BrancheService
	) {
		//this.spinner = spinnerservice;
		this.validationMessages = {
			Email: {
				required: 'Email is required.'
			},
			FirstName: {
				required: 'First Name is required.'
			},
			LastName: {
				required: 'Last Name is required.'
			},
			Password: {
				required: 'Password is required.'
			}
		};
		this.baseUrl = this.settings.retrieveUrl();
	}

	ngOnInit(): void {
		this.getBranches();
		//this.spinner.start();
		this.editUserForm = this.fb.group({
			Username: { value: ['', [Validators.required]], disabled: true },
			FirstName: ['', [Validators.required]],
			LastName: ['', [Validators.required]],
			Email: ['', [Validators.required]],
			Initials: '',
			Title: '',
			Address: '',
			City: '',
			Region: '',
			PostalCode: '',
			HomePhone: '',
			CellPhone: '',
			BirthDate: '',
			HireDate: '',
			EmergencyName: '',
			EmergencyPhone: '',
			Notes: '',
			Active: '',
			Image: '',
			Signature: '',
			Branch: 0,
			Password: ['', [Validators.required]],
			Id:''
			// ConfirmPassword: '',
		});

		this.genericValidator = new GenericValidator(this.validationMessages);

	
	}

	ngAfterViewInit(): void {
		// Watch for the blur event from any input element on the form.
		const controlBlurs: Observable<any>[] = this.formInputElements.map(
			(formControl: ElementRef) =>
				Observable.fromEvent(formControl.nativeElement, 'blur')
		);

		// Merge the blur event observable with the valueChanges observable
		Observable.merge(this.editUserForm.valueChanges, ...controlBlurs)
			.debounceTime(800)
			.subscribe(value => {
				this.displayMessage = this.genericValidator.processMessages(
					this.editUserForm
				);
			});
	}

	getBranches() {
		this.branchService.getAllBranches('')
			.subscribe(res => {
				this.branchs = res;
				this.sub = this.route.params.subscribe(params => {
					const id = params['Id'];
					this.getUser(id);
				});
			
			},
				(error: any) => this.errorMessage = <any>error);
	}

	 branchChange(id) {
	 	this.branchId = id;
	 }

	editUser(con: boolean): void {
		if (this.editUserForm.dirty && this.editUserForm.valid) {

			let userEdited = Object.assign(
				{},
				this.user,
				this.editUserForm.value
			);
			userEdited.BranchId = this.branchId;
			// console.log(userEdited);
			this.userService
				.updateUser(userEdited)
				.subscribe(
					() => this.onSaveComplete(con),
					(error: any) => (this.errorMessage = <any>error)
				);
		} else if (!this.editUserForm.dirty) {
			this.onSaveComplete(con);
		}
	}

	passwordCheck(): boolean {
		const password = this.editUserForm.controls['Password'].value;
		const confirmPassword = this.editUserForm.controls['ConfirmPassword']
			.value;

		if (password === confirmPassword) {
			return false;
		}
		return true; // returns true if passwords are not the same
	}

	onSaveComplete(con: boolean): void {
		if (con) {
			this.getBranches();
			this.editUserForm.reset();
			this.router.navigate(['/aspnetusers']);
		}
	}

	getUser(id: string): void {
		//console.log(this.branchs);
		//console.log('getting the user');

		this.userService.getUser(id).subscribe(
			(user: IUser) => this.onUserRetrieved(user),
			(error: any) => {
				this.errorMessage = <any>error;
				//this.spinner.stop();
			}
		);
	}

	onUserRetrieved(user: IUser): void {
		this.user = user;
		this.getRoles(this.user.UserId);

		this.editUserForm.patchValue({
			Id: this.user.UserId,
			Username: this.user.UserName,
			FirstName: this.user.FirstName,
			LastName: this.user.LastName,
			Email: this.user.Email,
			Initials: this.user.Initials,
			Title: this.user.Title,
			Address: this.user.Address,
			City: this.user.City,
			Region: this.user.Region,
			PostalCode: this.user.PostalCode,
			HomePhone: this.user.HomePhone,
			CellPhone: this.user.CellPhone,
			BirthDate: this.user.BirthDate,
			HireDate: this.user.HireDate,
			EmergencyName: this.user.EmergencyName,
			EmergencyPhone: this.user.EmergencyPhone,
			Notes: this.user.Notes,
			Active: this.user.Active,
			Image: this.user.Image,
			Signature: this.user.Signature,
			Branch: this.user.BranchId,
			Password: this.user.Password
		});
		this.branchId = this.user.BranchId;
		//this.spinner.stop();
		this.urldatausers=this.settings.retrieveUrl()+'/Files/Users/'+this.user.Image;
		this.urldatasignature=this.settings.retrieveUrl()+'/Files/Signature/'+this.user.Signature;
	}

	toggleCreateSection(): void {
		this.showCreateSection = !this.showCreateSection;
	}

	removeFromRole(role: string): void {
		if (
			confirm(`Are you sure you want to remove user from ${role} role?`)
		) {
			const roleToDeleteFrom = <IRole>{
				Id: this.user.UserId,
				Name: role
			};
			this.userService
				.removeFromRole(roleToDeleteFrom)
				.subscribe(
					response => this.refreshRolesTable(),
					error => (this.errorMessage = <any>error)
				);
		}
	}

	addToRole(): void {
		const roleToAdd = <IRole>{
			Id: this.user.UserId,
			Name: this.selectedRole
		};
		this.userService
			.addToRole(roleToAdd)
			.subscribe(
				response => this.refreshRolesTable(),
				error => (this.errorMessage = <any>error)
			);
	}

	refreshRolesTable(): void {
		this.selectedRole = "";
		this.formControlRole.setValue("");
		this.userService
		.getUserRoles(this.user.UserId)
		.subscribe(
			roles => {
			    this.user.Roles=roles;
			},
			error => (this.errorMessage = <any>error)
		);
		this.getRoles(this.user.UserId);
		//this.getUser(this.user.UserId);
	}

	getRoles(role: string): void {
		
		this.userService
			.getRoles(role)
			.subscribe(
				roles => (this.roleNames = roles),
				error => (this.errorMessage = <any>error)
			);
	}

		// Autocomplete filtering
		handleFilterRoles(value) {
			this.roleNamesFiltered = this.roleNames.filter(
				s => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
			);
		}
	
		handleValue(): void {
			console.log(this.formControlRole.value);
			this.selectedRole = this.formControlRole.value;
		}
		onFileSelected(event: any,location) {
			const target = event.target as HTMLInputElement;
			if (target.files && target.files.length > 0) {
			  console.log(target.files[0].name);
		   
			const formData = new FormData();
			formData.append('fileName', target.files[0]);
			formData.append('Id', this.editUserForm.value.Id.toString());
			formData.append('Location', location);
			
			this.userService.addFile(formData).subscribe(
			  retVal => {
				if (retVal !== "") {
				  alert("File Uploaded");
				  console.log("File Uploaded");
				  if(location=="Users"){
					this.urldatausers="";
					this.editUserForm.patchValue({
						Image: retVal
					  });				
					this.urldatausers=this.settings.retrieveUrl()+'/Files/'+location+'/'+retVal;
				  }
				 else if(location=="Signature"){
					this.urldatasignature="";
					this.editUserForm.patchValue({
						Signature: retVal
					  });				
					this.urldatasignature=this.settings.retrieveUrl()+'/Files/'+location+'/'+retVal;
				 }
				}
				else {
					if(location=="Users"){
						this.editUserForm.patchValue({
							Image: ''
						  });
						  this.urldatausers="";
					}
					else if(location=="Signature"){
						this.editUserForm.patchValue({
							Signature: ''
						  });
						  this.urldatasignature="";
					}
				  alert("File Not Uploaded");
				 
				}
			  }
			  ,(error: any) => this.errorMessage = <any>error
			);
		  }	
		}
}
