import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { CustomerPlanrecordService } from '../customerplanrecord-service';

@Component({
  selector: 'app-customerplanrecord-export',
  templateUrl: './customerplanrecord-export.component.html',
  styleUrls: ['./customerplanrecord-export.component.css']
})
export class CustomerPlanrecordExportComponent implements OnInit {

  
  errorMessage: string;


  public partsWithNumbers:string;
  public partsWithoutNumbers:string;
  public partsWithKits:string;
  public partsWithoutKits:string;


  constructor(
     private customerplanrecordService: CustomerPlanrecordService,
     public applicationSettings: ApplicationSettings
  ) { }

  ngOnInit(): void {
   
  }
  

  resetError() : void {
		this.errorMessage = '';
	}
  exportData(){
   
      this.customerplanrecordService.exportDataToCSV()
      .subscribe(resp=>{
        if(resp!=''){
          window.open(`${this.applicationSettings.retrieveUrl()}${resp}`);
        }
        else{
          alert("Records not available!");
        }
      })
    
   
  }
  getPartNumbers(){
   
    this.customerplanrecordService.getPartNumbers()
    .subscribe(resp=>{
      console.log(resp);
      this.partsWithNumbers=resp[0].PartsWithNumbers;
      this.partsWithoutNumbers=resp[0].PartsWithoutNumbers;
    })
  }
    getPartKits(){
   
      this.customerplanrecordService.getPartKits()
      .subscribe(resp=>{
        this.partsWithKits=resp[0].PartsWithKits;
        this.partsWithoutKits=resp[0].PartsWithoutKits;
      })
    }

}
