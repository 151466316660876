
<div class='panel panel-primary animaPlaceholder' *ngIf = 'millcert'>
	<div class='panel-heading'>
		<span *ngIf = 'millcert' >{{ page }} :{{ millcert.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
			<mat-tab-group>
			<mat-tab style="color: #101bb3;" label = "Mill Cert">
		<form class='form-horizontal' novalidate [formGroup]='millcertForm'>
			<fieldset>
			<div class='panel-body'>
				<div class="row">
					<div class="col-md-6">

					
					<div class='form-group'>
						<label class='col-md-2 control-label'> Supplier Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='SupplierName' />
							<span *ngIf = "millcertForm.controls['SupplierName'].invalid  && millcertForm.controls['SupplierName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Invoice Number</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='InvoiceNumber' />
							<span *ngIf = "millcertForm.controls['InvoiceNumber'].invalid  && millcertForm.controls['InvoiceNumber'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Po Number</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='PoNumber' />
							<span *ngIf = "millcertForm.controls['PoNumber'].invalid  && millcertForm.controls['PoNumber'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Container Airway Bill</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContainerAirwayBill' />
							<span *ngIf = "millcertForm.controls['ContainerAirwayBill'].invalid  && millcertForm.controls['ContainerAirwayBill'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
				</div>
			</div>
			</div>
			</fieldset>
		</form>
			</mat-tab>
			<mat-tab label="Files">
			<div *ngIf = "millcertId" >
			 <div class='panel-body'>
			<form [formGroup]='filesForm' novalidate
			style = "display: flex;flex-direction: column;row-gap: 10px; ">
			<div>
			<div class="col-md-2">
				<label class='control-label'>File Upload:</label>
			 </div>
			 <div class="col-md-4">
				<input class="form -control" type="file" #inputFile
			 (change)="onFileSelected($event)">
				<div *ngIf = "fileToEdit && fileToEdit.Id" >
				{{fileToEdit.FileName}}
			 </div>
			 <div *ngIf = "fileFormSubmitted && filesForm.controls.fileUpload.errors?.required" >
			 <span style="color:red"> Required </span></div>
			 </div>
			 </div>
			 <div>
			 <div class="col-md-2">
			 <label class=''> File Description </label>
			 </div>
			 <div class="col-md-4">
			 <input class="form-control" type ="text" formControlName = 'fileDescription' >
			 </div>
			 </div>
			 <div>
			 <div class="col-md-2">
			 <label class='control-label'> Sort Order </label>
			  </div>
			 <div class="col-md-4">
			 <input class="form-control" type = "number" formControlName = 'sortOrder' >
			 </div>
			 </div>
			 <div class= 'form-group' style ="display:flex;align-items: center;" >
			 <label class='col-md-2 control-label' > Include in Report </label>
			 <div class="col-md-1 text-left">
			  <input style = "width:34px;" class= "form-control" type = "checkbox"
			 formControlName = 'includeReport' >
			 </div>
			 </div>
			 <div class="col-md-6 form-group">
			 <button class="btn btn-primary form-control center-center"
			 style ="width: 10vw;float: right;" type = "button"(click)= "onSaveFile()" > Save
			 File </button>
			 </div>
			 </form>
			 </div>
			 <div>
			  <table mat-table [dataSource] = "dataSource" class= 'mat-elevation-z8 table-wdt' matSort >
			   <ng-container matColumnDef="Edit">
			     <th mat-header-cell *matHeaderCellDef class='col1' > EDIT </th>
			     <td mat-cell *matCellDef ="let element"> <button class= 'btn btn-default'
			     style= 'min-width: 50px;' (click) ="editFileData(element)" > <span style="color: rgb(4, 50, 255);"
			     class="glyphicon glyphicon-edit"> EDIT </span ></button></td>
			  </ng-container>
			  <ng-container matColumnDef="FileName" >
			  <th mat-header-cell *matHeaderCellDef class='col2'> File Name </th>
			  <td mat-cell *matCellDef = "let element" >
			    <a href ="javascript:void(0)"
			     (click) = "open(element)" > {{element.FileName}}
			    </a>
			  </td>
			 </ng-container>
			  <ng-container matColumnDef="FileDescription" >
			  <th mat-header-cell *matHeaderCellDef class= 'col4' > Description </th>
			  <td mat-cell *matCellDef = "let element" > {{ element.FileDescription}} </td>
			  </ng-container>
			  <ng-container matColumnDef ="SortOrder">
			  <th mat-header-cell *matHeaderCellDef class= 'col5' > Sort Order </th>
			  <td mat-cell *matCellDef="let element" > {{ element.SortOrder}} </td>
			  </ng-container>
			   <ng-container matColumnDef="IncludeInReport">
			   <th mat-header-cell *matHeaderCellDef class= 'col6' > Include In Report</th>
			   <td mat-cell *matCellDef ="let element" > {{element.IncludeInReport === 1 ? 'True' :
			   'False'}}
			   </td>
			   </ng-container>
			  <ng-container matColumnDef="Delete" >
			  <th mat-header-cell *matHeaderCellDef class= 'col7' > Delete </th>
			  <td mat-cell *matCellDef ="let element" >
			  <button class='btn btn-default btn-xs'
			  style ='min-width: 50px;background: none;border: none;'
			  (click) = "deleteFileData(element.Id)" >
			  <span class= "glyphicon glyphicon-trash text-success text-dlt" ></span>
			  </button>
			  </td>
			  </ng-container>
			 <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
			 <tr mat-row *matRowDef ="let row; columns: displayedColumns;" ></tr>
			 </table>
			 <mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
			 </div>
			 </div>
			 </mat-tab>
			 </mat-tab-group>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveMillcert(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!millcertForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveMillcert(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!millcertForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveMillcert(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!millcertForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!millcertForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteMillcert()'
								style='width: 150px; margin-right:10px'
								[disabled]='!millcertForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='millcert'>{{millcert.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='millcert'>{{millcert.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>