import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	templateUrl: './developmenttask-help.component.html',
	styleUrls: ['./developmenttask-help.component.css']
})

export class DevelopmenttaskHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'DevelopmentTasks Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}