import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { BrancheService } from 'src/app/admin/branches/branche-service';
import { UserService } from 'src/app/admin/users/user-service/user.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LookuplistService } from 'src/app/lookuplists/lookuplist-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { IDevelopmenttaskSearch } from '../Developmenttask-model/DevelopmenttaskSearch';
import { IDevelopmenttasks } from '../Developmenttask-model/Developmenttasks';
import { DevelopmenttaskFileDto } from '../developmenttask-model/DevelopmenttaskFileDto';
import { IDevelopmenttask } from '../developmenttask-model/developmenttask';
import { DevelopmenttaskService } from '../developmenttask-service';

@Component({
	templateUrl: './developmenttask-edit.component.html',
	styleUrls: ['developmenttask-edit.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class DevelopmenttaskEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	displayedColumns: string[] = ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport', 'Delete'];
	dispColDevTaskHrs: string[] = ['Hours', 'Date', 'User', 'Delete'];
	dispColDevTaskCosts: string[] = ['Name', 'Description', 'Price', 'Qty', 'Delete'];
	public page = 'Edit Development Task';
	errorMessage: string;
	developmenttaskForm: UntypedFormGroup;
	devTaskHoursForm: UntypedFormGroup;
	devTaskCostsForm: UntypedFormGroup;
	developmenttask: IDevelopmenttask;
	private sub: Subscription;
	public filesForm: UntypedFormGroup
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public DevelopmenttaskSearch: IDevelopmenttaskSearch;
	public ListData: IDevelopmenttasks;
	public showNextRecordButton: boolean = true;

	public fileSaved: boolean = false;
	public buttonName: any = 'Save File';
	public filename: any = '';
	public show: boolean = false;
	public showButtonName: any = 'Add New File';
	fileFormSubmitted: boolean = false;
	fileToEdit: DevelopmenttaskFileDto;
	public dataSource: any;
	developmenttaskId: number;
	status = '';
	statuses = [
		'Open',
		'Complete',
		'Online'
	]
	public employees;
	public branches;
	public statuslist;
	public applicationlist;
	public dSDevTaskHours: any;
	public dSDevTaskCosts: any;

	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true }) firstPaginator: MatPaginator;
	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private developmenttaskService: DevelopmenttaskService
		, public branchService: BrancheService
		, public userService: UserService
		, public auth: AuthService
		, public lookuplistService: LookuplistService
	) {
	}

	ngOnInit(): void {
		this.developmenttaskForm = this.fb.group({
			Id: '',
			Name: ['', [Validators.maxLength(250)]],
			BranchId: '',
			Description: ['', [Validators.maxLength(4000)]],
			EstimatedHours: '',
			Priority: '',
			Application: '',
			Type1: '',
			Type2: '',
			Type3: '',
			Notes: ['', [Validators.maxLength(4000)]],
			InstalledDate: '',
			Owner: '',
			AssignedUser: '',
			nextaction: ['', [Validators.maxLength(4000)]],
			DevNotes: ['', [Validators.maxLength(4000)]],
			Status: '',
			ScheduledDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			EmpEmailRpt: '',
			OwnerEmailRpt: '',
			FilesAndTables: ''
		});

		this.filesForm = this.fb.group({
			fileUpload: ['', Validators.required],
			fileDescription: ['', [Validators.maxLength(500)]],
			sortOrder: [''],
			includeReport: [''],
		})
		this.devTaskHoursForm = this.fb.group({
			Hours: ['', Validators.required],
			Date: ['', Validators.required],
			User: ['', Validators.required],
		})
		this.devTaskCostsForm = this.fb.group({
			Name: ['', Validators.required],
			Description: ['', Validators.required],
			Price: ['', Validators.required],
			Qty: ['', Validators.required],
		})
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.developmenttaskId = id;
				this.getAllEmployees();
				this.getDevelopmenttask(id);
			}
		);
		this.branchService.getAllBranchesForDropdown().subscribe(response => {
			console.log('list of branches:');
			console.log(response);
			this.branches = response;
		});
		this.getStatus();
		this.getApplication();

	}
	getStatus() {
		this.lookuplistService.getLookupListByForm('Development Tasks', 'Status').subscribe(response => {
			this.statuslist = response;
		});
	}
	getApplication() {
		this.lookuplistService.getLookupListByForm('Development Tasks', 'Application').subscribe(response => {
			this.applicationlist = response;
		});
	}
	getAllEmployees() {
		this.userService.getAllUsers('').subscribe(response => {
			this.employees = response;
			if (this.developmenttaskId == 0) {
				var username = this.auth.getUsername();
				let currentuser = this.employees.find(x => x.UserName == username);
				this.developmenttaskForm.patchValue({
					Owner: currentuser.Id,
				});
			}
		});
	}
	public getSelectedRecordSet(): void {

		this.developmenttaskService.getRecordSet(this.DevelopmenttaskSearch)
			.subscribe((obj: IDevelopmenttasks) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IDevelopmenttasks) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.developmenttask.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveDevelopmenttask(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.developmenttaskForm.valid) {
			const obj = Object.assign({}, this.developmenttask, this.developmenttaskForm.value);
			this.developmenttaskService.saveDevelopmenttask(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.developmenttaskForm.reset();
			this.router.navigate(['/developmenttasks']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.developmenttask.Id === 0) {
			this.router.navigate(['/developmenttask-details', data.Id]);
		}
		else if (this.developmenttask.Id != 0) {
			this.getDevelopmenttask(this.developmenttask.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.developmenttask.Id)
				record = count;
		});

		this.getDevelopmenttask(this.ListData.data[record].Id)
	}

	getDevelopmenttask(id: number): void {
		this.developmenttaskService.getDevelopmenttask(id)
			.subscribe((obj: IDevelopmenttask) => this.onDevelopmenttaskRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onDevelopmenttaskRetrieved(developmenttask: IDevelopmenttask): void {
		if (this.developmenttaskForm) {
			this.developmenttaskForm.reset();
		}
		this.developmenttask = developmenttask;
		if (this.developmenttask.Id === 0) {
			this.page = 'Add DevelopmentTasks';
		} else {
			this.page = 'Edit DevelopmentTasks';
		}

		this.developmenttaskForm.patchValue({
			Id: this.developmenttask.Id,
			Name: this.developmenttask.Name,
			BranchId: this.developmenttask.BranchId,
			Description: this.developmenttask.Description,
			EstimatedHours: this.developmenttask.EstimatedHours,
			Priority: this.developmenttask.Priority,
			Application: this.developmenttask.Application,
			Type1: this.developmenttask.Type1,
			Type2: this.developmenttask.Type2,
			Type3: this.developmenttask.Type3,
			Notes: this.developmenttask.Notes,
			InstalledDate: this.developmenttask.InstalledDate,
			Owner: this.developmenttask.OwnerId,
			AssignedUser: this.developmenttask.AssignedUserId,
			nextaction: this.developmenttask.nextaction,
			DevNotes: this.developmenttask.DevNotes,
			Status: this.developmenttask.Status,
			ScheduledDate: this.developmenttask.ScheduledDate,
			FilesAndTables: this.developmenttask.FilesAndTables,
			CreatedDate: this.developmenttask.CreatedDate,
			CreatedBy: this.developmenttask.CreatedBy,
			UpdatedDate: this.developmenttask.UpdatedDate,
			UpdatedBy: this.developmenttask.UpdatedBy,
		});

		this.getUserSearchData();
		this.getAllDevTaskHours();
		this.getAllDevTaskCosts();
	}

	ngOnDestroy(): void {
	}

	deleteDevelopmenttask(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.developmenttask.Name}?`)) {
			this.developmenttaskService.deleteDevelopmenttask(this.developmenttask.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/developmenttasks']);
	}

	showHide(): void {
		this.show = !this.show;
		// CHANGE THE NAME OF THE BUTTON.
		if (this.show)
			this.showButtonName = 'Close';
		else
			this.showButtonName = 'Add New File';
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Development Tasks List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.DevelopmenttaskSearch = this.developmenttaskService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.DevelopmenttaskSearch.Name = i.Value;

			if (i.Item == 'BranchId')
				this.DevelopmenttaskSearch.BranchId = i.Value;

			if (i.Item == 'Description')
				this.DevelopmenttaskSearch.Description = i.Value;

			if (i.Item == 'EstimatedHours')
				this.DevelopmenttaskSearch.EstimatedHours = i.Value;

			if (i.Item == 'Priority')
				this.DevelopmenttaskSearch.Priority = i.Value;

			if (i.Item == 'Application')
				this.DevelopmenttaskSearch.Application = i.Value;

			if (i.Item == 'Type1')
				this.DevelopmenttaskSearch.Type1 = i.Value;

			if (i.Item == 'Type2')
				this.DevelopmenttaskSearch.Type2 = i.Value;

			if (i.Item == 'Type3')
				this.DevelopmenttaskSearch.Type3 = i.Value;

			if (i.Item == 'Notes')
				this.DevelopmenttaskSearch.Notes = i.Value;

			if (i.Item == 'InstalledDate')
				this.DevelopmenttaskSearch.InstalledDate = i.Value;

			if (i.Item == 'Owner')
				this.DevelopmenttaskSearch.Owner = i.Value;

			if (i.Item == 'AssignedUser')
				this.DevelopmenttaskSearch.AssignedUser = i.Value;

			if (i.Item == 'nextaction')
				this.DevelopmenttaskSearch.nextaction = i.Value;

			if (i.Item == 'DevNotes')
				this.DevelopmenttaskSearch.DevNotes = i.Value;

			if (i.Item == 'Status')
				this.DevelopmenttaskSearch.Status = i.Value;

			if (i.Item == 'ScheduledDate')
				this.DevelopmenttaskSearch.ScheduledDate = i.Value;

			if (i.Item == 'HideCompleted') {
				if (i.Value == "true") {
					this.DevelopmenttaskSearch.HideCompleted = true;
				}
				else {
					this.DevelopmenttaskSearch.HideCompleted = false;
				}
			}

			if (i.Item == 'orderByString')
				this.DevelopmenttaskSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.DevelopmenttaskSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
		this.getFileDevelopmenttaskData();
	}

	onFileSelected(event) {
		console.log('onFileSelected:');
		// const file:File = event.target.files [0];

		// if (file) {
		// 	console.log('adding file');
		// 	this.filesForm.patchValue({
		// 		fileUpload: file,
		// 		})
		// 	this.filename = file.name;

		// 	this.fileSaved = false;
		// 	this.buttonName = 'Save File';
		// }
		if (event.target.files.length > 0) {
			this.filesForm.patchValue({
				fileUpload: event.target.files[0],
			})
		}
	}
	onSaveFile() {
		console.log('onSaveFile');
		// console.log(this.filesForm.value);
		// this.fileSaved = true;
		// this.buttonName = 'File Saved';

		this.fileFormSubmitted = true;

		if (this.filesForm.invalid) {
			console.log('onSaveFile::Form Invalid');
			this.fileSaved = false;
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.developmenttask.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
		formData.append('FileName', fileForm.fileUpload);
		// formData.append('File', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id) {
			formData.append('Id', this.fileToEdit.Id.toString());
			// formData.append('FileName', this.fileToEdit.FileName);
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);
			this.developmenttaskService.updateFileDevelopmenttask(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileDevelopmenttaskData();
			});
		}
		else {
			this.developmenttaskService.createFileDevelopmenttask(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileDevelopmenttaskData()
			});
		}
	}
	editFileData(file) {
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			sortOrder: this.fileToEdit.SortOrder,
			includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false,
			fileDescription: this.fileToEdit.FileDescription
		});
	}

	clearForm() {
		this.filesForm.reset();
		this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${this.applicationSettings.retrieveUrl()}${row.FilePath}${row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.developmenttaskService.deleteFileDevelopmenttask(id).subscribe(file => this.getFileDevelopmenttaskData())
	}

	//This gets the file grid
	getFileDevelopmenttaskData() {
		console.log('should get the file grid');
		if (this.developmenttask) {
			if (this.developmenttask.Id > 0) {
				this.developmenttaskService.getAllFilesDevelopmenttask(this.developmenttask.Id).subscribe(response => {
					console.log('file list returned');
					console.log(response);
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
					this.recordTotal = response.length;
				});
			}
		}
	}

	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	openReport() {

		let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';

		this.developmenttaskService.getReport(this.developmenttaskId).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	sendEmpEmail() {
		let emprptstatus = false;
		if (this.developmenttaskForm.value.EmpEmailRpt) {
			emprptstatus = true;
		}
		var obj: IDevelopmenttasks = Object.assign({}, this.developmenttaskForm.value);
		this.developmenttaskService.sendEmailToEmp(obj, emprptstatus).subscribe(
			(val: any) => {
				console.log(val);
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	sendOwnerEmail() {
		let ownerrptstatus = false;
		if (this.developmenttaskForm.value.OwnerEmailRpt) {
			ownerrptstatus = true;
		}
		var obj: IDevelopmenttasks = Object.assign({}, this.developmenttaskForm.value);
		this.developmenttaskService.sendEmailToOwner(obj, ownerrptstatus).subscribe(
			(val: any) => {
				console.log(val);
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	/*-------------DevTaskHours Section Start---------------*/
	createDevTaskHours(): void {
		if (this.devTaskHoursForm.valid) {
			const obj = this.devTaskHoursForm.value;
			obj.TaskId = this.developmenttaskForm.controls.Id.value;
			this.developmenttaskService.createDevTaskHours(obj)
				.subscribe((data) => {
					this.getAllDevTaskHours();
					this.devTaskHoursForm.reset();
				}
					, (error: any) => this.errorMessage = <any>error);
		}
		else {
			alert('Please fill all details for DevTaskHours');
		}
	}
	getAllDevTaskHours() {
		this.developmenttaskService.getAllDevTaskHours(this.developmenttaskForm.controls.Id.value)
			.subscribe((obj) => {
				this.dSDevTaskHours = obj;
			}
				, (error: any) => this.errorMessage = <any>error);
	}
	deleteDevTaskHours(element) {
		if (confirm(`Are you sure you want to delete this item: ${element.Hours}?`)) {
			this.developmenttaskService.deleteDevTaskHours(element.Id)
				.subscribe(() => this.getAllDevTaskHours()
					, (error: any) => this.errorMessage = <any>error);
		} 
	}
/*-------------DevTaskHours Section End---------------*/
	/*-------------DevTaskCosts Section Start---------------*/
	createDevTaskCosts(): void {
		if (this.devTaskCostsForm.valid) {
			const obj = this.devTaskCostsForm.value;
			obj.TaskId = this.developmenttaskForm.controls.Id.value;
			this.developmenttaskService.createDevTaskCosts(obj)
				.subscribe((data) => {
					this.getAllDevTaskCosts();
					this.devTaskCostsForm.reset();
				}
					, (error: any) => this.errorMessage = <any>error);
		}
		else {
			alert('Please fill all details for DevTaskCosts');
		}
	}
	getAllDevTaskCosts() {
		this.developmenttaskService.getAllDevTaskCosts(this.developmenttaskForm.controls.Id.value)
			.subscribe((obj) => {
				this.dSDevTaskCosts = obj;
			}
				, (error: any) => this.errorMessage = <any>error);
	}
	deleteDevTaskCosts(element) {
		if (confirm(`Are you sure you want to delete the item: ${element.Name}?`)) {
			this.developmenttaskService.deleteDevTaskCosts(element.Id)
				.subscribe(() => this.getAllDevTaskCosts()
					, (error: any) => this.errorMessage = <any>error);
		} 
	}
/*-------------DevTaskCosts Section End---------------*/
}
