import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription, merge } from 'rxjs';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IFerruleSearch } from '../ferrule.model/ferruleSearch';
import { IFerrules } from '../ferrule.model/ferrules';
import { FerruleService } from '../ferrule.service';
import { ApplicationSettings } from './../../../shared/application-settings';
import { IFerrule } from './../ferrule.model/ferrule';

@Component({
	selector: 'app-ferrule-list',
	templateUrl: './ferrule-list.component.html',
	styleUrls: ['./ferrule-list.component.css']
})
export class FerruleListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	public pageSize: number = +localStorage.getItem('rows');
	public pageSizeList: any[];
	public skip = 0;
	public sub: Subscription;
	public Ferrule = '';
	public recordTotal: number = 0;
	displayedColumns: string[] = ['Id','InsideDiameter','WallThickness'];
	public showHideSearch: boolean = false;
	pageTitle = 'Ferrule List';
	public dataSource: any;
	errorMessage = '';
	listFilter: string;
	public listOfferrule: IFerrule[];
	public allowUnsort = true;
	public multiple = false;
	isLoadingResults = true;
	public UserSearches: IUsersearch[];
	public SearchText: string = 'Show Filters';

	constructor(
		private userSearchService: UsersearchService,
		public appSettings: ApplicationSettings,
		public ferruleService: FerruleService,
		private router: Router) {

		if (this.pageSize === 0) {
			this.pageSize = 10;
			localStorage.setItem('rows', '10');
		}
	}

	refreshGrid(): void {
		this.skip = 0;
		this.getData();
	}

	ngOnInit(): void {
		this.skip = 0;
		this.Ferrule='';
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x; }
		else { this.pageSize = 10;}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	public ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
		.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
	}
	, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='ferrule')
				this.Ferrule = i.Value;

			if(i.Item=='orderByString') {
				if (this.appSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.appSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	valueChanged(): boolean {
		localStorage.setItem('rows', this.pageSize.toString());
		return true;
	}


	onFilter() {
		this.refreshGrid();
	}


	getData(): any {
		this.isLoadingResults = true;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.dataSource = null; 
		this.recordTotal = 0; 
		   let search: IFerruleSearch = this.ferruleService.getFreshSearch();
   
		   search.Ferrule = this.Ferrule;

		   search.skip = this.paginator.pageIndex * this.pageSize;
		   search.take = this.pageSize;
		   search.orderByString = this.sort.active + '-' + this.sort.direction;
   
		   this.ferruleService.getRecordSet(search).subscribe((obj: IFerrules) => this.onSelectedRecordSetReceived(obj),
		   (error: any) => {
			   this.errorMessage = <any> error;
		   });
	   }

	   private onSelectedRecordSetReceived(obj: IFerrules){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.isLoadingResults = false;
	}


	showHideSearchClick() {
		if (this.showHideSearch) 
		{
			this.showHideSearch = false; 
		   localStorage.setItem('showHideSearch', 'false'); 
		   this.SearchText = 'Show Filters'; 
	   }
	   else
	   {
			this.showHideSearch = true; 
		   localStorage.setItem('showHideSearch', 'true'); 
			 this.SearchText = 'Hide Filters'; 
	   }
   }
   searchAndHideClick() {
		this.skip = 0;
		this.paginator.pageIndex = 0;
	   this.getData();
		setTimeout(() => {
			this.showHideSearchClick();
		}, 500);
	}

	searchClick(): void {
	   this.skip = 0;
	   this.paginator.pageIndex = 0;
	   this.getData();
   }

   public searchClearClick(): void {
	this.Ferrule = '';
	this.skip = 0;
	this.sort.direction = 'asc';
	this.sort.active = 'Id';
	this.paginator.pageIndex = 0;
	this.getData();
}


	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/ferrules`])
		);
		window.open(url, '_blank');
	}
}
