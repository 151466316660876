<div class='panel panel-primary animaPlaceholder'>
	<div class="row">
		<div class="col-md-10">
			<h1>Walk Ins File</h1>
			<input (change)="onFileSelected($event)" type="file">
		</div>
		<div class="col-md-2">
			<div class="text-center" style="margin-top: 20px;" *ngIf="pdfFileName">
			<button class='btn btn-primary btn-sm' style='width: 100px' (click)='print()'>Print</button>
		</div>
		</div>
	</div>


</div>

<ng-container *ngIf='isShowList'>

	<div class='table-container'>
		<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort>
			<ng-container matColumnDef='Id'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
				<td mat-cell *matCellDef='let e'>
					<button class='btn btn-default' style='min-width: 80px;'
						[routerLink]="['/walkin-details', e.Id]">{{e.Id}}</button>
				</td>
			</ng-container>
			<ng-container matColumnDef='SalesOrder'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Sales Order</th>
				<td mat-cell *matCellDef='let e'>{{e.SalesOrder}}</td>
			</ng-container>
			<ng-container matColumnDef='DateString'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Date String</th>
				<td mat-cell *matCellDef='let e'>{{e.DateString}}</td>
			</ng-container>
			<ng-container matColumnDef='CustomerName'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Customer Name</th>
				<td mat-cell *matCellDef='let e'>{{e.CustomerName}}</td>
			</ng-container>
			<ng-container matColumnDef='Quantity'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Quantity</th>
				<td mat-cell *matCellDef='let e'>{{e.Quantity}}</td>
			</ng-container>
			<ng-container matColumnDef='EclipseId'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Eclipse Id</th>
				<td mat-cell *matCellDef='let e'>{{e.EclipseId}}</td>
			</ng-container>
			<ng-container matColumnDef='PartName'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Part Name</th>
				<td mat-cell *matCellDef='let e'>{{e.PartName}}</td>
			</ng-container>
			<ng-container matColumnDef='PartString'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Part String</th>
				<td mat-cell *matCellDef='let e'>{{e.PartString}}</td>
			</ng-container>
			<ng-container matColumnDef='HoseType'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Hose Type</th>
				<td mat-cell *matCellDef='let e'>{{e.HoseType}}</td>
			</ng-container>
			<ng-container matColumnDef='Fitting1'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Fitting1</th>
				<td mat-cell *matCellDef='let e'>{{e.Fitting1}}</td>
			</ng-container>
			<ng-container matColumnDef='Fitting2'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Fitting2</th>
				<td mat-cell *matCellDef='let e'>{{e.Fitting2}}</td>
			</ng-container>
			<ng-container matColumnDef='Length'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'> Length</th>
				<td mat-cell *matCellDef='let e'>{{e.Length}}</td>
			</ng-container>
			<ng-container matColumnDef='Orientation'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col13'> Orientation</th>
				<td mat-cell *matCellDef='let e'>{{e.Orientation}}</td>
			</ng-container>
			<ng-container matColumnDef='SpecialInstructions'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col14'> Special Instructions</th>
				<td mat-cell *matCellDef='let e'>{{e.SpecialInstructions}}</td>
			</ng-container>
			<ng-container matColumnDef='FileName'>
				<th mat-header-cell *matHeaderCellDef mat-sort-header class='col14'> File Name</th>
				<td mat-cell *matCellDef='let e'>{{e.FileName}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
			<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
		</table>
	</div>
	<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
</ng-container>