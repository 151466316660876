import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IMillcert} from './Millcert-model/Millcert';
import { IMillcerts } from './Millcert-model/Millcerts';
import { IMillcertSearch } from './Millcert-model/MillcertSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class MillcertService {
	private baseUrl;
	public pageTitle = 'Mill Certs List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Millcerts';
	}

	getMillcert(id: number):  Observable<IMillcert> {
		if (id === 0) {
			return of(this.initializeMillcert());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IMillcert>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveMillcert(millcert: IMillcert): Observable<any> {
		if (millcert.Id === 0) {
			return this.createMillcert(millcert);
		}
		return this.updateMillcert(millcert);
	}

	private createMillcert(millcert: IMillcert): Observable<any> {
		millcert.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/millcertcreate', millcert)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateMillcert(millcert: IMillcert): Observable<IMillcert> {
		return this.authHttp.put<IMillcert>(this.baseUrl, millcert)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteMillcert(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeMillcert(): IMillcert {
		return {
			Id: 0,
			SupplierName: '',
			InvoiceNumber: '',
			PoNumber: '',
			ContainerAirwayBill: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IMillcert;
	}

	public getRecordSet(search: IMillcertSearch): Observable<IMillcerts> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('SupplierName', search.SupplierName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('InvoiceNumber', search.InvoiceNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PoNumber', search.PoNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContainerAirwayBill', search.ContainerAirwayBill, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('SupplierName', search.SupplierName ? search.SupplierName : '')
			.set('InvoiceNumber', search.InvoiceNumber ? search.InvoiceNumber : '')
			.set('PoNumber', search.PoNumber ? search.PoNumber : '')
			.set('ContainerAirwayBill', search.ContainerAirwayBill ? search.ContainerAirwayBill : '')
		};

		return this.authHttp.get<IMillcerts>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IMillcerts>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IMillcertSearch { 
		let search: IMillcertSearch = {
			Id: 1,
			SupplierName: '',
			InvoiceNumber: '',
			PoNumber: '',
			ContainerAirwayBill: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	updateFileMillcert(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<IMillcerts>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFileMillcert(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesMillcert(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<IMillcerts[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFileMillcert(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	public toExcel(search: IMillcertSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('SupplierName', search.SupplierName ? search.SupplierName : '')
			.set('InvoiceNumber', search.InvoiceNumber ? search.InvoiceNumber : '')
			.set('PoNumber', search.PoNumber ? search.PoNumber : '')
			.set('ContainerAirwayBill', search.ContainerAirwayBill ? search.ContainerAirwayBill : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}