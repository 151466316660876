<div class='panel panel-primary animaPlaceholder'>
    <!-- <div class='panel-heading'>
       
    </div> -->
    <div class='panel-body'>
        <div class="row">
            <div>
                <div class='col-md-4'>
                    Branch
                    <select [(ngModel)]='BranchId' class='form-control block-element' (change)="getAllFunctions()">
                        <option value=""> </option>
                        <option *ngFor='let branch of branches' [value]="branch.BranchNumber">{{branch.BranchName}}
                            {{branch.BranchNumber}}</option>
                    </select>
                </div>
                <div class='col-md-1 text-center'>
                    Refresh in <countdown [config]='time' (finished)="onFinishedTimer()"></countdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <h3 style="text-align: left;">Daily Stats</h3>
                <table class="table table-fixed" style="width: 50%;">
                    <thead>
                        <tr>
                            <th>
    
                            </th>
                            <th>
                                {{month1dt | date:'MMM'}}  
                            </th>
                            <th>
                                {{month2dt | date:'MMM'}}
                            </th>
                            <th>
                                {{month3dt | date:'MMM'}}
                            </th>
                            <th>
                                Today
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of dailyStatsData">
                            <td>{{i.ColName}}</td>
                            <td>{{i.Month1}}</td>
                            <td>{{i.Month2}}</td>
                            <td>{{i.Month3}}</td>
                            <td>{{i.Today}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
           
            <div class="col-md-6">
                <h3 style="text-align: left;">Pick/Putaway/Verify</h3>
                <table class="table table-fixed" style="width: 50%;">
                    <thead>
                        <tr>
                            <th>
                                Person
                            </th>
                            <th>
                                Pick
                            </th>
                            <th>
                                Putaway
                            </th>
                            <th>
                                Verify
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of pickByPersonData">
                            <td>{{i.Person}}</td>
                            <td>{{i.Pick}}</td>
                            <td>{{i.PutAway}}</td>
                            <td>{{i.Verify}}</td>                           
                        </tr>
                    </tbody>
                </table>
            </div>
           
        </div>
    </div>
</div>