
<div class='panel panel-primary animaPlaceholder' *ngIf = 'branche'>
	<div class='panel-heading'>
		<span *ngIf = 'branche' >{{ page }} :{{ branche.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='brancheForm'>
			<fieldset>
			<div class='panel-body'>
				<div class="row">
					<div class="col-md-6">
						<div class='form-group'>
							<label class='col-md-2 control-label'> Branch Name</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='BranchName' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Business Number</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='BusinessNumber' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Branch Number</label>
							<div class='col-md-10'>
								<input class='form-control' type='number' formControlName='BranchNumber' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Toll Free Number</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='TollFreeNumber' />
							</div>
						</div>
						
						<div class='form-group'>
							<label class='col-md-2 control-label'> City</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='City' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Postal Code</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='PostalCode' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Province</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='Province' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Country</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='Country' />
							</div>
						</div>
						
						<div class='form-group'>
							<label class='col-md-2 control-label'>fax</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='fax' />
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> U P C</label>
							<div class='col-md-10'>
								<input class='form-control' type='text' formControlName='UPC' />
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class='form-group'>
							<label class='col-md-2 control-label'> Address 1</label>
							<div class='col-md-10'>
								<textarea class='form-control' type='text' formControlName='AddressLine' ></textarea>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Address 2</label>
							<div class='col-md-10'>
								<textarea class='form-control' type='text' formControlName='AddressLine2' ></textarea>
							</div>
						</div>
						<div class='form-group'>
							<label class='col-md-2 control-label'> Notes</label>
							<div class='col-md-10'>
								<textarea class='form-control' type='text' formControlName='Notes' ></textarea>
							</div>
						</div>
							<div class='form-group' >					
								<div class='col-md-10'>
									<div *ngIf="brancheForm.controls['Logo'].value">
										<img [src]="urldatalogo" style="width: 300px; height:160px;margin-left: 120px;" >
									</div>
								</div>
							</div>
							<div class='form-group' *ngIf="branche.Id">
								<label class='col-md-2 control-label'> Logo</label>
								<div class='col-md-10'>
									<input (change)="onFileSelected($event,'Logo')" type="file" >
								</div>
							</div>
							<div class='form-group' >					
								<div class='col-md-10'>
									<div *ngIf="brancheForm.controls['IsoLogo'].value">
										<img [src]="urldataisologo" style="width: 300px; height:160px;margin-left: 120px;" >
									</div>
								</div>
							</div>
							<div class='form-group' *ngIf="branche.Id">
								<label class='col-md-2 control-label'> Iso Logo</label>
								<div class='col-md-10'>
									<input (change)="onFileSelected($event,'IsoLogo')" type="file" >
								</div>
							</div>
					</div>
				</div>
				
				
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveBranche(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!brancheForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveBranche(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!brancheForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveBranche(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!brancheForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!brancheForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteBranche()'
								style='width: 150px; margin-right:10px'
								[disabled]='!brancheForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='branche'>{{branche.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='branche'>{{branche.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>