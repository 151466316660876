import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationSettings } from '../../../shared/application-settings';
import { AuditService } from '../audit-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-audit-record-view',
  templateUrl: './audit-record-view.component.html',
  styleUrls: ['./audit-record-view.component.css']
})
export class AuditRecordViewComponent implements OnInit {
  public Description = '';

  public dataSource: any;
  public dataSourceGrid: any;
  public pageTitle = 'AuditRecord View';
  public errorMessage = '';
  keyId: any;

  kitId: any;
  displayedColumns: string[] = ['Id','History', 'TableChanged', 'ColumnChanged', 'KeyId', 'Name', 'WhatHappened', 'OldValue', 'NewValue', 'CreatedDate', 'CreatedBy'];
  disColGrid: string[] = ['TableChanged', 'ColumnChanged', 'KeyId', 'WhatHappened', 'OldValue', 'NewValue', 'CreatedDate', 'CreatedBy'];

  private sub: Subscription;
  public FromDate: any;
  public ToDate: any;
  public OldValue: any;
  public NewValue: any;
  TableName: any;
  CreatedDate:any;
  CreatedBy:any;
  KeyId:any;
  PartName:any;
  PartType:any;

  search = {
    FromDate: '',
    ToDate: '',
    TableName: '',
    OldValue: '',
    NewValue: ''
  }

  constructor(public applicationSettings: ApplicationSettings
    , private _appService: AuditService,
    private route: ActivatedRoute
    , public datepipe: DatePipe
    , private router: Router) {

  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(
      params => {
        this.TableName = params['tableName'];
        console.log(this.TableName);
      }
    );
    this.setAllDates();

  }
  setAllDates() {
    this.FromDate = new Date();
    this.FromDate.setDate(this.FromDate.getDate() - 7);
    this.FromDate = this.datepipe.transform(this.FromDate, 'yyyy-MM-dd');
    this.ToDate = new Date()
    this.ToDate.setDate(this.ToDate.getDate() + 1);
    this.ToDate = this.datepipe.transform(this.ToDate, 'yyyy-MM-dd');
  }
  getAuditRecord(search): any {
    this.dataSource = [];
    this._appService.getAuditRecordData(search)
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response);

      }
        , (error: any) => this.errorMessage = <any>error);
  }
  searchClick() {

    this.search.FromDate = this.FromDate;
    this.search.ToDate = this.ToDate;
    this.search.TableName = this.TableName;
    this.search.OldValue = this.OldValue;
    this.search.NewValue = this.NewValue;
    console.log(this.search);
    this.getAuditRecord(this.search)
  }
  public searchClearClick(): void {
    this.setAllDates();
    this.search.OldValue = '';
    this.search.NewValue = '';
    this.getAuditRecord(this.search)
  }
  openRecordPage(keyid, tablechanged) {
    let baseurl = "";
    if (tablechanged == "_Hoses") {
      baseurl = "/hose-details/" + keyid;
    }
    else if (tablechanged == "_Ferrules") {
      baseurl = "/ferrule-details/" + keyid;
    }
    else if (tablechanged == "_Fittings") {
      baseurl = "/fitting-details/" + keyid;
    }
    if (baseurl != "") {

      const url = this.router.serializeUrl(
        this.router.createUrlTree([baseurl])
      );
      window.open(url, '_blank');
    }
  }
  getAuditDataByKeyId(keyid,tablechanged,name): any {
    this.KeyId=keyid;
    this.PartName=name;
    if (tablechanged == "_Hoses") {
      this.PartType = "Hose";
    }
    else if (tablechanged == "_Ferrules") {
      this.PartType = "Ferrule";
    }
    else if (tablechanged == "_Fittings") {
      this.PartType = "Fitting";
    }
    this.dataSourceGrid=[];
    this._appService.getAuditDataByKeyId(keyid, tablechanged)
      .subscribe((obj) => {
        this.dataSourceGrid=new MatTableDataSource(obj.Data);
        this.CreatedDate=obj.CreatedDate;
        this.CreatedBy=obj.CreatedBy;
        console.log(obj);
      }
        , (error: any) => this.errorMessage = <any>error);
  }
}
