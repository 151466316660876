import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { catchError, map, retry } from 'rxjs/operators';
// tslint:disable-next-line:import-blacklist
import { Observable, throwError } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';

import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { ICustomer } from './Customer-model/Customer';
import { ICustomerSearch } from './Customer-model/customerSearch';
import { ICustomers } from './customer-model/customers';

@Injectable()
export class CustomerService {
	private baseUrl;
	public pageTitle = 'Customers List';
	constructor(private authHttp: HttpClient, private settings: ApplicationSettings, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Customers';
	}

	getCustomer(id: number):  Observable<ICustomer> {
		if (id === 0) {
			return Observable.of(this.initializeCustomer());
		}

		const url = `${this.baseUrl}/${id}`;
		return this.authHttp.get<ICustomer>(url)
			.catch(this.handleError);
	}

	saveCustomer(customer: ICustomer): Observable < any > {
		if (customer.Id === 0) {
			return this.createCustomer(customer);
		}
		return this.updateCustomer(customer);
	}

	private createCustomer(customer: ICustomer): Observable <any> {
		customer.Id = undefined;
		return this.authHttp.post(this.baseUrl, customer)
			.catch (this.handleError);
	}

	private updateCustomer(customer: ICustomer): Observable <ICustomer> {
		return this.authHttp.put(this.baseUrl, customer)
			.catch (this.handleError);
	}

	deleteCustomer(id: number): Observable < Response > {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete(url)
			.catch (this.handleError);
	}

	private handleError(error: Response): Observable <any> {
		return Observable.throw (error.json().ErrorDescription || 'Server error');
	}

	initializeCustomer(): ICustomer {
		return {
			Id: 0,
			Customer: '',
			Address1: '',
			Address2: '',
			City: '',
			Pr: '',
			PC: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICustomer;
	}

	public getRecordSet(search: ICustomerSearch): Observable<ICustomers> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Id', search.Id ? search.Id.toString() : '', this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Customer', search.Customer, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Address1', search.Address1, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Address2', search.Address2, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('City', search.City, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Pr', search.Pr, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PC', search.PC, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Id', search.Id ? search.Id : '')
			.set('Customer', search.Customer ? search.Customer : '')
			.set('Address1', search.Address1 ? search.Address1 : '')
			.set('Address2', search.Address2 ? search.Address2 : '')
			.set('City', search.City ? search.City : '')
			.set('Pr', search.Pr ? search.Pr : '')
			.set('PC', search.PC ? search.PC : '')
		};

		return this.authHttp.get<ICustomers>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICustomers>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}

	public toExcel(search: ICustomerSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Id', search.Id ? search.Id : '')
			.set('Customer', search.Customer ? search.Customer : '')
			.set('Address1', search.Address1 ? search.Address1 : '')
			.set('Address2', search.Address2 ? search.Address2 : '')
			.set('City', search.City ? search.City : '')
			.set('Pr', search.Pr ? search.Pr : '')
			.set('PC', search.PC ? search.PC : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	public getFreshSearch() : ICustomerSearch { 
		let search: ICustomerSearch = {
			Id: 0,
			Customer: '',
			Address1: '',
			Address2: '',
			City: '',
			Pr: '',
			PC: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};
	
	searchCustomers(text: string): Observable<any> {
		const url = this.baseUrl + '/searchcustomers?text=' + text;
		return this.authHttp.get(url)
			.catch(this.handleError);
	}

	getAllCustomers(): Observable<any> {
		const url = this.baseUrl + '/getallcustomer';
		return this.authHttp.get(url)
			.catch(this.handleError);
	}
}
