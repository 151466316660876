
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/part-details/0']" style='width:auto;' [disabled]="progressStatus">Add New Part</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: auto' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Part Number</label>
				<input type='text' class='form-control block-element' placeholder=' Part Number' [(ngModel)]='PartNumber' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Part Description</label>
				<input type='text' class='form-control block-element' placeholder=' Part Description' [(ngModel)]='PartDescription' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Eclipse Id</label>
				<input type='text' class='form-control block-element' placeholder=' Eclipse Id' [(ngModel)]='EclipseId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Part Roots Id</label>
				<input type='text' class='form-control block-element' placeholder=' Part Roots Id' [(ngModel)]='PartRootsId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Bag Qty</label>
				<input type='text' class='form-control block-element' placeholder=' Bag Qty' [(ngModel)]='BagQty' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Box Qty</label>
				<input type='text' class='form-control block-element' placeholder=' Box Qty' [(ngModel)]='BoxQty' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Upc</label>
				<input type='text' class='form-control block-element' placeholder=' Upc' [(ngModel)]='Upc' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Box Size</label>
				<input type='text' class='form-control block-element' placeholder=' Box Size' [(ngModel)]='BoxSize' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/part-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='PartNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Part Number</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.PartNumber}}</td>
	</ng-container>
	<ng-container matColumnDef='PartDescription'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Part Description</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.PartDescription}}</td>
	</ng-container>
	<ng-container matColumnDef='EclipseId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Eclipse Id</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.EclipseId}}</td>
	</ng-container>
	<ng-container matColumnDef='PartRootsId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Part Roots Id</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.PartRootsId}}</td>
	</ng-container>
	<ng-container matColumnDef='BagQty'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Bag Qty</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.BagQty}}</td>
	</ng-container>
	<ng-container matColumnDef='BoxQty'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Box Qty</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.BoxQty}}</td>
	</ng-container>
	<ng-container matColumnDef='Upc'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Upc</th>
		<td mat-cell *matCellDef='let e' class='col8' >{{e.Upc}}</td>
	</ng-container>
	<ng-container matColumnDef='BoxSize'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Box Size</th>
		<td mat-cell *matCellDef='let e' class='col9' >{{e.BoxSize}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>