<div class="container">
    <div class='row'>
        <div class='col-md-12'>
            <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                {{ errorMessage }}
            </div>
        </div>
    </div>
    <h2> Customer Plan Records</h2>
    
    <div class="row"  style="font-size: 20px;">
        <div class="col-md-5">
            Step 1: get part numbers
            <button class="btn btn-primary" style="margin-left: 50px;"  (click)="getPartNumbers()" >
                get
            </button>
        </div>
        <div class="col-md-4">
            Results : Parts OK {{partsWithNumbers}}
        </div>
        <div class="col-md-3">
          no#
          {{partsWithoutNumbers}}
        </div>
    </div>
    <div class="row" style="margin-top: 50px;font-size: 20px;">
        <div class="col-md-5">
            Step 2: get kit details      
            <button class="btn btn-primary" style="margin-left: 50px;" (click)="getPartKits()" >
                get
            </button>
        </div>
        <div class="col-md-4">
            Results : Parts OK {{partsWithKits}}
        </div>
        <div class="col-md-3">
           no#
          {{partsWithoutKits}}
        </div>
    </div>
    <div class="row" style="margin-top: 50px;font-size: 20px;">
        <div class="col-md-5">
            Step 3: Export Records
            <button class="btn btn-primary" style="margin-left: 50px;" (click)='exportData()'>Export</button>
        </div>
       
    </div>
</div>