import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPartcat } from '../partcat-model/partcat';

import { Subscription } from 'rxjs/Subscription';
import { PartcatService } from '../partcat-service';
import { IPartcats } from '../Partcat-model/Partcats';
import { IPartcatSearch } from '../Partcat-model/PartcatSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './partcat-edit.component.html',
	styleUrls: ['partcat-edit.component.css']
})

export class PartcatEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'View Part Category';
	errorMessage: string;
	partcatForm: UntypedFormGroup;
	partcat: IPartcat;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public PartcatSearch: IPartcatSearch;
	public ListData: IPartcats;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private partcatService: PartcatService,
		) {
	}

	ngOnInit(): void {
		this.partcatForm = this.fb.group({
			Id: '',
			PriceLine: ['',[Validators.maxLength(255)]],
			Tag: ['',[Validators.maxLength(255)]],
			CreatedBy: '',
			CreatedDate: '',
			UpdatedBy: '',
			UpdatedDate: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getPartcat(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.partcatService.getRecordSet(this.PartcatSearch)
			.subscribe((obj: IPartcats) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IPartcats){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.partcat.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	savePartcat(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.partcatForm.valid) {
			const obj = Object.assign({ }, this.partcat, this.partcatForm.value);
			this.partcatService.savePartcat(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.partcatForm.reset();
			this.router.navigate(['/partcats']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.partcat.Id === 0) {
			this.router.navigate(['/partcat-details', data.Id]);
		} 
		else if (this.partcat.Id != 0){
			this.getPartcat(this.partcat.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.partcat.Id)
				record = count;
		});

		this.getPartcat(this.ListData.data[record].Id)
	}

	getPartcat(id: number): void {
		this.partcatService.getPartcat(id)
			.subscribe((obj: IPartcat) => this.onPartcatRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onPartcatRetrieved(partcat: IPartcat): void {
		if (this.partcatForm) {
			this.partcatForm.reset();
		}
		this.partcat = partcat;
		if (this.partcat.Id === 0) {
			this.page = 'Add Part Category';
		} else {
			this.page = 'View Part Category';
		}

		this.partcatForm.patchValue({
			Id: this.partcat.Id,
			PriceLine: this.partcat.PriceLine,
			Tag: this.partcat.Tag,
			CreatedBy: this.partcat.CreatedBy,
			CreatedDate: this.partcat.CreatedDate,
			UpdatedBy: this.partcat.UpdatedBy,
			UpdatedDate: this.partcat.UpdatedDate,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deletePartcat(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.partcat.PriceLine}?`)) {
			this.partcatService.deletePartcat(this.partcat.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/partcats']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Part Category List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.PartcatSearch = this.partcatService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'PriceLine')
				this.PartcatSearch.PriceLine = i.Value;

			if (i.Item == 'Tag')
				this.PartcatSearch.Tag = i.Value;

			if (i.Item == 'orderByString')
				this.PartcatSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.PartcatSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
