
<div class="panel panel-primary animaPlaceholder">
<div class="container-fluid">
	<div class="panel-heading">
		{{page}}
		<div class='has-error' class="text-danger" *ngIf='errorMessage'>
			<button class="btn btn-primary btn-sm" (click)="resetError()">Reset Error</button>
			{{ errorMessage }}
		</div>
	</div>
	<div class="panel-body">
		<form class="form-horizontal" novalidate [formGroup]="nonStockLabelForm">
			<fieldset>
				<div class="row">
					<div class="col-md-5">
						<div class="form-group">
							<label class="col-md-4 control-label">Part Number</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="PartNumber" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Part Description</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="PartDescription" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Upc / Part Number</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="Upc" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Quantity</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="Quantity" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Number Of Labels</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="NumberOfLabels" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Qty Type</label>
							<div class="col-md-8">
								<select formControlName='QtyType' class='form-control block-element'>
									<option *ngFor='let qtytype of qtyTypes' [value]="qtytype">{{qtytype}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Label Type</label>
							<div class="col-md-8">
								<select formControlName='LabelType' class='form-control block-element'>
									<option *ngFor='let item of labelTypes' [value]="item.value">{{item.label}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Customer</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="Customer" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Sales Order</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="SalesOrder" />
							</div>
						</div>
						<div class="form-group">
							<div class="col-md-offset-2 col-md-4">
								<button class="btn btn-primary btn-sm" (click)="print()" [disabled]="!nonStockLabelForm.valid">Print</button>
							</div>
						</div>
						<div class="form-group">
							<a target="_blank" href="https://{{printerIp()}}/GLPrinting">Click here if you are getting a printing error.</a>
						</div>
						<div class="form-group">
							Your branch is currently set to: {{Branch}}.  If this is wrong your printing won't work.
						</div>
					</div>
					<div class="col-md-7">
						<!-- Non Stock Image -->
						<!-- <img src="../../../assets/nonstock.jpg" alt="" class="img-responsive"> -->
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
