import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DataService } from '../data/data-service';
import { IHydroTest } from '../hydro-test/hydro-test-model/hydro-test';
import { ModalComponent } from '../shared/modal.component';
import { IHose } from './hose-model/hose';
import { HosesService } from './hoses-service/hoses.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'http-hoses',
	templateUrl: './hoses.html',
	styleUrls: ['./hose.css']
})
// tslint:disable-next-line:component-class-suffix
export class HosesComponent {
	@ViewChild(ModalComponent, { static: true }) modal: ModalComponent;

	public hydroTest: IHydroTest;
	salesOrder: string;
	baseUrl: string;
	hosesToDuplicate = 1;
	showDuplicateHoseButton = false;
	private tabClass: string[]; //  = { "Active", "", ""};
	private tabVisible: boolean[];
	private hoseDetail: IHose;
	private loading = false;
	private showTabs = false;
	private formState = 'no hose selected';
	private showStuff = false;
	private createNewHoseVisible = true;
	private triggerUpdate = 1;
	private triggerHoseListRefresh = 1;
	private errorMessage: string;

	private testMachineType: string;

	duplicateDisabled = false;

	constructor(
		private appSettings: ApplicationSettings,
		public http: HttpClient,
		private hoseService: HosesService,
		private dataService: DataService
	) {
		this.tabClass = ['active', '', '', '', ''];
		this.tabVisible = [true, false, false, false, false, false];
		this.baseUrl = this.appSettings.retrieveUrl();
	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngOnInit(): void {
		this.dataService.evGotData.subscribe((data: string) =>
			this.GotData(data)
		);
		if (this.dataService.gotData) {
			this.GotData('');
		}
	}

	GotData(val: string) {
		this.hydroTest = this.dataService.HydroTest;
		this.testMachineType = this.hydroTest.testMachine;
		this.salesOrder = this.hydroTest.salesOrder;
	}

	selectTab(tab: number): void {
		// console.log('tab selected:' + tab.toString());
		this.tabClass = ['', '', '', '', '', ''];
		this.tabVisible = [false, false, false, false, false, false];
		this.tabClass[tab] = 'active';
		this.tabVisible[tab] = true;
	}

	onHoseSelected(val: string): void {
		// console.log('Hoses::onHoseSelected::' + val);
		this.getHoseDetail(val);
		this.createNewHoseVisible = true;
	}

	getHoseDetail(id: string): void {
		this.showTabs = false;
		// console.log('Hoses:getHoseDetail Getting the hose detail:' + id);

		this.hoseService
			.getHoseDetail(id)
			.subscribe(
				data => (this.hoseDetail = data),
				err => console.error(err),
				() => this.loadRecordComplete()
			);
	}

	saveHoseDetail(hoseDetail: IHose): void {
		this.clearFormText();
		this.createNewHoseVisible = true;
		this.formState = 'Saving hose ....';
		hoseDetail.HydroTestsId = this.hydroTest.hydroTestId;

		this.hoseService
			.update(hoseDetail)
			.subscribe(
				data => {
					this.showDuplicateHoseButton = true;
					this.handlingSaveCompleted(data);
				},
				error =>
					this.handleAddUpdateError(
						'Serial number is already in database. Cannot add this record.'
					)
			);
	}
	handleAddUpdateError(err: string): void {
		// console.log(err);

		this.errorMessage = err;
		this.formState = 'Failed to save the hose!';
	}

	handlingSaveCompleted(retVal: any): void {
		this.hoseDetail.Id = retVal;
		this.formState = 'Hose saved.';
		// console.log('hoses::handlingSaveCompleted hoseId:' + retVal);
		this.triggerHoseListRefresh = this.triggerHoseListRefresh + 1;
	}

	handlingSaveError(error: any): void {
		this.formState =
			'Error saving hose, please try again.  If issue persists open a support call.';
		// console.log('Error saving hose');
		// console.error(error);
		if (this.hoseDetail.Id === 0) {
			this.createNewHoseVisible = false;
		}
	}

	loadRecordComplete(): void {
		this.hoseDetail.fileNameAndPath =
			this.baseUrl + '/api/' + this.hoseDetail.fileNameAndPath;
		this.loading = false;
		// console.log(
		// 	'hose detail record loaded: ' + this.hoseDetail.fileNameAndPath
		// );
		this.showTabs = true;
		this.formState = 'Hose selected: ' + this.hoseDetail.SerialNumber;
		this.showStuff = true;

		// console.log(this.hoseDetail);
	}

	addNewHose(): void {
		// Hide duplicate hose button
		this.showDuplicateHoseButton = false;

		this.createNewHoseVisible = false;
		this.showTabs = true;
		this.formState = 'Adding new hose.';
		this.showStuff = true;

		this.hoseDetail = {
			Id: 0,
			fileName: '',
			fileNameAndPath: '',
			VICover: 'Fail',
			VIEnd1: 'Fail',
			VIEnd2: 'Fail',
			VITube: 'Fail',
			VIResult: 'Fail',
			OverallResult: 'Fail',

			VINotes: '',
			ElectricalConOhms: 0,

			ElectricalConResult: '',
			ECPressure: 0,
			ECPressureResults: '',
			ECImmediateRelease: 0,
			ECImmediateReleaseResult: '',
			ECPermanentElongation: 0,
			ECPermanentElongationResult: '',

			HoseLengthInitialInch: 0,
			HoseLengthInitialFeet: 0,
			HoseLengthInitialTPInch: 0,
			HoseLengthInitialTPFeet: 0,

			HoseLengthReleaseInch: 0,
			HoseLengthReleaseFeet: 0,
			HoseLengthPostInch: 0,
			HoseLengthPostFeet: 0,

			HydroStaticTestResult: '',
			HydroTestsId: 0,
			SerialNumberCustomer: '',

			SerialNumber: ''
		};

		this.http
			.get(
				this.baseUrl +
				'/api/HydroTestHose/NextSn?id=' +
				this.hydroTest.hydroTestId
			)
			.subscribe(
				data => this.gettingNextSerialNumber(data),
				err => this.handleAddUpdateError(err.json().message),
				() => console.log('Got next sn.')
			);

		this.triggerUpdate = this.triggerUpdate + 1;
		this.selectTab(0);
	}

	gettingNextSerialNumber(data: any): void {
		this.hoseDetail.SerialNumber = data;
		this.hoseDetail.SerialNumberCustomer = data;

		if (this.hoseDetail.SerialNumber.length === 0) {
			this.hoseDetail.SerialNumber = this.salesOrder + '.001';
			this.hoseDetail.SerialNumberCustomer = this.salesOrder + '.001';
		}
	}

	clearFormText(): void {
		this.errorMessage = '';
	}
	onSerialBlur(): void {
		// console.log("left sn field");
		if (this.hoseDetail.SerialNumberCustomer.length === 0) {
			this.hoseDetail.SerialNumberCustomer = this.hoseDetail.SerialNumber;
		}
	}

	duplicateHose(): void {
		// alert(`Duplicating hose with Id ${this.hoseDetail.Id} ${this.hosesToDuplicate} times`);
		this.duplicateDisabled = true;

		this.http
			.post(
				this.baseUrl + '/api/HydroTestHose/Duplicate',
				{
					hoseId: this.hoseDetail.Id,
					duplicates: this.hosesToDuplicate
				}
			)
			.subscribe(
				_ => {
					this.formState = 'Hose duplicated.';
					this.modal.hide();
					this.triggerHoseListRefresh = this.triggerHoseListRefresh + 1;
					this.showDuplicateHoseButton = false;
					this.duplicateDisabled = false;
				},
				error => {
					this.modal.hide();
					const message = error.json().Message;
					this.errorMessage = message;
					this.formState = message;
				}
			);
	}

	duplicateHoses_click(): void {
		// modal1.show();
		this.showDuplicateHoseButton = false;
	}

	hydroTestDataState(): string {
		return this.dataService.CheckHydrotestData();
	}

	deleteThisHose(): void {
		// console.log('delete: hoseid:' + this.hoseDetail.Id);
		if (this.hoseDetail.Id === 0) {
			this.handlingDeleteCompleted('');
		} else {
			this.formState = 'Deleting hose ....';

			this.hoseService
				.delete(this.hoseDetail)
				.subscribe(
					data => {
						this.handlingDeleteCompleted(data);
					},
					error => this.handleAddUpdateError(error.json().message)
				);
		}
	}
	handlingDeleteCompleted(data: any) {
		this.createNewHoseVisible = true;
		this.formState = 'no hose selected';
		this.showStuff = false;
		this.showTabs = false;
		this.hoseDetail = null;
		this.clearFormText();
		this.triggerHoseListRefresh = this.triggerHoseListRefresh + 1;
	}

}
