import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IPartcat} from './Partcat-model/Partcat';
import { IPartcats } from './Partcat-model/Partcats';
import { IPartcatSearch } from './Partcat-model/PartcatSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class PartcatService {
	private baseUrl;
	public pageTitle = 'Part Category List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Partcats';
	}

	getPartcat(id: number):  Observable<IPartcat> {
		if (id === 0) {
			return of(this.initializePartcat());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IPartcat>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	savePartcat(partcat: IPartcat): Observable<any> {
		if (partcat.Id === 0) {
			return this.createPartcat(partcat);
		}
		return this.updatePartcat(partcat);
	}

	private createPartcat(partcat: IPartcat): Observable<any> {
		partcat.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/partcatcreate', partcat)
			.pipe(retry(1), catchError(this.processError));
	}

	private updatePartcat(partcat: IPartcat): Observable<IPartcat> {
		return this.authHttp.put<IPartcat>(this.baseUrl, partcat)
			.pipe(retry(1), catchError(this.processError));
	}

	deletePartcat(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializePartcat(): IPartcat {
		return {
			Id: 0,
			PriceLine: '',
			Tag: '',
			CreatedBy: '',
			CreatedDate: '',
			UpdatedBy: '',
			UpdatedDate: '',
		} as IPartcat;
	}

	public getRecordSet(search: IPartcatSearch): Observable<IPartcats> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('PriceLine', search.PriceLine, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Tag', search.Tag, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('PriceLine', search.PriceLine ? search.PriceLine : '')
			.set('Tag', search.Tag ? search.Tag : '')
		};

		return this.authHttp.get<IPartcats>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IPartcats>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IPartcatSearch { 
		let search: IPartcatSearch = {
			Id: 1,
			PriceLine: '',
			Tag: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IPartcatSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('PriceLine', search.PriceLine ? search.PriceLine : '')
			.set('Tag', search.Tag ? search.Tag : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getTagList(): Observable<any> {
		const url = `${ this.baseUrl}/gettaglist`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	getPriceLineList(): Observable<any> {
		const url = `${ this.baseUrl}/getpricelinelist`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	getCustomerWithPartsList(): Observable<any> {
		const url = `${ this.baseUrl}/getcustomerswparts`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
}