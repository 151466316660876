import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../../../../shared/application-settings';
import { AppPropertiesService } from '../../../app-properties.service';

@Injectable()
export class FileUploadService {
	appPropertiesService: AppPropertiesService;
	baseUrl: string;

	constructor(
		private http: HttpClient,
		private appSettings: ApplicationSettings,
		appPropertiesService: AppPropertiesService) {
			this.appPropertiesService = appPropertiesService;
			this.baseUrl = appSettings.retrieveUrl();
}

	upload(fileToUpload: any, id: number): any {
		const input: any = new FormData();
		input.append('file', fileToUpload);
		input.append('id', id);

		return this.http
			.post(this.baseUrl + '/api/hydrotesthose/ReportImage?id=' + id.toString(), input);
	}
}
