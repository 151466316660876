<div class='panel panel-primary animaPlaceholder' *ngIf='video'>
	<div class='panel-heading'>
		<span *ngIf='video'>{{ page }} :{{ video.Id }}</span>
		<span class='pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<form class='form-horizontal' novalidate [formGroup]='videoForm'>
					<fieldset>
						<div class='panel-body'>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Title</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Title' />
									<span
										*ngIf="videoForm.controls['Title'].invalid  && videoForm.controls['Title'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Category</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Category' />
									<span
										*ngIf="videoForm.controls['Category'].invalid  && videoForm.controls['Category'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> File Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='FileName' />
									<span
										*ngIf="videoForm.controls['FileName'].invalid  && videoForm.controls['FileName'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Notes</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Notes' />
									<span
										*ngIf="videoForm.controls['Notes'].invalid  && videoForm.controls['Notes'].errors.maxlength"
										class="text-danger">
										Only 4000 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Vimeo Id</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='VimeoId' readonly/>
								</div>
							</div>
							<app-vimeo *ngIf="video && video.Id !== 0" [fileName]="video.FileName" [description]="video.Notes"
								(uploadFinished)="videoUploaded($event)" >
							</app-vimeo>						
						</div>								
					</fieldset>				
				</form>
			</div>
			<div class="col-md-6">
				<div class="col-md-6 col-sm-6 col-xs-12" >
					<div id="vimeo-viewer" ></div>
				</div>
			</div>
		</div>
		<div class='row' style='padding: 15px;'>
			<div class='col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='saveVideo(false, false)'
						style='width: 110px; margin-right:10px' [disabled]='!videoForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='saveVideo(true, false)'
						style='width: 150px; margin-right:10px' [disabled]='!videoForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>
					<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
						(click)='saveVideo(false, true)' style='width: 190px; margin-right:10px'
						[disabled]='!videoForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
					</button>
				</span>

				<span class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='cancel()'
						style='width: 150px; margin-right:10px' [disabled]='progressStatus'>
						Cancel &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='deleteVideo()'
						style='width: 150px; margin-right:10px' [disabled]='!videoForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
					</button>
				</span>
			</div>
		</div>

		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='video'>{{video.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='video'>{{video.UpdatedDate}}</label></span>
			</div>
		</div>
	</div>
</div>
<div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>