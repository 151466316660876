import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReportData } from './IReportData';

 
@Injectable()
export class ApplicationSettings {
	baseUrl = '';
	vimeoUrl = '';
	vimeoToken = '';
	serviceBaseUrl = '';
	legacyAppUrl = '';
	PLCUrl = '';
	glPrintingUrl = '';

	// Legacy app variables
	appPage = '';
	user: string;

	public pageSizes = [
		'10'
		,'15'
		,'20'
		,'50'
		, '100'
		, '500'
	  ];
	  public divisions: string[] = [
		''
		, 'GLM' 
		, 'Pulsar'
		, 'Green Line'
	  ];

	public ncrCodes: string[] = [
		''
		, 'Customer At Fault'
	
		, 'Supplier Defect/Adapters'
		, 'Supplier Defect/Cover Issues'
		, 'Supplier Defect/Incorrect Length'
		, 'Supplier Defect/Incorrect Labelling'
		, 'Supplier Defect/Incorrect Fitting(s)'
		, 'Supplier Defect/Thread Issues'
		, 'Supplier Defect/Other'
		, 'Supplier Defect/Pin Holes'
		, 'Supplier Defect/Plating'
		, 'Supplier Defect/Swivel Nut Crimp Issues'
		, 'Green Line Error/Adapters'
		, 'Green Line Error/Assembly Failed or Leaks'
		, 'Green Line Error/Data Entry'
		, 'Green Line Error/Incorrect Crimp Spec'
		, 'Green Line Error/Incorrect Orientation'
		, 'Green Line Error/Not Fully Installed'
		, 'Green Line Error/Not Crimped'
		, 'Green Line Error/Packaging, Kitting'
		, 'Not Green Line Product'
		, 'Safety'
		, 'Other'
  
	  ];

	  public statuses: string[] = [
		''
		, 'Cancelled' 
		, 'Closed'
		, 'Open'
		
	  ];
	constructor(public http: HttpClient) {}

	public getUrl() {
		return new Promise((resolve, reject) => {
			this.http
				.get('config.json') // path of config.json file
				.subscribe(
					(data: any) => {
						this.baseUrl = data.baseUrl;
						this.serviceBaseUrl = data.serviceBaseUrl;
						this.legacyAppUrl = data.legacyAppUrl;
						this.vimeoUrl = data.vimeoUrl;
						this.vimeoToken = data.vimeoToken;
						this.PLCUrl = data.PLCUrl;
						this.glPrintingUrl = data.glPrintingUrl;
						resolve(true);
					},
					err => console.log(err)
				);
		});
	}

	retrievePLCUrl(): string {
		return this.PLCUrl;
	}

	retrieveUrl(): string {
		return this.baseUrl;
	}

	retrieveVimeoUrl(): string {
		return this.vimeoUrl;
	}

	retrieveVimeoToken(): string {
		return this.vimeoToken;
	}

	retrieveLegacyAppUrl(): string {
		return this.legacyAppUrl;
	}

	retrieveGlPrintingUrl(): string {
		return this.glPrintingUrl;
	}

	public getIReportData() : IReportData { 
		let r: IReportData = {
			ReportType: '',
			ReportName: '',
			Parameter1: '',
			Parameter2: '',
			Parameter3: ''
		};
		return r;
	};

	public retrievePageSize(): string
    {
      let retVal = "";
      retVal = localStorage.getItem('rows');
      if(isNaN(+retVal)||retVal==null){
		retVal = '10';
		localStorage.setItem('rows', '10');
	  }
        return retVal;
    }

	public getOrderByDirection(orderby: string): string {
		let retVal = '';
		let split = orderby.split('::');
	
		if (split.length > 1)
		{
		  retVal = split[0];
		}
		return retVal;
	  }

	  public getOrderByName(orderby: string): string {
		let retVal = '';
		let split = orderby.split('::');
	
		if (split.length > 1)
		{
		  retVal = split[1];
		}
		return retVal;
	  }

	  public setPageSize(pageSize: string): void {
		localStorage.setItem('rows', pageSize);
	}

}
