import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BranchlabelprinterService } from 'src/app/branchlabelprinters/branchlabelprinter-service';
import { CalibrationServiceService } from './calibration-service.service';
import { Calibration } from './calibration.model';
import { DataFromPLC } from './datafromPLC.model';

const CHAT_URL = "ws://localhost:5001/chathub";

export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}


@Component({
	selector: 'app-root',
	templateUrl: './calibration.component.html',
	styleUrls: ['./calibration.component.css']
})

export class CalibrationComponent implements OnInit, OnDestroy {
	title = 'Calibration Setup';
	macAddress = '';
	plcValue = 0;
	plcValueHighPressure = 0;
	plcPressure = 0;
	pressureSwitch = 'low';
	pressureSwitchHighPressure = 'high';
	retval: any;
	cal300: Calibration;
	calZero: Calibration;
	pcCalibrationList: Calibration[];
	pcCalibrationListHighPressure: Calibration[];
	result: DataFromPLC;
	private startTime: number;
	private timer: any;
	private timerReadPlcValues: any;
	errorMessages: string[] = [];
	readingFromPlc = false;
	pressureType = 'LowPressure';
    localGLHoseFlag=false;

	public respLocalGLHose: any;
	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};
	public serverStatus = false;
	private subject: AnonymousSubject<MessageEvent>;
	public messages: Subject<Message>;


	constructor(private calibrationService: CalibrationServiceService
		, elementRef: ElementRef
		, private brnchlblprnterService: BranchlabelprinterService
		, private auth: AuthService) {

		this.connectServer();

	}

	ngOnInit() {
		this.localGLHoseFlag=this.auth.branchHydrotestUpdated();
		this.init();
	}

	ngOnDestroy() {
		this.stopReadPlcValues();
		this.stop();
	}

	init() {
		// Get Mac Address first, then get calibration data
		// only if everything passes without errors start reading plc values
		this.calibrationService.getMacAddress()
			.subscribe(macAddress => {
				console.log(macAddress);
				this.macAddress = macAddress;
				this.calibrationService.getCalibrationData(this.pressureSwitch, macAddress)
					.do(result => {
						this.pcCalibrationList = result;
						console.log(this.pcCalibrationList);
					})
					.concatMap(_ => {
						return this.calibrationService.getCalibrationData(this.pressureSwitchHighPressure, macAddress)
							.do(result => {
								this.pcCalibrationListHighPressure = result;
							});
					})
					.subscribe(_ => {
						this.startReadPlcValues();
					}, error => {
						this.errorMessages.push(error);
					});
			}, error => this.errorMessages.push(error));
	}

	clickSet300(type: string): void {
		this.cal300 = new Calibration();
		this.cal300.Pressure = 300;
		this.cal300.MacAddress = this.macAddress;

		if (type === 'low') {
			this.cal300.PLCValue = this.plcValue;
			this.cal300.Id = 1;
			this.cal300.PressureSwitch = 'low';
		} else if (type === 'high') {

			this.cal300.PLCValue = +this.plcValueHighPressure;
			this.cal300.Id = 3;
			this.cal300.PressureSwitch = 'high';
			this.cal300.Pressure = 10000;
		}

		this.calibrationService.saveCalibrationData(this.cal300)
			.subscribe((result) => {
				this.calibrationService.getCalibrationData(type, this.macAddress)
					.subscribe(data => {
						if (type === 'low') {
							this.pcCalibrationList = data;
						} else if (type === 'high') {
							this.pcCalibrationListHighPressure = data;
						}
					});
			},
				error => this.errorMessages.push(<any>error));
	}

	clickSetZero(type: string): void {
		this.calZero = new Calibration();
		this.calZero.Pressure = 0;
		this.calZero.MacAddress = this.macAddress;

		if (type === 'low') {
			this.calZero.PLCValue = this.plcValue;
			this.calZero.Id = 2;
			this.calZero.PressureSwitch = 'low';
		} else if (type === 'high') {
			this.calZero.PLCValue = +this.plcValueHighPressure;
			this.calZero.Id = 4;
			this.calZero.PressureSwitch = 'high';
		}

		this.calibrationService.saveCalibrationData(this.calZero)
			.subscribe((result) => {
				this.calibrationService.getCalibrationData(type, this.macAddress)
					.subscribe(data => {
						if (type === 'low') {
							this.pcCalibrationList = data;
						} else if (type === 'high') {
							this.pcCalibrationListHighPressure = data;
						}
					});
			},
				error => this.errorMessages.push(<any>error));
	}

	// value refresh
	refreshPlcValue(): void { }

	refreshPlcValuePressureHigh(): void {
		this.calibrationService.getPlcValueHighPressure()
			.subscribe(result => this.plcValueHighPressure = +result[0].pressure,
				error => this.errorMessages.push(<any>error));
	}

	refreshAllValues() {
		if (!this.readingFromPlc) {
			this.readingFromPlc = true;
			this.calibrationService.getPlcValue()
				.do(result => {
					this.plcValue = parseInt(result[0].pressure, 10);
				})
				.concatMap(_ =>
					this.calibrationService.getPlcValueHighPressure()
						.do(result => this.plcValueHighPressure = +result[0].pressure))
				.subscribe(_ => {
					this.readingFromPlc = false;
				},
					error => {
						this.readingFromPlc = false;
						this.errorMessages.push(<any>error);
						this.stopReadPlcValues();
					});
		}
	}

	getPlcValuesNew(): any {

		return () => {

			this.messages.next(this.trnsfrData);
			//this.plcValue = this.trnsfrData.content
			console.log("calibration::getPlcValuesNew");
			console.log(this.trnsfrData);

		};
	}

	// All of the timer stuff.....

	stopReadPlcValues(): void {
		clearInterval(this.timerReadPlcValues);
	}

	startReadPlcValues(): void {
		if (this.localGLHoseFlag) {
			this.getConnectionInfo();
		}
		else {
			this.timerReadPlcValues = setInterval(() => {
				this.refreshAllValues();
			}, 1500);
			this.startTime = _now();

		}

	}

	stop(): void {
		clearInterval(this.timer);
	}


	//Connecting websocket//
	private create(url): AnonymousSubject<MessageEvent> {
		let ws = new WebSocket(url);
		ws.onopen = openevent => {
			console.log("Successfully connected: " + url);
			this.serverStatus = true;
			this.trnsfrData = {
				source: 'hydrotest client',
				content: [],
				message: 'register client',
				function: 'registerAngularClient'
			};
			this.messages.next(this.trnsfrData);
			// this.receivePrinterList();
		}

		let observable = new Observable((obs: Observer<MessageEvent>) => {
			ws.onmessage = obs.next.bind(obs);
			ws.onerror = obs.error.bind(obs);
			ws.onclose = obs.complete.bind(obs);
			return ws.close.bind(ws);
		});
		let observer = {
			error: null,
			complete: null,
			next: (data: Object) => {  //sending messge to websocket
				console.log('Message sent to websocket: ', data);
				if (ws.readyState === WebSocket.OPEN) {
					ws.send(JSON.stringify(data));
				}
				else {
					this.serverStatus = false;
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, observable);
	}

	getConnectionInfo() {
		let ip: string = '';
		//let type: any = "Both";
		let port: number = 502;
		let type: any = this.pressureType;

		console.log('get connection info');

		console.log('type: ' + type);
		let branchid = this.auth.getBranchId();

		console.log('branch: ' + branchid.toString());

		this.brnchlblprnterService.getBrnchLblPrntrData(branchid, type).subscribe(
			resp => {
				//console.log('response: ' + resp.length.toString());
				console.log('calibration.component::getConnectionInfo');
				console.log(resp);
				if (resp.length != 0) {
					ip = resp[0].IP;

					if(resp[0].Port>1)
					   port = resp[0].Port;

					let plcchannel = resp[0].PLCChannel;
					this.trnsfrData = {
						source: 'hydrotest client',
						content: [],
						message: 'Reading From PLC',
						function: 'readFromPlc'
					};
					//this.trnsfrData.content.push(type);
					this.trnsfrData.content.push(type);
					this.trnsfrData.content.push(ip);
					this.trnsfrData.content.push(plcchannel);
					this.trnsfrData.content.push(port)

					this.timer = setInterval(this.getPlcValuesNew(), 1500);
					this.startTime = (new Date()).getTime();
				}

			})
	}
	public connect(url): AnonymousSubject<MessageEvent> {
		this.subject = this.create(url);
		return this.subject;
	}
	connectServer() {
		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			}
			)
		);
		//Response section from websocket Start//
		this.messages.subscribe(msg => {
			if (msg.function == "receiveFromPLC") {
				console.log("responsefromlocalglhose");
				console.log(msg);
				this.respLocalGLHose = msg.content[0];

				if (this.pressureType == "LowPressure") {
					this.plcValue = msg.content[0];
				}
				else if (this.pressureType == "HighPressure") {
					this.plcValueHighPressure = msg.content[0];
				}

			}
			else {
				console.log("Response from websocket: " + msg.message);
			}
		});
		//Response section from websocket End//
	}
	deleteCalibrationRecord(type, id) {
		this.calibrationService.deleteRecord(id).subscribe(() => {
			this.calibrationService.getCalibrationData(type, this.macAddress)
				.subscribe(data => {
					if (type === 'low') {
						this.pcCalibrationList = data;
					} else if (type === 'high') {
						this.pcCalibrationListHighPressure = data;
					}
				});
		},
			(error: any) => this.errorMessages.push(<any>error));
	}
	tabClick(tabtype) {
		this.stopReadPlcValues();
		this.stop();
		
		if(tabtype=="LowPressure"){
			this.pressureType = 'LowPressure';
			this.startReadPlcValues();
		}
		else {
			this.pressureType = 'HighPressure';
			this.startReadPlcValues();
		}
		
	}
}

function _now(): number {
	return (new Date()).getTime();
}
