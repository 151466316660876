import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';

@Injectable()
export class NewFromCrimpService {
	private baseUrl;
	public message = { MessageType: '', Message: '' };

	constructor(
		private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/crimp/GetNewFromCrimp';
	}
}

