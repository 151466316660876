<div class="panel panel-primary animaPlaceholder">
	<div class="panel-heading">
		{{ pageTitle }}
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>

	<div class="panel-body">
		<form class="form-horizontal" novalidate (ngSubmit)="editRole()" [formGroup]="editRoleForm">
			<div class="form-group">
				<label class="col-md-1 control-label" for="Name">Name</label>
				<div class="col-md-3">
					<input class="form-control" id="Name" type="text" placeholder="Name (required)" formControlName="Name" />
				</div>
				<span class="text-danger" *ngIf="displayMessage.Name">
					{{displayMessage.Name}}
				</span>
			</div>
			<div class="row">
				<div class="col-md-12">
					<span>
						<button class="btn btn-primary btn-sm" type="submit" [disabled]='!editRoleForm.valid'>
							<span class="glyphicon glyphicon-floppy-save"></span> Save
						</button>
						<span>
							<a class="btn btn-default btn-sm" [routerLink]="['/roles']">
								Cancel
							</a>
						</span>
					</span>
				</div>
			</div>
		</form>
	</div>
</div>
