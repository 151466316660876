import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardAdmin  {
	constructor(private auth: AuthService, private router: Router) {}

	/**
	 *  User route guard. If user is not
	 *  admin then redirection to welcome page is performed
	 */
	canActivate() {
		if (this.auth.isAdmin()) {
			return true;
		} else {
			this.router.navigateByUrl('/welcome');
			return false;
		}
	}
}
