import { FittingGridService } from './fitting-list/fitting-list.service';
import { FittingEditComponent } from './fitting-edit/fitting-edit.component';
import { FittingListComponent } from './fitting-list/fitting-list.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FittingService } from './fitting.service';
import { MaterialModule } from 'src/app/material-module';


@NgModule({
	declarations: [
		FittingListComponent
		, FittingEditComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'fittings', component: FittingListComponent },
			{ path: 'fitting-details/:Id', component: FittingEditComponent },
		])
	],
	providers: [
		FittingGridService
		, FittingService
	]
})
export class FittingModule { }
