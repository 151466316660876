import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationSettings } from '../../../shared/application-settings';
import { Users } from '../../app-classes';
import { AppPropertiesService } from '../../app-properties.service';
import { DataService } from '../../data/data-service';
import { IHydroTest } from '../hydro-test-model/hydro-test';

export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}


@Component({
	selector: 'http-hydrotestcertificate',
	templateUrl: './hydro-test-certificate.html',
	styleUrls: ['./hydro-test-certificate.css'],
	encapsulation: ViewEncapsulation.None
})

// tslint:disable-next-line:component-class-suffix
export class HydroTestCertificate implements OnInit {
	hydroTest: IHydroTest;
	@Output() hydroTestIdUpdated: EventEmitter<any> = new EventEmitter();

	public returnData: string;
	public query = '';
	public elementRef: any;
	public errorMessage: string;
	public baseUrl: string;

	public yesNo: any[];
	public testingMachineOptions: any[];
	public plcOptions: any[];

	public listOfCustomersFiltered: Customer[];
	public valueChanged = false;
	public valueNormalizer = (text: Observable<string>) => text.map(_ => null);

	staffList: Users[];
	customersData = [];
	CustomerSearchForm: UntypedFormGroup;

	public listCustomers: Array<CustomerLookup> = [];
	public listCustomersNames: Array<string> = [];
	customerfilteredOptions: Observable<string[]>;

	public respLocalGLHose: any;

	loadingCustomer = true;

	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};
	public serverStatus = false;

	constructor(
		private fb: UntypedFormBuilder,
		private auth: AuthService,
		public http: HttpClient,
		public appPropertiesService: AppPropertiesService,
		private appSettings: ApplicationSettings,
		private dataService: DataService
	) {

		this.baseUrl = this.appSettings.retrieveUrl();

		this.yesNo = [
			{ name: '', value: '' },
			{ name: 'Yes', value: 'Y' },
			{ name: 'No', value: 'N' }
		];

		this.testingMachineOptions = [
			{ name: 'File', value: 'File' },
			{ name: 'PLC Direct', value: 'PLC Direct' }
		];

		this.plcOptions = [
			{ name: 'High', value: 'High' },
			{ name: 'Low', value: 'Low' }
		];
		// this.connectServer();
	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngOnInit(): void {

		this.CustomerSearchForm = this.fb.group({
			Customer: [null],
		});

		this.appPropertiesService.getUsers()
			.subscribe((obj: Users[]) => {
				this.staffList = obj;
				this.getData2();
			},
				(error: any) => {
					this.errorMessage = <any>error;
				});

		this.customerfilteredOptions = this.CustomerSearchForm.controls.Customer.valueChanges
			.pipe(
				startWith(''),
				map(value => this.handleFilter(value))
			);
		this.getAllCustomers();
	}


	public getData2() {
		this.appPropertiesService.getListOfTestLocations2()
			.subscribe((data => {
				this.appPropertiesService.testLocations = data;
				this.initializeBranch(data);
				this.getData3();
			}),
				_ => () => console.error('error getting list of locations'));

	}

	public getData3() {
		this.dataService.evGotData.subscribe((data: string) => {
			this.GotData(data);

		});
		if (this.dataService.gotData) {
			this.GotData('');
		}
	}

	selectCustomerAutocomplete(value): void {
		this.hydroTest.customer = '';
		this.hydroTest.address = '';
		this.hydroTest.city = '';
		this.hydroTest.postalCode = '';
		this.hydroTest.province = '';
		let customer = this.listCustomers.find(
			x => (x.customerId + ' ' + x.Customer) == value
		);
		if (customer) {
			this.CustomerSearchForm.controls.Customer.setValue(customer.Customer);
			this.hydroTest.customer = customer.Customer;
			this.hydroTest.address = customer.Address1;
			this.hydroTest.city = customer.City;
			this.hydroTest.postalCode = customer.PC;
			this.hydroTest.province = customer.Pr;
		}
	}

	onCustomerFilterChange(value): void {
		//console.log('onCustomerFilterChange()');
		this.appPropertiesService.getThirtyCustomers(value)
			.subscribe(customers => {
				this.hydroTest.customer = '';
				this.hydroTest.address = '';
				this.hydroTest.city = '';
				this.hydroTest.postalCode = '';
				this.hydroTest.province = '';
				if (customers[0]) {
					this.hydroTest.customer = customers[0].Customer;
					this.hydroTest.address = customers[0].Address1;
					this.hydroTest.city = customers[0].City;
					this.hydroTest.postalCode = customers[0].PC;
					this.hydroTest.province = customers[0].Pr;
				}
				//console.log(customers);
			}, _ => this.errorMessage = 'Error loading customers from the server');
	}

	initializeBranch(data: any) {
		// tslint:disable-next-line:triple-equals
		if (this.hydroTest && this.hydroTest.hydroTestId == 0) {
			const branchId = this.auth.getBranchId();
			// tslint:disable-next-line:triple-equals
			const branch = data.find((br: any) => br.branchId == +branchId);

			// Default to currently logged in user's branch
			if (branch) {
				this.hydroTest.testLocation = branch.name;
			}
		}
	}

	GotData(val: string) {
		this.hydroTest = Object.assign({}, this.dataService.HydroTest);
		if (this.hydroTest && this.hydroTest.hydroTestId != 0) {
			this.CustomerSearchForm.controls.Customer.setValue(this.hydroTest.customer);
		}

		// default username to currently logged in user
		// default test machine and plc to stored values
		// tslint:disable-next-line:triple-equals
		if (this.hydroTest && this.hydroTest.hydroTestId == 0) {
			this.hydroTest.tester = this.auth.getUsername();
			this.loadStoredValue('testMachine');
			this.loadStoredValue('PLC');
		}
		this.valueChanged = false;
	}

	saveRecord(): void {
		this.dataService.saveHydroTest(this.hydroTest)
			.subscribe((res) => {
				this.returnData = res; this.onUpdateFinished();
			});
	}

	Cancel(): void {
		if (this.dataService.gotData) {
			this.GotData('');
		}
	}

	onUpdateFinished(): void {
		this.hydroTest.hydroTestId = +this.returnData;
		this.hydroTestIdUpdated.emit(this.hydroTest.hydroTestId);
		this.GotData('');
		this.valueChanged = false;
	}

	public onCustomerSelected(selected: any) {
		console.log('onCustomerSelected');
		console.log(selected);

		this.hydroTest.customer = '';
		this.hydroTest.address = '';
		this.hydroTest.city = '';
		this.hydroTest.postalCode = '';
		this.hydroTest.province = '';

		if (selected) {
			//console.log(selected);
			let cust = this.listOfCustomersFiltered.find(
				x => x.Customer == selected
			);
			if (cust) {
				this.hydroTest.customer = cust.Customer;
				this.hydroTest.address = cust.Address1;
				this.hydroTest.city = cust.City;
				this.hydroTest.postalCode = cust.PC;
				this.hydroTest.province = cust.Pr;
			}
		}
	}

	getAllCustomers() {
		this.loadingCustomer = true;
		this.http.get(this.baseUrl + '/api/customers/getallcustomer')
			.subscribe((data => this.setCustomerList(data)));
	}

	private handleFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.listCustomersNames.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 30);
	}

	ValueChanged(field?: string) {
		this.valueChanged = true;

		// For testMachine and PLC fields only cache the values
		// and return without transforming the value
		if (field === 'testMachine' || field === 'PLC') {
			localStorage.setItem(field, this.hydroTest[field]);
			return;
		}

		// This will transform the input field value to uppercase
		// if a field is defined
		if (field) {
			this.hydroTest[field] = (<string>this.hydroTest[field]).toUpperCase();
		}
	}

	loadStoredValue(field: string) {
		const storedValue = localStorage.getItem(field);
		if (storedValue) {
			this.hydroTest[field] = storedValue;
		}
	}

	isSalesOrderNumberValid(): void {
		const SO_REGEXP = new RegExp('^[ST][0-9][0-9][0-9][0-9][0-9][0-9][0-9]+\.[0-9][0-9][0-9]$');
		const SO_REGEXP1 = new RegExp('^[ST][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$');
		const test = SO_REGEXP.test(this.hydroTest.salesOrder);
		const test1 = SO_REGEXP1.test(this.hydroTest.salesOrder);
		if (test === false && test1 === false) {
			alert('An invalid sales order format was entered: ' + this.hydroTest.salesOrder
				+ '  You must enter a sales order number in the following format: '
				+ '(S1234567) or (S1234567.123). For a test record you can use T.');

			this.hydroTest.salesOrder = '';
		}
	}

	setCustomerList(data: any) {
		this.listCustomers = data;

		for (const h of this.listCustomers) {
			this.listCustomersNames.push(h.customerId + ' ' + h.Customer);
		}
		this.loadingCustomer = false;
	}
}




class Customer {
	public Id: number;
	public Customer: string;
	public Address1: string;
	public Address2: string;
	public City: string;
	public Pr: string;
	public PC: string;
	public CreatedDate: string;
	public CreatedBy: any;
	public UpdatedDate: string;
	public UpdatedBy: any;
	public Combined: string;
}


class CustomerLookup {
	public customerId: number;
	public Customer: string;
	public Address1: string;
	public City: string;
	public Pr: string;
	public PC: string;
}


