import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { PackageLabelComponent } from './package-label/package-label.component';
import { PackageLabelService } from './package-label-service';
import { MaterialModule} from '../material-module';
@NgModule({
	declarations: [
		PackageLabelComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'package-label', component: PackageLabelComponent },
		])
	],
	providers: [
		PackageLabelService
	]
})
export class PackageLabelModule { }
