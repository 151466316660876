import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentEditComponent } from './document-edit/document-edit.component';
import { DocumentHelpComponent } from './document-help/document-help.component';
import {TrainingDocumentListComponent} from './training-document-list/training-document-list.component';
import { WhmisDocumentListComponent } from './whmis-document-list/whmis-document-list.component';
import { DocumentService } from './document-service';

@NgModule({
	declarations: [
		DocumentListComponent
		, DocumentEditComponent
		, DocumentHelpComponent
		, TrainingDocumentListComponent
		, WhmisDocumentListComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'documents', component: DocumentListComponent },
			{ path: 'document-details/:Id', component: DocumentEditComponent },
			{ path: 'document-help', component: DocumentHelpComponent },
			{ path: 'training-document-list', component: TrainingDocumentListComponent },
			{ path: 'whmis-document-list', component: WhmisDocumentListComponent },
		])
	],
	providers: [
		DocumentService
	]
})
export class DocumentModule { }
