<div class="panel panel-primary">
	<div class='panel-heading'>
		<h3> Product Data</h3>
	</div>
	<div class="panel-body">
    <div class='row'>
        <div class='col-md-12'>
            <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                {{ errorMessage }}
            </div>
        </div>
    </div>
    <div> 
        <h2> Upload File</h2>
        <div class="row">
            <div class="col-md-11">
                <form class='form-horizontal' novalidate [formGroup]='uploadfileForm'>
                    <div class="col-md-10" style="margin-top: 50px;">
                        <input (change)="onFileSelected($event)" type="file" formControlName="fileName">
                    </div>
                </form>
            </div>
        </div>
        <div class="row" style="margin-top: 200px;">
            <div class="col-md-4">
                <button class="btn btn-primary" [routerLink]="['/productdata-menu']" [disabled]="progressStatus">Back to Menu</button>
            </div>
        </div>
    </div>
</div>
</div>
<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>