<div class="container">
    <div class='row'>
        <div class='col-md-12'>
            <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                {{ errorMessage }}
            </div>
        </div>
    </div>
    <h2> Customer Plan Records</h2>
    <h2>Step1. Upload File</h2>
    <div class="row">
        <div class="col-md-1">

        </div>
        <div class="col-md-11">
            <form class='form-horizontal' novalidate [formGroup]='uploadfileForm'>
            <!-- <div class="row">
                <label>Date</label>
                <input class='form-control' type='text' formControlName="date"/>
            </div>
            <div class="row">
                <label>Customers</label>
                <input class='form-control' type='text' formControlName="customer" />
            </div> -->
            <div class="row">
                <div class="col-md-6">
                    <label>Date</label>
                    <input class='form-control' type='date' formControlName="date" [attr.readonly]='processStatus? true : null'/>
                </div>
                <div class="col-md-6">
                    <label>Customers</label>
                    <input class='form-control' type='text' formControlName="customer" [matAutocomplete]="auto" [attr.readonly]='processStatus? true : null' />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let item of customersData" [value]="item.Customer">
                            {{item.Customer}}
                        </mat-option>
                    </mat-autocomplete>	
                </div>
              
            </div>
          

            <div class="col-md-10" style="margin-top: 50px;">
                <input (change)="onFileSelected($event)" type="file" formControlName="fileName" [attr.disabled]='processStatus? true : null'/>
            </div>
        </form>
        </div>
    </div>
    <div class="row">
     

    </div>

</div>
