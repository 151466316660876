import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';
import { IPackageLabel } from './package-label-model/package-label';
import { PrinterIpService } from '../shared/printer-ip.service';

@Injectable()
export class PackageLabelService {
	private baseUrl: string;
	private glPrintingUrl: string;

	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings,
		private printerIpService: PrinterIpService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/packagelabels';
		
	}

	createPackagelabel(packageLabel: IPackageLabel): Observable <any> {
		return this.authHttp.post(this.baseUrl, packageLabel)
			.catch (this.handleError);
	}

	createPackagelabelGlPrinting(packageLabel: IPackageLabel, branch: string): Observable <any> {
		this.glPrintingUrl = 'https://' + this.printerIpService.getIp(branch) +'/GLPrinting' + '/api/packagelabels';
		return this.authHttp.post(this.glPrintingUrl, packageLabel)
			.catch (this.handleError);
	}

	private handleError(error: any): Observable <any> {
		return Observable.throw (error.message || 'Server error');
	}
}
