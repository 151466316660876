import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { IHydrotestSearch } from '../hydrotest-model/hydrotestSearch';
import { IHydrotests } from '../hydrotest-model/hydrotests';
import { HydrotestService } from '../hydrotest-service';
import { HydrotestGridService } from './hydrotest-list.service';

@Component({
	providers: [HydrotestGridService],
	templateUrl: './hydrotest-list.component.html',
	styleUrls: ['./hydrotest-list.component.css']
})

export class HydrotestListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id','Contact','Customer', 'PurchaseOrder','TestLocation', 'Hose', 'End1','SalesOrderNumber'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public pageSize = '10';
	public pageSizeList: any[];
	public skip = 0;
	public sub: Subscription;
	public Contact = '';
	public Customer = '';
	public PurchaseOrder = '';
	public TestLocation = '';
	public Hose = '';
	public End1 = '';
	public SalesOrderNumber = '';

	public pageTitle = 'Hydro Test List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: HydrotestService) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='Contact')
				this.Contact = i.Value;

				if(i.Item=='Customer')
				this.Customer = i.Value;

			if(i.Item=='PurchaseOrder')
				this.PurchaseOrder = i.Value;

			if(i.Item=='TestLocation')
				this.TestLocation = i.Value;
			
			if(i.Item=='Hose')
				this.Hose = i.Value;

			if(i.Item=='End1')
				this.End1 = i.Value;

			if(i.Item=='SalesOrderNumber')
				this.SalesOrderNumber = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IHydrotestSearch = this._appService.getFreshSearch();

		search.Contact = this.Contact;
		search.Customer = this.Customer;
		search.PurchaseOrder = this.PurchaseOrder;
		search.TestLocation = this.TestLocation;
		search.Hose = this.Hose;
		search.End1 = this.End1;
		search.SalesOrderNumber = this.SalesOrderNumber;
	
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IHydrotests) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IHydrotests){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {

		this.Contact = '';
		this.Customer = '';
		this.PurchaseOrder = '';
		this.TestLocation = '';
		this.Hose = '';
		this.End1 = '';
		this.SalesOrderNumber = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/branches`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: IHydrotestSearch = this._appService.getFreshSearch();

			search.Contact = this.Contact;
			search.Customer = this.Customer;
			search.PurchaseOrder = this.PurchaseOrder;
			search.TestLocation = this.TestLocation;
			search.Hose = this.Hose;
			search.End1 = this.End1;
			search.SalesOrderNumber = this.SalesOrderNumber;

			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
}