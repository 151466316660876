import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CustomerconcernService } from '../customerconcern-service';

@Component({
  selector: 'app-customerconcern-mgmt',
  templateUrl: './customerconcern-mgmt.component.html',
  styleUrls: ['./customerconcern-mgmt.component.css']
})
export class CustomerconcernMgmtComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'Customer','NCRCode', 'Problem', 'EmployeeAssigned', 'Owner'];
	public errorMessage = '';
	public dataSource: any;
  public emailBtnPushed=false;

  constructor(
     private customerconcernService: CustomerconcernService
  ) { }

  ngOnInit(): void {
    this.customerconcernService.getOpenCustomerConcerns().subscribe(response => {
			console.log('list of customer concerns:');
			console.log(response);
      this.dataSource=response;
			
		});
  }

  sendEmailForOpenIssues(){
    this.emailBtnPushed=true;
    this.customerconcernService.sendEmailForOpenIssues(this.dataSource).subscribe(response => {
			console.log(response);
      alert(response);
      this.emailBtnPushed=false;
		});
  }

  sendEmailForOpenIssuesToCraig(){
    this.emailBtnPushed=true;
    this.customerconcernService.sendEmailForOpenIssuesToCraig(this.dataSource).subscribe(response => {
			console.log(response);
      alert(response);
      this.emailBtnPushed=false;
		});
  }
}
