<div class="panel panel-primary animated fadeIn">
	<div class="panel-heading">
		{{ pageTitle }}
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>

	<div class="panel-body">
		<form class="form-horizontal" novalidate (ngSubmit)="changePassword()" [formGroup]="changePasswordForm">
			<div class="form-group">
				<label class="col-md-2 control-label" for="OldPassword">
					Old Password</label>
				<div class="col-md-4">
					<input class="form-control" id="OldPassword" type="password" placeholder="Old Password (required)" formControlName="OldPassword"
					/>
				</div>
				<span class="text-danger" *ngIf="displayMessage.OldPassword">
					{{displayMessage.OldPassword}} </span>
			</div>
			<div class="form-group">
				<label class="col-md-2 control-label" for="NewPassword">
					New Password</label>
				<div class="col-md-4">
					<input class="form-control" id="NewPassword" type="password" placeholder="New Password (required)" formControlName="NewPassword"
					/>
				</div>
				<span class="text-danger" *ngIf="displayMessage.NewPassword">
					{{displayMessage.NewPassword}} </span>
			</div>
			<div class="form-group">
				<label class="col-md-2 control-label" for="ConfirmPassword">
					Confirm Password</label>
				<div class="col-md-4">
					<input class="form-control" id="ConfirmPassword" type="password" placeholder="Confirm Password (required)" formControlName="ConfirmPassword"
					/>
				</div>
				<span class="text-danger" *ngIf="displayMessage.ConfirmPassword">
					{{displayMessage.ConfirmPassword}} </span>
			</div>

			<div class="form-group">
				<div class="col-md-offset-4 col-md-2">
					<div class="pull-right">
						<a class="btn btn-default btn-flat" [routerLink]="['/welcome']"> Cancel </a>
						<button class="btn btn-primary btn-flat" type="submit" [disabled]='!changePasswordForm.valid'>
							<span class="glyphicon glyphicon-floppy-save"></span> Save
						</button>
					</div>
				</div>
			</div>

		</form>
	</div>
</div>
