<div class="col-md-12 nopadding form-my mb-40">
    <div class="card-form">
        <div class="form-title">
            <div class="col-md-11 text-center">
                {{ pageTitle }}
            </div>
            <div class='col-md-1 text-left'>
                <button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
            </div>
        </div>
        <div class='panel-body'>
            <div class='row'>
            <div class="col-md-12">
                <a class='btn btn-default btn-sm' [routerLink]="['/ferrule-details/0']">
                    <span class='glyphicon glyphicon-plus-sign'></span> &nbsp; Add New Ferrule
                </a>
            </div>
        </div>
        <br>
        <div class='row'>
            <div class="col-md-12">
            <div class='row filters' *ngIf='showHideSearch'>
                <div class='col-md-9'>
                    <div class='row'>
                    <div class='col-md-4'>
                        <label class='inline-label'> Ferrule</label>
                        <input type='text' class='form-control block-element' placeholder=' Ferrule' [(ngModel)]='Ferrule' />
                    </div>
                    </div>
                </div>
                <div class='col-md-3'>
                    <div class='row'>
                        <div class='col-md-12 text-right' style='margin-top: 15px;'>
                            <button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
                        </div>
                        <div class='col-md-12 text-right' style='margin-top: 15px;'>
                            <button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
                        </div>
                        <div class='col-md-12 text-right' style='margin-top: 15px;'>
                            <button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <br>

            <div class='table-container'>
                <table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
                    <ng-container matColumnDef='Id'>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
                        <td mat-cell *matCellDef='let e'>
                            <button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/ferrule-details', e.Id]">{{e.Ferrule}}</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef='InsideDiameter'>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> ID</th>
                        <td mat-cell *matCellDef='let e' class='col2' >{{e.InsideDiameter}}</td>
                    </ng-container>
                    <ng-container matColumnDef='WallThickness'>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Wall Thickness</th>
                        <td mat-cell *matCellDef='let e' class='col3' >{{e.WallThickness}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
                    <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
                </table>
                </div>
                <mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
                <div>
                    <mat-spinner color="primary" class="spinner" *ngIf="isLoadingResults"></mat-spinner>
                    </div>

                </div>
            </div>
            