import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { map, startWith } from 'rxjs/operators';
import { AppPropertiesService } from '../../hydro-test-legacy/app-properties.service';
import { IFerrule } from './../../part-management/ferrule/ferrule.model/ferrule';
import { IFitting } from './../../part-management/fitting/fitting-model/fitting';
import { IHose } from './../../part-management/hose/hose.model/hose';
import { ICrimp } from './../crimp-model/crimp';
import { CrimpService } from './../crimp.service';

import { FerruleService } from 'src/app/part-management/ferrule/ferrule.service';
import { FittingService } from 'src/app/part-management/fitting/fitting.service';
import { HoseService } from './../../part-management/hose/hose.service';

@Component({
	selector: 'app-crimp-edit',
	templateUrl: './crimp-edit.component.html',
	styleUrls: ['./crimp-edit.component.css']
})

export class CrimpEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	limitResults = 50; 
	hosefilteredOptions: Observable<string[]>;
	endonefilteredOptions: Observable<string[]>;
	endtwofilteredOptions: Observable<string[]>;

	endoneFerrulefilteredOptions: Observable<string[]>;
	endtwoFerrulefilteredOptions: Observable<string[]>;

	private sub: Subscription;
	public hosedataddl: IHose[] = [];
	public ferruledata1: IFerrule[] = [];
	public ferruledata2: IFerrule[] = [];
	public fittingdata1: IFitting[] = [];
	public fittingdata2: IFitting[] = [];
	public pageTitle = 'Crimp Management';
	public copyCrimp = false;
	public buttonPushed = false;
	public locked = true;

	nextHose = false;
	hoseWallInfo: any;
	message = { MessageType: '', Message: '' };
	crimpForm: FormGroup;
	crimp: ICrimp;
	isCrimp: boolean;
	emptyStringArray: string[] = [];

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		//public spinner: SpinnerService,
		private crimpService: CrimpService,
		private appPropertiesService:AppPropertiesService,
		private _hoseService: HoseService,
		private _ferruleService: FerruleService,
		private _fittingService: FittingService) { 

			appPropertiesService.getHosesAndFittings();
		}

	ngOnInit(): void {
		this.crimpForm = this.fb.group({
			Id: '',
			HoseName: ['', Validators.required],
			FerruleName: ['', Validators.required],
			FittingName: ['', Validators.required],
			FittingName_2: ['', Validators.required],
			FerruleName_2: ['', Validators.required],
			OrderNumber: ['', Validators.required],
			TestRequired: '',
			TestResults: '',
			End2Same: '',
			HoseOutsideDiameter: '',
			Compression: '',
			Measurement1: '',
			Measurement2: '',
			Measurement3: '',
			MeasuredHoseOD: '',
			OverrideCompression: '',
			CalculatedInch: '',
			CalculatedMM: '',
			Compression_2: '',
			Measurement1_2: '',
			Measurement2_2: '',
			Measurement3_2: '',
			MeasuredHoseOD_2: '',
			OverrideCompression_2: '',
			CalculatedInch_2: '',
			CalculatedMM_2: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			CrimpInstructions:'',
			IsCrimp:''
		});

		this.crimpForm.valueChanges
			.subscribe(_ => {
				if (this.copyCrimp) {
					this.copyCrimpData();
				}
			});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getCrimp(id);
			}
		);

		 this.hosefilteredOptions =  this.crimpForm.controls.HoseName.valueChanges
		 .pipe(
		 	startWith(''),
		 	map(value => this.handleFilter(value))
		   );

		   this.endonefilteredOptions =  this.crimpForm.controls.FittingName.valueChanges
		   .pipe(
			   startWith(''),
			   map(value => this.handleFilterEndOne(value))
			 );
   
		 this.endtwofilteredOptions =  this.crimpForm.controls.FittingName_2.valueChanges
		   .pipe(
			   startWith(''),
			   map(value => this.handleFilterEndTwo(value))
			 );
		
		
		 this.endoneFerrulefilteredOptions =  this.crimpForm.controls.FerruleName.valueChanges
		 .pipe(
			 startWith(''),
			 map(value => this.handleFerruleEndOne(value))
		  );
	 
		  this.endtwoFerrulefilteredOptions =  this.crimpForm.controls.FerruleName_2.valueChanges
		 .pipe(
			 startWith(''),
			 map(value => this.handleFerruleEndTwo(value))
		  );
	}


	saveCrimp(): void {
		this.buttonPushed = true;

		console.log('crimp values');
		console.log('compression:' + this.crimp.Compression);
		console.log('isCrimip' + this.isCrimp.toString());
		console.log('Override' + this.crimp.OverrideCompression);

		this.message = { MessageType: '', Message: '' };

		if (this.isCrimp && this.crimpForm.valid && !this.measurementsInvalid()) {
			const obj = Object.assign({}, this.crimp, this.crimpForm.value);
			obj.End2Same = this.copyCrimp ? 1 : 0;

			this.crimpService.saveCrimp(obj)
				.subscribe((data) => this.onSaveComplete(data),
					(error: any) => {
						this.message = { MessageType: 'ERROR', Message: <any>error };
						this.buttonPushed = false;
					});
		} else {
			this.message = {
				MessageType: 'ERROR',
				Message: 'The form is invalid or required field is empty !'
			};
		}

		

	}

	/**
	 * If any of the measurement fields is out of allowed range method should return true
	 */
	measurementsInvalid() {
		const controls = Object.keys(this.crimpForm.controls).filter(c => c.startsWith('Measurement'));
		for (const field of controls) {
			const control = this.crimpForm.get(field);
			if (!this.hoseWallInRange(control.value)) {
				return true;
			}
		}

		return false;
	}

	onSaveComplete(data: any): void {
		if (data !== null && this.crimp.Id === 0) {
			this.router.navigate(['/crimp-details', data.Id]);
		} else {
			this.crimp = data;
			this.message = { MessageType: 'Success', Message: 'Form saved successfully.' };
			this.buttonPushed = false;
		}
	}

	getCrimp(id: number): void {
		//this.spinner.start();
		this.crimpService.getCrimp(id)
			.subscribe((obj: ICrimp) => this.onCrimpRetrieved(obj),
				(error: any) => {
					this.message = { MessageType: 'ERROR', Message: <any>error };
					//this.spinner.stop();
				});

	}

	/**
	 * On Create it returns value 0.000, on View it formats the value to a precison of 3
	 * since the API cuts off the trailing zeros for numbers like 0.200
	 * @param data Measurement value
	 */
	formatMeasurement(data: number) {
		if (!data) {
			data = 0;
		}
		if(data>0){
		return data.toFixed(3);
		}else{
			return '';
		}
	}

	onCrimpRetrieved(crimp: ICrimp): void {
		// Before reseting the form save the old form value
		// since nextHose feature might need it.
		let oldForm: any = {};
		if (this.crimpForm) {
			oldForm = Object.assign({}, this.crimpForm.value);
			this.crimpForm.reset();
		}

		this.crimp = crimp;

		if (this.crimp.Id === 0) {
			this.pageTitle = 'Add Crimp';
			// This puts an overlay over the page on non-create view
			this.locked = false;
		} else {
			this.pageTitle = 'Edit Crimp';
			//this.handleHoseFilter(this.crimp.HoseName);
		}

		// if(this.isNotNullOrEmpty(this.crimp.Compression)){
		// 	this.crimp.Compression = 0;
		// }
		
		// If a user clicks on Clear Measurement for Next Hose
		// Populate part of the form from old form value
		// In other case it will populate it with existing crimp data
		// or with default data if user navigated through "Add Crimp Details"
		if (this.nextHose) {
			this.nextHose = false;
			this.crimpForm.patchValue({
				HoseName: oldForm.HoseName,
				FerruleName: oldForm.FerruleName,
				FittingName: oldForm.FittingName,
				FerruleName_2: oldForm.FerruleName_2,
				FittingName_2: oldForm.FittingName_2,
			});
		} else {
			this.crimpForm.patchValue({
				HoseName: this.crimp.HoseName,
				FerruleName: this.crimp.FerruleName,
				FittingName: this.crimp.FittingName,
				FerruleName_2: this.crimp.FerruleName_2,
				FittingName_2: this.crimp.FittingName_2,
			});
		}
		


		// Populate all the other fields on the form
		this.crimpForm.patchValue({
			Id: this.crimp.Id,
			TestRequired: this.crimp.TestRequired,
			OrderNumber: this.crimp.OrderNumber,
			TestResults: this.crimp.TestResults,
			End2Same: this.crimp.End2Same,
			HoseOutsideDiameter: this.crimp.HoseOutsideDiameter,
			Compression: this.crimp.Compression,
			Measurement1: this.formatMeasurement(this.crimp.Measurement1),
			Measurement2: this.formatMeasurement(this.crimp.Measurement2),
			Measurement3: this.formatMeasurement(this.crimp.Measurement3),
			MeasuredHoseOD: this.crimp.MeasuredHoseOD,
			OverrideCompression: this.crimp.OverrideCompression,
			CalculatedInch: this.crimp.CalculatedInch,
			CalculatedMM: this.crimp.CalculatedMM,
			Compression_2: this.crimp.Compression_2,
			Measurement1_2: this.formatMeasurement(this.crimp.Measurement1_2),
			Measurement2_2: this.formatMeasurement(this.crimp.Measurement2_2),
			Measurement3_2: this.formatMeasurement(this.crimp.Measurement3_2),
			MeasuredHoseOD_2: this.crimp.MeasuredHoseOD_2,
			OverrideCompression_2: this.crimp.OverrideCompression_2,
			CalculatedInch_2: this.crimp.CalculatedInch_2,
			CalculatedMM_2: this.crimp.CalculatedMM_2,
			CreatedDate: this.crimp.CreatedDate,
			CreatedBy: this.crimp.CreatedBy,
			UpdatedDate: this.crimp.UpdatedDate,
			UpdatedBy: this.crimp.UpdatedBy
		});
		
		if(this.crimp.Hose){
		this.isCrimp = this.crimp.Hose.IsCrimp;
		}
		
		if (this.crimp.End2Same == 1) { this.copyCrimp = true; }
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	cancel(): void {
		this.router.navigate(['/crimp-list']);
	}

	selectHoseAutocomplete(value): void {
		const hose = this.appPropertiesService.listHoses.find(
			x => x.hoseName === value
		);
		
	   if (hose) {
		   this.crimpForm['HoseName'] = hose.hoseName;
		   this._hoseService.getHosebyId(hose.id)
			.subscribe((obj: IHose) => this.onHoseRetrieved(obj),
				(error: any) => {
					this.message = { MessageType: 'ERROR', Message: <any>error };
					//this.spinner.stop();
				});
	   } else {
		   return;
	   }
   }
   onHoseRetrieved(obj: IHose): void {
	console.log(obj);
	
	//if(this.isNotNullOrEmpty(this.crimp.Hose.Compression)){
//		this.crimp.Hose.Compression = 0;
//	}

	this.crimp.Hose = obj;
	this.crimp.Compression = this.crimp.Hose.Compression;
	this.crimp.Compression_2 = this.crimp.Hose.Compression;
	this.hoseWallInfo = (this.crimp.Hose.OutsideDiameter - this.crimp.Hose.InsideDiameter) / 2;
	this.crimpForm.patchValue({
		CrimpInstructions: this.crimp.Hose.CrimpInstructions,
		IsCrimp: this.crimp.Hose.IsCrimp		
	});

	console.log(this.crimp.Hose.IsCrimp);
	this.isCrimp = this.crimp.Hose.IsCrimp;
   }

	onCrimpCopyChange(value: boolean) {
		this.copyCrimp = value;
		if (this.copyCrimp) {
			this.copyCrimpData();
		}
	}

	copyCrimpData() {
		const measurement1 = this.crimpForm.get('Measurement1').value;
		const measurement2 = this.crimpForm.get('Measurement2').value;
		const measurement3 = this.crimpForm.get('Measurement3').value;
		const ferrule1 = this.crimpForm.get('FerruleName').value;
		const fitting2 = this.crimpForm.get('FittingName').value;
		const overrideCompression = this.crimpForm.get('OverrideCompression').value;

		this.crimpForm.patchValue({
			Measurement1_2: measurement1,
			Measurement2_2: measurement2,
			Measurement3_2: measurement3,
			FerruleName_2: ferrule1,
			FittingName_2: fitting2,
			OverrideCompression_2: overrideCompression
		}, { emitEvent: false });
	}

	onReset() {
		this.crimpForm.reset();
		this.message = { MessageType: '', Message: '' };
		this.hoseWallInfo = null;
	}

	onClearMeasurement() {
		this.nextHose = true;
		this.router.navigate(['crimp-details', 0]);
	}

	onOverrideCompressionChange(){
		const overrideCompression = this.crimpForm.get('OverrideCompression').value;
		this.crimp.OverrideCompression=overrideCompression;
	}

	/**
	 * Validates user's input. Value must be a decimal with precision of 3
	 * @param event Keydown event object
	 */
	validateFormat(event: any) {
		const input = event.target;
		const oldVal = input.value;
		const regex = new RegExp(input.getAttribute('pattern'), 'g');

		setTimeout(() => {
			const newVal = input.value;
			if (!regex.test(newVal)) {
				input.value = oldVal;
			}
		}, 0);
	}

	/**
	 * Formats the input value to decimal with precision of 3
	 * @param event Blur event object
	 */
	onBlur(event: any) {
		const value = Number(event.target.value).toFixed(3);
		const id = `${event.target.getAttribute('id')}`;
		if(value!='0.000'){
		this.crimpForm.get(id).setValue(value);
		}else{
			this.crimpForm.get(id).setValue('0');
		}
	}

	hoseWallValid(value: number): boolean {
		if (this.hoseWallInfo != null) {
			return this.hoseWallInRange(value);
		}

		return true;
	}

	/**
	 * Method that returns false if input field value is not in the allowed range
	 * @param value Measurement Input field value
	 */
	hoseWallInRange(value: number) {
		const minWallValue = +(this.hoseWallInfo - (this.hoseWallInfo * 0.1)).toFixed(3);
		const maxWallValue = +(this.hoseWallInfo + (this.hoseWallInfo * 0.15)).toFixed(3);
		if (value < minWallValue || value > maxWallValue) {
			return false;
		}

		return true;
	}

	/**
	 * Returns true if hose wall is invalid and the input field is touched
	 * or when hose wall is invalid and Calculate button is pressed.
	 * Returned value decides should the error message will be shown or not.
	 * @param element A reference to the measurement input field
	 */
	validateHoseWall(element: any) {
		const value = +Number(element.value).toFixed(3);
		const hoseWallValid = this.hoseWallValid(value);

		const fieldName = `${element.getAttribute('id')}`;
		const touched = this.crimpForm.get(fieldName).touched;

		return (!hoseWallValid && touched) || (!hoseWallValid && this.buttonPushed);
	}

	/**
	 * Generates string that represents allowed hose wall range which is used in the info
	 * message after hose selection
	 */
	getHoseWallRange() {
		const minWallValue = (this.hoseWallInfo - (this.hoseWallInfo * 0.1)).toFixed(3);
		const maxWallValue = (this.hoseWallInfo + (this.hoseWallInfo * 0.15)).toFixed(3);
		return `${minWallValue} - ${maxWallValue}`;
	}

	

	selectEndOneAutocomplete(value): void {
		let fitting = this.appPropertiesService.listFittings.find(
			x => x.fittingName == value
		);
		if(fitting){  
			this._fittingService.getFittingbyId(fitting.id)
			 .subscribe((obj: IFitting) => this.onfitting1Retrieved(obj),
			 (error: any) => {
				 this.message = { MessageType: 'ERROR', Message: <any>error };
				 //this.spinner.stop();
			 });	
		} else {
			return;
		}
	}
	onfitting1Retrieved(obj: IFitting): void {
 		this.crimp.Fitting = obj;
	}

	selectEndTwoAutocomplete(value): void {
		let fitting = this.appPropertiesService.listFittings.find(
			x => x.fittingName == value
		);
		if(fitting){  
			this._fittingService.getFittingbyId(fitting.id)
			 .subscribe((obj: IFitting) => this.onfitting2Retrieved(obj),
			 (error: any) => {
				 this.message = { MessageType: 'ERROR', Message: <any>error };
				 //this.spinner.stop();
			 });	
		} else {
			return;
		}
	}
	onfitting2Retrieved(obj: IFitting): void {
 		this.crimp.Fitting2 = obj;
	}

	selectFerruleOneAutocomplete(value): void {
		let ferrule = this.appPropertiesService.listFerrules.find(
			x => x.Ferrule1 == value
		);

		if(ferrule){  
			this._ferruleService.getFerrulebyId(ferrule.FerruleId)
			 .subscribe((obj: IFerrule) => this.onFerrul1Retrieved(obj),
			 (error: any) => {
				 this.message = { MessageType: 'ERROR', Message: <any>error };
				 //this.spinner.stop();
			 });	
		} else {
			return;
		}
	}
	onFerrul1Retrieved(obj: IFerrule): void {
 		this.crimp.Ferrule = obj;
	}

	
	selectFerruleTwoAutocomplete(value): void {
		let ferrule = this.appPropertiesService.listFerrules.find(
			x => x.Ferrule1 == value
		);
		if(ferrule){  
			this._ferruleService.getFerrulebyId(ferrule.FerruleId)
			 .subscribe((obj: IFerrule) => this.onFerrul2Retrieved(obj),
			 (error: any) => {
				 this.message = { MessageType: 'ERROR', Message: <any>error };
				 //this.spinner.stop();
			 });	
		} else {
			return;
		}
	}
	onFerrul2Retrieved(obj: IFerrule): void {
 		this.crimp.Ferrule2= obj;
	}

	
	
	
	
	private handleFilter(value: string): string[] {
		if(this.isNotNullOrEmpty(value)){
			const filterValue = value.toLowerCase();
			return this.appPropertiesService.listHoseStrings.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
		}
		return this.emptyStringArray;
	}

	private handleFilterEndOne(value: string): string[] {
		if(this.isNotNullOrEmpty(value)){
			const filterValue = value.toLowerCase();
			return this.appPropertiesService.listEndOne.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
		}
		return this.emptyStringArray;
	  }

	  private handleFilterEndTwo(value: string): string[] {
		if(this.isNotNullOrEmpty(value)){
			const filterValue = value.toLowerCase();
			return this.appPropertiesService.listEndTwo.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
		}
		
		return this.emptyStringArray;
	  }

	  // utils.ts
		private isNotNullOrEmpty(value: any): boolean {
			return value !== null && value !== '';
  		}
  
	  private handleFerruleEndOne(value: string): string[] {
		if(this.isNotNullOrEmpty(value)){
			const filterValue = value.toLowerCase();
		return this.appPropertiesService.listFerruleOne.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
		}
		return this.emptyStringArray;
	  }

	  private handleFerruleEndTwo(value: string): string[] {
		if(this.isNotNullOrEmpty(value)){
			const filterValue = value.toLowerCase();
			return this.appPropertiesService.listFerruleTwo.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
		}
		return this.emptyStringArray;
	  }
	  
	  private showCrimpCaluclation(isCrimp: boolean, compression: number, override: number):boolean{
		//console.log('isCrimp:' + isCrimp)
		if(isCrimp){
			if(override>0&&override<10){
				return false;
			}
			if(override==0&&compression<10){
				return false;
			}
		}
		else {
			return false
		}
		return true;
	  }
}

