<div class='panel panel-primary animated fadeIn'>
	<div class='panel-heading'>
		<div class="row">
			<div class="col-md-12">
				<div class="col-md-11 text-center">
					{{ pageTitle }}
				</div>
				<div class='col-md-1 text-left'>
					<button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
				</div>
			</div>
			<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
		</div>
	</div>

	<div class='panel-body'>
		<a class='btn btn-default btn-sm' [routerLink]="['/create-role']">
			<span class='glyphicon glyphicon-plus-sign'></span> Add New Role
		</a>
		<div class='table-responsive'>
			<table class='table table-hover' *ngIf='roles && roles.length'>
				<thead>
					<tr>
						<th>Name</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor='let role of roles'>
						<td>
							{{ role.Name }}
						</td>
						<td>
							<a class="btn btn-default btn-sm pull-right" [routerLink]="['/edit-role', role.Id]">
								<span class='glyphicon glyphicon-pencil'></span>
								Edit
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
