import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PrinterIpService } from '../../shared/printer-ip.service';
import { NonStockLabelService } from '../non-stock-service';
import { AuthService } from './../../auth/auth.service';

@Component({
	templateUrl: './non-stock.component.html',
	styleUrls: ['non-stock.component.css']
})

export class NonStockLabelComponent implements OnInit {
	public page = 'Non Stock Label';
	errorMessage: string;
	nonStockLabelForm: UntypedFormGroup;
	partsData = [];
	qtyTypes = ['ea', 'ft'];
	labelTypes = [
		{ label: '2 X 4', value: 'Package'},
		//{ label: '4 X 6', value: 'Box'},
	];
	public Branch: string;

	constructor(
		private authService: AuthService,
		private fb: UntypedFormBuilder,
		private nonStockLabelService: NonStockLabelService,
		private printerIpService: PrinterIpService) { }

	ngOnInit(): void {
		this.Branch = this.authService.getBranchId();

		this.nonStockLabelForm = this.fb.group({
			Id: '',
			PartNumber: ['', Validators.required],
			PartDescription: ['', Validators.required],
			Upc: ['', Validators.required],
			Quantity: ['', Validators.required],
			QtyType: ['', Validators.required],
			LabelType: ['', Validators.required],
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: [1, Validators.required],
			Customer: [''],
			SalesOrder: ['']
		});
	}

	print(): void {

		if (this.nonStockLabelForm.valid) {
			const obj = Object.assign({}, this.nonStockLabelForm.getRawValue());
			
			// if (!isNaN(obj.Upc)){
			// 	this.errorMessage = 'The upc code must be a number.'
			// 	return;
			// }
			if (obj.Quantity<=0) {
				this.errorMessage = 'The quantity must be above 0.'
				return;
			}

			this.nonStockLabelService.createNonStocklabel(obj)
				.subscribe(_ => this.onSaveComplete()
					, (_: any) => this.errorMessage = 'Error while printing the label');

			this.nonStockLabelService.createNonStockGlPrinting(obj, this.Branch)
				.subscribe(_ => this.onSaveComplete()
					, (_: any) => this.errorMessage = 'Error while printing the label (GL Printing)');
		}
	}

	onSaveComplete(): void {
		//this.errorMessage = '';
		this.nonStockLabelForm.reset();
		this.nonStockLabelForm.patchValue({
			LabelType: '',
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: 1
		});
	}

	printerIp(): string {
		return this.printerIpService.getIp(this.Branch);
	}

	resetError() : void {
		this.errorMessage = '';
	}
}
