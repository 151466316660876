import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IWalkin} from './Walkin-model/Walkin';
import { IWalkins } from './Walkin-model/Walkins';
import { IWalkinSearch } from './Walkin-model/WalkinSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class WalkinService {
	private baseUrl;
	public pageTitle = 'Walk In List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Walkins';
	}

	getWalkin(id: number):  Observable<IWalkin> {
		if (id === 0) {
			return of(this.initializeWalkin());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IWalkin>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveWalkin(walkin: IWalkin): Observable<any> {
		if (walkin.Id === 0) {
			return this.createWalkin(walkin);
		}
		return this.updateWalkin(walkin);
	}

	private createWalkin(walkin: IWalkin): Observable<any> {
		walkin.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/walkincreate', walkin)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateWalkin(walkin: IWalkin): Observable<IWalkin> {
		return this.authHttp.put<IWalkin>(this.baseUrl, walkin)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteWalkin(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeWalkin(): IWalkin {
		return {
			Id: 0,
			SalesOrder: '',
			DateString: '',
			CustomerName: '',
			Quantity: 0,
			EclipseId: 0,
			PartName: '',
			PartString: '',
			HoseType: '',
			Fitting1: '',
			Fitting2: '',
			Length: 0,
			Orientation: 0,
			SpecialInstructions: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IWalkin;
	}

	public getRecordSet(search: IWalkinSearch): Observable<IWalkins> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('SalesOrder', search.SalesOrder, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('DateString', search.DateString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CustomerName', search.CustomerName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Quantity', search.Quantity, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EclipseId', search.EclipseId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PartName', search.PartName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PartString', search.PartString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('HoseType', search.HoseType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Fitting1', search.Fitting1, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Fitting2', search.Fitting2, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Length', search.Length, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Orientation', search.Orientation, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SpecialInstructions', search.SpecialInstructions, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('FileName', search.FileName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('SalesOrder', search.SalesOrder ? search.SalesOrder : '')
			.set('DateString', search.DateString ? search.DateString : '')
			.set('CustomerName', search.CustomerName ? search.CustomerName : '')
			.set('Quantity', search.Quantity ? search.Quantity : '')
			.set('EclipseId', search.EclipseId ? search.EclipseId : '')
			.set('PartName', search.PartName ? search.PartName : '')
			.set('PartString', search.PartString ? search.PartString : '')
			.set('HoseType', search.HoseType ? search.HoseType : '')
			.set('Fitting1', search.Fitting1 ? search.Fitting1 : '')
			.set('Fitting2', search.Fitting2 ? search.Fitting2 : '')
			.set('Length', search.Length ? search.Length : '')
			.set('Orientation', search.Orientation ? search.Orientation : '')
			.set('SpecialInstructions', search.SpecialInstructions ? search.SpecialInstructions : '')
			.set('FileName', search.FileName ? search.FileName : '')
		};

		return this.authHttp.get<IWalkins>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IWalkins>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IWalkinSearch { 
		let search: IWalkinSearch = {
			Id: 1,
			SalesOrder: '',
			DateString: '',
			CustomerName: '',
			Quantity: '',
			EclipseId: '',
			PartName: '',
			PartString: '',
			HoseType: '',
			Fitting1: '',
			Fitting2: '',
			Length: '',
			Orientation: '',
			SpecialInstructions: '',
			FileName:'',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	addFile(file:any){
		console.log(file);
		const url = `${this.baseUrl}/fileupload`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	printWalkin(id: number) {
		const url = `${ this.baseUrl}/printwalkin?walkinid=${ id}`;
		return this.authHttp.get<string>(url)
			.pipe(retry(1), catchError(this.processError));
	}
}