<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>	
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/developmenttask-details/0']" style='width:auto;'>Add New Development Task</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: 80px' (click)='showHideSearchClick()'>{{SearchText}}</button>
		</div>
		<div class='col-md-2  div-list-sml divexcel'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: 100px' (click)='toExcel()'>To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Name</label>
				<input type='text' class='form-control block-element' placeholder=' Name' [(ngModel)]='Name' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Branch </label>
				<select [(ngModel)]='BranchId' class='form-control block-element'>
					<option value=""> </option>
					<option *ngFor='let branch of branches' [value]="branch.BranchNumber">{{branch.BranchName}}
						{{branch.BranchNumber}}</option>
				</select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Description</label>
				<input type='text' class='form-control block-element' placeholder=' Description' [(ngModel)]='Description' />
			</div>
		</div>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Estimated Hours</label>
				<input type='text' class='form-control block-element' placeholder=' Estimated Hours' [(ngModel)]='EstimatedHours' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Priority</label>
				<input type='text' class='form-control block-element' placeholder=' Priority' [(ngModel)]='Priority' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Installed Date</label>
				<input type='date' class='form-control block-element' placeholder=' Installed Date' [(ngModel)]='InstalledDate' />
			</div>
		
		</div>
		<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Application</label>
			    <select class="form-control" [(ngModel)]='Application'>
					<option *ngFor='let data of applicationlist;' [value]="data.Value">
						{{data.Value}}</option>
				</select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Type1</label>
				<input type='text' class='form-control block-element' placeholder=' Type1' [(ngModel)]='Type1' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Type2</label>
				<input type='text' class='form-control block-element' placeholder=' Type2' [(ngModel)]='Type2' />
			</div>
		</div>
		<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Type3</label>
				<input type='text' class='form-control block-element' placeholder=' Type3' [(ngModel)]='Type3' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'>nextaction</label>
				<input type='text' class='form-control block-element' placeholder='nextaction' [(ngModel)]='nextaction' />
			</div>
		</div>
		<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Owner</label>
				<ng-select [(ngModel)]="Owner">
					<ng-option *ngFor="let employee of employees" [value]="employee.Id">{{employee.LastName
						+ ', ' + employee.FirstName}}</ng-option>
				</ng-select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Assigned User</label>
				<ng-select [(ngModel)]="AssignedUser">
					<ng-option *ngFor="let employee of employees" [value]="employee.Id">{{employee.LastName
						+ ', ' + employee.FirstName}}</ng-option>
				</ng-select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Scheduled Date</label>
				<input type='date' class='form-control block-element' placeholder=' Scheduled Date' [(ngModel)]='ScheduledDate' />
			</div>
		</div>
		<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Dev Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Dev Notes' [(ngModel)]='DevNotes' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Status</label>
				<select class="form-control" [(ngModel)]='Status'>
					<option *ngFor='let status of statuslist;' [value]="status.Value">
						{{status.Value}}</option>
				</select>
			</div>
			<div class='col-md-4' style="margin-top: 25px;">			
				<input type='checkbox'   [(ngModel)]='HideCompleted' />
				<label style="margin-left: 10px;">   Hide Completed</label>
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;' >
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e' class='col1'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/developmenttask-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='Name'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Name</th>
		<td mat-cell *matCellDef='let e' class='col2'>{{e.Name}}</td>
	</ng-container>
		<ng-container matColumnDef='Description'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Description</th>
		<td mat-cell *matCellDef='let e' class='col3'>{{e.Description}}</td>
	</ng-container>
	<ng-container matColumnDef='Status'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Status</th>
		<td mat-cell *matCellDef='let e' class='col4'>{{e.Status}}</td>
	</ng-container>
	<!-- <ng-container matColumnDef='BranchId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Branch Id</th>
		<td mat-cell *matCellDef='let e'>{{e.BranchId}}</td>
	</ng-container> -->

	<ng-container matColumnDef='EstimatedHours'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Estimated Hours</th>
		<td mat-cell *matCellDef='let e' class='col5'>{{e.EstimatedHours}}</td>
	</ng-container>
	<ng-container matColumnDef='Priority'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Priority</th>
		<td mat-cell *matCellDef='let e' class='col6'>{{e.Priority}}</td>
	</ng-container>
	<ng-container matColumnDef='ScheduledDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Scheduled Date</th>
		<td mat-cell *matCellDef='let e' class='col7'>{{e.ScheduledDate}}</td>
	</ng-container>
	<ng-container matColumnDef='InstalledDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Installed Date</th>
		<td mat-cell *matCellDef='let e' class='col7'>{{e.InstalledDate}}</td>
	</ng-container>
	<ng-container matColumnDef='Application'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Application</th>
		<td mat-cell *matCellDef='let e' class='col8'>{{e.Application}}</td>
	</ng-container>
	<!-- <ng-container matColumnDef='Type1'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Type1</th>
		<td mat-cell *matCellDef='let e'>{{e.Type1}}</td>
	</ng-container>
	<ng-container matColumnDef='Type2'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Type2</th>
		<td mat-cell *matCellDef='let e'>{{e.Type2}}</td>
	</ng-container>
	<ng-container matColumnDef='Type3'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Type3</th>
		<td mat-cell *matCellDef='let e'>{{e.Type3}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'> Notes</th>
		<td mat-cell *matCellDef='let e'>{{e.Notes}}</td>
	</ng-container>
	 -->
	<!-- <ng-container matColumnDef='Owner'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col14'> Owner</th>
		<td mat-cell *matCellDef='let e'>{{e.Owner}}</td>
	</ng-container>
	<ng-container matColumnDef='AssignedUser'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col15'> Assigned User</th>
		<td mat-cell *matCellDef='let e'>{{e.AssignedUser}}</td>
	</ng-container>
	<ng-container matColumnDef='nextaction'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col16'>nextaction</th>
		<td mat-cell *matCellDef='let e'>{{e.nextaction}}</td>
	</ng-container>
	<ng-container matColumnDef='DevNotes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col17'> Dev Notes</th>
		<td mat-cell *matCellDef='let e'>{{e.DevNotes}}</td>
	</ng-container> -->
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>