
<div class='panel panel-primary animaPlaceholder' *ngIf = 'binlabelcat'>
	<div class='panel-heading'>
		<span *ngIf = 'binlabelcat' >{{ page }} :{{ binlabelcat.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='binlabelcatForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> P Line</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='PLine' />
							<span *ngIf = "binlabelcatForm.controls['PLine'].invalid  && binlabelcatForm.controls['PLine'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Label Tag</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LabelTag' />
							<span *ngIf = "binlabelcatForm.controls['LabelTag'].invalid  && binlabelcatForm.controls['LabelTag'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Label Group</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LabelGroup' />
							<span *ngIf = "binlabelcatForm.controls['LabelGroup'].invalid  && binlabelcatForm.controls['LabelGroup'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Number Of Pages</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='NumberOfPages' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Total Pages</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='TotalPages' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Notes</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Notes' />
							<span *ngIf = "binlabelcatForm.controls['Notes'].invalid  && binlabelcatForm.controls['Notes'].errors.maxlength" class="text-danger">
									Only 4000 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveBinlabelcat(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!binlabelcatForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveBinlabelcat(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!binlabelcatForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveBinlabelcat(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!binlabelcatForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!binlabelcatForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteBinlabelcat()'
								style='width: 150px; margin-right:10px'
								[disabled]='!binlabelcatForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='binlabelcat'>{{binlabelcat.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='binlabelcat'>{{binlabelcat.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>