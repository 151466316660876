import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { LabelpropertyconfigListComponent } from './labelpropertyconfig-list/labelpropertyconfig-list.component';
import { LabelpropertyconfigEditComponent } from './labelpropertyconfig-edit/labelpropertyconfig-edit.component';
import { LabelpropertyconfigService } from './labelpropertyconfig-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { LabelpropertyresultComponent } from './labelpropertyresult/labelpropertyresult.component';

@NgModule({
	declarations: [
		LabelpropertyconfigListComponent
		, LabelpropertyconfigEditComponent
		,LabelpropertyresultComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'labelpropertyconfigs', component: LabelpropertyconfigListComponent },
			{ path: 'labelpropertyconfig-details/:Id', component: LabelpropertyconfigEditComponent },
			{ path: 'labelpropertyconfig-result/:Id', component: LabelpropertyresultComponent },
		])
	],
	providers: [
		LabelpropertyconfigService
	]
})
export class LabelpropertyconfigModule { }
