import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { IMillcertSearch } from '../Millcert-model/MillcertSearch';
import { IMillcerts } from '../Millcert-model/Millcerts';
import { MillcertFileDto } from '../millcert-model/MillcertFileDto';
import { IMillcert } from '../millcert-model/millcert';
import { MillcertService } from '../millcert-service';

@Component({
	templateUrl: './millcert-edit.component.html',
	styleUrls: ['millcert-edit.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class MillcertEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

@ViewChild(MatPaginator) paginator: MatPaginator;
displayedColumns: string[] =  ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport','Delete'];
	public page = 'Edit Mill Certs';
	errorMessage: string;
	millcertForm: UntypedFormGroup;
	millcert: IMillcert;
	private sub: Subscription;
	public filesForm: UntypedFormGroup
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public MillcertSearch: IMillcertSearch;
	public ListData: IMillcerts;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	public buttonName:any = 'Save File';
	public filename:any = '';
	public show:boolean = false;
	public showButtonName:any = 'Add New File';
	fileFormSubmitted: boolean = false;
	fileToEdit: MillcertFileDto;
	public dataSource: any;
	millcertId: number;
	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true}) firstPaginator: MatPaginator;
	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private millcertService: MillcertService,
		) {
	}

	ngOnInit(): void {
		this.millcertForm = this.fb.group({
			Id: '',
			SupplierName: ['',[Validators.maxLength(250)]],
			InvoiceNumber: ['',[Validators.maxLength(250)]],
			PoNumber: ['',[Validators.maxLength(250)]],
			ContainerAirwayBill: ['',[Validators.maxLength(250)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.filesForm = this.fb.group({
			fileUpload:['',Validators.required],
			fileDescription:[''],
			sortOrder:[''],
			includeReport:[''],
		})
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.millcertId = id;
				this.getMillcert(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.millcertService.getRecordSet(this.MillcertSearch)
			.subscribe((obj: IMillcerts) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IMillcerts){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.millcert.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveMillcert(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		
		if (this.millcertForm.valid) {
			this.progressStatus = true;
			const obj = Object.assign({ }, this.millcert, this.millcertForm.value);
			this.millcertService.saveMillcert(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.millcertForm.reset();
			this.router.navigate(['/millcerts']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.millcert.Id === 0) {
			this.router.navigate(['/millcert-details', data.Id]);
		} 
		else if (this.millcert.Id != 0){
			this.getMillcert(this.millcert.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.millcert.Id)
				record = count;
		});

		this.getMillcert(this.ListData.data[record].Id)
	}

	getMillcert(id: number): void {
		this.millcertService.getMillcert(id)
			.subscribe((obj: IMillcert) => this.onMillcertRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onMillcertRetrieved(millcert: IMillcert): void {
		if (this.millcertForm) {
			this.millcertForm.reset();
		}
		this.millcert = millcert;
		if (this.millcert.Id === 0) {
			this.page = 'Add Mill Certs';
		} else {
			this.page = 'Edit Mill Certs';
		}

		this.millcertForm.patchValue({
			Id: this.millcert.Id,
			SupplierName: this.millcert.SupplierName,
			InvoiceNumber: this.millcert.InvoiceNumber,
			PoNumber: this.millcert.PoNumber,
			ContainerAirwayBill: this.millcert.ContainerAirwayBill,
			CreatedDate: this.millcert.CreatedDate,
			CreatedBy: this.millcert.CreatedBy,
			UpdatedDate: this.millcert.UpdatedDate,
			UpdatedBy: this.millcert.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteMillcert(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.millcert.SupplierName}?`)) {
			this.millcertService.deleteMillcert(this.millcert.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/millcerts']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Mill Certs List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.MillcertSearch = this.millcertService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'SupplierName')
				this.MillcertSearch.SupplierName = i.Value;

			if (i.Item == 'InvoiceNumber')
				this.MillcertSearch.InvoiceNumber = i.Value;

			if (i.Item == 'PoNumber')
				this.MillcertSearch.PoNumber = i.Value;

			if (i.Item == 'ContainerAirwayBill')
				this.MillcertSearch.ContainerAirwayBill = i.Value;

			if (i.Item == 'orderByString')
				this.MillcertSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.MillcertSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
		this.getFileMillcertData();
	}

	onFileSelected(event) {
		console.log('onFileSelected:');
		if (event.target.files.length > 0) {
		 this.filesForm.patchValue({
		 fileUpload: event.target.files [0],
		 })
		 }
	}
	onSaveFile(){
		console.log('onSaveFile');

		this.fileFormSubmitted = true;

		if (this.filesForm.invalid)
		{
			console.log('onSaveFile::Form Invalid');
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.millcert.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
			formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id)
		{
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.millcertService.updateFileMillcert(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileMillcertData();
			});
		}
		else
		{
			this.millcertService.createFileMillcert(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileMillcertData()});
			}
		}
	editFileData(file){
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			fileDescription: this.fileToEdit.FileDescription,
			sortOrder: this.fileToEdit.SortOrder,
			includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false
		});
	}

	clearForm() {
		this.filesForm.reset();
			this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${ this.applicationSettings.retrieveUrl()}${ row.FilePath}${ row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.millcertService.deleteFileMillcert(id).subscribe(file => this.getFileMillcertData())
	}

	//This gets the file grid
	getFileMillcertData(){
		console.log('should get the file grid');
		if (this.millcert)
		{
			if (this.millcert.Id > 0)
			{
				this.millcertService.getAllFilesMillcert(this.millcert.Id).subscribe(response => {
					console.log('file list returned');
					console.log(response);
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
					
				});
			}
		}
	}

	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
