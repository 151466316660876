<div class="panel panel-primary animated fadeIn">
	<div class="panel-body">
		<div class="row">
			<form class="form-horizontal" (ngSubmit)="onLogin()" [formGroup]="loginForm" *ngIf="!auth.loggedIn()">
				<div class="col-md-12">
					Please visit <a href="https://www.greenlinehose.com/">Green Line Hose & Fittings</a> for customer
					information.
				</div>


				<div class="row">

					<div class="col-md-7">
						<img src="./assets/LogInImage.jpg" class="img-responsive" />
					</div>
					<div class="col-md-5">
						<div class="col-md-12">
							<div class='has-error' *ngIf='errorMessage'>
								<font color="red">{{ errorMessage }}</font>
							</div>
						</div>
						<div *ngIf="!passwordflag">
							<div class="col-md-12">
								<h4 style="margin-bottom: 28px">Please log in first</h4>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label class="col-md-3 control-label" for="Username">Username</label>
									<div class="col-md-5">
										<input class="form-control" ngModel type="text" placeholder="Username"
											formControlName="Email">
									</div>
									<span class="text-danger" *ngIf="displayMessage.Email">
										{{ displayMessage.Email }}
									</span>
								</div>
								<div class="form-group">
									<label class="col-md-3 control-label" for="Password">Password</label>
									<div class="col-md-5">
										<input class="form-control" type="password" placeholder="password"
											formControlName="Password">
									</div>
									<span class="text-danger" *ngIf="displayMessage.Password">
										{{ displayMessage.Password }}
									</span>
								</div>
								<div class="form-group">
									<div class="col-md-8">
										<button class="btn btn-primary btn-sm pull-right" type="submit"
											[disabled]="!loginForm.valid">Submit</button>
									</div>
								</div>
							</div>
							<a (click)="resetPassword()" >Reset Password</a>
						</div>
						<div class="col-md-12">							
							<div *ngIf="passwordflag">
								<div>Reset Password</div>
								<form class='form-horizontal' novalidate [formGroup]='resetPasswordForm'>
									<div class="form-group">
										<label class="col-md-3 control-label" for="userEmail">Email</label>
										<div class="col-md-5">
											<input class="form-control" type="email"
												pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email"
												formControlName="userEmail" required>
										</div>
										<div class="text-danger"
											*ngIf="resetPasswordForm.controls['userEmail'].hasError('pattern')">
											<span>Invalid Email </span>
										</div>
										<div class="text-danger"
											*ngIf="resetPasswordForm.controls['userEmail'].hasError('required') && resetPasswordForm.controls['userEmail'].touched">
											<span>Please Enter Email </span>
										</div>
									</div>
									<div class="form-group">
										<div class="col-md-8">
											<button class="btn btn-primary btn-sm pull-right" (click)="sendResetEmail()"
												[disabled]="!resetPasswordForm.valid">Send Email</button>
										</div>
									</div>
								</form>
								<a (click)="goToLogin()" >Go to Login</a>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="row" *ngIf="auth.loggedIn()">
			<div class="col-md-12">
				<div class="alert alert-warning">You are already logged in</div>
			</div>
		</div>
		<header-version></header-version>
	</div>
</div>