import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IHydrotestcalibrationSearch } from '../Hydrotestcalibration-model/HydrotestcalibrationSearch';
import { IHydrotestcalibrations } from '../Hydrotestcalibration-model/Hydrotestcalibrations';
import { IHydrotestcalibration } from '../hydrotestcalibration-model/hydrotestcalibration';
import { HydrotestcalibrationService } from '../hydrotestcalibration-service';

@Component({
	templateUrl: './hydrotestcalibration-edit.component.html',
	styleUrls: ['hydrotestcalibration-edit.component.css']
})

export class HydrotestcalibrationEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit HydroTestCalibrations';
	errorMessage: string;
	hydrotestcalibrationForm: UntypedFormGroup;
	hydrotestcalibration: IHydrotestcalibration;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public HydrotestcalibrationSearch: IHydrotestcalibrationSearch;
	public ListData: IHydrotestcalibrations;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private hydrotestcalibrationService: HydrotestcalibrationService,
		) {
	}

	ngOnInit(): void {
		this.hydrotestcalibrationForm = this.fb.group({
			Id: '',
			machineip: ['',[Validators.maxLength(20)]],
			PressureSwitch: ['',[Validators.maxLength(20)]],
			Pressure: '',
			PLCValue: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getHydrotestcalibration(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.hydrotestcalibrationService.getRecordSet(this.HydrotestcalibrationSearch)
			.subscribe((obj: IHydrotestcalibrations) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IHydrotestcalibrations){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.hydrotestcalibration.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveHydrotestcalibration(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.hydrotestcalibrationForm.valid) {
			const obj = Object.assign({ }, this.hydrotestcalibration, this.hydrotestcalibrationForm.value);
			this.hydrotestcalibrationService.saveHydrotestcalibration(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.hydrotestcalibrationForm.reset();
			this.router.navigate(['/hydrotestcalibrations']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.hydrotestcalibration.Id === 0) {
			this.router.navigate(['/hydrotestcalibration-details', data.Id]);
		} 
		else if (this.hydrotestcalibration.Id != 0){
			this.getHydrotestcalibration(this.hydrotestcalibration.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.hydrotestcalibration.Id)
				record = count;
		});

		this.getHydrotestcalibration(this.ListData.data[record].Id)
	}

	getHydrotestcalibration(id: number): void {
		this.hydrotestcalibrationService.getHydrotestcalibration(id)
			.subscribe((obj: IHydrotestcalibration) => this.onHydrotestcalibrationRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onHydrotestcalibrationRetrieved(hydrotestcalibration: IHydrotestcalibration): void {
		if (this.hydrotestcalibrationForm) {
			this.hydrotestcalibrationForm.reset();
		}
		this.hydrotestcalibration = hydrotestcalibration;
		if (this.hydrotestcalibration.Id === 0) {
			this.page = 'Add HydroTestCalibrations';
		} else {
			this.page = 'Edit HydroTestCalibrations';
		}

		this.hydrotestcalibrationForm.patchValue({
			Id: this.hydrotestcalibration.Id,
			machineip: this.hydrotestcalibration.machineip,
			PressureSwitch: this.hydrotestcalibration.PressureSwitch,
			Pressure: this.hydrotestcalibration.Pressure,
			PLCValue: this.hydrotestcalibration.PLCValue,
			CreatedDate: this.hydrotestcalibration.CreatedDate,
			CreatedBy: this.hydrotestcalibration.CreatedBy,
			UpdatedDate: this.hydrotestcalibration.UpdatedDate,
			UpdatedBy: this.hydrotestcalibration.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteHydrotestcalibration(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.hydrotestcalibration.machineip}?`)) {
			this.hydrotestcalibrationService.deleteHydrotestcalibration(this.hydrotestcalibration.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/hydrotestcalibrations']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('HydroTestCalibrations List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.HydrotestcalibrationSearch = this.hydrotestcalibrationService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'machineip')
				this.HydrotestcalibrationSearch.machineip = i.Value;

			if (i.Item == 'PressureSwitch')
				this.HydrotestcalibrationSearch.PressureSwitch = i.Value;

			if (i.Item == 'Pressure')
				this.HydrotestcalibrationSearch.Pressure = i.Value;

			if (i.Item == 'PLCValue')
				this.HydrotestcalibrationSearch.PLCValue = i.Value;

			if (i.Item == 'orderByString')
				this.HydrotestcalibrationSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.HydrotestcalibrationSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
