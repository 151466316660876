﻿<h1>HYDROSTATIC TEST</h1>
<table>
    <tr>
        <td>
            Hose Length Initial
        </td>
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthInitialFeet" (ngModelChange)="changeInitialFt($event)" placeholder="" class="form-control  input-list">
        </td>
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthInitialInch"  (ngModelChange)="changeInitialIn($event)" placeholder="" class="form-control  input-list">
        </td>
        
        <td>ft|in</td>
        <td>
            <span class="alert alert-warning" *ngIf="errorIntialFt == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorIntialIn == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>
            Hose Length Initial TP
        </td>
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthInitialTPFeet" (ngModelChange)="changeInitialTpFt($event)" placeholder="" class="form-control  input-list">
        </td>
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthInitialTPInch"  (ngModelChange)="changeInitialTpIn($event)" placeholder="" class="form-control  input-list">
        </td>

        <td>ft|in</td>
         <td>
            <span class="alert alert-warning" *ngIf="errorIntialTpFt == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorIntialTpIn == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>
            Hose Length Release
        </td>
        
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthReleaseFeet" (ngModelChange)="changeIntialReFt($event)" placeholder="" class="form-control  input-list">
        </td>
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthReleaseInch" (ngModelChange)="changeIntialReIn($event)"  placeholder="" class="form-control  input-list">
        </td>
        <td>ft|in</td>
        <td>
            <span class="alert alert-warning" *ngIf="errorIntialReFt == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorIntialReIn == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>
            Hose Length Post
        </td>
       
        <td>
            <input [(ngModel)]="hoseDetail.HoseLengthPostFeet" (ngModelChange)="changeIntialPoFt($event)"  placeholder="" class="form-control  input-list">
        </td>
         <td>
            <input [(ngModel)]="hoseDetail.HoseLengthPostInch" (ngModelChange)="changeIntialPoIn($event)"  placeholder="" class="form-control  input-list">
        </td>
        <td>ft|in</td>
         <td>
            <span class="alert alert-warning" *ngIf="errorIntialPoFt == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorIntialPoIn == false">
                        {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>
            Hydrostatic Test Overal Result
        </td>
        <td colspan="3">
            <select [(ngModel)]="hoseDetail.HydroStaticTestResult" class="form-control  input-list">
                <option *ngFor="let o of passFail" [value]="o.value">{{o.name}}</option>
            </select>
        </td>
    </tr>
</table>