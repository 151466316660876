import { IFitting } from './fitting-model/fitting';
import { ApplicationSettings } from './../../shared/application-settings';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';

import { of } from 'rxjs';

@Injectable()
export class FittingService {
	private baseUrl;

	constructor(private authHttp: HttpClient, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Fitting';
	}

	getFittingbyId(id: number): Observable<IFitting> {
		if (id === 0) {
			return of(this.initializeFitting());
		}

		const url = `${this.baseUrl}` + '/GetFittingById?id=' + `${id}`;
		return this.authHttp.get<IFitting>(url)
			.catch(this.handleError);
	}


	saveFitting(fitting: IFitting): Observable<any> {
		if (fitting.Id === 0) {
			return this.createFitting(fitting);
		}
		return this.updateFitting(fitting);
	}

	private createFitting(fitting: IFitting): Observable<any> {
		const url = `${this.baseUrl}` + '/Createfitting';
		return this.authHttp.post(url, fitting)
			.catch(this.handleError);
	}

	private updateFitting(fitting: IFitting): Observable<IFitting> {
		return this.authHttp.put(this.baseUrl + '/Updatefitting', fitting)
			.catch(this.handleError);
	}

	deleteFitting(id: number): Observable<Response> {
		const url = `${this.baseUrl}` + '/Deletefitting/' + `${id}`;
		return this.authHttp.delete(url)
			.catch(this.handleError);
	}

	private handleError(error: Response): Observable<any> {
		return Observable.throw(error.json().ErrorDescription || 'Server error');
	}

	initializeFitting(): IFitting {
		return {
			Id: 0,
			Fitting: '',
			Description: '',
			OutsideDiameter: null,
			ArchiveDate: '',

			CrimpInstructions: '',
			Notes: '',

			Pressure: null,
			PressureTest: null,
			ProductTypeId: null,
			ProductType: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IFitting;
	}
}
