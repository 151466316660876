import { Component } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { BinlabelcatService } from 'src/app/binlabelcats/binlabelcat-service';
import { LabelsmenuService } from '../labelsmenu-service';
import { PartcatService } from 'src/app/partcats/partcat-service';

@Component({
	templateUrl: 'customlabel.component.html',
	styleUrls: ['customlabel.component.css']
})

export class CustomeLabelComponent {

	public errorMessage = '';
	binLabelCatList: any;
	labelTag: any;
	vendorName: any;
	pLine: any;
	selCustomer: any;
	menutab = '';
	vendorsList: any;
    pLineList:any;
	customerList:any;
	rackBinPLine:any;
	public progressStatus=true;
    tagList:any;
	priceLineList:any;
	pLineXOver:any;
    pdfLinkList:any;
    prodCatFileStatus:any;
	prodGroupFileStatus:any;
	reqCntProdCat=0;
	reqCntProdGroup=0;
	parts_filter = 'all-parts';
	parts_filterAll = 'all-parts';

	constructor(public applicationSettings: ApplicationSettings
		, private binlabelcatService: BinlabelcatService
		, private labelsmenuService: LabelsmenuService
		, private partcatService:PartcatService
	) {
	}
	ngOnInit() {
		this.getBinLabelCatData();
		this.getTagList();
		this.getPriceLineList();
		this.getCustomerWithPartsList();
	}

	getBinLabelCatData() {
		this.binlabelcatService.getBinLabelCatForList().subscribe(
			(val) => {
				this.binLabelCatList = val;
				this.pLineList = this.binLabelCatList.filter(
					(thing, i, arr) => arr.findIndex(t => t.PLine === thing.PLine) === i
				  );
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);

	}
	createLabelByProductCategory() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/pdflabels/';
		this.labelsmenuService.createLabelByProductCategory(this.labelTag,'one',0,false).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	
	createLabelByPLXOver() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
		this.labelsmenuService.createLabelByPLXOver(this.pLineXOver,this.vendorName).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
				this.progressStatus=false;
			}
		);
	}
	
	mainmenufunt(value){
		this.menutab=value;
	}


	resetError(): void {
		this.errorMessage = '';
		this.progressStatus = false;
	}
	getTagList() {
		this.partcatService.getTagList().subscribe(
			(val) => {
				this.tagList=val;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);

	}
	getPriceLineList() {
		this.partcatService.getPriceLineList().subscribe(
			(val) => {
				this.priceLineList=val;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);

	}

	getCustomerWithPartsList() {
		this.partcatService.getCustomerWithPartsList().subscribe(
			(val) => {
				this.customerList=val;
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
				this.progressStatus=false;
			}
		);

	}


	createLabelByProductGroupCustomer() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/pdflabels/';
		this.labelsmenuService.createLabelByProductGroupCustomer(this.pLine,'one',0,false,this.selCustomer,this.parts_filter).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
				this.progressStatus=false;
			}
		);
	}

	createLabelByProductCategoryCustomer() {
		this.progressStatus=true;
		let url = this.applicationSettings.retrieveUrl() + '/pdflabels/';
		this.labelsmenuService.createLabelByProductCategoryCustomer(this.labelTag,'one',0,false,this.selCustomer,this.parts_filter).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
				this.progressStatus=false;
			}
		);
	}

	createLabelAllCustomerParts(averytype: string) {
		this.progressStatus=true;
		if(this.selCustomer){
		var customerSelected = this.customerList.find(cust => cust.Id == this.selCustomer);
		let url = this.applicationSettings.retrieveUrl() + '/pdflabels/';
		this.labelsmenuService.createLabelAllCustomerParts(customerSelected.Customer,this.selCustomer,averytype).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
				console.log('pdf created:' + val)
				this.progressStatus=false;
			},
			(error: any) => {
				this.errorMessage = <any>error;
				this.progressStatus=false;
			}
		);
	}else{
		this.errorMessage ="No customer selected";
		this.progressStatus=false;
	}
	}
	// generateAllProdCatRpt() {
	// 	this.reqCntProdCat=0;
	// 	this.getAllProdCatRpt();		
	// }
	// getAllProdCatRpt(){
	// 	this.progressStatus=true;
	// 	this.labelsmenuService.createLabelByProductCategory(this.tagList[this.reqCntProdCat].Tag).subscribe(
	// 		(val: string) => {
	// 			this.reqCntProdCat=this.reqCntProdCat+1;
	// 			this.prodCatFileStatus='';
	// 			this.prodCatFileStatus= val+ ' file is created!';
	// 			console.log(val+ ' file is created!');		
	// 			this.progressStatus=false;		
	// 			if(this.reqCntProdCat<this.tagList.length){
	// 				this.getAllProdCatRpt();
	// 			}
					
	// 		},
	// 		(error: any) => {
	// 			this.errorMessage = <any>error;
	// 		}
	// 	);
	// }
	// generateAllProdGroupRpt() {
	// 	this.reqCntProdGroup=0;
	// 	this.getAllProdGroupRpt();		
	// }

	// getAllProdGroupRpt(){
	// 	this.progressStatus=true;
	// 	this.labelsmenuService.createLabelByProductGroup(this.priceLineList[this.reqCntProdGroup].PriceLine).subscribe(
	// 		(val: string) => {
	// 			this.reqCntProdGroup=this.reqCntProdGroup+1;
	// 			this.prodGroupFileStatus='';
	// 			this.prodGroupFileStatus=val+ ' file is created!';
	// 			console.log(val+ ' file is created!');		
	// 			this.progressStatus=false;		
	// 			if(this.reqCntProdGroup<this.priceLineList.length){
	// 				this.getAllProdGroupRpt();
	// 			}
	// 		},
	// 		(error: any) => {
	// 			this.errorMessage = <any>error;
	// 		}
	// 	);
	// }

	
}