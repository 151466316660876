import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { AutomationListComponent } from './automation-list/automation-list.component';
import { AutomationEditComponent } from './automation-edit/automation-edit.component';
import { AutomationService } from './automation-service';

@NgModule({
	declarations: [
		AutomationListComponent
		, AutomationEditComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'automations', component: AutomationListComponent },
			{ path: 'automation-details/:Id', component: AutomationEditComponent },
		])
	],
	providers: [
		AutomationService
	]
})
export class AutomationModule { }
