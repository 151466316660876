import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { IUsersearch } from 'src/app/admin/usersearches/usersearch-model/usersearch';
import { AuthService } from 'src/app/auth/auth.service';
import { PartService } from 'src/app/parts/part-service';
import { ApplicationSettings } from 'src/app/shared/application-settings';
import { LabelprintingService } from '../../labelprinting-service';

const CHAT_URL = "ws://localhost:5001/chathub";
export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}


@Component({
	templateUrl: './box-label.component.html',
	styleUrls: ['box-label.component.css']
})

export class BoxLabelComponent implements OnInit, OnDestroy, AfterViewInit {
	public page = 'Stock Parts 4 X 6';
	errorMessage: string;
	boxlabelForm: UntypedFormGroup;
	partsData = [];
	qtyTypes = ['ea', 'ft'];
	
	public pageTitle = 'BoxLabel';
	public UserSearches: IUsersearch[];
	public PrinterName = '';
	public printerList=[];

	public PrinterResolutionList = ['203','300']

	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};
	public serverStatus = false;
	public printerAvailable = false;
	public serverStatusMessage= 'start local communications';
	public serverStatusMessageDef= 'start local communications';
	private subject: AnonymousSubject<MessageEvent>;
	public messages: Subject<Message>;

	constructor(
		private authService: AuthService,
		private partService: PartService,
		private fb: UntypedFormBuilder,
		private labelPrintingService: LabelprintingService,
		public applicationSettings: ApplicationSettings
	) {
		this.connectServer();
	 }

	ngOnInit(): void {

		this.boxlabelForm = this.fb.group({
			Id: '',
			PartNumber: ['', Validators.required],
			PartDescription: [{ value: '', disabled: true }, Validators.required],
			Upc: [{ value: '', disabled: true }, Validators.required],
			Quantity: ['', Validators.required],
			QtyType: ['', Validators.required],
			EclipseId: ['', Validators.required],
			LabelType: ['Box', Validators.required],
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: [1, Validators.required],
			PrinterName: ['', Validators.required],
			PrinterResolution: ['300']
		});
		this.getPrinterList();
	}

	public ngAfterViewInit(): void {
		this.boxlabelForm.controls.PartNumber.valueChanges
			.pipe(
				debounceTime(1000),
				switchMap(value =>
					this.partService.getParts(value)
				)
			)
			.subscribe(parts => {
				this.partsData = parts;
			}, _ => this.errorMessage = 'Error loading parts from the server');
		// this.getUserSearchesForBoxLabel();

	}
	getPrinterList() {
		let branchid = this.authService.getBranchId();
		this.labelPrintingService.getPrinterListData(branchid, '4x6')
			.subscribe(val => {
				if(val.length>0){
				this.printerList = val;
				this.boxlabelForm.patchValue({
					PrinterName: this.printerList[0].IP, 
				  });
				  this.printerAvailable = true;
				  this.serverStatusMessage = this.serverStatusMessageDef;
				}else{
					this.printerAvailable = false;
					this.serverStatusMessage = this.serverStatusMessageDef + ' Unable to find printer for 6x2 size';
				}
			});
	}
	
	print(): void {
		if (this.boxlabelForm.valid) {
			const obj = Object.assign({}, this.boxlabelForm.getRawValue());
			this.labelPrintingService.saveSearch(obj, this.pageTitle);
			this.labelPrintingService.createBoxlabel(obj)
				.subscribe(val => {

					//let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
					this.trnsfrData = {
						source: 'labelprint client',
						content: [],
						message: 'Label Sent For Print',
						function: 'sendLabelForPrint'
					};
					//this.trnsfrData.content.push(url + val);
					this.trnsfrData.content.push(val);
					this.trnsfrData.content.push(obj.PrinterName);
					this.trnsfrData.content.push(obj.NumberOfLabels);
					this.messages.next(this.trnsfrData);
					this.onSaveComplete()
				}


					, (_: any) => this.errorMessage = 'Error while printing the label');
		}
	}

	onSaveComplete(): void {
		this.boxlabelForm.patchValue({
			Id: '',
			PartNumber: '',
			PartDescription: '',
			Upc: '',
			Quantity: '',
			QtyType: '',
			EclipseId: '',
			LabelType: 'Box',
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: 1	
		});
		
	}

	ngOnDestroy(): void {
	}

	// tslint:disable-next-line:no-shadowed-variable
	public valueNormalizer = (text: Observable<string>) => text.map(_ => null);

	partChanged(value) {

		let partnumber = value;
		const part = this.partsData.find(p => p.PartNumber === partnumber);
		if (part) {
			this.boxlabelForm.patchValue({
				Upc: part.Upc,
				EclipseId: part.EclipseId,
				PartDescription: part.PartDescription
			});
		}
	}



	resetError(): void {
		this.errorMessage = '';
	}

	connectServer() {
		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			}
			)
		);
		//Response section from websocket Start//
		this.messages.subscribe(msg => {
			// if (msg.function == "receivePrinterList") {
			// 	this.printerList = msg.content
			// }
			// else 
			if (msg.function == "labelPrintResponse") {
				//alert(msg.message);
			}
			else {
				// this.received.push(msg);
				console.log("Response from websocket: " + msg.message);
			}
		});
		//Response section from websocket End//
	}
	public connect(url): AnonymousSubject<MessageEvent> {
		this.subject = this.create(url);
		return this.subject;
	}

    //Connecting websocket//
	private create(url): AnonymousSubject<MessageEvent> {
		let ws = new WebSocket(url);
		ws.onopen = openevent => {
			console.log("Successfully connected: " + url);
			this.serverStatus = true;
			this.trnsfrData = {
				source: 'labelprint client',
				content: [],
				message: 'register client',
				function: 'registerAngularClient'
			};
			this.messages.next(this.trnsfrData);
				// this.receivePrinterList();
		}

		let observable = new Observable((obs: Observer<MessageEvent>) => {
			ws.onmessage = obs.next.bind(obs);
			ws.onerror = obs.error.bind(obs);
			ws.onclose = obs.complete.bind(obs);
			return ws.close.bind(ws);
		});
		let observer = {
			error: null,
			complete: null,
			next: (data: Object) => {  //sending messge to websocket
				console.log('Message sent to websocket: ', data);
				if (ws.readyState === WebSocket.OPEN) {
					ws.send(JSON.stringify(data));
				}
				else {
					this.serverStatus = false;
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, observable);
	}
	reconnect() {
		this.connectServer();
	}
		//Websocket Communication Section End//
}
