<h1 class="text-center">Audit Records</h1>
<div class="panel">  
    <div class="row">
        <div class="col-md-9">
            <div class='col-md-4'>
                <label class='inline-label'> From Date</label>
                <input type='date' class='form-control block-element'  [(ngModel)]='FromDate' />
            </div>
            <div class='col-md-4'>
                <label class='inline-label'> To Date</label>
                <input type='date' class='form-control block-element'  [(ngModel)]='ToDate' />
            </div>
          
            <div class='col-md-4'>
                <label class='inline-label'>Old Value</label>
                <input type='text' class='form-control block-element' placeholder='OldValue'
                [(ngModel)]='OldValue' />
            </div>
            <div class='col-md-4'>
                <label class='inline-label'> New Value</label>
                <input type='text' class='form-control block-element' placeholder='NewValue'
                [(ngModel)]='NewValue' />
            </div>
        </div>
       <div class="col-md-3">
        <div class='row'>
            <div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
                <button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()'>Search</button>
            </div>
            <div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
				<button class='btn btn-primary btn-sm' style='width: 110px'
					(click)='searchClearClick()'>Clear-Search</button>
			</div>
        </div>
       </div>
    </div>
    <div class='table-container' style="margin-top: 30px;">
        <table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort>
            <ng-container matColumnDef='Id'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'> #</th>
                <td mat-cell *matCellDef='let e'>
                    <button (click)="openRecordPage(e.KeyId,e.TableChanged)" class="btn btn-primary">Open</button>
                </td>
            </ng-container>
            <ng-container matColumnDef='History'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'> History</th>
                <td mat-cell *matCellDef='let e'>
                    <button (click)="getAuditDataByKeyId(e.KeyId,e.TableChanged,e.Name)" class="btn btn-primary">Open</button>
                </td>
            </ng-container>
            <ng-container matColumnDef='TableChanged'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'> TableChanged</th>
                <td mat-cell *matCellDef='let e'>{{e.TableChanged}}</td>
            </ng-container>
            <ng-container matColumnDef='ColumnChanged'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> ColumnChanged</th>
                <td mat-cell *matCellDef='let e'>{{e.ColumnChanged}}</td>
            </ng-container>
            <ng-container matColumnDef='KeyId'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> KeyId</th>
                <td mat-cell *matCellDef='let e'>{{e.KeyId}}</td>
            </ng-container>
            <ng-container matColumnDef='Name'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Name</th>
                <td mat-cell *matCellDef='let e'>{{e.Name}}</td>
            </ng-container>
            <ng-container matColumnDef='WhatHappened'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> WhatHappened</th>
                <td mat-cell *matCellDef='let e'>{{e.WhatHappened}}</td>
            </ng-container>
            <ng-container matColumnDef='OldValue'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> OldValue</th>
                <td mat-cell *matCellDef='let e'>{{e.OldValue}}</td>
            </ng-container>
            <ng-container matColumnDef='NewValue'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> NewValue</th>
                <td mat-cell *matCellDef='let e'>{{e.NewValue}}</td>
            </ng-container>
            <ng-container matColumnDef='CreatedDate'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Date</th>
                <td mat-cell *matCellDef='let e'>{{e.CreatedDate | date:'dd/MM/YYYY'}}</td>
            </ng-container>
            <ng-container matColumnDef='CreatedBy'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> User</th>
                <td mat-cell *matCellDef='let e'>{{e.CreatedBy}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
            <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
        </table>
    </div>
    <div style="margin-left: 60px;">
      <h3>  History of {{PartType}} :  {{KeyId}}  Name : {{PartName}} <br/>
        Created By: {{CreatedBy}}     Created Date: {{CreatedDate | date:'dd/MM/YYYY'}}</h3>
    </div>
    <div class='table-container' style="margin-top: 30px;">
        <table mat-table [dataSource]='dataSourceGrid' class='mat-elevation-z8 table-head' matSort>
            <ng-container matColumnDef='TableChanged'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'> TableChanged</th>
                <td mat-cell *matCellDef='let e'>{{e.TableChanged}}</td>
            </ng-container>
            <ng-container matColumnDef='ColumnChanged'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> ColumnChanged</th>
                <td mat-cell *matCellDef='let e'>{{e.ColumnChanged}}</td>
            </ng-container>
            <ng-container matColumnDef='KeyId'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> KeyId</th>
                <td mat-cell *matCellDef='let e'>{{e.KeyId}}</td>
            </ng-container>
            <ng-container matColumnDef='Name'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Name</th>
                <td mat-cell *matCellDef='let e'>{{e.Name}}</td>
            </ng-container>
            <ng-container matColumnDef='WhatHappened'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> WhatHappened</th>
                <td mat-cell *matCellDef='let e'>{{e.WhatHappened}}</td>
            </ng-container>
            <ng-container matColumnDef='OldValue'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> OldValue</th>
                <td mat-cell *matCellDef='let e'>{{e.OldValue}}</td>
            </ng-container>
            <ng-container matColumnDef='NewValue'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> NewValue</th>
                <td mat-cell *matCellDef='let e'>{{e.NewValue}}</td>
            </ng-container>
            <ng-container matColumnDef='CreatedDate'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Date</th>
                <td mat-cell *matCellDef='let e'>{{e.CreatedDate | date:'dd/MM/YYYY'}}</td>
            </ng-container>
            <ng-container matColumnDef='CreatedBy'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> User</th>
                <td mat-cell *matCellDef='let e'>{{e.CreatedBy}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='disColGrid'></tr>
            <tr mat-row *matRowDef='let row; columns: disColGrid;'></tr>
        </table>
    </div>
</div>