<div class="col-md-12 nopadding form-my mb-40">
    <div class="card-form">
        <div class="form-title">
            <div class="col-md-11 text-center">
                {{ pageTitle }}
            </div>           
            <div class='col-md-1 text-left'>
                <button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
            </div>
        </div>
        
        <div class="form-body">
        <div class="row">
            <div class="col-md-12">
                <div [ngClass]="{'alert-danger': (service.message.MessageType=='ERROR'), 'alert-success': (service.message.MessageType!='ERROR')}"
                    class="col-md-12 alert  mt-20 mb-20 text-center" *ngIf='service.message.Message'>
                    <strong>{{service.message.MessageType}} !</strong> {{ service.message.Message }}.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="mt-40 mb-20">
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-20">
                        <div class="form-group">
                            <label for="OrderNumber" class="col-md-4 control-label">Order number</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="OrderNumber" class="form-control" type="text" id="OrderNumber" (blur)='onFilter()' placeholder="Order Number" name="OrderNumber">
                            </div>
                        </div>
                    </div>
            
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-20">
                        <div class="form-group">
                            <label for="crimpDate" class="col-md-4 control-label">Crimped Date </label>
                            <select id="crimpDate" [(ngModel)]="crimpDate" (change)="onFilter()"
                            [ngModelOptions]="{standalone: true}" class="form-control cust-select">
                            <option *ngFor="let o of crimpDates" [value]="o.value">{{o.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 mb-20">
                        <div class="form-group">
                            <label for="test" class="col-md-4 control-label">Test Required </label>
                            <div class="col-md-8">
                                <input [(ngModel)]="TestRequired" type="checkbox" (change)="onFilter()" class="test-required" id="test" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

       
        <div class='table-container'>
            <table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
                <ng-container matColumnDef='Id'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
                    <td mat-cell *matCellDef='let e'>
                        <button class='btn btn-default' style='min-width: 80px;' (click)="createTraveler(e.Id)">Select</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef='OrderNumber'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Sales Order</th>
                    <td mat-cell *matCellDef='let e'>{{e.OrderNumber}}</td>
                </ng-container>
                <ng-container matColumnDef='HoseName'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Hose</th>
                    <td mat-cell *matCellDef='let e'>{{e.HoseName}}</td>
                </ng-container>
                <ng-container matColumnDef='FerruleName'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> FerruleName</th>
                    <td mat-cell *matCellDef='let e'>{{e.FerruleName}}</td>
                </ng-container>
                <ng-container matColumnDef='FittingName'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> FittingName</th>
                    <td mat-cell *matCellDef='let e'>{{e.FittingName}}</td>
                </ng-container>
                <ng-container matColumnDef='CreatedDate'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> CreatedDate</th>
                    <td mat-cell *matCellDef='let e'>{{e.CreatedDate}}</td>
                </ng-container>
                <ng-container matColumnDef='TestRequired'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> TestRequired</th>
                    <td mat-cell *matCellDef='let e'>{{e.TestRequired}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
                <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
            </table>
            </div>
            <mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>

    </div>

    <div>
        <mat-spinner color="primary" class="spinner" *ngIf="isLoadingResults"></mat-spinner>
        </div>