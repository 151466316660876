import { AuthGuardAdmin } from './../auth/auth-guard-admin.service';
import { AuthGuard } from './../auth/auth-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminComponent } from '../greenline-legacy/admin/admin.component';
import { AppManagementComponent } from '../greenline-legacy/app-management/app-management.component';
import { CustomerConcernsComponent } from '../greenline-legacy/customer-concerns/customer-concerns.component';
import { HelpComponent } from '../greenline-legacy/help/help.component';
import { HrAdminComponent } from '../greenline-legacy/hr-admin/hr-admin.component';
import { HrComponent } from '../greenline-legacy/hr/hr.component';
import { HydrotestComponent } from '../greenline-legacy/hydrotest/hydrotest.component';
import { IndustrialAssemblyComponent } from '../greenline-legacy/industrial-assembly/industrial-assembly.component';
import { PartManagementComponent } from '../greenline-legacy/part-management/part-management.component';
import { ReportsComponent } from '../greenline-legacy/reports/reports.component';
//import { BillOfLadingLegacyModule } from '../bill-of-lading-legacy/bill-of-lading-legacy.module';
import { HydroTestLegacyModule } from '../hydro-test-legacy/hydro-test-legacy.module';

@NgModule({
	declarations: [
		AdminComponent,
		AppManagementComponent,
		CustomerConcernsComponent,
		HelpComponent,
		HrComponent,
		HrAdminComponent,
		HydrotestComponent,
		IndustrialAssemblyComponent,
		PartManagementComponent,
		ReportsComponent,
	],
	imports: [
		//BillOfLadingLegacyModule,
		HydroTestLegacyModule,
		RouterModule.forChild([
			{
				path: 'admin',
				component: AdminComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'app-management',
				component: AppManagementComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'customer-concerns',
				component: CustomerConcernsComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'help',
				component: HelpComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'hr',
				component: HrComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'hr-admin',
				component: HrAdminComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'hydro-test/:Id',
				component: HydrotestComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'industrial-assembly',
				component: IndustrialAssemblyComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'part-management',
				component: PartManagementComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'reports',
				component: ReportsComponent,
				canActivate: [AuthGuard]
			}
		])
	],
	providers: []
})
export class GreenLineLegacyModule { }
