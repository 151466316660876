export class Chart {
    public times = [
        {value: 420, label: '7 minutes'},
        {value: 1020, label:'17 minutes'},
        {value:1800, label:'continuous'},
        ];
        
    public selectedTime = 420;
    
    //SVG element can be accessed through #chart
    //Points can be accessed with #circle
    //Lines can be accessed with #line

    //Values for aesthetics (feel free to mess around with these, won't break anything)
    pointRadius: number = 5; //This might actually be diameter, I'm not really sure
    pointColor = "black";

    lineWidth = 3;
    lineColor = "black";

    numTicksX = 20;
    numTicksY = 12;
    
    //Used for storage of previous point in order to know where to draw a line from
    previousX = 0;
    previousY = 0;

}