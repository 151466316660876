import { Component, OnInit } from '@angular/core';
import { IRole } from '../role-model/role';
import { RoleService } from '../role-service/role.service';
import { Router } from '@angular/router';
@Component({
	templateUrl: './role-list.component.html'
})
export class RolesListComponent implements OnInit {
	pageTitle = 'Roles List';
	errorMessage = '';
	roles!: IRole[];

	constructor(private _roleService: RoleService
		,private router:Router) {}

	ngOnInit(): void {
		this._roleService
			.getRoles()
			.subscribe(
				roles => (this.roles = roles),
				error => (this.errorMessage = <any>error)
			);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/roles`])
		);
		window.open(url, '_blank');
	}
}
