
<div class="panel panel-primary animaPlaceholder">
<div class="container-fluid">
	<div class="panel-heading">
		{{page}}
		<div class='has-error' class="text-danger" *ngIf='errorMessage'>
				<button class="btn btn-primary btn-sm" (click)="resetError()">Reset Error</button>
				{{ errorMessage }}
		</div>
	</div>
	<div class="panel-body">
		<form class="form-horizontal" novalidate [formGroup]="boxlabelForm">
			<fieldset>
				<div class="row">
					<div class="col-md-5">
						<div class="form-group">
							<label class="col-md-4 control-label">Part Number</label>

							<div class="col-md-8">
								
								<input type="text" placeholder="Part Number" class="form-control" matInput
									formControlName="PartNumber" [matAutocomplete]="auto" >
								<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
									<mat-option *ngFor="let item of partsData" [value]="item.PartNumber" (onSelectionChange)="partChanged(item.PartNumber)">
										{{item.PartNumber}}
									</mat-option>
								</mat-autocomplete>
							</div>

						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Part Description</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="PartDescription" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Upc</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="Upc" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Quantity</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="Quantity" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Number Of Labels</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="NumberOfLabels" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Qty Type</label>
							<div class="col-md-8">
								<select formControlName='QtyType' class='form-control block-element'>
									<option *ngFor='let qtytype of qtyTypes' [value]="qtytype">{{qtytype}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<div class="col-md-offset-2 col-md-4">
								<button class="btn btn-primary btn-sm" (click)="print()" [disabled]="!boxlabelForm.valid">Print</button>
							</div>
						</div>
						<div class="form-group">
							<a target="_blank" href="https://{{printerIp()}}/GLPrinting">Click here if you are getting a printing error.</a>
						</div>
						<div class="form-group">
							Your branch is currently set to: {{Branch}}.  If this is wrong your printing won't work.
						</div>
					</div>
					<div class="col-md-7">

						<div class="container" style="height: 400px; width: 500px;">
							<img src="../../../assets/boxlabel.jpg" style="width:500px">
							<div class="bottom-left">{{boxlabelForm.get('Quantity').value}}</div>
							  <div class="top-left">{{boxlabelForm.get('PartNumber').value}}</div>
							  <div class="part-desc">{{boxlabelForm.get('PartDescription').value}}</div>
							 <div class="top-right">{{boxlabelForm.get('Upc').value}}</div>
							  <div class="bottom-right">{{boxlabelForm.get('Quantity').value}}</div>
						   <div class="centered">{{boxlabelForm.get('QtyType').value}}</div>
							  (*print will be slightly different)
						</div>
						<!-- <img src="../../../assets/boxlabel.jpg" alt="" class="img-responsive"> -->
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
