import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ILabelpropertyconfig} from './labelpropertyconfig-model/labelpropertyconfig';
import { ILabelpropertyconfigs } from './labelpropertyconfig-model/labelpropertyconfigs';
import { ILabelpropertyconfigSearch } from './labelpropertyconfig-model/labelpropertyconfigSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class LabelpropertyconfigService {
	private baseUrl;
	public pageTitle = 'Label Property Configs List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Labelpropertyconfigs';
	}

	getLabelpropertyconfig(id: number):  Observable<ILabelpropertyconfig> {
		if (id === 0) {
			return of(this.initializeLabelpropertyconfig());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ILabelpropertyconfig>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveLabelpropertyconfig(labelpropertyconfig: ILabelpropertyconfig): Observable<any> {
		if (labelpropertyconfig.Id === 0) {
			return this.createLabelpropertyconfig(labelpropertyconfig);
		}
		return this.updateLabelpropertyconfig(labelpropertyconfig);
	}

	private createLabelpropertyconfig(labelpropertyconfig: ILabelpropertyconfig): Observable<any> {
		labelpropertyconfig.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/labelpropertyconfigcreate', labelpropertyconfig)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateLabelpropertyconfig(labelpropertyconfig: ILabelpropertyconfig): Observable<ILabelpropertyconfig> {
		return this.authHttp.put<ILabelpropertyconfig>(this.baseUrl, labelpropertyconfig)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteLabelpropertyconfig(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeLabelpropertyconfig(): ILabelpropertyconfig {
		return {
			Id: 0,
			Header1: '',
			Header2: '',
			ExpressionLike: '',
			ExpressionEquals: '',
			Order: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ILabelpropertyconfig;
	}

	public getRecordSet(search: ILabelpropertyconfigSearch): Observable<ILabelpropertyconfigs> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Header1', search.Header1, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Header2', search.Header2, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ExpressionLike', search.ExpressionLike, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ExpressionEquals', search.ExpressionEquals, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Order', search.Order, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Header1', search.Header1 ? search.Header1 : '')
			.set('Header2', search.Header2 ? search.Header2 : '')
			.set('ExpressionLike', search.ExpressionLike ? search.ExpressionLike : '')
			.set('ExpressionEquals', search.ExpressionEquals ? search.ExpressionEquals : '')
			.set('Order', search.Order ? search.Order : '')
		};

		return this.authHttp.get<ILabelpropertyconfigs>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ILabelpropertyconfigs>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ILabelpropertyconfigSearch { 
		let search: ILabelpropertyconfigSearch = {
			Id: 1,
			Header1: '',
			Header2: '',
			ExpressionLike: '',
			ExpressionEquals: '',
			Order: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ILabelpropertyconfigSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Header1', search.Header1 ? search.Header1 : '')
			.set('Header2', search.Header2 ? search.Header2 : '')
			.set('ExpressionLike', search.ExpressionLike ? search.ExpressionLike : '')
			.set('ExpressionEquals', search.ExpressionEquals ? search.ExpressionEquals : '')
			.set('Order', search.Order ? search.Order : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	executeLabelPropertys(labelpropertyconfig: ILabelpropertyconfig): Observable<any> {
		return this.authHttp.post<any>(this.baseUrl + '/executelabelpropertys', labelpropertyconfig)
			.pipe(retry(1), catchError(this.processError));
	}
	getResultExecutePropertys(id): Observable<any> {
		return this.authHttp.get<any>(this.baseUrl + '/getresultexecutepropertys?id='+id)
			.pipe(retry(1), catchError(this.processError));
	}
	executeAllLabelPropertys(count): Observable<any> {
		return this.authHttp.get<any>(this.baseUrl + '/executealllabelpropertys?count='+count)
			.pipe(retry(0), catchError(this.processError));
	}
	generateExcel(): Observable<any> {
		return this.authHttp.get<any>(this.baseUrl + '/generateexcelforlabeldata')
			.pipe(retry(1), catchError(this.processError));
	}
	getWebPartInfo(exprval): Observable<any> {
		return this.authHttp.get<any>(this.baseUrl + '/getwebpartinfo?exprval='+exprval)
			.pipe(retry(1), catchError(this.processError));
	}
}