import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IReportData } from '../../shared/IReportData';
import { ApplicationSettings } from '../../shared/application-settings';
import { AppPropertiesService } from '../app-properties.service';
import { IHydroTest } from '../hydro-test/hydro-test-model/hydro-test';

@Injectable()
export class DataService {
	public HydroTest: IHydroTest;

	public evGotData = new Subject<string>();
	public errorMessage: any;
	public gotData = false;
	public baseUrl = '';

	constructor(
		private http: HttpClient,
		private appSettings: ApplicationSettings,
		private appPropertiesService: AppPropertiesService) {
			this.baseUrl = this.appSettings.retrieveUrl();
	}

	GetHydroTest(hydroTestId: number) {
		this.loadHydroTestFromDb(hydroTestId).subscribe(
			(data => this.retrievedHydroTest(data)),
			error => this.errorMessage = <any>error);
	}

	private retrievedHydroTest(data: IHydroTest) {
		this.HydroTest = data;

		if (this.HydroTest.hydroTestId.toString() === '0') {

			const strDate = new Date().getFullYear() + '-'
				+ this.getTwoDigits((new Date().getMonth() + 1).toString())
				+ '-' + this.getTwoDigits(new Date().getDate().toString());

			this.HydroTest.testDate = strDate;

			this.HydroTest.tester = this.appPropertiesService.currentUser;

			this.HydroTest.testMachine = this.appPropertiesService.defaultTestMachine;
			this.HydroTest.testLocation = this.appPropertiesService.currentUserBranch;
		}

		this.evGotData.next('NewData');
		this.gotData = true;
	}

	private loadHydroTestFromDb(hydroTestId: number): Observable<IHydroTest> {
		const path = this.baseUrl + '/api/HydroTest/Model?id=';
		return this.http.get<any>(path + hydroTestId);
	}

	getTwoDigits(digit: string): string {
		if (digit.length === 1) {
			return '0' + digit;
		} else {
			return digit;
		}
	}

	saveHydroTest(hydroTest: IHydroTest): any {
		this.HydroTest = hydroTest;

		this.ValidateHydrotestData();

		return this.http.post(
			this.baseUrl + '/api/HydroTest',
			this.HydroTest
		);
	}

	ValidateHydrotestData() {
		if (!this.HydroTest.hawp) {
			this.HydroTest.hawp = 0;
		}

		if (!this.HydroTest.testPressure) {
			this.HydroTest.testPressure = 0;
		}

		if (!this.HydroTest.insideDiameter) {
			this.HydroTest.insideDiameter = '';
		}

		if (!this.HydroTest.hoseWp) {
			this.HydroTest.hoseWp = 0;
		}

		if (!this.HydroTest.inch) {
			this.HydroTest.inch = 0;
		}

		if (!this.HydroTest.feet) {
			this.HydroTest.feet = 0;
		}

		if (!this.HydroTest.endOneWp) {
			this.HydroTest.endOneWp = 0;
		}

		if (!this.HydroTest.endTwoWp) {
			this.HydroTest.endTwoWp = 0;
		}
	}

	CheckHydrotestData(): string {
		let retVal = '';

		if (!this.HydroTest.testMachine) {
			retVal += 'Mising test machine type.  ';
		}

		if (!this.HydroTest.elecTestReq) {
			retVal += 'Mising the value for Continuity Required.  ';
		}

		if (this.HydroTest.testPressure < 10) {
			retVal += 'The test pressure is less than 10.  ';
		}

		// tslint:disable-next-line:triple-equals
		if (this.HydroTest.testMachine == 'PLC Direct') {
			if (!this.HydroTest.PLC) {
				retVal += 'Mising PLC type.  ';
			}
		}

		// tslint:disable-next-line:triple-equals
		if (this.HydroTest.salesOrder == '') {
			retVal += 'You must have a sales order.  ';
		}

		return retVal;
	}

	public getReport(rep: IReportData): Observable<string> {

		console.log("");
		return this.http.post(this.baseUrl + '/api/Report/', rep)
			.catch (this.handleError);
	}
	public getNewReport(rep: IReportData): Observable<string> {

		console.log("");
		return this.http.post(this.baseUrl + '/api/HydroTest/getReport/', rep)
			.catch (this.handleError);
	}
	private handleError(error: any): Observable <any> {
		return Observable.throw (error.message || 'Server error');
	}
}
