import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-module';
import { HydrotestcalibrationEditComponent } from './hydrotestcalibration-edit/hydrotestcalibration-edit.component';
import { HydrotestcalibrationHelpComponent } from './hydrotestcalibration-help/hydrotestcalibration-help.component';
import { HydrotestcalibrationListComponent } from './hydrotestcalibration-list/hydrotestcalibration-list.component';
import { HydrotestcalibrationService } from './hydrotestcalibration-service';

// calibration
import { CalibrationServiceService } from './calibration/calibration-service.service';
import { CalibrationComponent } from './calibration/calibration.component';

@NgModule({
	declarations: [
		HydrotestcalibrationListComponent
		, HydrotestcalibrationEditComponent
		, HydrotestcalibrationHelpComponent
		,CalibrationComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'hydrotestcalibrations', component: HydrotestcalibrationListComponent },
			{ path: 'hydrotestcalibration-details/:Id', component: HydrotestcalibrationEditComponent },
			{ path: 'hydrotestcalibration-help', component: HydrotestcalibrationHelpComponent },
			{ path: 'calibration', component: CalibrationComponent }
		])
	],
	providers: [
		HydrotestcalibrationService,
		CalibrationServiceService
	]
})
export class HydrotestcalibrationModule { }
