import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

	url: SafeResourceUrl;
	legacyUrl = '/Help/Help.aspx';

	constructor(
		public sanitizer: DomSanitizer,
		private settings: ApplicationSettings) {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.retrieveLegacyAppUrl() + this.legacyUrl);
	}

	ngOnInit() {
	}

}
