import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
// tslint:disable-next-line:import-blacklist

import { ApplicationSettings } from '../../../shared/application-settings';
import { AppPropertiesService } from '../../app-properties.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'http-hose-list',
	template:
		`<div>
   		<div style="overflow-y: auto;margin: 2px; height: 500px" >
   		  <h4>Hose List</h4>
   				<ul *ngFor='let item of listOfHoses' class="list-group" >
   					<li class="list-group-item">
   						<a (click)='select(item.Id)'>{{item.SerialNumber}}</a>
   					</li>
   				</ul>
   		<div>
   	  <div>
   	`
})

// tslint:disable-next-line:component-class-suffix
export class HoseList implements OnInit {

	@Input() hydrotestId: number;
	// @Input() path: string;
	baseUrl = '';

	@Input()
	public set triggerHoseListRefresh(val: number) {
		this._triggerUpdate = val;
		if (this._triggerUpdate > 1) {
			this.getRecords();
		}
	}
	@Output() hoseSelected: EventEmitter<any> = new EventEmitter();

	public listOfHoses: ListOfHoses[];
	public listOfHosesFiltered: ListOfHoses[];
	public selectedHose: string;

	private _triggerUpdate: number;

	constructor(
		elementRef: ElementRef,
		private appSettings: ApplicationSettings,
		public http: HttpClient,
		private appPropertiesService: AppPropertiesService) {
		this.listOfHoses = new Array<ListOfHoses>();
		this.listOfHosesFiltered = this.listOfHoses.slice();
		this.selectedHose = '';
		this.baseUrl = this.appSettings.retrieveUrl();
	}

	ngOnInit(): void {
		this.getRecords();
	}

	getRecords(): void { // this must be removed eventually (PUT IN SERVICE)
		// console.log('getRecord::Getting the hose list, hydrotestId:' + this.hydrotestId);
		if (this.hydrotestId > 0) {
			this.http.get<any>(this.baseUrl + '/api/HydroTestHose/List?id=' + this.hydrotestId)
				.subscribe((data => this.listOfHoses = data),
					err => console.error(err),
					() => this.loadRecordComplete());
		}
	}

	handleFilterHoseList(value) {
		this.listOfHosesFiltered = this.listOfHoses
			.filter((s) => s.SerialNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}
	loadRecordComplete(): void {
		// this.loading = false;
		// console.log('record loaded.');
		// console.log(this.listOfHoses);
	}
	select(item: any): void {
		this.selectedHose = item;
		// alert('Selected hose: ' + this.selectedHose);
		this.hoseSelected.emit(this.selectedHose);
	}

	selectHose(value): void {
		if (value) {
			this.selectedHose = value.id;
			this.hoseSelected.emit(this.selectedHose);
		}
	}
}

class ListOfHoses {
	public Id: number;
	public SerialNumber: string;
}

