import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAutomation } from '../automation-model/automation';

import { Subscription } from 'rxjs/Subscription';
import { AutomationService } from '../automation-service';
import { IAutomations } from '../Automation-model/Automations';
import { IAutomationSearch } from '../Automation-model/AutomationSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './automation-edit.component.html',
	styleUrls: ['automation-edit.component.css']
})

export class AutomationEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Automations';
	errorMessage: string;
	automationForm: UntypedFormGroup;
	automation: IAutomation;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public AutomationSearch: IAutomationSearch;
	public ListData: IAutomations;
	public showNextRecordButton: boolean = true;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private automationService: AutomationService,
		) {
	}

	ngOnInit(): void {
		this.automationForm = this.fb.group({
			Id: '',
			Service: '',
			Step: '',
			Description: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getAutomation(id);
			}
		);
	}

	public getSelectedRecordSet():void{
		this.automationService.getRecordSet(this.AutomationSearch)
			.subscribe((obj: IAutomations) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IAutomations){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.automation.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveAutomation(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.automationForm.valid) {
			const obj = Object.assign({ }, this.automation, this.automationForm.value);
			this.automationService.saveAutomation(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.automationForm.reset();
			this.router.navigate(['/automations']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.automation.Id === 0) {
			this.router.navigate(['/automation-details', data.Id]);
		} 
		else if (this.automation.Id != 0){
			this.getAutomation(this.automation.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.automation.Id)
				record = count;
		});

		this.getAutomation(this.ListData.data[record].Id)
	}

	getAutomation(id: number): void {
		this.automationService.getAutomation(id)
			.subscribe((obj: IAutomation) => this.onAutomationRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onAutomationRetrieved(automation: IAutomation): void {
		if (this.automationForm) {
			this.automationForm.reset();
		}
		this.automation = automation;
		if (this.automation.Id === 0) {
			this.page = 'Add Automations';
		} else {
			this.page = 'Edit Automations';
		}

		this.automationForm.patchValue({
			Id: this.automation.Id,
			Service: this.automation.Service,
			Step: this.automation.Step,
			Description: this.automation.Description,
			CreatedDate: this.automation.CreatedDate,
			CreatedBy: this.automation.CreatedBy,
			UpdatedDate: this.automation.UpdatedDate,
			UpdatedBy: this.automation.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteAutomation(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.automation.Service}?`)) {
			this.automationService.deleteAutomation(this.automation.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/automations']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Automations List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.AutomationSearch = this.automationService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Service')
				this.AutomationSearch.Service = i.Value;

			if (i.Item == 'Step')
				this.AutomationSearch.Step = i.Value;

			if (i.Item == 'Description')
				this.AutomationSearch.Description = i.Value;

			if (i.Item == 'orderByString')
				this.AutomationSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.AutomationSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
