import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';
import { INonStock } from './non-stock-model/non-stock';
import { PrinterIpService } from '../shared/printer-ip.service';

@Injectable()
export class NonStockLabelService {
	private baseUrl: string;
	private glPrintingUrl: string;

	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings,
		private printerIpService: PrinterIpService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/nonstocks';
		//this.glPrintingUrl = this.settings.retrieveGlPrintingUrl() + '/api/nonstocks';
	}

	createNonStocklabel(nonStockLabel: INonStock): Observable <any> {
		return this.authHttp.post(this.baseUrl, nonStockLabel)
			.catch (this.handleError);
	}

	createNonStockGlPrinting(nonStockLabel: INonStock, branch: string): Observable <any> {
		this.glPrintingUrl = 'https://' + this.printerIpService.getIp(branch) +'/GLPrinting' + '/api/nonstocks';
		return this.authHttp.post(this.glPrintingUrl, nonStockLabel)
			.catch (this.handleError);
	}

	private handleError(error: any): Observable <any> {
		return Observable.throw (error.message || 'Server error');
	}
}
