import { Injectable } from '@angular/core';

@Injectable()
export class ReportSettings {

	getDefaultReportStyle() {
		return {
			position: 'relative',
			'width': '100%',
			height: '100vh',
			['font-family']: 'ms sans serif'
		};
	}

}
