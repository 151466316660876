import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../shared/application-settings';
import { IHydrotest } from './Hydrotest-model/Hydrotest';
import { IHydrotestSearch } from './hydrotest-model/hydrotestSearch';
import { IHydrotests } from './hydrotest-model/hydrotests';

@Injectable()
export class HydrotestService {
	private baseUrl;
	public pageTitle = 'Hydro Test List';
	constructor(private authHttp: HttpClient, private settings: ApplicationSettings, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Hydrotests';
	}

	getHydrotest(id: number):  Observable<IHydrotest> {
		if (id === 0) {
			return Observable.of(this.initializeHydrotest());
		}

		const url = `${this.baseUrl}/${id}`;
		return this.authHttp.get<IHydrotest>(url)
			.catch(this.handleError);
	}

	saveHydrotest(hydrotest: IHydrotest): Observable < any > {
		if (hydrotest.Id === 0) {
			return this.createHydrotest(hydrotest);
		}
		return this.updateHydrotest(hydrotest);
	}

	private createHydrotest(hydrotest: IHydrotest): Observable <any> {
		hydrotest.Id = undefined;
		return this.authHttp.post(this.baseUrl, hydrotest)
			.catch (this.handleError);
	}

	private updateHydrotest(hydrotest: IHydrotest): Observable <IHydrotest> {
		return this.authHttp.put(this.baseUrl, hydrotest)
			.catch (this.handleError);
	}

	deleteHydrotest(id: number): Observable < Response > {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete(url)
			.catch (this.handleError);
	}

	private handleError(error: any): Observable <any> {
		return Observable.throw (error.message || 'Server error');
	}

	public getRecordSet(search: IHydrotestSearch): Observable<IHydrotests> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Contact', search.Contact, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Customer', search.Customer, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PurchaseOrder', search.PurchaseOrder, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('TestLocation', search.TestLocation, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Hose', search.Hose, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('End1', search.End1, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SalesOrderNumber', search.SalesOrderNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Contact', search.Contact ? search.Contact : '')
			.set('Customer', search.Customer ? search.Customer : '')
			.set('PurchaseOrder', search.PurchaseOrder ? search.PurchaseOrder : '')
			.set('TestLocation', search.TestLocation ? search.TestLocation : '')
			.set('Hose', search.Hose ? search.Hose : '')
			.set('End1', search.End1 ? search.End1 : '')
			.set('SalesOrderNumber', search.SalesOrderNumber ? search.SalesOrderNumber : '')
		};

		return this.authHttp.get<IHydrotests>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IHydrotests>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	public getFreshSearch() : IHydrotestSearch { 
		let search: IHydrotestSearch = {
			Id: 1,
			CustomerId: 0,
			Contact: '',
			Customer: '',
			Address: '',
			City: '',
			PostalCode: '',
			Telephone: '',
			Fax: '',
			TestDate: '',
			PurchaseOrder: '',
			TestLocation: '',
			Hose: '',
			HoseLength: 0,
			HoseId: '',
			HoseWP: 0,
			End1: '',
			End1WP: 0,
			End2: '',
			End2WP: 0,
			HAWP: 0,
			TestPreasure: 0,
			Tester: '',
			Province: '',
			ElectricalConRequired: '',
			SalesOrderNumber: '',
			TestType: '',
			InternalNotes: '',
			TestingMachine: '',
			PLC: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	public toExcel(search: IHydrotestSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Contact', search.Contact ? search.Contact : '')
			.set('Customer', search.Customer ? search.Customer : '')
			.set('PurchaseOrder', search.PurchaseOrder ? search.PurchaseOrder : '')
			.set('TestLocation', search.TestLocation ? search.TestLocation : '')
			.set('Hose', search.Hose ? search.Hose : '')
			.set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('City', search.City ? search.City : '')
			.set('Province', search.Province ? search.Province : '')
			.set('End1', search.End1 ? search.End1 : '')
			.set('SalesOrderNumber', search.SalesOrderNumber ? search.SalesOrderNumber : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeHydrotest(): IHydrotest {
		return {
			Id: 0,
			CustomerId: 0,
			Contact: '',
			Customer: '',
			Address: '',
			City: '',
			PostalCode: '',
			Telephone: '',
			Fax: '',
			TestDate: '',
			PurchaseOrder: '',
			TestLocation: '',
			Hose: '',
			HoseLength: 0,
			HoseId: '',
			HoseWP: 0,
			End1: '',
			End1WP: 0,
			End2: '',
			End2WP: 0,
			HAWP: 0,
			TestPreasure: 0,
			Tester: '',
			Province: '',
			ElectricalConRequired: '',
			SalesOrderNumber: '',
			TestType: '',
			InternalNotes: '',
			TestingMachine: '',
			PLC: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IHydrotest;
	}
}
