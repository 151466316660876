import { Component, OnInit } from '@angular/core';
import { LabelpropertyconfigService } from 'src/app/labelpropertyconfigs/labelpropertyconfig-service';
import { LabelsmenuService } from 'src/app/labelsmenu/labelsmenu-service';
import { PartcatService } from 'src/app/partcats/partcat-service';
import { ProductdataService } from '../productdata-service';

@Component({
  selector: 'app-productdata-menu',
  templateUrl: './productdata-menu.component.html',
  styleUrls: ['./productdata-menu.component.css']
})
export class ProductsdatamenuComponent implements OnInit {

  public errorMessage = '';
  public progressStatus=false;
  priceLineList:any;
	prodGroupFileStatus:any;
	reqCntProdGroup=0;
  tagList:any;
  prodCatFileStatus:any;
	reqCntProdCat=0;
  reqCntExeProperty=0;
  exePropertyStatus:any;
  exePropertyDate:any;
  prodGroupDate:any;
  prodCatDate:any;
  ProductDataDate:any;

  constructor(
    private labelpropertyconfigService:LabelpropertyconfigService
    , private partcatService:PartcatService
    , private labelsmenuService: LabelsmenuService
    , private productdataService:ProductdataService
    ) { }

  ngOnInit(): void {
    this.getPriceLineList();
    this.getTagList();
    this.getLastExecutionData();
  }
  
    resetError() : void {
      this.errorMessage = '';
    }
    executeAllPropertys(){
     this.reqCntExeProperty=0;
     this.executePropertys();

    }
    executePropertys(){
      this.progressStatus=true;
      this.labelpropertyconfigService.executeAllLabelPropertys(this.reqCntExeProperty).subscribe(
        resp => {
          this.exePropertyStatus=resp.message;
          if(resp.status==true){
            this.reqCntExeProperty=this.reqCntExeProperty+100;
            this.executePropertys();
          }
          else{
            this.progressStatus=false;   
            this.getLastExecutionData();
          }
             
        },
         (error: any) => {
           this.errorMessage = < any > error;
        });
    }
    generateAllProdGroupRpt() {
      if(this.priceLineList){
        this.reqCntProdGroup=0;
        this.getAllProdGroupRpt();	
      }
      else{
        alert("No Data Available");
      }
	
    }
  
    getAllProdGroupRpt(){
      this.progressStatus=true;
      let lastrec=false;
      if(this.reqCntProdGroup==this.priceLineList.length-1){
        lastrec=true;
      }
      this.labelsmenuService.createLabelByProductGroup(this.priceLineList[this.reqCntProdGroup].PriceLine,'all',this.reqCntProdGroup,lastrec).subscribe(
        (val: string) => {
          this.reqCntProdGroup=this.reqCntProdGroup+1;
          this.prodGroupFileStatus='';
          if(val!=null && val!=''){
            this.prodGroupFileStatus=val+ ' file is created!';
            console.log(val+ ' file is created!');	
          }        
          this.progressStatus=false;		
          if(this.reqCntProdGroup<this.priceLineList.length){
            this.getAllProdGroupRpt();
          }
          else{
            this.getLastExecutionData();
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
    }
    getPriceLineList() {
      this.partcatService.getPriceLineList().subscribe(
        (val) => {
          this.priceLineList=val;
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
  
    }
    generateAllProdCatRpt() {
      if(this.tagList){
        this.reqCntProdCat=0;
        this.getAllProdCatRpt();	
      }
      else{
        alert("No Data Available");
      }
     	
    }
    getAllProdCatRpt(){
      this.progressStatus=true;
      let lastrec=false;
      if(this.reqCntProdCat==this.tagList.length-1){
        lastrec=true;
      }
      this.labelsmenuService.createLabelByProductCategory(this.tagList[this.reqCntProdCat].Tag,'all',this.reqCntProdCat,lastrec).subscribe(
        (val: string) => {
          this.reqCntProdCat=this.reqCntProdCat+1;
          this.prodCatFileStatus='';
          if(val!=null && val!=''){
          this.prodCatFileStatus= val+ ' file is created!';
          console.log(val+ ' file is created!');	
          }	
          this.progressStatus=false;		
          if(this.reqCntProdCat<this.tagList.length){
            this.getAllProdCatRpt();
          }
           else{
            this.getLastExecutionData();
           } 
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
    }
    getTagList() {
      this.partcatService.getTagList().subscribe(
        (val) => {
          this.tagList=val;
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
  
    }
    getLastExecutionData() {
      this.productdataService.getLastExecutionData().subscribe(
        (val) => {
         this.exePropertyDate=val.ExecutePropertyDate;
         this.prodGroupDate=val.ProductGroupDate;
         this.prodCatDate=val.ProductCatDate;
         this.ProductDataDate=val.ProductDataDate;
        },
        (error: any) => {
          this.errorMessage = <any>error;
        }
      );
  
    }
}
