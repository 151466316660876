import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription, merge } from 'rxjs';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IHoseSearch } from '../hose.model/hoseSearch';
import { IHoses } from '../hose.model/hoses';
import { DdlService } from './../../../shared/ddlService/ddl.service';
import { IHose } from './../Hose.model/Hose';
import { HoseGridService } from './hose-list.service';

@Component({
	selector: 'app-hose-list',
	templateUrl: './hose-list.component.html',
	styleUrls: ['./hose-list.component.css']
})

export class HoseListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	public pageSize: number = +localStorage.getItem('rows');
	public pageSizeList: any[];
	public skip = 0;
	public sub: Subscription;

	public active: boolean;
	public product = '';

	pageTitle = 'Hose List';
	errorMessage = '';
	listFilter: string;
	public listOfcustomers: IHose[];
	public allowUnsort = true;
	public multiple = false;

	public producttypesddl = [];
	public showHideSearch: boolean = false;
	isLoadingResults = true;
	public UserSearches: IUsersearch[];
	public SearchText: string = 'Show Filters';
	Hose = '';
	ProductType = '';
	public dataSource: any;
	public recordTotal: number = 0;
	displayedColumns: string[] = ['Id','InsideDiameter','OutsideDiameter','Compression','Pressure','ProductType'];

	constructor(
		private appSettings: ApplicationSettings,
		private service: HoseGridService,
		private userSearchService: UsersearchService,
		public ddlService: DdlService,
		//private configurationService: ConfigurationService,	
        private router: Router) {
		//this.pageSizeList = this.configurationService.pageSizeList;
		if (this.pageSize === 0) {
			this.pageSize = 10;
			localStorage.setItem('rows', '10');
		}
	}

	refreshGrid(): void {
		this.skip = 0;
		this.getData();
	}

	ngOnInit(): void {
				this.skip = 0;
				this.Hose='';
				this.ProductType='all-types';
				let x  = +localStorage.getItem('rows');
				if(x>0) { this.pageSize = x; }
				else { this.pageSize = 10;}
				if (localStorage.getItem('showHideSearch') === 'true')
				{
					   this.showHideSearch = true;
					   this.SearchText = 'Hide Filters';
				}
				else
				{
					   this.showHideSearch = false;
					   this.SearchText = 'Show Filters';
				 }
				 this.ddlService.ddl('PRODUCTTYPE')
				 .subscribe(
					 (data) => {
						 this.producttypesddl = data;
					 });

	}


	public ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
		.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
	}
	, (error: any) => this.errorMessage = < any > error);
	}


	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='hose')
				this.Hose = i.Value;

				if(i.Item=='productType')
				this.ProductType = i.Value;	

			if(i.Item=='orderByString') {
				if (this.appSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.appSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	getData(): any {
		this.isLoadingResults = true;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.dataSource = null; 
		this.recordTotal = 0; 
		  let search: IHoseSearch = this.service.getFreshSearch();
   
		   search.Hose = this.Hose;
		   search.ProductType = this.ProductType;

		   search.skip = this.paginator.pageIndex * this.pageSize;
		   search.take = this.pageSize;
		   search.orderByString = this.sort.active + '-' + this.sort.direction;
   
		   this.service.getRecordSet(search).subscribe((obj: IHoses) => this.onSelectedRecordSetReceived(obj),
		   (error: any) => {
			   this.errorMessage = <any> error;
		   });
	   }

	   private onSelectedRecordSetReceived(obj: IHoses){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.isLoadingResults = false;
	}


	showHideSearchClick() {
		if (this.showHideSearch) 
		{
			this.showHideSearch = false; 
		   localStorage.setItem('showHideSearch', 'false'); 
		   this.SearchText = 'Show Filters'; 
	   }
	   else
	   {
			this.showHideSearch = true; 
		   localStorage.setItem('showHideSearch', 'true'); 
			 this.SearchText = 'Hide Filters'; 
	   }
   }
   searchAndHideClick() {
		this.skip = 0;
		this.paginator.pageIndex = 0;
	   this.getData();
		setTimeout(() => {
			this.showHideSearchClick();
		}, 500);
	}

	searchClick(): void {
	   this.skip = 0;
	   this.paginator.pageIndex = 0;
	   this.getData();
   }

   public searchClearClick(): void {
	this.Hose = '';
	this.ProductType = 'all-types';
	this.skip = 0;
	this.sort.direction = 'asc';
	this.sort.active = 'Id';
	this.paginator.pageIndex = 0;
	this.getData();
}
	
public help() {
	const url = this.router.serializeUrl(
		this.router.createUrlTree([`/help-view/hoses`])
	);
	window.open(url, '_blank');
}
}
