import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http'; //

import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IHose } from '../hose-model/hose';

@Injectable()
export class HosesService {
	baseUrl = '';

	constructor(
		private _http: HttpClient,
		private appSettings: ApplicationSettings) {
			this.baseUrl = this.appSettings.retrieveUrl();
	}

	update(hoseDetail: IHose): Observable<any> {
		return this._http.post(this.baseUrl + '/api/HydroTestHose/AddOrUpdate', hoseDetail)
			.catch(this.handleError);
	}

	delete(hoseDetail: IHose): Observable<any> {
		return this._http.post(this.baseUrl + '/api/HydroTestHose/Delete', hoseDetail)
			.catch(this.handleError);
	}

	getHoseDetail(id: string): Observable<IHose> {
		return this._http.get<any>(this.baseUrl + '/api/HydroTestHose/Detail?id=' + id);
	}

	private handleError(error: Response): Observable<void> {
		return Observable.throw(error.json().error || 'Server.error');
	}
}
