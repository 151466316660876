import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

	url: SafeResourceUrl;
	legacyUrl = '/Admin/AdminMenu.aspx';

	constructor(
		public sanitizer: DomSanitizer,
		private settings: ApplicationSettings) {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.retrieveLegacyAppUrl() + this.legacyUrl);
	}

	ngOnInit() {
	}

}
