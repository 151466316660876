import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CustomerService } from 'src/app/customers/customer-service';
import { CustomerPlanrecordService } from '../customerplanrecord-service';

@Component({
  selector: 'app-customerplanrecord-upload',
  templateUrl: './customerplanrecord-upload.component.html',
  styleUrls: ['./customerplanrecord-upload.component.css']
})
export class CustomerPlanrecordUploadComponent implements OnInit {

  uploadfileForm: UntypedFormGroup;
  errorMessage: string;
  customersData = [];
  processStatus=false;
  constructor(
    private fb: UntypedFormBuilder,
     private customerplanrecordService: CustomerPlanrecordService,
     private customerService:CustomerService
  ) { }

  ngOnInit(): void {
    this.uploadfileForm = this.fb.group({
      date: ['', Validators.required],
      customer: ['', Validators.required],
      fileName: ''
    });
    this.uploadfileForm.controls.customer.valueChanges
			.pipe(
				debounceTime(1000),
				switchMap(value =>
					this.customerService.searchCustomers(value)
				)
			)
			.subscribe(resp => {
				this.customersData = resp;
			}, _ => this.errorMessage = 'Error loading customers from the server');

  }
  onFileSelected(event: any) {

    if (this.uploadfileForm.valid) {
      this.processStatus=true;
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        console.log(target.files[0].name);
      }
      const formData = new FormData();
      formData.append('fileName', target.files[0]);
      formData.append('date', this.uploadfileForm.value.date);
      formData.append('customer', this.uploadfileForm.value.customer);
      console.log(formData);

      this.customerplanrecordService.addFile(formData).subscribe(
        retVal => {
          console.log("File Uploaded.");
          console.log(retVal);
          if (retVal == "File Uploaded") {
            alert("File Uploaded");
            this.uploadfileForm.reset();
            // this.router.navigate(['/customerplanrecords-details']);
          }
          else {
            alert(retVal);
            this.uploadfileForm.patchValue({
              fileName: ''
            });
          }
          this.processStatus=false;
        }
        ,(error: any) => this.errorMessage = <any>error
      );

    }
    else {
      alert("Please select date and customers!");
      this.uploadfileForm.patchValue({
        fileName: ''
      });

    }
    
  }
  resetError() : void {
		this.errorMessage = '';
    this.uploadfileForm.patchValue({
      fileName: ''
    });
    this.processStatus=false;
	}
}
