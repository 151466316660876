﻿<h1>File Upload</h1>

<div *ngIf="hoseDetail.Id == 0" style="color: red; ">
    <h3>You must press save before adding a file.</h3>
</div>
<form class="form-horizontal" novalidate [formGroup]="uploadForm">
<div *ngIf="hoseDetail.Id > 0">
    <input (change)="onFileSelected($event)" type="file" accept="image/png, image/gif, image/jpeg">

    <label>FileName: </label>&nbsp;&nbsp;
    <label>{{ hoseDetail.fileName }}</label>&nbsp;&nbsp;
    <a *ngIf="hoseDetail.fileName !=''" href="{{hoseDetail.fileNameAndPath}}" target="_blank">
        Show image in new tab
    </a>
    <br /><br />
    <div *ngIf="hoseDetail.fileName !=''" class="error">
        <img src="{{hoseDetail.fileNameAndPath}}" width="500px" />
    </div>
</div>
</form>