import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
//import { UserListComponent } from '../users/user-list/user-list.component';
import { UserService } from '../users/user-service/user.service';
import { UserCreateComponent } from '../users/user-create/user-create.component';
import { UserEditComponent } from '../users/user-edit/user-edit.component';
import { UserChangePasswordComponent } from '../users/user-change-password/user-change-password.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { AuthGuardAdmin } from 'src/app/auth/auth-guard-admin.service';
import { AspnetuserListComponent } from '../users/aspnetuser-list/aspnetuser-list.component';
import { MaterialModule } from '../../material-module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserResetPasswordComponent } from '../users/user-reset-password/user-reset-password.component';

@NgModule({
	declarations: [
		//UserListComponent,
		UserCreateComponent,
		UserEditComponent,
		UserChangePasswordComponent,
		AspnetuserListComponent,
		UserResetPasswordComponent
	],
	imports: [
		MatPaginatorModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		
		CommonModule,
		RouterModule.forChild([
			// {
			// 	path: 'users',
			// 	component: UserListComponent,
			// 	canActivate: [AuthGuard, AuthGuardAdmin]
			// },
			{
				path: 'create-user',
				component: UserCreateComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'edit-user/:Id',
				component: UserEditComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'change-password',
				component: UserChangePasswordComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'aspnetusers',
				component: AspnetuserListComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'reset-password/:Id',
				component: UserResetPasswordComponent,
			}
		])
	],
	providers: [UserService]
})
export class UserModule {}
