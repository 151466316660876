
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-7 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/hydrotestcalibration-details/0']" style='width:auto;' [disabled]="progressStatus">Add New HydroTestCalibration</button>
		</div>
		<div class='col-md-1 col-xs-5 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: 80px' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: 100px' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'>machineip</label>
				<input type='text' class='form-control block-element' placeholder='machineip' [(ngModel)]='machineip' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Pressure Switch</label>
				<input type='text' class='form-control block-element' placeholder=' Pressure Switch' [(ngModel)]='PressureSwitch' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Pressure</label>
				<input type='text' class='form-control block-element' placeholder=' Pressure' [(ngModel)]='Pressure' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> P L C Value</label>
				<input type='text' class='form-control block-element' placeholder=' P L C Value' [(ngModel)]='PLCValue' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/hydrotestcalibration-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='machineip'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'>machineip</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.machineip}}</td>
	</ng-container>
	<ng-container matColumnDef='PressureSwitch'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Pressure Switch</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.PressureSwitch}}</td>
	</ng-container>
	<ng-container matColumnDef='Pressure'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Pressure</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.Pressure}}</td>
	</ng-container>
	<ng-container matColumnDef='PLCValue'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> P L C Value</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.PLCValue}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>