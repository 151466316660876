import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { PartattListComponent } from './partatt-list/partatt-list.component';
import { PartattEditComponent } from './partatt-edit/partatt-edit.component';
import { PartattService } from './partatt-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		PartattListComponent
		, PartattEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'partatts', component: PartattListComponent },
			{ path: 'partatt-details/:Id', component: PartattEditComponent },
		])
	],
	providers: [
		PartattService
	]
})
export class PartattModule { }
