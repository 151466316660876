import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { LookuplistService } from 'src/app/lookuplists/lookuplist-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { IPartattSearch } from '../Partatt-model/PartattSearch';
import { IPartatts } from '../Partatt-model/Partatts';
import { IPartatt } from '../partatt-model/partatt';
import { PartattService } from '../partatt-service';

@Component({
	templateUrl: './partatt-edit.component.html',
	styleUrls: ['partatt-edit.component.css']
})

export class PartattEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit PartAtts';
	errorMessage: string;
	partattForm: UntypedFormGroup;
	partatt: IPartatt;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public PartattSearch: IPartattSearch;
	public ListData: IPartatts;
	public showNextRecordButton: boolean = true;
	public progressStatus = false;
	public primaryDimList;
	public secondaryDimList;
	urldata:any;
	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private partattService: PartattService
		, private lookuplistService: LookuplistService
		, public applicationSettings: ApplicationSettings
	) {
	}

	ngOnInit(): void {
		this.partattForm = this.fb.group({
			Id: '',
			EclipseId: '',
			PrimaryDimension: ['', [Validators.maxLength(250)]],
			PrimaryDimensionValue: ['', [Validators.maxLength(250)]],
			SecondaryDimension: ['', [Validators.maxLength(250)]],
			SecondaryDimensionValue: ['', [Validators.maxLength(250)]],
			Var3: ['',[Validators.maxLength(300)]],
			Series: ['',[Validators.maxLength(300)]],
			Style: ['',[Validators.maxLength(300)]],
			LabelTag: ['',[Validators.maxLength(300)]],
			LabelGroup: ['',[Validators.maxLength(300)]],
			SortOrder: '',
			DescriptionCount: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			PartImage:'',
			FilePath:''
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getPartatt(id);
				this.progressStatus = true;
			}
		);
		this.getPrimaryDimension();
		this.getSecondaryDimension();
	}

	public getSelectedRecordSet(): void {
		this.partattService.getRecordSet(this.PartattSearch)
			.subscribe((obj: IPartatts) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IPartatts) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.partatt.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	savePartatt(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.partattForm.valid) {
			const obj = Object.assign({}, this.partatt, this.partattForm.value);
			this.partattService.savePartatt(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.partattForm.reset();
			this.router.navigate(['/partatts']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.partatt.Id === 0) {
			this.router.navigate(['/partatt-details', data.Id]);
		}
		else if (this.partatt.Id != 0) {
			this.getPartatt(this.partatt.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.partatt.Id)
				record = count;
		});

		this.getPartatt(this.ListData.data[record].Id)
	}

	getPartatt(id: number): void {
		this.partattService.getPartatt(id)
			.subscribe((obj: IPartatt) => this.onPartattRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onPartattRetrieved(partatt: IPartatt): void {
		if (this.partattForm) {
			this.partattForm.reset();
		}
		this.partatt = partatt;
		if (this.partatt.Id === 0) {
			this.page = 'Add PartAtts';
		} else {
			this.page = 'Edit PartAtts';
		}

		this.partattForm.patchValue({
			Id: this.partatt.Id,
			EclipseId: this.partatt.EclipseId,
			PrimaryDimension: this.partatt.PrimaryDimension,
			PrimaryDimensionValue: this.partatt.PrimaryDimensionValue,
			SecondaryDimension: this.partatt.SecondaryDimension,
			SecondaryDimensionValue: this.partatt.SecondaryDimensionValue,
			Var3: this.partatt.Var3,
			Series: this.partatt.Series,
			Style: this.partatt.Style,
			LabelTag: this.partatt.LabelTag,
			LabelGroup: this.partatt.LabelGroup,
			SortOrder: this.partatt.SortOrder,
			DescriptionCount: this.partatt.DescriptionCount,
			PartImage:this.partatt.PartImage,
			FilePath:this.partatt.FilePath,
			CreatedDate: this.partatt.CreatedDate,
			CreatedBy: this.partatt.CreatedBy,
			UpdatedDate: this.partatt.UpdatedDate,
			UpdatedBy: this.partatt.UpdatedBy,
		});
		this.urldata=this.applicationSettings.retrieveUrl()+'/uploadedfiles/PartAttsImage/'+this.partatt.PartImage;
		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deletePartatt(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.partatt.EclipseId}?`)) {
			this.partattService.deletePartatt(this.partatt.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/partatts']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('PartAtts List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.PartattSearch = this.partattService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'EclipseId')
				this.PartattSearch.EclipseId = i.Value;

			if (i.Item == 'PrimaryDimension')
				this.PartattSearch.PrimaryDimension = i.Value;

			if (i.Item == 'PrimaryDimensionValue')
				this.PartattSearch.PrimaryDimensionValue = i.Value;

			if (i.Item == 'SecondaryDimension')
				this.PartattSearch.SecondaryDimension = i.Value;

			if (i.Item == 'SecondaryDimensionValue')
				this.PartattSearch.SecondaryDimensionValue = i.Value;

				if (i.Item == 'Var3')
				this.PartattSearch.Var3 = i.Value;

			if (i.Item == 'Series')
				this.PartattSearch.Series = i.Value;

			if (i.Item == 'Style')
				this.PartattSearch.Style = i.Value;

			if (i.Item == 'LabelTag')
				this.PartattSearch.LabelTag = i.Value;

			if (i.Item == 'LabelGroup')
				this.PartattSearch.LabelGroup = i.Value;

			if (i.Item == 'SortOrder')
				this.PartattSearch.SortOrder = i.Value;

			if (i.Item == 'DescriptionCount')
				this.PartattSearch.DescriptionCount = i.Value;

			if (i.Item == 'orderByString')
				this.PartattSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.PartattSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	getPrimaryDimension() {
		this.lookuplistService.getLookupListByForm('PartAtts', 'PrimaryDimension').subscribe(response => {
			this.primaryDimList = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
	getSecondaryDimension() {
		this.lookuplistService.getLookupListByForm('PartAtts', 'SecondaryDimension').subscribe(response => {
			this.secondaryDimList = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
	onFileSelected(event: any) {

		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
		  console.log(target.files[0].name);
	   
		const formData = new FormData();
		formData.append('fileName', target.files[0]);
		formData.append('id', this.partatt.Id.toString());
  
		this.partattService.addFile(formData).subscribe(
		  retVal => {
			if (retVal !== "") {
			  alert("File Uploaded");
			  console.log("File Uploaded");
			  this.partattForm.patchValue({
				  PartImage: retVal
				});
			  this.urldata=this.applicationSettings.retrieveUrl()+'/uploadedfiles/PartAttsImage/'+retVal;
			}
			else {
			  alert("File Not Uploaded");
			  this.partattForm.patchValue({
				Image: ''
			  });
			  this.urldata="";
			}
		  }
		  ,(error: any) => this.errorMessage = <any>error
		);
	  }	
	}
}
