import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { ApplicationSettings } from './../shared/application-settings';
import { ICrimp } from './crimp-model/crimp';
import { ICrimpSearch } from './crimp-model/IcrimpSearch';
import { ICrimps } from './crimp-model/ICrimps';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';


@Injectable()
export class CrimpService {
	private baseUrl;

	constructor(private authHttp: HttpClient, private settings: ApplicationSettings, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Crimp';
	}
	public pageTitle = 'New From Crimp';

	getCrimp(id: number): Observable<ICrimp> {
		if (id === 0) {
			return of(this.initializeCrimp());
		}

		const url = `${this.baseUrl + '/GetCrimpById'}/${id}`;
		return this.authHttp.get<ICrimp>(url)
			.catch(this.handleError);
	}

	getCrimpsForExport(): Observable<ICrimp[]> {
		
		const url = this.baseUrl + '/GetCrimpsForExport';
		return this.authHttp.get<ICrimp[]>(url)
			.catch(this.handleError);
	}

	saveCrimp(crimp: ICrimp): Observable<any> {
		if (crimp.Id === 0) {
			return this.createCrimp(crimp);
		}
		return this.updateCrimp(crimp);
	}

	private createCrimp(crimp: ICrimp): Observable<any> {
		const url = `${this.baseUrl}` + '/CreateCrimp';
		return this.authHttp.post(url, crimp)
			.catch(this.handleError);
	}

	private updateCrimp(crimp: ICrimp): Observable<ICrimp> {
		return this.authHttp.put(this.baseUrl + '/UpdateCrimp', crimp)
			.catch(this.handleError);
	}

	deleteCrimp(id: number): Observable<Response> {
		const url = `${this.baseUrl}` + '/DeleteCrimp/' + `${id}`;
		return this.authHttp.delete(url)
			.catch(this.handleError);
	}

	private handleError(error: any): Observable<any> {
		return throwError(error.error.ErrorDescription || 'Server error');
	}

	public getFreshSearch() : ICrimpSearch { 
		let search: ICrimpSearch = {
			Id: 0,
			HoseName: '',
			TestRequired: 0,
			OrderNumber: '',
			TestResults: '',
			End2Same: 0,
			HoseOutsideDiameter: 0,
			FerruleName: '',
			FittingName: '',
			Compression: 0,
			Measurement1: null,
			Measurement2: null,
			Measurement3: null,
			MeasuredHoseOD: 0,
			OverrideCompression: 0,
			CalculatedInch: 0,
			CalculatedMM: 0,
			FerruleName_2: '',
			FittingName_2: '',
			Compression_2: 0,
			Measurement1_2: null,
			Measurement2_2: null,
			Measurement3_2: null,
			MeasuredHoseOD_2: 0,
			OverrideCompression_2: 0,
			CalculatedInch_2: 0,
			CalculatedMM_2: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			crimpDate:0,
			Branch:'',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};


	public getRecordSet(search: ICrimpSearch): Observable<ICrimps> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('OrderNumber', search.OrderNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('TestRequired', search.TestRequired.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('crimpDate', search.crimpDate.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('take', search.take.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('TestRequired', search.TestRequired.toString())
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('crimpDate', search.crimpDate.toString())
			.set('OrderNumber', search.OrderNumber ? search.OrderNumber : '')
			.set('orderByString', search.orderByString)
		};

		return this.authHttp.get<ICrimps>(this.baseUrl + '/GetNewFromCrimp', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICrimps>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	initializeCrimp(): ICrimp {
		return {
			Id: 0,
			HoseName: '',
			TestRequired: 0,
			OrderNumber: '',
			TestResults: '',
			End2Same: 0,
			HoseOutsideDiameter: 0,
			FerruleName: '',
			FittingName: '',
			Compression: 0,
			Measurement1: null,
			Measurement2: null,
			Measurement3: null,
			MeasuredHoseOD: 0,
			OverrideCompression: 0,
			CalculatedInch: 0,
			CalculatedMM: 0,
			FerruleName_2: '',
			FittingName_2: '',
			Compression_2: 0,
			Measurement1_2: null,
			Measurement2_2: null,
			Measurement3_2: null,
			MeasuredHoseOD_2: 0,
			OverrideCompression_2: 0,
			CalculatedInch_2: 0,
			CalculatedMM_2: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICrimp;
	}

	public addHydroTest(id: number): Observable<any> {
		const url = `${this.baseUrl}` + '/CreateHydroTest/' + `${id}`;
		return this.authHttp.post(url, id)
			.catch(this.handleError);
	}

	public toExcel(search: ICrimpSearch): Observable<string> {
		const options = {
		params: new HttpParams()
		.set('TestRequired', search.TestRequired.toString())
		.set('skip', search.skip.toString())
		.set('take', search.take.toString())
		.set('crimpDate', search.crimpDate.toString())
		.set('OrderNumber', search.OrderNumber ? search.OrderNumber : '')
		.set('orderByString', search.orderByString)
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}
