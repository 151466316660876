import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { LabelsmenuService } from '../labelsmenu-service';

@Component({
  selector: 'app-averylabels',
  templateUrl: './averylabels.component.html',
  styleUrls: ['./averylabels.component.css']
})
export class AverylabelsComponent implements OnInit {
  
  prodCatPdfLinkList:any;
  prodGroupPdfLinkList:any;
  public errorMessage = '';

  constructor(
    public applicationSettings: ApplicationSettings
    ,private labelsmenuService:LabelsmenuService
  ) { }

  ngOnInit(): void {
    this.getProdCatPdfLinkList();
    this.getProdGroupPdfLinkList();
  }
  getProdCatPdfLinkList(){
		this.labelsmenuService.getProdCatPdfLinkList().subscribe(
			(obj) => {
				this.prodCatPdfLinkList=obj;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
  getProdGroupPdfLinkList(){
		this.labelsmenuService.getProdGroupPdfLinkList().subscribe(
			(obj) => {
				this.prodGroupPdfLinkList=obj;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	public openPdfFile(filename) {
		window.open(`${this.applicationSettings.retrieveUrl()}/pdflabels/`+filename);
	}
  resetError(): void {
		this.errorMessage = '';
	}
}
