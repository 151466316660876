import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { DevelopmenttaskService } from '../developmenttask-service';
import { IDevelopmenttaskSearch } from '../developmenttask-model/developmenttasksearch';
import { IDevelopmenttasks } from '../developmenttask-model/developmenttasks';
import { BrancheService } from 'src/app/admin/branches/branche-service';
import { UserService } from 'src/app/admin/users/user-service/user.service';
import { LookuplistService } from 'src/app/lookuplists/lookuplist-service';


@Component({
	templateUrl: './developmenttask-list.component.html',
	styleUrls: ['./developmenttask-list.component.css']
})

export class DevelopmenttaskListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'Name', 'Description', 'Status', 'EstimatedHours', 'Priority', 'ScheduledDate', 'InstalledDate', 'Application'];

	// displayedColumns: string[] = ['Id', 'Name', 'BranchId', 'Description', 'EstimatedHours', 'Priority', 'Application', 'Type1', 'Type2', 'Type3', 'Notes', 'InstalledDate', 'Owner', 'AssignedUser', 'nextaction', 'DevNotes', 'Status'];


	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public Name = '';
	public BranchId = '';
	public Description = '';
	public EstimatedHours = '';
	public Priority = '';
	public Application = '';
	public Type1 = '';
	public Type2 = '';
	public Type3 = '';
	public Notes = '';
	public InstalledDate = '';
	public Owner = '';
	public AssignedUser = '';
	public nextaction = '';
	public DevNotes = '';
	public Status = '';
	public ScheduledDate = '';
	public HideCompleted = false;

	public pageTitle = 'Development Tasks List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public branches;
	public employees;
	public statuslist;
	public applicationlist;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, public branchService: BrancheService
		, private _appService: DevelopmenttaskService
		, public userService: UserService
		, public lookuplistService: LookuplistService
	) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x = +localStorage.getItem('rows');
		if (x > 0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10'; }
		if (localStorage.getItem('showHideSearch') === 'true') {
			this.showHideSearch = true;
			this.SearchText = 'Hide Filters';
		}
		else {
			this.showHideSearch = false;
			this.SearchText = 'Show Filters';
		}
		this.branchService.getAllBranchesForDropdown().subscribe(response => {
			console.log(response);
			this.branches = response;
		});
		this.userService.getAllUsers('').subscribe(response => {
			this.employees = response;
		});
		this.getStatus();
		this.getApplication();
	}
	getStatus() {
		this.lookuplistService.getLookupListByForm('Development Tasks', 'Status').subscribe(response => {
			this.statuslist = response;
		});
	}
	getApplication() {
		this.lookuplistService.getLookupListByForm('Development Tasks', 'Application').subscribe(response => {
			this.applicationlist = response;
		});
	}
	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {

				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.Name = i.Value;

			if (i.Item == 'BranchId')
				this.BranchId = i.Value;

			if (i.Item == 'Description')
				this.Description = i.Value;

			if (i.Item == 'EstimatedHours')
				this.EstimatedHours = i.Value;

			if (i.Item == 'Priority')
				this.Priority = i.Value;

			if (i.Item == 'Application')
				this.Application = i.Value;

			if (i.Item == 'Type1')
				this.Type1 = i.Value;

			if (i.Item == 'Type2')
				this.Type2 = i.Value;

			if (i.Item == 'Type3')
				this.Type3 = i.Value;

			if (i.Item == 'Notes')
				this.Notes = i.Value;

			if (i.Item == 'InstalledDate')
				this.InstalledDate = i.Value;

			if (i.Item == 'Owner')
				this.Owner = i.Value;

			if (i.Item == 'AssignedUser')
				this.AssignedUser = i.Value;

			if (i.Item == 'nextaction')
				this.nextaction = i.Value;

			if (i.Item == 'DevNotes')
				this.DevNotes = i.Value;

			if (i.Item == 'Status')
				this.Status = i.Value;

			if (i.Item == 'ScheduledDate')
				this.ScheduledDate = i.Value;

			if (i.Item == 'HideCompleted') {
				if (i.Value == "true") {
					this.HideCompleted = true;
				}
				else {
					this.HideCompleted = false;
				}
			}

			if (i.Item == 'orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value) === 'asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(() => this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
		this.dataSource = null;
		this.recordTotal = 0;
		let search: IDevelopmenttaskSearch = this._appService.getFreshSearch();

		search.Name = this.Name;
		search.BranchId = this.BranchId;
		search.Description = this.Description;
		search.EstimatedHours = this.EstimatedHours;
		search.Priority = this.Priority;
		search.Application = this.Application;
		search.Type1 = this.Type1;
		search.Type2 = this.Type2;
		search.Type3 = this.Type3;
		search.Notes = this.Notes;
		search.InstalledDate = this.InstalledDate;
		search.Owner = this.Owner;
		search.AssignedUser = this.AssignedUser;
		search.nextaction = this.nextaction;
		search.DevNotes = this.DevNotes;
		search.HideCompleted = this.HideCompleted;
		search.Status = this.Status;
		search.ScheduledDate=this.ScheduledDate;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IDevelopmenttasks) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}

	private onSelectedRecordSetReceived(obj: IDevelopmenttasks) {
		console.log(obj);
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.Name = '';
		this.BranchId = '';
		this.Description = '';
		this.EstimatedHours = '';
		this.Priority = '';
		this.Application = '';
		this.Type1 = '';
		this.Type2 = '';
		this.Type3 = '';
		this.Notes = '';
		this.InstalledDate = '';
		this.Owner = '';
		this.AssignedUser = '';
		this.nextaction = '';
		this.DevNotes = '';
		this.Status = '';
		this.ScheduledDate='';
		this.HideCompleted = false;
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError(): void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		if (this.showHideSearch) {
			this.showHideSearch = false;
			localStorage.setItem('showHideSearch', 'false');
			this.SearchText = 'Show Filters';
		}
		else {
			this.showHideSearch = true;
			localStorage.setItem('showHideSearch', 'true');
			this.SearchText = 'Hide Filters';
		}
	}
	searchAndHideClick() {
		this.skip = 0;
		this.getData();
		setTimeout(() => {
			this.showHideSearchClick();
		}, 500);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/developmenttasks`])
		);
		window.open(url, '_blank');
	}
	public toExcel() {
		let search: IDevelopmenttaskSearch = this._appService.getFreshSearch();

		search.Name = this.Name;
		search.BranchId = this.BranchId;
		search.Description = this.Description;
		search.EstimatedHours = this.EstimatedHours;
		search.Priority = this.Priority;
		search.Application = this.Application;
		search.Type1 = this.Type1;
		search.Type2 = this.Type2;
		search.Type3 = this.Type3;
		search.Notes = this.Notes;
		search.InstalledDate = this.InstalledDate;
		search.Owner = this.Owner;
		search.AssignedUser = this.AssignedUser;
		search.nextaction = this.nextaction;
		search.DevNotes = this.DevNotes;
		search.Status = this.Status;
		search.ScheduledDate=this.ScheduledDate;
		search.HideCompleted = this.HideCompleted;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';


		this._appService.toExcel(search).subscribe(
			retVal => {
				this.excelFile = retVal;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}
	public hideFileLink() {
		this.excelFile = "";
	}
	public openExcelFile() {
		window.open(`${this.applicationSettings.retrieveUrl()}${this.excelFile}`);
	}
}