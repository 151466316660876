import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthGuardAdmin } from '../auth/auth-guard-admin.service';
import { AuthGuard } from '../auth/auth-guard.service';
import { AuthService } from '../auth/auth.service';
import { ApplicationSettings } from '../shared/application-settings';
import { JwtHttpInterceptor } from '../shared/jwtHttp.interceptor';

export function tokenGetter() {
	return localStorage.getItem('token');
}

@NgModule({
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
		AuthService,
		AuthGuard,
		AuthGuardAdmin,
		ApplicationSettings
	]
})
export class AuthorizationModule { }
