<div class="col-md-12 nopadding form-my mb-40">
    <div class="card-form">
        <div class="form-title">{{ page }}</div>
        <form class="form-horizontal" novalidate [formGroup]="fittingForm">
            <div class="form-body">
                <div class="col-md-12">
                    <a class='btn btn-default btn-sm' [routerLink]="['/fittings']">
                        <span class=" glyphicon glyphicon-chevron-left"></span>Back
                    </a>

                    <div class="mt-40">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="Fitting" class="col-md-3 control-label">Fitting Name <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <input formControlName="Fitting" type="text" id="Fitting" placeholder="Fitting Name*" name="Fitting" required class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="ProductTypeId" class="col-md-3 control-label">Product <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <select formControlName="ProductType" class="form-control">
                                        <option *ngFor="let item of producttypesddl" [value]="item.lookupText">{{item.lookupText}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="OutsideDiameter" class="col-md-3 control-label">Outside Diameter <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <input formControlName="OutsideDiameter" type="text" id="OutsideDiameter" placeholder="Outside Diameter" name="OutsideDiameter" required class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Pressure" class="col-md-3 control-label">Pressure</label>
                                <div class="col-md-9">
                                    <input formControlName="Pressure" type="number" class="form-control" id="Pressure" placeholder="Pressure.." name="Pressure" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="PressureTest" class="col-md-3 control-label">Pressure Test</label>
                                <div class="col-md-9">
                                    <input formControlName="PressureTest" class="form-control" type="number" id="PressureTest" placeholder="Pressure Test.." name="PressureTest" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12">

                            <div class="form-group">
                                <label for="Description" class="col-md-3 control-label">Description</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="Description" type="text" id="Description" placeholder="Description" name="Description"></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="CrimpInstructions" class="col-md-3 control-label">Crimp Instructions</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="CrimpInstructions" type="text" id="CrimpInstructions" placeholder="Crimp Instructions" name="CrimpInstructions"></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Notes" class="col-md-3 control-label">Notes</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="Notes" type="text" id="Notes" placeholder="Notes.." name="Notes"></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="form-footer">

                <div class="col-md-12 alert alert-danger mt-20 mb-20 text-center" *ngIf='errorMessage'>
                    <strong>Error occured !</strong> {{ errorMessage }}.
                </div>

                <div class="col-md-12 alert alert-success mt-20 mb-20 text-center" *ngIf='message'>
                    {{ message }}.
                </div>

                <div class="col-md-12 buttons-row text-center">
                    <button [disabled]="!editPermission" class="btn btn-flat btn-primary" (click)="saveFitting(true)" style="width: 150px; margin-right:10px">
                      <span class="glyphicon glyphicon-floppy-save"></span> Save & Return &nbsp;
              </button>
                    <span>
                      <button [disabled]="!editPermission" class="btn btn-flat btn-primary"
                          (click)="saveFitting(false)"
                          style="width: 150px; margin-right:10px">
                          <span class="glyphicon glyphicon-floppy-save"></span> Save & Continue &nbsp;
                    </button>
                    </span>
                    <span>
                      <button class="btn btn-flat btn-warning"
                          (click)="cancel()"
                          style="width: 150px; margin-right:10px">
                          <span class=""></span> Cancel &nbsp;
                    </button>
                    </span>
                    <span>
                      <button class="btn btn-flat btn-danger"
                          (click)="deleteFitting()"
                          style="width: 150px; margin-right:10px"
                          [disabled]='!fittingForm.valid||!editPermission'>
                          <span class="glyphicon glyphicon-remove-circle"></span> Delete &nbsp;
                    </button>
                    </span>
                </div>

                <div class="col-md-12 footer-info">
                    <div class="text-left col-sm-6">
                        Updated By: <span *ngIf="fitting" class="label label-default">{{fitting.UpdatedBy}}</span>

                    </div>
                    <div class="text-right col-sm-6">
                        Time: <span *ngIf="fitting" class="label label-success">{{fitting.UpdatedDate}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>