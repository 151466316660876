import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IBranche} from './Branche-model/Branche';
import { IBranches } from './Branche-model/Branches';
import { IBrancheSearch } from './Branche-model/BrancheSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { Response } from '@angular/http';

@Injectable()

export class BrancheService {
	private baseUrl;
	public pageTitle = 'Branches List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Branches';
	}

	getBranche(id: number):  Observable<IBranche> {
		if (id === 0) {
			return of(this.initializeBranche());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IBranche>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveBranche(branche: IBranche): Observable<any> {
		if (branche.Id === 0) {
			return this.createBranche(branche);
		}
		return this.updateBranche(branche);
	}

	private createBranche(branche: IBranche): Observable<any> {
		branche.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/branchecreate', branche)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateBranche(branche: IBranche): Observable<IBranche> {
		return this.authHttp.put<IBranche>(this.baseUrl, branche)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteBranche(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeBranche(): IBranche {
		return {
			Id: 0,
			BranchName: '',
			BusinessNumber: '',
			TollFreeNumber: '',
			AddressLine: '',
			AddressLine2: '',
			PostalCode: '',
			City: '',
			Province: '',
			Country: '',
			Notes: '',
			BranchNumber: 0,
			fax: '',
			UPC: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IBranche;
	}

	public getRecordSet(search: IBrancheSearch): Observable<IBranches> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('BranchName', search.BranchName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BranchNumber', search.BranchNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BusinessNumber', search.BusinessNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('TollFreeNumber', search.TollFreeNumber, this.pageTitle));
		// uSearch.push(this.userSearchService.setSearch('AddressLine', search.AddressLine, this.pageTitle));
		// uSearch.push(this.userSearchService.setSearch('AddressLine2', search.AddressLine2, this.pageTitle));
		// uSearch.push(this.userSearchService.setSearch('PostalCode', search.PostalCode, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('City', search.City, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Province', search.Province, this.pageTitle));
		// uSearch.push(this.userSearchService.setSearch('Country', search.Country, this.pageTitle));
		// uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));

		
		uSearch.push(this.userSearchService.setSearch('fax', search.fax, this.pageTitle));
		//uSearch.push(this.userSearchService.setSearch('UPC', search.UPC, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('BranchName', search.BranchName ? search.BranchName : '')
			.set('BusinessNumber', search.BusinessNumber ? search.BusinessNumber : '')
			.set('TollFreeNumber', search.TollFreeNumber ? search.TollFreeNumber : '')
			.set('BranchNumber', search.BranchNumber ? search.BranchNumber : '')
			.set('fax', search.fax ? search.fax : '')
			// .set('AddressLine', search.AddressLine ? search.AddressLine : '')
			// .set('AddressLine2', search.AddressLine2 ? search.AddressLine2 : '')
			// .set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('City', search.City ? search.City : '')
			.set('Province', search.Province ? search.Province : '')
			// .set('Country', search.Country ? search.Country : '')
			// .set('Notes', search.Notes ? search.Notes : '')
		
			.set('UPC', search.UPC ? search.UPC : '')
		};

		return this.authHttp.get<IBranches>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IBranches>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IBrancheSearch { 
		let search: IBrancheSearch = {
			Id: 1,
			BranchName: '',
			BusinessNumber: '',
			TollFreeNumber: '',
			AddressLine: '',
			AddressLine2: '',
			PostalCode: '',
			City: '',
			Province: '',
			Country: '',
			Notes: '',
			BranchNumber: '',
			fax: '',
			UPC: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IBrancheSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('BranchName', search.BranchName ? search.BranchName : '')
			.set('BusinessNumber', search.BusinessNumber ? search.BusinessNumber : '')
			.set('TollFreeNumber', search.TollFreeNumber ? search.TollFreeNumber : '')
			.set('AddressLine', search.AddressLine ? search.AddressLine : '')
			.set('AddressLine2', search.AddressLine2 ? search.AddressLine2 : '')
			.set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('City', search.City ? search.City : '')
			.set('Province', search.Province ? search.Province : '')
			.set('Country', search.Country ? search.Country : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('BranchNumber', search.BranchNumber ? search.BranchNumber : '')
			.set('fax', search.fax ? search.fax : '')
			.set('UPC', search.UPC ? search.UPC : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getAllBranches(nameB: string):  Observable<IBranche[]> {
		return this.authHttp.get<IBranche[]>( `${this.baseUrl}?branchText=${nameB}`)
			.catch(this.handleError);
	}
	private handleError(error: Response): Observable <any> {
		return Observable.throw (error.json().ErrorDescription || 'Server error');
	}
	
	getAllBranchesForDropdown():  Observable<IBranche[]> {
		return this.authHttp.get<IBranche[]>( `${this.baseUrl}`)
			.catch(this.handleError);
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadimage`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
}