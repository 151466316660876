
<div class='panel panel-primary animaPlaceholder' *ngIf = 'partatt'>
	<div class='panel-heading'>
		<span *ngIf = 'partatt' >{{ page }} :{{ partatt.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='partattForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Eclipse Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='EclipseId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Primary Dimension</label>
						<div class='col-md-10'>
							<select class="form-control" formControlName='PrimaryDimension'>
								<option *ngFor='let i of primaryDimList;' [value]="i.Value">
									{{i.Value}}</option>
							</select>
							<span *ngIf = "partattForm.controls['PrimaryDimension'].invalid  && partattForm.controls['PrimaryDimension'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Primary Dimension Value</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='PrimaryDimensionValue' />
							<span *ngIf = "partattForm.controls['PrimaryDimensionValue'].invalid  && partattForm.controls['PrimaryDimensionValue'].errors.maxlength" class="text-danger">
								Only 250 characters are allowed.
						</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Secondary Dimension</label>
						<div class='col-md-10'>
							<select class="form-control" formControlName='SecondaryDimension'>
								<option *ngFor='let i of secondaryDimList;' [value]="i.Value">
									{{i.Value}}</option>
							</select>
							<span *ngIf = "partattForm.controls['SecondaryDimension'].invalid  && partattForm.controls['SecondaryDimension'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Secondary Dimension Value</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='SecondaryDimensionValue' />
							<span *ngIf = "partattForm.controls['SecondaryDimensionValue'].invalid  && partattForm.controls['SecondaryDimensionValue'].errors.maxlength" class="text-danger">
								Only 250 characters are allowed.
						</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Var3</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Var3' />
							<span *ngIf = "partattForm.controls['Var3'].invalid  && partattForm.controls['Var3'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Series</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Series' />
							<span *ngIf = "partattForm.controls['Series'].invalid  && partattForm.controls['Series'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Style</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Style' />
							<span *ngIf = "partattForm.controls['Style'].invalid  && partattForm.controls['Style'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Label Tag</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LabelTag' />
							<span *ngIf = "partattForm.controls['LabelTag'].invalid  && partattForm.controls['LabelTag'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Label Group</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LabelGroup' />
							<span *ngIf = "partattForm.controls['LabelGroup'].invalid  && partattForm.controls['LabelGroup'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Sort Order</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='SortOrder' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Description Count</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='DescriptionCount' />
						</div>
					</div>
					<div class='form-group' *ngIf="partatt.Id">
						<label class='col-md-2 control-label'> PartImage</label>
						<div class='col-md-10'>
							<input (change)="onFileSelected($event)" type="file" >
							<div *ngIf="partattForm.controls['PartImage'].value">
								<img [src]="urldata" style="width: 200px; height:150px; margin-top: 10px;" >
							</div>
							
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> File Path</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='FilePath' />
							<span *ngIf = "partattForm.controls['FilePath'].invalid  && partattForm.controls['FilePath'].errors.maxlength" class="text-danger">
									Only 300 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='savePartatt(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!partattForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml' 
									[disabled]='buttonPushed'
									(click)='savePartatt(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!partattForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='savePartatt(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!partattForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!partattForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deletePartatt()'
								style='width: 150px; margin-right:10px'
								[disabled]='!partattForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='partatt'>{{partatt.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='partatt'>{{partatt.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>