import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material-module';
import { AuditEditComponent } from './audit-edit/audit-edit.component';
import { AuditListComponent } from './audit-list/audit-list.component';
import { AuditRecordViewComponent } from './audit-record-view/audit-record-view.component';
import { AuditService } from './audit-service';

@NgModule({
	declarations: [
		AuditListComponent
		, AuditEditComponent
		, AuditRecordViewComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'audits', component: AuditListComponent },
			{ path: 'audit-details/:Id', component: AuditEditComponent },
			{ path: 'audit-record-view/:tableName', component: AuditRecordViewComponent }
		])
	],
	providers: [
		AuditService
	]
})
export class AuditModule { }
