import { Component, ElementRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ApplicationSettings } from '../shared/application-settings';
import { AppPropertiesService } from './app-properties.service';
import { DataService } from './data/data-service';
import { IHydroTest } from './hydro-test/hydro-test-model/hydro-test';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'hydro-test-legacy-app',
	templateUrl: './hydro-test-legacy.component.html',
	styleUrls: ['./hydro-test-legacy.component.css']
})

@Injectable()
// tslint:disable-next-line:component-class-suffix
export class HydroTestLegacyApp implements OnInit, OnDestroy  {
	public hydroTestDetail: IHydroTest;
	public tabVisible: boolean[];
	public tabClass: string[];
	public errorMessage: string;
	public hydroTestId = 'loading';
	public loadingResult = true;

	private loading: boolean;
	//private assembly: Assembly;

	private today: Date;
	private minDate: Date;
	private maxDate: Date;
	private sub: Subscription;

	constructor(
		elementRef: ElementRef,
		private appPropertiesService: AppPropertiesService,
		private route: ActivatedRoute,
		public dataService: DataService,
		private appSettings: ApplicationSettings) {

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getHose(id);
			}
		);

		const today = new Date();
		this.today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 13, 30);
		this.minDate = new Date(today.getFullYear(), 0, 2);
		this.maxDate = new Date(today.getFullYear(), 11, 31);

		this.loadingResult = false;

		this.tabClass = ['active', '', ''];
		this.tabVisible = [true, false, false];

		appPropertiesService.path = elementRef.nativeElement.getAttribute('path');
		appPropertiesService.currentUser = elementRef.nativeElement.getAttribute('username');
		appPropertiesService.currentUserBranch = elementRef.nativeElement.getAttribute('branch');
		appPropertiesService.defaultTestMachine = elementRef.nativeElement.getAttribute('defaultTestMachine');
		// appPropertiesService.getListOfTestLocations();
		appPropertiesService.getListOfUsers();
		appPropertiesService.getCustomerList();
		appPropertiesService.getHosesAndFittings();

		//this.assembly = new Assembly();
	}

	ngOnDestroy(): void {

	}
	ngOnInit(): void {

	}

	getHose(id: number): void {
		// console.log("getHose::HydroTestId:" + id.toString());
		this.hydroTestId = id.toString();
		this.dataService.evGotData.subscribe((data: string) => this.GotData(data));
		this.dataService.GetHydroTest(Number(this.hydroTestId));
	}



	GotData(val: string) {
		this.hydroTestDetail = this.dataService.HydroTest;
	}

	loadRecordComplete(): void {
		// console.log('loadRecordComplete-sales order: ' + this.dataService.HydroTest.salesOrder);
		this.loadingResult = false;
		this.hydroTestId = this.dataService.HydroTest.hydroTestId.toString();
	}

	selectTab(tab: number): void {
		if (this.hydroTestId === '0') {
			alert('You need to save the first page before you can access the other pages.');
		} else {
			this.tabClass = ['', '', ''];
			this.tabVisible = [false, false, false];
			this.tabClass[tab] = 'active';
			this.tabVisible[tab] = true;
		}
	}

	hydroTestIdUpdatedEvent(val: any): void {
		this.hydroTestId = val.toString();
		this.hydroTestDetail = this.dataService.HydroTest;
		// console.log('hydroTest update event successful:' + val.toString());
	}

	createReport(rpt: string) {
		console.log("create report clicked");
		let par = this.appSettings.getIReportData();
		par.ReportName ='ID_' + this.hydroTestId.toString() +  '_' + rpt + '.pdf';
		par.ReportType = rpt;
		par.Parameter1 = this.hydroTestId.toString();

		this.dataService.getReport(par)
			.subscribe(obj => this.openReport(obj),
			(error: any) => {
			this.errorMessage = <any>error;
		});

	 }
	 
	 openReport(fileName: string){
		 console.log('open report: ' + fileName);
		let url = this.appSettings.retrieveUrl() +  '/uploadedfiles/' + fileName;
		window.open(url, "_blank");
		this.loadingResult = false;
	}
	createNewReport(rpt: string) {
		if(!this.loadingResult){
		this.loadingResult = true;
		console.log("create new report clicked");
		let par = this.appSettings.getIReportData();
		par.ReportName ='ID_' + this.hydroTestId.toString() +  '_' + rpt + '.pdf';
		par.ReportType = rpt;
		par.Parameter1 = this.hydroTestId.toString();
		this.dataService.getNewReport(par)
			.subscribe(obj => this.openReport(obj),
			(error: any) => {
			this.errorMessage = <any>error;
			this.loadingResult = false;
		});
		}
	 }
}

