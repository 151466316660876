import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { VprodinventListComponent } from './vprodinvent-list/vprodinvent-list.component';
import { VprodinventEditComponent } from './vprodinvent-edit/vprodinvent-edit.component';
import { VprodinventService } from './vprodinvent-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		VprodinventListComponent
		, VprodinventEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'vprodinvents', component: VprodinventListComponent },
			{ path: 'vprodinvent-details/:Id', component: VprodinventEditComponent },
		])
	],
	providers: [
		VprodinventService
	]
})
export class VprodinventModule { }
