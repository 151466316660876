import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ApplicationSettings } from '../shared/application-settings';

@Injectable()
export class HydraulicfittingscategorieService {
	private baseUrl;
	public pageTitle = 'HydraulicFittingsCategories List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Hydraulicfittingscategories';
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadfile`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
}