import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { BranchlabelprinterListComponent } from './branchlabelprinter-list/branchlabelprinter-list.component';
import { BranchlabelprinterEditComponent } from './branchlabelprinter-edit/branchlabelprinter-edit.component';
import { BranchlabelprinterHelpComponent } from './branchlabelprinter-help/branchlabelprinter-help.component';
import { BranchlabelprinterService } from './branchlabelprinter-service';
import { BranchlabelprinterIPListComponent } from './branchlabelprinter-list-ip/branchlabelprinter-list-ip.component';

@NgModule({
	declarations: [
		BranchlabelprinterListComponent
		, BranchlabelprinterEditComponent
		, BranchlabelprinterHelpComponent
		,BranchlabelprinterIPListComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'branchlabelprinters', component: BranchlabelprinterListComponent },
			{ path: 'branchlabelprinter-details/:Id', component: BranchlabelprinterEditComponent },
			{ path: 'branchlabelprinter-help', component: BranchlabelprinterHelpComponent },
			{ path: 'printers-ip-list', component: BranchlabelprinterIPListComponent },
		])
	],
	providers: [
		BranchlabelprinterService
	]
})
export class BranchlabelprinterModule { }
