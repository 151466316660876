
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-4'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/walkin-details/0']" style='width:160px'>Add New Walk In</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: 80px' (click)='searchClick()'>Search</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: 100px' (click)='searchClearClick()'>Clear-Search</button>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters'>
			<div class='col-md-3'>
				<label class='inline-label'> Sales Order</label>
				<input type='text' class='form-control block-element' placeholder=' Sales Order' [(ngModel)]='SalesOrder' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Date String</label>
				<input type='text' class='form-control block-element' placeholder=' Date String' [(ngModel)]='DateString' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Customer Name</label>
				<input type='text' class='form-control block-element' placeholder=' Customer Name' [(ngModel)]='CustomerName' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Quantity</label>
				<input type='text' class='form-control block-element' placeholder=' Quantity' [(ngModel)]='Quantity' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Eclipse Id</label>
				<input type='text' class='form-control block-element' placeholder=' Eclipse Id' [(ngModel)]='EclipseId' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Part Name</label>
				<input type='text' class='form-control block-element' placeholder=' Part Name' [(ngModel)]='PartName' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Part String</label>
				<input type='text' class='form-control block-element' placeholder=' Part String' [(ngModel)]='PartString' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Hose Type</label>
				<input type='text' class='form-control block-element' placeholder=' Hose Type' [(ngModel)]='HoseType' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Fitting1</label>
				<input type='text' class='form-control block-element' placeholder=' Fitting1' [(ngModel)]='Fitting1' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Fitting2</label>
				<input type='text' class='form-control block-element' placeholder=' Fitting2' [(ngModel)]='Fitting2' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Length</label>
				<input type='text' class='form-control block-element' placeholder=' Length' [(ngModel)]='Length' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Orientation</label>
				<input type='text' class='form-control block-element' placeholder=' Orientation' [(ngModel)]='Orientation' />
			</div>
			<div class='col-md-3'>
				<label class='inline-label'> Special Instructions</label>
				<input type='text' class='form-control block-element' placeholder=' Special Instructions' [(ngModel)]='SpecialInstructions' />
			</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/walkin-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='SalesOrder'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Sales Order</th>
		<td mat-cell *matCellDef='let e'>{{e.SalesOrder}}</td>
	</ng-container>
	<ng-container matColumnDef='DateString'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Date String</th>
		<td mat-cell *matCellDef='let e'>{{e.DateString}}</td>
	</ng-container>
	<ng-container matColumnDef='CustomerName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Customer Name</th>
		<td mat-cell *matCellDef='let e'>{{e.CustomerName}}</td>
	</ng-container>
	<ng-container matColumnDef='Quantity'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Quantity</th>
		<td mat-cell *matCellDef='let e'>{{e.Quantity}}</td>
	</ng-container>
	<ng-container matColumnDef='EclipseId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Eclipse Id</th>
		<td mat-cell *matCellDef='let e'>{{e.EclipseId}}</td>
	</ng-container>
	<ng-container matColumnDef='PartName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Part Name</th>
		<td mat-cell *matCellDef='let e'>{{e.PartName}}</td>
	</ng-container>
	<ng-container matColumnDef='PartString'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Part String</th>
		<td mat-cell *matCellDef='let e'>{{e.PartString}}</td>
	</ng-container>
	<ng-container matColumnDef='HoseType'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Hose Type</th>
		<td mat-cell *matCellDef='let e'>{{e.HoseType}}</td>
	</ng-container>
	<ng-container matColumnDef='Fitting1'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Fitting1</th>
		<td mat-cell *matCellDef='let e'>{{e.Fitting1}}</td>
	</ng-container>
	<ng-container matColumnDef='Fitting2'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Fitting2</th>
		<td mat-cell *matCellDef='let e'>{{e.Fitting2}}</td>
	</ng-container>
	<ng-container matColumnDef='Length'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'> Length</th>
		<td mat-cell *matCellDef='let e'>{{e.Length}}</td>
	</ng-container>
	<ng-container matColumnDef='Orientation'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col13'> Orientation</th>
		<td mat-cell *matCellDef='let e'>{{e.Orientation}}</td>
	</ng-container>
	<ng-container matColumnDef='SpecialInstructions'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col14'> Special Instructions</th>
		<td mat-cell *matCellDef='let e'>{{e.SpecialInstructions}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
