import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { LabelsmenuService } from '../labelsmenu-service';

@Component({
  selector: 'app-customaverylabels',
  templateUrl: './customaverylabels.component.html',
  styleUrls: ['./customaverylabels.component.css']
})
export class CustomaverylabelsComponent implements OnInit {
  
  public errorMessage = '';
  public progressStatus=false;
  shwrmExcelLink:any;
  fileNmShowrmlbl:any;
  uploaddata:any;
  uploadstatus=false;

  constructor(
    public applicationSettings: ApplicationSettings
    , private labelsmenuService: LabelsmenuService
  ) { }

  ngOnInit(): void {
  }
	onFileSelected(event: any) {
		this.progressStatus=true;
		  const target = event.target as HTMLInputElement;
		  if (target.files && target.files.length > 0) {
			console.log(target.files[0].name);
		  }
		  const formData = new FormData();
		  formData.append('fileName', target.files[0]);
		  console.log(formData);
	
		  this.labelsmenuService.addFileShowroomLabel(formData).subscribe(
			obj => {
			  console.log("File Uploaded.");
			  console.log(obj);
			  if(obj.status==true)
			  {
				// alert(obj.message);
				this.uploaddata=obj.data;
				this.uploadstatus=true;
			  }
			  else{
				alert("File Not Uploaded!");
			  }
		
			 this.progressStatus=false;
			}
			,(error: any) => this.errorMessage = <any>error
		  );
	  }
	  public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.shwrmExcelLink}`);
	}
	resetError(): void {
		this.errorMessage = '';
		this.progressStatus = false;
		this.fileNmShowrmlbl='';
		this.uploaddata='';
		this.uploadstatus=false;
	}
	insertFileData(){
		this.progressStatus=true;
		this.labelsmenuService.insertFileShowroomLabel(this.uploaddata).subscribe(
			obj => {
			  if(obj.status==true)
			  {
				alert(obj.message);
				let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
				window.open(url + obj.reportfilename, "_blank");
				url=this.applicationSettings.retrieveUrl() + '/uploadedfiles/exports/';
                this.shwrmExcelLink=obj.excelfilename;
				this.fileNmShowrmlbl='';
				this.uploaddata='';
				this.uploadstatus=false;
			  }
			  else{
				this.fileNmShowrmlbl="";
			  }
		
			 this.progressStatus=false;
			}
			,(error: any) => this.errorMessage = <any>error
		  );
	}
	reupload(){
		this.uploaddata='';
		this.uploadstatus=false;
		this.fileNmShowrmlbl="";
	}
}
