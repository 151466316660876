import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { UsersearchModule } from './usersearches/usersearch-module';
//import { UserModule } from './users/user.module';
import { RoleModule } from './roles/role.module';
import { BrancheModule } from './branches/branche-module';
import { AuditModule } from './audits/audit-module';
import { UserModule } from './users/user.module';

@NgModule({
	declarations: [
	],
	imports: [
		AuditModule,
		BrancheModule,
        UsersearchModule,
        UserModule,
        RoleModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([		
		])
	],
	providers: [
	]
})
export class AdminModule { }
