import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HydraulicfittingscategorieService } from '../hydraulicfittingscategorie-service';

@Component({
  selector: 'app-hydraulicfittingscategorie-upload',
  templateUrl: './hydraulicfittingscategorie-upload.component.html',
  styleUrls: ['./hydraulicfittingscategorie-upload.component.css']
})
export class HydraulicfittingscategorieUploadComponent implements OnInit {

  UploadFileName:any;
  uploadfileForm: UntypedFormGroup;
  errorMessage:any;
  public progressStatus=false;
  public collist='';
  constructor(private fb: UntypedFormBuilder
   ,private hydraulicfittingscategorieService:HydraulicfittingscategorieService
  ) { }

  ngOnInit(): void {
    this.uploadfileForm = this.fb.group({
      fileName: ''
    });
  }
  onFileSelected(event: any) {
console.log(event);
this.collist='';
    if (this.uploadfileForm.valid) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        console.log(target.files[0].name);
      }
      const formData = new FormData();
      formData.append('fileName', target.files[0]);
      console.log(formData);
      this.progressStatus=true;
      this.hydraulicfittingscategorieService.addFile(formData).subscribe(
        obj => {
          if (obj.status == true) {
            console.log("File Uploaded.");
            alert("File Uploaded");
            this.uploadfileForm.patchValue({
              fileName: ''
            });
          }
          else {
            alert("File Not Uploaded");
            this.uploadfileForm.patchValue({
              fileName: ''
            });
            this.collist=obj.collist;
          }
          this.progressStatus=false;
        }
        , (error: any) => this.errorMessage = <any>error
      );

    }

  }
  resetError() : void {
    this.errorMessage = '';
    this.progressStatus=false;
    this.uploadfileForm.patchValue({
      fileName: ''
    });
  }
}
