<div class="container">
	<div class='row'>
		<div class='col-md-12'>
			<div class='has-error' class='text-danger' *ngIf='errorMessage'>
				<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
				{{ errorMessage }}
			</div>
		</div>
	</div>
<div >
	<h2>Hydraulic Fittings Categories Menu</h2>
	<br />	<br />
	<a [routerLink]="['/hydraulicfittingscategorie-upload']">Upload Hydraulic Fittings Categories File</a>
	<br />	<br />

</div>
</div>