<div class="panel panel-primary">
    <div class="panel-heading">
        Result For Label Property Execution
    </div>
    <div class="panel-body">
        <div class='row'>
            <div class='col-md-12'>
                <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                    <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <div>

            <div class="row">
<div class="col-md-12">
    <div class='table-container'>
        <table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
            <ng-container matColumnDef='Id'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
                <td mat-cell *matCellDef='let e'>
                    {{e.Id}}
                </td>
            </ng-container>
            <ng-container matColumnDef='PrimaryDimension'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Primary Dimension</th>
                <td mat-cell *matCellDef='let e' class='col2' >{{e.PrimaryDimension}}</td>
            </ng-container>
            <ng-container matColumnDef='SecondaryDimension'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Secondary Dimension</th>
                <td mat-cell *matCellDef='let e' class='col3' >{{e.SecondaryDimension}}</td>
            </ng-container>
            <ng-container matColumnDef='PrimaryDimensionValue'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Primary Dimension Value</th>
                <td mat-cell *matCellDef='let e' class='col4' >{{e.PrimaryDimensionValue}}</td>
            </ng-container>
            <ng-container matColumnDef='SecondaryDimensionValue'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Secondary Dimension Value</th>
                <td mat-cell *matCellDef='let e' class='col5' >{{e.SecondaryDimensionValue}}</td>
            </ng-container>
            <ng-container matColumnDef='PartNumber'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> PartNumber</th>
                <td mat-cell *matCellDef='let e' class='col6' >{{e.PartNumber}}</td>
            </ng-container>
            <ng-container matColumnDef='EclipseId'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Eclipse Id</th>
                <td mat-cell *matCellDef='let e' class='col7' >
                    {{e.EclipseId}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
            <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
        </table>
        </div>
</div>
            </div>
        </div>
    </div>
</div>