import { FerruleGridService } from './ferrule-list/ferrule-list.service';
import { FerruleService } from './ferrule.service';
import { FerruleEditComponent } from './ferrule-edit/ferrule-edit.component';
import { FerruleListComponent } from './ferrule-list/ferrule-list.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material-module';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [
		FerruleListComponent
		, FerruleEditComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		
		CommonModule,
		RouterModule.forChild([
			{ path: 'ferrules', component: FerruleListComponent },
			{ path: 'ferrule-details/:Id', component: FerruleEditComponent },
		])
	],
	providers: [
		FerruleGridService
		, FerruleService
	]
})
export class FerruleModule { }
