import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IVideo } from '../video-model/video';
import { Subscription } from 'rxjs/Subscription';
import { IVideoSearch } from '../video-model/videoSearch';
import { IVideos } from '../video-model/videos';
import { VideoService } from '../video-service';
import Player from '@vimeo/player';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Component({
	selector: 'app-video-edit',
	templateUrl: './video-edit.component.html',
	styleUrls: ['video-edit.component.css']
})

export class VideoEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Videos';
	errorMessage: string;
	videoForm: UntypedFormGroup;
	video: IVideo;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public VideoSearch: IVideoSearch;
	public ListData: IVideos;
	public showNextRecordButton: boolean = true;
	public progressStatus = false;
	player: Player;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private videoService: VideoService,
	) {
	}

	ngOnInit(): void {
		this.videoForm = this.fb.group({
			Id: '',
			Title: ['', [Validators.maxLength(250), Validators.required]],
			Category: ['', [Validators.maxLength(250), Validators.required]],
			FileName: ['', [Validators.maxLength(250), Validators.required]],
			Notes: ['', [Validators.maxLength(4000)]],
			VimeoId: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getVideo(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet(): void {
		this.videoService.getRecordSet(this.VideoSearch, 'Videos List')
			.subscribe((obj: IVideos) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IVideos) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.video.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveVideo(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.videoForm.valid) {
			const obj = Object.assign({}, this.video, this.videoForm.value);
			this.videoService.saveVideo(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.videoForm.reset();
			this.router.navigate(['/videos']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.video.Id === 0) {
			this.router.navigate(['/video-details', data.Id]);
		}
		else if (this.video.Id != 0) {
			this.getVideo(this.video.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.video.Id)
				record = count;
		});

		this.getVideo(this.ListData.data[record].Id)
	}

	getVideo(id: number): void {
		this.videoService.getVideo(id)
			.subscribe((obj: IVideo) => this.onVideoRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onVideoRetrieved(video: IVideo): void {
		if (this.videoForm) {
			this.videoForm.reset();
		}
		this.video = video;
		if (this.video.Id === 0) {
			this.page = 'Add Videos';
		} else {
			this.page = 'Edit Videos';
			if (video.VimeoId > 0) {
				this.setupPlayer(video.VimeoId);
			}
		}

		this.videoForm.patchValue({
			Id: this.video.Id,
			Title: this.video.Title,
			Category: this.video.Category,
			FileName: this.video.FileName,
			Notes: this.video.Notes,
			VimeoId: this.video.VimeoId,
			CreatedDate: this.video.CreatedDate,
			CreatedBy: this.video.CreatedBy,
			UpdatedDate: this.video.UpdatedDate,
			UpdatedBy: this.video.UpdatedBy,
		});

		this.getUserSearchData();

	}

	ngOnDestroy(): void {
	}

	deleteVideo(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.video.Title}?`)) {
			this.videoService.deleteVideo(this.video.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/videos']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Videos List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.VideoSearch = this.videoService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Title')
				this.VideoSearch.Title = i.Value;

			if (i.Item == 'Category')
				this.VideoSearch.Category = i.Value;

			if (i.Item == 'FileName')
				this.VideoSearch.FileName = i.Value;

			if (i.Item == 'Notes')
				this.VideoSearch.Notes = i.Value;

			if (i.Item == 'VimeoId')
				this.VideoSearch.VimeoId = i.Value;

			if (i.Item == 'orderByString')
				this.VideoSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.VideoSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}

	videoUploaded(vimeoId) {
		this.video.VimeoId = vimeoId;
		this.videoForm.patchValue({
			VimeoId: vimeoId
		});
		this.videoService.saveVideo(this.video)
			.subscribe(_ => {
				alert('The video has been uploaded');
				this.setupPlayer(vimeoId);
			});
	}
	setupPlayer(vimeoId) {
		const options = {
			id: vimeoId,
			width: 640,
			loop: true
		};

		setTimeout(() => {
			this.player = new Player('vimeo-viewer', options);
		}, 5000);
	}


}
