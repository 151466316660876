
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/hydro-test/0']" style='width: auto;'>Add New Hydro Test</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='showHideSearchClick()'>{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: auto;' (click)='toExcel()'>To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize'  style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Contact</label>
				<input type='text' class='form-control block-element' placeholder=' Contact' [(ngModel)]='Contact' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Customer</label>
				<input type='text' class='form-control block-element' placeholder=' Customer' [(ngModel)]='Customer' />
			</div>
			 <div class='col-md-4'>
				<label class='inline-label'> Purchase Order</label>
				<input type='text' class='form-control block-element' placeholder=' Purchase Order' [(ngModel)]='PurchaseOrder' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Test Location</label>
				<input type='text' class='form-control block-element' placeholder=' Test Location' [(ngModel)]='TestLocation' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Hose</label>
				<input type='text' class='form-control block-element' placeholder=' Hose' [(ngModel)]='Hose' />
			</div> 
			<div class='col-md-4'>
				<label class='inline-label'> End1</label>
				<input type='text' class='form-control block-element' placeholder=' End1' [(ngModel)]='End1' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> SalesOrderNumber</label>
				<input type='text' class='form-control block-element' placeholder=' SalesOrderNumber' [(ngModel)]='SalesOrderNumber' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm ' style='width: 110px' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm ' style='width: 110px' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>
<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/hydro-test', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='Contact'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Contact</th>
		<td mat-cell *matCellDef='let e'>{{e.Contact}}</td>
	</ng-container>
	<ng-container matColumnDef='Customer'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Customer</th>
		<td mat-cell *matCellDef='let e'>{{e.Customer}}</td>
	</ng-container>
	<ng-container matColumnDef='PurchaseOrder'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> PurchaseOrder</th>
		<td mat-cell *matCellDef='let e'>{{e.PurchaseOrder}}</td>
	</ng-container>
	<ng-container matColumnDef='TestLocation'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> TestLocation</th>
		<td mat-cell *matCellDef='let e'>{{e.TestLocation}}</td>
	</ng-container>
	<ng-container matColumnDef='Hose'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Hose</th>
		<td mat-cell *matCellDef='let e'>{{e.Hose}}</td>
	</ng-container>
	<ng-container matColumnDef='End1'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> End1</th>
		<td mat-cell *matCellDef='let e'>{{e.End1}}</td>
	</ng-container>
	<ng-container matColumnDef='SalesOrderNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'>SalesOrderNumber</th>
		<td mat-cell *matCellDef='let e'>{{e.SalesOrderNumber}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
