import { Observable } from 'rxjs';
import { ApplicationSettings } from './../application-settings';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';



@Injectable()
export class DdlService {
	private baseUrl;

	jsonDdlvalue;

	constructor(private authHttp: HttpClient, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Lookup/GetLookupvaluesByType';
	}

	private ddlPost(lookuptype): Observable<any> {
		const url = `${this.baseUrl}` + '?lookupType=' + `${lookuptype}`;
		return this.authHttp.get(url)
			.catch(this.handleError);
	}

	ddl(lookuptype: string) {
		return this.ddlPost(lookuptype);
	}


	private handleError(error: Response): Observable<any> {
		return Observable.throw(error.json().ErrorDescription || 'Server error');
	}

	// loadData(data) {
	//   this.ddlItems = data;
	//   this.ddlItems.DDLItems = JSON.parse(this.ddlItems.DDLItems);
	// }

	// getDDLByType(name) {
	//   if (typeof(this.ddlItems.DDLItems)=='undefined') return [];
	//   var filtered = this.ddlItems.DDLItems.filter(
	//     obj => obj.DDLName === name);
	//     if (filtered.length == 1) {
	//       return filtered[0].Items;
	//     }
	//     return [];
	// }

}
