import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { PartService } from '../part-service';
import { IPartSearch } from '../part-model/partsearch';
import { IParts } from '../part-model/parts';

@Component({
	templateUrl: './part-list.component.html',
	styleUrls: ['./part-list.component.css']
})

export class PartListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'PartNumber', 'PartDescription', 'EclipseId', 'PartRootsId', 'BagQty', 'BoxQty', 'Upc', 'BoxSize'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public PartNumber = '';
	public PartDescription = '';
	public EclipseId = '';
	public PartRootsId = '';
	public BagQty = '';
	public BoxQty = '';
	public Upc = '';
	public BoxSize = '';

	public pageTitle = 'Parts List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: PartService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='PartNumber')
				this.PartNumber = i.Value;

			if(i.Item=='PartDescription')
				this.PartDescription = i.Value;

			if(i.Item=='EclipseId')
				this.EclipseId = i.Value;

			if(i.Item=='PartRootsId')
				this.PartRootsId = i.Value;

			if(i.Item=='BagQty')
				this.BagQty = i.Value;

			if(i.Item=='BoxQty')
				this.BoxQty = i.Value;

			if(i.Item=='Upc')
				this.Upc = i.Value;

			if(i.Item=='BoxSize')
				this.BoxSize = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IPartSearch = this._appService.getFreshSearch();

		search.PartNumber = this.PartNumber;
		search.PartDescription = this.PartDescription;
		search.EclipseId = this.EclipseId;
		search.PartRootsId = this.PartRootsId;
		search.BagQty = this.BagQty;
		search.BoxQty = this.BoxQty;
		search.Upc = this.Upc;
		search.BoxSize = this.BoxSize;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IParts) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IParts){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.PartNumber = '';
		this.PartDescription = '';
		this.EclipseId = '';
		this.PartRootsId = '';
		this.BagQty = '';
		this.BoxQty = '';
		this.Upc = '';
		this.BoxSize = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public toExcel(){
			let search: IPartSearch = this._appService.getFreshSearch();

			search.PartNumber = this.PartNumber;
			search.PartDescription = this.PartDescription;
			search.EclipseId = this.EclipseId;
			search.PartRootsId = this.PartRootsId;
			search.BagQty = this.BagQty;
			search.BoxQty = this.BoxQty;
			search.Upc = this.Upc;
			search.BoxSize = this.BoxSize;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/parts`])
		);
		window.open(url, '_blank');
	}
}