import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { WalkinService } from '../walkin-service';
import { IWalkinSearch } from '../walkin-model/walkinsearch';
import { IWalkins } from '../walkin-model/walkins';

@Component({
	templateUrl: './walkin-dropfile.component.html',
	styleUrls: ['./walkin-dropfile.component.css']
})

export class WalkinDropFileComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'SalesOrder', 'DateString', 'CustomerName', 'Quantity', 'EclipseId', 'PartName', 'PartString', 'HoseType', 'Fitting1', 'Fitting2', 'Length', 'Orientation', 'SpecialInstructions','FileName'];
	isLoadingResults = true;
    
	public isShowList=false;
	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public SalesOrder = '';
	public DateString = '';
	public CustomerName = '';
	public Quantity = '';
	public EclipseId = '';
	public PartName = '';
	public PartString = '';
	public HoseType = '';
	public Fitting1 = '';
	public Fitting2 = '';
	public Length = '';
	public Orientation = '';
	public SpecialInstructions = '';
	public FileName = '';
	public pageTitle = 'Walk In List';
	public errorMessage = '';
	public dataSource: any;
    public pdfFileName="";

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private _appService: WalkinService) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
	}

	onFileSelected(event: any) {
		const target = event.target as HTMLInputElement;
    	if (target.files && target.files.length > 0) {
        	console.log(target.files[0].name);
    	}
		const formData = new FormData();
		formData.append('FileName', target.files[0]);
        console.log(formData);

		this._appService.addFile(formData).subscribe(
			retVal => {
				console.log("File Uploaded.");
				console.log(retVal.filename1);
				this.pdfFileName=retVal.filename2;
				this.FileName=retVal.filename1;
				this.isShowList=true;
				this.userSearchService.getUserSearchesByForm(this.pageTitle)
				.subscribe((data) => {
					this.UserSearches = data;
					this.setUserFilters();
			}
			, (error: any) => this.errorMessage = < any > error);			
			}
		);


	}

	ngAfterViewInit(): void {
		
	}


	setUserFilters(): void {
		if(this.isShowList){

		
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='SalesOrder')
				this.SalesOrder = i.Value;

			if(i.Item=='DateString')
				this.DateString = i.Value;

			if(i.Item=='CustomerName')
				this.CustomerName = i.Value;

			if(i.Item=='Quantity')
				this.Quantity = i.Value;

			if(i.Item=='EclipseId')
				this.EclipseId = i.Value;

			if(i.Item=='PartName')
				this.PartName = i.Value;

			if(i.Item=='PartString')
				this.PartString = i.Value;

			if(i.Item=='HoseType')
				this.HoseType = i.Value;

			if(i.Item=='Fitting1')
				this.Fitting1 = i.Value;

			if(i.Item=='Fitting2')
				this.Fitting2 = i.Value;

			if(i.Item=='Length')
				this.Length = i.Value;

			if(i.Item=='Orientation')
				this.Orientation = i.Value;

			if(i.Item=='SpecialInstructions')
				this.SpecialInstructions = i.Value;

			// if(i.Item=='FileName')
			// 	this.FileName = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IWalkinSearch = this._appService.getFreshSearch();

		search.SalesOrder = this.SalesOrder;
		search.DateString = this.DateString;
		search.CustomerName = this.CustomerName;
		search.Quantity = this.Quantity;
		search.EclipseId = this.EclipseId;
		search.PartName = this.PartName;
		search.PartString = this.PartString;
		search.HoseType = this.HoseType;
		search.Fitting1 = this.Fitting1;
		search.Fitting2 = this.Fitting2;
		search.Length = this.Length;
		search.Orientation = this.Orientation;
		search.SpecialInstructions = this.SpecialInstructions;
		search.FileName = this.FileName;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IWalkins) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWalkins){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
	}

	resetError() : void {
		this.errorMessage = '';
	}
	print(){
		window.open(`${this.applicationSettings.retrieveUrl()}${this.pdfFileName}`);
	}
}