import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IDevelopmenttask} from './Developmenttask-model/Developmenttask';
import { IDevelopmenttasks } from './Developmenttask-model/Developmenttasks';
import { IDevelopmenttaskSearch } from './Developmenttask-model/DevelopmenttaskSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class DevelopmenttaskService {
	private baseUrl;
	public pageTitle = 'Development Tasks List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Developmenttasks';
	}

	getDevelopmenttask(id: number):  Observable<IDevelopmenttask> {
		if (id === 0) {
			return of(this.initializeDevelopmenttask());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IDevelopmenttask>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveDevelopmenttask(developmenttask: IDevelopmenttask): Observable<any> {
		if (developmenttask.Id === 0) {
			return this.createDevelopmenttask(developmenttask);
		}
		return this.updateDevelopmenttask(developmenttask);
	}

	private createDevelopmenttask(developmenttask: IDevelopmenttask): Observable<any> {
		developmenttask.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/developmenttaskcreate', developmenttask)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateDevelopmenttask(developmenttask: IDevelopmenttask): Observable<IDevelopmenttask> {
		return this.authHttp.put<IDevelopmenttask>(this.baseUrl, developmenttask)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteDevelopmenttask(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeDevelopmenttask(): IDevelopmenttask {
		return {
			Id: 0,
			Name: '',
			BranchId: 0,
			Description: '',
			EstimatedHours: 0,
			Priority: 0,
			Application: '',
			Type1: '',
			Type2: '',
			Type3: '',
			Notes: '',
			InstalledDate: '',
			Owner: '',
			AssignedUser: '',
			nextaction: '',
			DevNotes: '',
			Status: '',
			ScheduledDate:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IDevelopmenttask;
	}

	public getRecordSet(search: IDevelopmenttaskSearch): Observable<IDevelopmenttasks> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BranchId', search.BranchId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EstimatedHours', search.EstimatedHours, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Priority', search.Priority, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Application', search.Application, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Type1', search.Type1, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Type2', search.Type2, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Type3', search.Type3, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('InstalledDate', search.InstalledDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Owner', search.Owner, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('AssignedUser', search.AssignedUser, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('nextaction', search.nextaction, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('DevNotes', search.DevNotes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Status', search.Status, this.pageTitle));		
		uSearch.push(this.userSearchService.setSearch('ScheduledDate', search.ScheduledDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('HideCompleted', search.HideCompleted ? search.HideCompleted.toString() : 'false', this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('Description', search.Description ? search.Description : '')
			.set('EstimatedHours', search.EstimatedHours ? search.EstimatedHours : '')
			.set('Priority', search.Priority ? search.Priority : '')
			.set('Application', search.Application ? search.Application : '')
			.set('Type1', search.Type1 ? search.Type1 : '')
			.set('Type2', search.Type2 ? search.Type2 : '')
			.set('Type3', search.Type3 ? search.Type3 : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('InstalledDate', search.InstalledDate ? search.InstalledDate : '')
			.set('Owner', search.Owner ? search.Owner : '')
			.set('AssignedUser', search.AssignedUser ? search.AssignedUser : '')
			.set('nextaction', search.nextaction ? search.nextaction : '')
			.set('DevNotes', search.DevNotes ? search.DevNotes : '')
			.set('Status', search.Status ? search.Status : '')
			.set('ScheduledDate', search.ScheduledDate ? search.ScheduledDate : '')
			.set('HideCompleted', search.HideCompleted ? search.HideCompleted.toString() : 'false')
		};

		return this.authHttp.get<IDevelopmenttasks>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IDevelopmenttasks>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IDevelopmenttaskSearch { 
		let search: IDevelopmenttaskSearch = {
			Id: 1,
			Name: '',
			BranchId: '',
			Description: '',
			EstimatedHours: '',
			Priority: '',
			Application: '',
			Type1: '',
			Type2: '',
			Type3: '',
			Notes: '',
			InstalledDate: '',
			Owner: '',
			AssignedUser: '',
			nextaction: '',
			DevNotes: '',
			Status: '',
			ScheduledDate:'',
			HideCompleted:false,
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	
	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	updateFileDevelopmenttask(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<IDevelopmenttasks>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFileDevelopmenttask(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesDevelopmenttask(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<IDevelopmenttasks[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFileDevelopmenttask(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	public toExcel(search: IDevelopmenttaskSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('Description', search.Description ? search.Description : '')
			.set('EstimatedHours', search.EstimatedHours ? search.EstimatedHours : '')
			.set('Priority', search.Priority ? search.Priority : '')
			.set('Application', search.Application ? search.Application : '')
			.set('Type1', search.Type1 ? search.Type1 : '')
			.set('Type2', search.Type2 ? search.Type2 : '')
			.set('Type3', search.Type3 ? search.Type3 : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('InstalledDate', search.InstalledDate ? search.InstalledDate : '')
			.set('Owner', search.Owner ? search.Owner : '')
			.set('AssignedUser', search.AssignedUser ? search.AssignedUser : '')
			.set('nextaction', search.nextaction ? search.nextaction : '')
			.set('DevNotes', search.DevNotes ? search.DevNotes : '')
			.set('Status', search.Status ? search.Status : '')
			.set('ScheduledDate', search.ScheduledDate ? search.ScheduledDate : '')
			.set('HideCompleted', search.HideCompleted ? search.HideCompleted.toString() : 'false')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	public getReport(fileId: number): Observable<any> {
		console.log('fileid:' + fileId);
		const url = `${this.baseUrl}/getReport?id=${fileId}`;
		return this.authHttp
			.get(url)
	}
	sendEmailToEmp(developmenttask,EmpEmailRpt): Observable<any> {
		const url=`${this.baseUrl}/sendEmpEmail?EmpEmailRpt=${EmpEmailRpt}`;
		 return this.authHttp.post<any>(url, developmenttask)
			 .pipe(retry(1), catchError(this.processError));
	 }
	 
	 sendEmailToOwner(developmenttask,OwnerEmailRpt): Observable<any> {
		const url=`${this.baseUrl}/sendOwnerEmail?OwnerEmailRpt=${OwnerEmailRpt}`;
		 return this.authHttp.post<any>(url, developmenttask)
			 .pipe(retry(1), catchError(this.processError));
	 }
	 download(url: string): Observable<Blob> {
		return this.authHttp.get(url, {
		  responseType: 'blob'
		})
	  }
	createDevTaskHours(obj): Observable<any> {
		
		return this.authHttp.post<number>(this.baseUrl + '/devtaskhourscreate', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	public getAllDevTaskHours(id: number): Observable<any> {
		const url = `${this.baseUrl}/getalldevtaskhours?id=${id}`;
		return this.authHttp
			.get(url)
	}
	deleteDevTaskHours(id: number){
		const url=`${this.baseUrl}/devtaskhoursdelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	createDevTaskCosts(obj): Observable<any> {
		
		return this.authHttp.post<number>(this.baseUrl + '/devtaskcostscreate', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	public getAllDevTaskCosts(id: number): Observable<any> {
		const url = `${this.baseUrl}/getalldevtaskcosts?id=${id}`;
		return this.authHttp
			.get(url)
	}
	deleteDevTaskCosts(id: number){
		const url=`${this.baseUrl}/devtaskcostsdelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}