import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { MillcertListComponent } from './millcert-list/millcert-list.component';
import { MillcertEditComponent } from './millcert-edit/millcert-edit.component';
import { MillcertHelpComponent } from './millcert-help/millcert-help.component';
import { MillcertService } from './millcert-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		MillcertListComponent
		, MillcertEditComponent
		, MillcertHelpComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'millcerts', component: MillcertListComponent },
			{ path: 'millcert-details/:Id', component: MillcertEditComponent },
			{ path: 'millcert-help', component: MillcertHelpComponent },
		])
	],
	providers: [
		MillcertService
	]
})
export class MillcertModule { }
