import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { ProductdataService } from './productdata-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { ProductdataUploadComponent } from './productdata-upload/productdata-upload.component';
import { ProductsdatamenuComponent } from './productdata-menu/productdata-menu.component';

@NgModule({
	declarations: [
		ProductdataUploadComponent
		, ProductsdatamenuComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'productdata-upload', component: ProductdataUploadComponent },
			{ path: 'productdata-menu', component: ProductsdatamenuComponent },
		])
	],
	providers: [
		ProductdataService
	]
})
export class ProductdataModule { }
