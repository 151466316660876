import { IFerrule } from './ferrule.model/ferrule';
import { ApplicationSettings } from './../../shared/application-settings';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable,of,throwError } from 'rxjs';
import { IFerruleSearch } from './ferrule.model/ferruleSearch';
import { IFerrules } from './ferrule.model/ferrules';
import { IUsersearch } from 'src/app/admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from 'src/app/admin/usersearches/usersearch-service';
import { retry, catchError, map } from 'rxjs/operators';

@Injectable()
export class FerruleService {
	private baseUrl;
	pageTitle = 'Ferrule List';
	constructor(private authHttp: HttpClient, private settings: ApplicationSettings,public userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Ferrule';
	}

	getFerrulebyId(id: number):  Observable<IFerrule> {
		if (id === 0) {
			return of(this.initializeFerrule());
		}

		const url = `${this.baseUrl}`+'/GetFerruleById?id='+`${id}`;
		return this.authHttp.get<IFerrule>(url)
			.catch(this.handleError);
	}
	

	saveFerrule(ferrule: IFerrule): Observable < any > {
		if (ferrule.Id === 0) {
			return this.createFerrule(ferrule);
		}
		return this.updateFerrule(ferrule);
	}

	private createFerrule(ferrule: IFerrule): Observable <any> {
		const url = `${this.baseUrl}`+'/Createferrules';
		return this.authHttp.post(url, ferrule)
			.catch (this.handleError);
	}

	private updateFerrule(ferrule: IFerrule): Observable <IFerrule> {
		return this.authHttp.put(this.baseUrl +'/Updateferrules', ferrule)
			.catch (this.handleError);
	}

	deleteFerrule(id: number): Observable < Response > {
		const url = `${this.baseUrl}`+'/Deleteferrules/'+`${id}`;
		return this.authHttp.delete(url)
			.catch (this.handleError);
	}

	private handleError(error: Response): Observable <any> {
		return Observable.throw (error.json().ErrorDescription || 'Server error');
	}

	public getFreshSearch() : IFerruleSearch { 
		let search: IFerruleSearch = {
			Ferrule: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	public getRecordSet(search: IFerruleSearch): Observable<IFerrules> {

		const url = this.settings.retrieveUrl() + '/api/Ferrule/GetFerrule';

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('hoseName', search.Ferrule, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('take', search.take.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('ferrule', search.Ferrule.toString())
			.set('sort', search.orderByString)
		};

		return this.authHttp.get<IFerrules>(url, options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IFerrules>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}


	initializeFerrule(): IFerrule {
		return {
			Id: 0,
			Ferrule: '',
			InsideDiameter: null,
			WallThickness: null,

			CrimpInstructions: null,
			Notes: '',
			Pressure: null,
			PressureTest: null,

			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IFerrule;
	}
}
