﻿<h1>Visual Inspection</h1>
<table>
    <tr>
        <td valign="top">
            <table>
                <tr>
                    <td>
                        Visual Inspection Cover&nbsp;&nbsp;
                    </td>
                    <td>
                        <ul style="list-style-type: none">
                            <li *ngFor="let vicOption of visualInspectionCoverOptions" style="float: left; padding:10px">
                                <input type="radio" name="viCover" (click)="getViCover(vicOption.id)"
                                       [checked]="vicOption.id == visualInspectionCover.id"
                                       value={{vicOption.id}}>&nbsp;{{vicOption.name}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        Visual Inspection End 1&nbsp;&nbsp;
                    </td>
                    <td>
                        <ul style="list-style-type: none">
                            <li *ngFor="let v of visualInspectionEnd1Options" style="float: left; padding:10px">
                                <input type="radio" name="viEnd1" (click)="getViEnd1(v.id)"
                                       [checked]="v.id == visualInspectionEnd1.id"
                                       value={{v.id}}>&nbsp;{{v.name}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        Visual Inspection End 2&nbsp;&nbsp;
                    </td>
                    <td>
                        <ul style="list-style-type: none">
                            <li *ngFor="let v of visualInspectionEnd2Options" style="float: left; padding:10px">
                                <input type="radio" name="viEnd2" (click)="getViEnd2(v.id)"
                                       [checked]="v.id == visualInspectionEnd2.id"
                                       value={{v.id}}>&nbsp;{{v.name}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        Visual Inspection Tube&nbsp;&nbsp;
                    </td>
                    <td>
                        <ul style="list-style-type: none">
                            <li *ngFor="let v of visualInspectionTubeOptions" style="float: left; padding:10px">
                                <input type="radio" name="viTube" (click)="getViTube(v.id)"
                                       [checked]="v.id == visualInspectionTube.id"
                                       value={{v.id}}>&nbsp;{{v.name}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        VI Result&nbsp;&nbsp;
                    </td>
                    <td>
                        <ul style="list-style-type: none">
                            <li *ngFor="let v of visualInspectionResultOptions" style="float: left; padding:10px">
                                <input type="radio" name="viResult" (click)="getViResult(v.id)"
                                       [checked]="v.id == visualInspectionResult.id"
                                       value={{v.id}}>&nbsp;{{v.name}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        OVERALL TEST RESULTS&nbsp;&nbsp;
                    </td>
                    <td>
                        <ul style="list-style-type: none">
                            <li *ngFor="let v of visualInspectionOverallOptions" style="float: left; padding:10px">
                                <input type="radio" name="viOverall" (click)="getViOverall(v.id)"
                                       [checked]="v.id == visualInspectionOverall.id"
                                       value={{v.id}}>&nbsp;{{v.name}}
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </td>
        <td valign="top">
            Visual inspection Notes
            <br />
            <textarea [(ngModel)]="hoseDetail.VINotes" rows="6" cols="50"></textarea>
        </td>
    </tr>
</table>


