import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControlName, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../user-service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserResetPassword } from '../user-model/user-reset-password';

@Component({
	selector: 'app-user-reset-password',
	templateUrl: './user-reset-password.component.html',
	styleUrls: ['./user-reset-password.component.css']
})
export class UserResetPasswordComponent implements OnInit {

	resetPasswordForm: UntypedFormGroup;
	pageTitle = 'Reset Password';
	errorMessage = '';
	private sub: Subscription;
	key: string;
	emailId: string;
	userInfo:any;
	resetData:UserResetPassword;
	resetdisplay=false;
	constructor(
		private fb: UntypedFormBuilder,
		private userService: UserService,
		private router: Router,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.resetPasswordForm = this.fb.group({
			NewPassword: ['', [Validators.required]],
			ConfirmPassword: ['', [Validators.required]]
		});
		this.sub = this.route.params.subscribe(
			params => {
				const id = params['Id'].toString();
				this.key = id;
				this.getLinkUser(this.key);

			}
		);
	}

	resetPassword(): void {
		if (this.resetPasswordForm.dirty && this.resetPasswordForm.valid) {

			const resetPassword = Object.assign(
				{},
				this.resetData,
				this.resetPasswordForm.value
			);
			this.userService
				.resetUserPassword(resetPassword,this.userInfo.data.Id)
				.subscribe(
					(data) => this.onSaveComplete(),
					(error: any) => (this.errorMessage = <any>error)
				);
		} else if (!this.resetPasswordForm.dirty) {
			this.onSaveComplete();
		}
	}

	onSaveComplete(): void {
		alert("Password reset done successfully!!");
		this.resetPasswordForm.reset();
		this.router.navigate(['/login']);
	}
	getLinkUser(id: string): void {
		this.userService.getLinkUserData(id)
			.subscribe((obj) => {
				if(obj.msg!="Valid Link"){
					alert(obj.msg);
					this.router.navigate(['/login']);
				}
				else{
					this.resetdisplay=true;
					this.emailId=obj.data.Email;
					this.userInfo=obj;
				}
                    
			},
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}
}
