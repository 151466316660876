import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { UserService } from '../../admin/users/user-service/user.service';
import { BrancheService } from '../../admin/branches/branche-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { ColumnManagement } from '../customerconcern-model/columnmanagement';
import { ICustomerconcerns } from '../customerconcern-model/customerconcerns';
import { ICustomerconcernSearch } from '../customerconcern-model/customerconcernsearch';
import { CustomerconcernService } from '../customerconcern-service';
import { ICustomerConcernsFailureMode } from '../customerconcern-model/customerConcernsFailureMode';

@Component({
	templateUrl: './customerconcern-list.component.html',
	styleUrls: ['./customerconcern-list.component.css']
})

export class CustomerconcernListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'NCRDate', 'NCRCode', 'StatusID', 'Customer', 'Problem', 'OriginalSalesOrderNumber', 'EmployeeAssigned', 'CustomerComplaintNumber', 'ResponsibleFor', 'BranchResponsible', 'Division', 'Owner'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public UserColumns: IUsersearch[];

	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public NCRFromDate = '';
	public NCRToDate = '';
	public Customer = '';
	public OriginalSalesOrderNumber = '';
	public Problem = '';
	public NCRCode = '';
	public BranchResponsible = '';
	public StatusID;
	public EmployeeAssigned = '';
	public ResponsibleFor = '';
	public CustomerContact = '';
	public CustomerComplaintNumber = '';
	public Division = '';
	public Owner = '';
	public NCRNumber = '';
	public Level1FailureMode = '';
	public Level2FailureMode = ''; 
	public allCustomerConcernsStatus = '';

	public pageTitle = 'NCR List';
	public errorMessage = '';
	public dataSource: any;
	public branches;
	public employees;
	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';
	public showHideColumns: boolean = false;
	public ShowColumnsText: string = 'Show Columns';
	public columnManagementCheckboxes: ColumnManagement = new ColumnManagement;
	public excelFile;

	public allFailureModes: ICustomerConcernsFailureMode[] = [];
	public level1FailureModes: ICustomerConcernsFailureMode[] = [];
	public level2FailureModes: ICustomerConcernsFailureMode[] = [];
	
	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private customerConcernService: CustomerconcernService
		, public branchService: BrancheService
		, private router: Router
		, public userService: UserService) {
	}

	ngOnInit(): void {

		this.skip = 0;

		this.pageSize = this.applicationSettings.retrievePageSize();
		this.branchService.getAllBranchesForDropdown().subscribe(response => {
			this.branches = response;
		});

		this.userService.getAllUsersCustomerConcerns('').subscribe(response => {
			this.employees = response;
		});

		this.customerConcernService.getFailureModes().subscribe(response => {
			this.allFailureModes = response;
			this.level1FailureModes = this.allFailureModes.filter(failureMode => failureMode.Level1 > 0 
				                                               && failureMode.Level2 == 0 
															   && failureMode.Level3 == 0)
															.sort((a, b) => a.ID - b.ID);
			this.level2FailureModes = [];
		});

		this.customerConcernService.getCustomerConcernsStatus().subscribe(response => {
			this.allCustomerConcernsStatus = response;
		});

		if (localStorage.getItem('showHideSearch') === 'true') {
			this.showHideSearch = true;
			this.SearchText = 'Hide Filters';
		}
		else {
			this.showHideSearch = false;
			this.SearchText = 'Show Filters';
		}
		if (localStorage.getItem('showHideColumns') === 'true') {
			this.showHideColumns = true;
			this.ShowColumnsText = 'Hide Data Columns';
		}
	}

	onLevel1FailureModeSelect(newValue: any) {
		this.level2FailureModes = this.allFailureModes.filter(failureMode => failureMode.Level1 == newValue 
			                                                              && failureMode.Level2 > 0 
																		  && failureMode.Level3 == 0);
	}


	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.sort.direction = 'desc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if (i.Item == 'NCRFromDate')
				this.NCRFromDate = i.Value;

			if (i.Item == 'NCRToDate')
				this.NCRToDate = i.Value;

			if (i.Item == 'NCRNumber')
				this.NCRNumber = i.Value;

			if (i.Item == 'Customer')
				this.Customer = i.Value;

			if (i.Item == 'OriginalSalesOrderNumber')
				this.OriginalSalesOrderNumber = i.Value;

			if (i.Item == 'Problem')
				this.Problem = i.Value;

			if (i.Item == 'BranchResponsible')
				this.BranchResponsible = i.Value;

			/*if (i.Item == 'StatusID')
				this.StatusID = i.Value;*/

			if (i.Item == 'EmployeeAssigned')
				this.EmployeeAssigned = i.Value;

			if (i.Item == 'CustomerComplaintNumber')
				this.CustomerComplaintNumber = i.Value;

			if (i.Item == 'ResponsibleFor')
				this.ResponsibleFor = i.Value;

			if (i.Item == 'Division')
				this.Division = i.Value;

			if (i.Item == 'Owner')
				this.Owner = i.Value;

			if (i.Item == 'Level1FailureMode')
				this.Level1FailureMode = i.Value;

			if (i.Item == 'Level2FailureMode')
				this.Level2FailureMode = i.Value;

			if (i.Item == 'orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value) === 'asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(() => this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {

		this.dataSource = null;
		this.recordTotal = 0;
		let search: ICustomerconcernSearch = this.customerConcernService.getFreshSearch();

		search.NCRFromDate = this.NCRFromDate;
		search.NCRToDate = this.NCRToDate;
		search.NCRNumber = this.NCRNumber;
		search.Customer = this.Customer;
		search.OriginalSalesOrderNumber = this.OriginalSalesOrderNumber;
		search.Problem = this.Problem;
		search.BranchResponsible = this.BranchResponsible;
		search.StatusID = this.StatusID;
		search.EmployeeAssigned = this.EmployeeAssigned;
		search.CustomerComplaintNumber = this.CustomerComplaintNumber;
		search.ResponsibleFor = this.ResponsibleFor;
		search.Division = this.Division;
		search.Owner=this.Owner;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';
		search.FailureModeLevel1=this.Level1FailureMode;
		search.FailureModeLevel2=this.Level2FailureMode;

		this.customerConcernService.getRecordSet(search).subscribe((obj: ICustomerconcerns) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}

	private onSelectedRecordSetReceived(obj: ICustomerconcerns) {
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
	}

	searchClick(): void {

		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.NCRFromDate = '';
		this.NCRToDate = '';
		this.NCRNumber = '';
		this.Customer = '';
		this.OriginalSalesOrderNumber = '';
		this.Problem = '';
		this.NCRCode = '';
		this.BranchResponsible = '';
		this.StatusID = '';
		this.EmployeeAssigned = '';
		this.CustomerContact = '';
		this.CustomerComplaintNumber = '';
		this.ResponsibleFor = '';
		this.Division = '';
		this.Owner='';
		this.Level1FailureMode = '';
		this.Level2FailureMode = '';
		this.skip = 0;
		this.sort.direction = 'desc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError(): void {
		this.errorMessage = '';
	}

	onFromDate(event): void {
		this.NCRFromDate = event;
	}

	onToDate(event): void {
		this.NCRToDate = event;
	}
	report(ncrID: number, reportType: number) {
		let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';

		this.customerConcernService.getReport(ncrID, reportType).subscribe(
			(val: string) => {
				window.open(url + val, "_blank");
			},
			(error: any) => {
				this.errorMessage = <any>error;
			}
		);
	}
	showHideSearchClick() {
		if (this.showHideSearch) {
			this.showHideSearch = false;
			localStorage.setItem('showHideSearch', 'false');
			this.SearchText = 'Show Filters';
		}
		else {
			this.showHideSearch = true;
			localStorage.setItem('showHideSearch', 'true');
			this.SearchText = 'Hide Filters';
		}
	}
	showHideColumnsClick() {
		if (this.showHideColumns) {
			this.showHideColumns = false;
			localStorage.setItem('showHideColumns', 'false');
			this.ShowColumnsText = 'Show Data Columns';
		}
		else {
			this.showHideColumns = true;
			localStorage.setItem('showHideColumns', 'true');
			this.ShowColumnsText = 'Hide Data Columns';
		}
	}
	updateColumnsClick(val: string) {

		this.customerConcernService.saveColumns(this.columnManagementCheckboxes);
		this.updateColumns();

		if (val == 'true')
			this.showHideColumnsClick()
	}

	updateColumns() {
		this.displayedColumns = this.columnManagementCheckboxes.GetColumns();


	}

	setUserColumns() {

		this.UserColumns.forEach(i => {
			this.columnManagementCheckboxes.setValues(i.Item, i.Value);
		});
		this.updateColumns();
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/customerconcerns`])
		);
		window.open(url, '_blank');
	}
	public toExcel() {
		let search: ICustomerconcernSearch = this.customerConcernService.getFreshSearch();

		search.NCRFromDate = this.NCRFromDate;
		search.NCRToDate = this.NCRToDate;
		search.NCRNumber = this.NCRNumber;
		search.Customer = this.Customer;
		search.OriginalSalesOrderNumber = this.OriginalSalesOrderNumber;
		search.Problem = this.Problem;
		search.BranchResponsible = this.BranchResponsible;
		search.StatusID = this.StatusID;
		search.EmployeeAssigned = this.EmployeeAssigned;
		search.CustomerComplaintNumber = this.CustomerComplaintNumber;
		search.ResponsibleFor = this.ResponsibleFor;
		search.Division = this.Division;
		search.Owner=this.Owner;
		search.FailureModeLevel1=this.Level1FailureMode;
		search.FailureModeLevel2=this.Level2FailureMode;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this.customerConcernService.toExcel(search).subscribe(retVal => 
		{
			this.excelFile = retVal;
		},
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}
	public hideFileLink() {
		this.excelFile = "";
	}
	public openExcelFile() {
		window.open(`${this.applicationSettings.retrieveUrl()}${this.excelFile}`);
	}
}