import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { PartattService } from '../partatt-service';
import { IPartattSearch } from '../partatt-model/partattsearch';
import { IPartatts } from '../partatt-model/partatts';
import { LookuplistService } from 'src/app/lookuplists/lookuplist-service';

@Component({
	templateUrl: './partatt-list.component.html',
	styleUrls: ['./partatt-list.component.css']
})

export class PartattListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'EclipseId', 'PrimaryDimension', 'PrimaryDimensionValue', 'SecondaryDimension', 'SecondaryDimensionValue','Var3', 'Series', 'Style', 'LabelTag', 'LabelGroup', 'SortOrder', 'DescriptionCount'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public EclipseId = '';
	public PrimaryDimension = '';
	public PrimaryDimensionValue = '';
	public SecondaryDimension = '';
	public SecondaryDimensionValue = '';
	public Var3 = '';
	public Series = '';
	public Style = '';
	public LabelTag = '';
	public LabelGroup = '';
	public SortOrder = '';
	public DescriptionCount = '';

	public pageTitle = 'PartAtts List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;
	public primaryDimList;
	public secondaryDimList;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: PartattService
		, private lookuplistService: LookuplistService
		) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
		this.getPrimaryDimension();
		this.getSecondaryDimension();
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='EclipseId')
				this.EclipseId = i.Value;

			if(i.Item=='PrimaryDimension')
				this.PrimaryDimension = i.Value;

			if(i.Item=='PrimaryDimensionValue')
				this.PrimaryDimensionValue = i.Value;

			if(i.Item=='SecondaryDimension')
				this.SecondaryDimension = i.Value;

			if(i.Item=='SecondaryDimensionValue')
				this.SecondaryDimensionValue = i.Value;
            if(i.Item=='Var3')
				this.Var3 = i.Value;

			if(i.Item=='Series')
				this.Series = i.Value;

			if(i.Item=='Style')
				this.Style = i.Value;

			if(i.Item=='LabelTag')
				this.LabelTag = i.Value;

			if(i.Item=='LabelGroup')
				this.LabelGroup = i.Value;

			if(i.Item=='SortOrder')
				this.SortOrder = i.Value;

			if(i.Item=='DescriptionCount')
				this.DescriptionCount = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IPartattSearch = this._appService.getFreshSearch();

		search.EclipseId = this.EclipseId;
		search.PrimaryDimension = this.PrimaryDimension;
		search.PrimaryDimensionValue = this.PrimaryDimensionValue;
		search.SecondaryDimension = this.SecondaryDimension;
		search.SecondaryDimensionValue = this.SecondaryDimensionValue;
		search.Var3 = this.Var3;
		search.Series = this.Series;
		search.Style = this.Style;
		search.LabelTag = this.LabelTag;
		search.LabelGroup = this.LabelGroup;
		search.SortOrder = this.SortOrder;
		search.DescriptionCount = this.DescriptionCount;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IPartatts) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IPartatts){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.EclipseId = '';
		this.PrimaryDimension = '';
		this.PrimaryDimensionValue = '';
		this.SecondaryDimension = '';
		this.SecondaryDimensionValue = '';
		this.Var3 = '';
		this.Series = '';
		this.Style = '';
		this.LabelTag = '';
		this.LabelGroup = '';
		this.SortOrder = '';
		this.DescriptionCount = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public toExcel(){
			let search: IPartattSearch = this._appService.getFreshSearch();

			search.EclipseId = this.EclipseId;
			search.PrimaryDimension = this.PrimaryDimension;
			search.PrimaryDimensionValue = this.PrimaryDimensionValue;
			search.SecondaryDimension = this.SecondaryDimension;
			search.SecondaryDimensionValue = this.SecondaryDimensionValue;
			search.Var3 = this.Var3;
			search.Series = this.Series;
			search.Style = this.Style;
			search.LabelTag = this.LabelTag;
			search.LabelGroup = this.LabelGroup;
			search.SortOrder = this.SortOrder;
			search.DescriptionCount = this.DescriptionCount;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
	getPrimaryDimension() {
		this.lookuplistService.getLookupListByForm('PartAtts', 'PrimaryDimension').subscribe(response => {
			this.primaryDimList = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
	getSecondaryDimension() {
		this.lookuplistService.getLookupListByForm('PartAtts', 'SecondaryDimension').subscribe(response => {
			this.secondaryDimList = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/partatts`])
		);
		window.open(url, '_blank');
	}
}