<div class="panel panel-primary animated fadeIn" *ngIf="resetdisplay">
	<div class="panel-heading">
		{{ pageTitle }}
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>

	<div class="panel-body" >
		<form class="form-horizontal" novalidate (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
			<div class="form-group">
				<label class="col-md-2 control-label" for="OldPassword">
					User Email Id</label>
				<div class="col-md-4">
					{{emailId}}
				</div>
			
			</div>
			<div class="form-group">
				<label class="col-md-2 control-label" for="NewPassword">
				New	Password</label>
				<div class="col-md-4">
					<input class="form-control" id="Password" type="password" placeholder="Password (required)" formControlName="NewPassword"
					/>
				</div>
			
			</div>
			<div class="form-group">
				<label class="col-md-2 control-label" for="ConfirmPassword">
					Confirm Password</label>
				<div class="col-md-4">
					<input class="form-control" id="ConfirmPassword" type="password" placeholder="Confirm Password (required)" formControlName="ConfirmPassword"
					/>
				</div>
				
			</div>
			<div class="form-group">
				<div class="col-md-offset-4 col-md-2">
					<div class="pull-right">
						<button class="btn btn-primary btn-flat" type="submit" [disabled]='!resetPasswordForm.valid'>
							<span class="glyphicon glyphicon-floppy-save"></span> Save
						</button>
					</div>
				</div>
			</div>

		</form>
	</div>
</div>
