<div class="crimp-form mb-40">
    <div class="row">
        <div class="col-md-12">
            <div class="form-title">
                {{ pageTitle }}
            </div>
            
            <a class='btn btn-default btn-sm back-button' [routerLink]="['/crimp-list']">
                <span class=" glyphicon glyphicon-chevron-left"></span>Back
            </a>
        </div>
    </div>
    
    <div *ngIf="crimp" class="row">
        <div class="col-md-12" [class.overlay]="locked">
            <!--  <div class="text-center" *ngIf="spinner.active">loading..</div> -->
        
            <form class="form-horizontal" novalidate [formGroup]="crimpForm">
                <fieldset >
                    <div class="form-body">
                        <div class="col-md-12">
    
                            <div [ngClass]="{'alert-danger': (message.MessageType=='ERROR'), 'alert-success': (message.MessageType!='ERROR')}"
                                class="alert mt-20 mb-20 text-center" *ngIf='message.Message'>
                                <strong>{{message.MessageType}} !</strong> {{ message.Message }}.
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
    
                            <div class="mt-20">
                                <div class="col-md-12 alert alert-info mt-20 mb-20 text-center" *ngIf='hoseWallInfo'>
                                    <strong>Please Note: </strong>The hose wall should be 
                                    <span class="badge mr-5">
                                        {{ hoseWallInfo | number: '1.2-3' }}
                                    </span>
                                    <span class="text-danger">
                                        Hose wall range: 
                                        {{ getHoseWallRange() }}
                                    </span>
                                </div>
    
                                <div class="col-md-12 nopadding">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="HoseName" class="col-md-3 control-label">
                                                Hose Name 
                                                <span class="required-star">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input placeholder="(Hose)" class='form-control' type='text' formControlName="HoseName"
                                                     [matAutocomplete]="autoHose" />
                                                <mat-autocomplete autoActiveFirstOption #autoHose="matAutocomplete" (optionSelected)="selectHoseAutocomplete($event.option.value)">
                                                    <mat-option *ngFor="let item of hosefilteredOptions | async"
                                                        [value]="item">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="OrderNumber" class="col-md-3 control-label">
                                                Order/Ref Number 
                                                <span class="required-star">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input 
                                                    formControlName="OrderNumber" 
                                                    type="text" 
                                                    id="OrderNumber"
                                                    placeholder="Order/Ref Number"
                                                    name="OrderNumber" 
                                                    class="form-control">
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-md-12 nopadding">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="IsCrimp" class="col-md-12 control-label" style="font-weight: 600;"
                                                [ngStyle]="{'color': isCrimp ? 'green' : 'red'}">
                                                {{!isCrimp ? "Cannot be Crimped" : "Can be Crimped"}}
                                            </label>
                                            <div class="col-md-9">
                                                <input 
                                                    formControlName="IsCrimp" 
                                                    type="checkbox" 
                                                    id="IsCrimp"
                                                    placeholder="IsCrimp"
                                                    name="IsCrimp" 
                                                    class="form-control hidden">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="CrimpInstructions" class="col-md-3 control-label">
                                               Crimp Instructions
                                            </label>
                                            <div class="col-md-9">
                                                <input 
                                                    formControlName="CrimpInstructions" 
                                                    type="text" 
                                                    placeholder="Crimp Instructions"
                                                    name="CrimpInstructions" 
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 nopadding">
                                    <hr>
                                    <div class="col-md-12">
                                        <h4 class="badge btn-flat badge-success">Crimp 1</h4>
                                    </div>
    
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="col-md-3 control-label" for="FerruleName">Ferrule:
                                                <span class="required-star">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input placeholder="(End2)" class='form-control' type='text' formControlName="FerruleName"
                                                [matAutocomplete]="autoEndFerrule1" />
                                            <mat-autocomplete autoActiveFirstOption #autoEndFerrule1="matAutocomplete" (optionSelected)="selectFerruleOneAutocomplete($event.option.value)">
                                                <mat-option *ngFor="let item of endoneFerrulefilteredOptions | async"
                                                    [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-autocomplete>
                                               
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label for="City" class="col-md-3 control-label">
                                                Fitting:
                                                <span class="required-star">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input placeholder="(End1)" class='form-control' type='text' formControlName="FittingName"
                                                [matAutocomplete]="autoEnd1" />
                                            <mat-autocomplete autoActiveFirstOption #autoEnd1="matAutocomplete" (optionSelected)="selectEndOneAutocomplete($event.option.value)">
                                                <mat-option *ngFor="let item of endonefilteredOptions | async"
                                                    [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-autocomplete>
                                               
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label for="OverrideCompression1" class="col-md-3 control-label">
                                                Compression Override:
                                            </label>
                                            <div class="col-md-9">
                                                <input 
                                                    formControlName="OverrideCompression" 
                                                    type="number" 
                                                    id="OverrideCompression1"
                                                    placeholder="Compression Override" 
                                                    name="OverrideCompression1" 
                                                    class="form-control"
                                                    (change)="onOverrideCompressionChange()"
                                                    >
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Measurement1" class="col-md-3 control-label">
                                                Hose Wall 1:
                                                <span class="required-star">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input
                                                    #measurement1 
                                                    formControlName="Measurement1" 
                                                    type="text" 
                                                    class="form-control" 
                                                    pattern="^\d*(\.\d{0,3})?$"
                                                    (keydown)="validateFormat($event)"
                                                    (blur)="onBlur($event)"                                               
                                                    id="Measurement1"
                                                    placeholder="Hose Wall 1 (inches)" 
                                                    name="Measurement1" 
                                                    required />
                                                <span 
                                                    *ngIf="validateHoseWall(measurement1)"
                                                    class="hose-wall-validation">
                                                    Hose wall out of tolerance
                                                </span>
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label for="Measurement2" class="col-md-3 control-label">Hose Wall 2:<span
                                                    class="required-star">*</span></label>
                                            <div class="col-md-9">
                                                <input
                                                    #measurement2
                                                    formControlName="Measurement2" 
                                                    class="form-control" 
                                                    type="text" 
                                                    pattern="^\d*(\.\d{0,3})?$"
                                                    (keydown)="validateFormat($event)"
                                                    (blur)="onBlur($event)"                                                   
                                                    id="Measurement2"
                                                    placeholder="Hose Wall 2 (inches)" 
                                                    name="Measurement2" 
                                                    required />
                                                <span 
                                                    *ngIf="validateHoseWall(measurement2)"
                                                    class="hose-wall-validation">
                                                    Hose wall out of tolerance
                                                </span>
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label for="Measurement3" class="col-md-3 control-label">Hose Wall 3:<span
                                                    class="required-star">*</span></label>
                                            <div class="col-md-9">
                                                <input
                                                    #measurement3 
                                                    formControlName="Measurement3" 
                                                    class="form-control" 
                                                    type="text" 
                                                    pattern="^\d*(\.\d{0,3})?$"
                                                    (keydown)="validateFormat($event)"
                                                    (blur)="onBlur($event)"                                               
                                                    id="Measurement3"
                                                    placeholder="Hose Wall 3 (inches)" 
                                                    name="Measurement3" 
                                                    required />
                                                <span 
                                                    *ngIf="validateHoseWall(measurement3)"
                                                    class="hose-wall-validation">
                                                    Hose wall out of tolerance
                                                </span>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-12">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="IsCrimp" class="col-md-12 control-label" style="font-weight: 600;"
                                                    [ngStyle]="{'color': crimp.Compression < 10 ? 'red' : 'green'}">
                                                    {{(crimp.Compression < 10 && crimp.OverrideCompression == 0) ? "YOUR HOSE COMPRESSION IS BELOW 10% - STOP AND REVIEW WITH YOUR MANAGER" : ""}}
                                                </label>
                                                <label for="IsCrimp" class="col-md-12 control-label" style="font-weight: 600;"
                                                [ngStyle]="{'color': crimp.OverrideCompression < 10 ? 'red' : 'green'}">
                                                {{(crimp.OverrideCompression > 0 && crimp.OverrideCompression < 10) ? "YOUR OVERRIDE COMPRESSION IS BELOW 10% - STOP AND REVIEW WITH YOUR MANAGER" : ""}}
                                            </label>
                                                <div class="col-md-9">
                                                    <input 
                                                        formControlName="Compression" 
                                                        type="number" 
                                                        id="Compression"
                                                        placeholder="Compression"
                                                        name="Compression" 
                                                        class="form-control hidden">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox text-center">
                                                <input 
                                                    #crimpCheckBox 
                                                    type="checkbox" 
                                                    id="CrimpCheckBox" 
                                                    value="" 
                                                    [checked]="copyCrimp"
                                                    (change)="onCrimpCopyChange(crimpCheckBox.checked)">
                                                <label for="CrimpCheckBox">
                                                    Crimp 2 is same
                                                </label>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
    
                                <div class="col-md-12 nopadding">
                                    <hr>
                                    <fieldset [disabled]="copyCrimp">
                                        <div class="col-md-12">
                                            <h4 class="badge btn-flat badge-success">Crimp 2</h4>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="col-md-3 control-label" for="Ferrule_2">
                                                    Ferrule:
                                                    <span *ngIf="!copyCrimp" class="required-star">*</span>
                                                </label>
                                                <div class="col-md-9">
                                                    <input placeholder="(End2)" class='form-control' type='text' formControlName="FerruleName_2"
                                                    [matAutocomplete]="autoEndFerrule2" />
                                                <mat-autocomplete autoActiveFirstOption #autoEndFerrule2="matAutocomplete" (optionSelected)="selectFerruleTwoAutocomplete($event.option.value)">
                                                    <mat-option *ngFor="let item of endtwoFerrulefilteredOptions | async"
                                                        [value]="item">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                </div>
                                            </div>
    
                                            <div class="form-group">
                                                <label for="City" class="col-md-3 control-label">Fitting:
                                                    <span *ngIf="!copyCrimp" class="required-star">*</span>
                                                </label>
                                                <div class="col-md-9">
                                                    <input placeholder="(End2)" class='form-control' type='text' formControlName="FittingName_2"
                                                    [matAutocomplete]="autoEnd2" />
                                                <mat-autocomplete autoActiveFirstOption #autoEnd2="matAutocomplete" (optionSelected)="selectEndTwoAutocomplete($event.option.value)">
                                                    <mat-option *ngFor="let item of endtwofilteredOptions | async"
                                                        [value]="item">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                   
                                                </div>
                                            </div>
    
                                            <div class="form-group">
                                                <label for="OverrideCompression_2" class="col-md-3 control-label">
                                                    Compression Override:
                                                </label>
                                                <div class="col-md-9">
                                                    <input 
                                                        formControlName="OverrideCompression_2" 
                                                        type="number" 
                                                        id="OverrideCompression_2"
                                                        placeholder="Compression Override" 
                                                        name="OverrideCompression_2"
                                                        class="form-control">
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="Measurement1_2" class="col-md-3 control-label">
                                                    Hose Wall 1:
                                                    <span *ngIf="!copyCrimp" class="required-star">*</span>
                                                </label>
                                                <div class="col-md-9">
                                                    <input
                                                        #measurement1_2
                                                        formControlName="Measurement1_2" 
                                                        type="text" 
                                                        class="form-control" 
                                                        pattern="^\d*(\.\d{0,3})?$"
                                                        (keydown)="validateFormat($event)"
                                                        (blur)="onBlur($event)"                                                     
                                                        id="Measurement1_2" 
                                                        placeholder="Hose Wall 1 (inches)" 
                                                        name="Measurement1_2"
                                                        [required]='!copyCrimp' />
                                                    <span 
                                                        *ngIf="validateHoseWall(measurement1_2)"
                                                        class="hose-wall-validation">
                                                        Hose wall out of tolerance
                                                    </span>
                                                </div>
                                            </div>
    
                                            <div class="form-group">
                                                <label for="Measurement2_2" class="col-md-3 control-label">
                                                    Hose Wall 2:
                                                    <span *ngIf="!copyCrimp" class="required-star">*</span>
                                                </label>
                                                <div class="col-md-9">
                                                    <input
                                                        #measurement2_2 
                                                        formControlName="Measurement2_2" 
                                                        class="form-control" 
                                                        type="text" 
                                                        pattern="^\d*(\.\d{0,3})?$"
                                                        (keydown)="validateFormat($event)"
                                                        (blur)="onBlur($event)"                                                    
                                                        id="Measurement2_2" 
                                                        placeholder="Hose Wall 2 (inches)" 
                                                        name="Measurement2_2"
                                                        [required]='!copyCrimp' />
                                                    <span 
                                                        *ngIf="validateHoseWall(measurement2_2)"
                                                        class="hose-wall-validation">
                                                        Hose wall out of tolerance
                                                    </span>
                                                </div>
                                            </div>
    
                                            <div class="form-group">
                                                <label for="Measurement3_2" class="col-md-3 control-label">
                                                    Hose Wall 3:
                                                    <span *ngIf="!copyCrimp" class="required-star">*</span>
                                                </label>
                                                <div class="col-md-9">
                                                    <input
                                                        #measurement3_2 
                                                        formControlName="Measurement3_2" 
                                                        class="form-control" 
                                                        type="text" 
                                                        pattern="^\d*(\.\d{0,3})?$"
                                                        (keydown)="validateFormat($event)"
                                                        (blur)="onBlur($event)"                                                    
                                                        id="Measurement3_2"
                                                        placeholder="Hose Wall 3 (inches)"
                                                        name="Measurement3_2"
                                                        [required]='!copyCrimp' />
                                                    <span 
                                                        *ngIf="validateHoseWall(measurement3_2)"
                                                        class="hose-wall-validation">
                                                        Hose wall out of tolerance
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div class="col-md-12">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="IsCrimp" class="col-md-12 control-label" style="font-weight: 600;"
                                                    [ngStyle]="{'color': crimp.Compression < 10 ? 'red' : 'green'}">
                                                    {{(crimp.Compression < 10 && crimp.OverrideCompression_2 == 0 && !this.crimp.End2Same) ? "YOUR HOSE COMPRESSION IS BELOW 10% - STOP AND REVIEW WITH YOUR MANAGER" : ""}}
                                                </label>
                                                <label for="IsCrimp" class="col-md-12 control-label" style="font-weight: 600;"
                                                [ngStyle]="{'color': crimp.OverrideCompression < 10 ? 'red' : 'green'}">
                                                {{(crimp.OverrideCompression_2 > 0 && crimp.OverrideCompression_2 < 10 && !this.crimp.End2Same) ? "YOUR OVERRIDE COMPRESSION IS BELOW 10% - STOP AND REVIEW WITH YOUR MANAGER" : ""}}
                                            </label>
                                                <div class="col-md-9">
                                                    <input 
                                                        formControlName="Compression_2" 
                                                        type="number" 
                                                        id="Compression_2"
                                                        placeholder="Compression_2"
                                                        name="Compression_2" 
                                                        class="form-control hidden">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
    
                        <div class="col-md-12">
                            <div [ngClass]="{'alert-danger': (message.MessageType=='ERROR'), 'alert-success': (message.MessageType!='ERROR')}"
                                class="alert mt-20 mb-20 text-center" *ngIf='message.Message'>
                                <strong>{{message.MessageType}} !</strong> {{ message.Message }}.
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
    
                            <div class="col-md-12 mt-40 buttons-row">
                                <button [disabled]="!isCrimp" class="btn btn-flat btn-primary" style="margin-right:10px" (click)="saveCrimp()">
                                    <span class="fa fa-calculator"></span> Calculate Crimp Setting &nbsp;
                                </button>
                                <span>
                                    <button class="btn btn-flat btn-default" (click)="onReset()" style="margin-right:10px">
                                        <span class="fa fa-refresh"></span> Reset Form &nbsp;
                                    </button>
                                </span>
                                <span>
                                    <button
                                        [disabled]="crimp && crimp.Id === 0" 
                                        class="btn btn-flat btn-warning" 
                                        (click)="onClearMeasurement()" 
                                        style="margin-right:10px">
                                        <span class="fa fa-eraser"></span> Clear Measurement for Next Hose &nbsp;
                                    </button>
                                </span>
                                <span>
                                    <button class="btn btn-flat btn-danger" (click)="cancel()" style="margin-right:10px">
                                        <span class="fa fa-arrow-left"></span> Cancel &nbsp;
                                    </button>
                                </span>
                            </div>
                        </div>
    
                        <div class="col-md-12 mb-20 nopadding" *ngIf="crimp?.Id > 0">
                            <hr>
                            <div class="col-md-6">
                                <div class="col-md-12">
                                    <h4 class="badge btn-flat badge-success">Table Crimp 1</h4>
                                    <div class='table-responsive'>
                                        <table class='table table-hover animated fadeIn'>
                                            <thead>
                                                <tr>
                                                    <th>Measured Hose O.D</th>
                                                    <th>Hose I.D.</th>
                                                    <th>Fitting O.D.</th>
                                                    <th>Ferrule Wall</th>
                                                    <th>Hose Compression</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{ crimp?.MeasuredHoseOD | number : '1.3-3' }}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.Hose?.InsideDiameter | number : '1.3-3'}}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.Fitting?.OutsideDiameter | number : '1.3-3'}}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.Ferrule?.WallThickness  | number : '1.3-3'}}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.CompressionStringValue }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  <p *ngIf="showCrimpCaluclation(isCrimp, crimp.Compression, crimp.OverrideCompression)"  style="font-size:120%;">
                                        <b> Crimp Diameter: {{ crimp?.CalculatedInch | number: '1.3-3' }} inches and {{
                                        crimp?.CalculatedMM | number : '1.2-2' }} mm 
                                        </b>
                                    </p>
                                        <p *ngIf="!showCrimpCaluclation(isCrimp, crimp.Compression, crimp.OverrideCompression)"  style="font-size:120%; color: red;">
                                        <b> Error, can not crimp, compression or override compression must be 10 or greater.  Or, this is not a crimpable hose.
                                        </b>
                                    </p>
                                </div>
    
                            </div>
    
                            <div class="col-md-6" *ngIf="!copyCrimp">
                                <div class="col-md-12">
                                    <h4 class="badge btn-flat badge-success">Table Crimp 2</h4>
                                    <div class='table-responsive'>
                                        <table class='table table-hover animated fadeIn'>
                                            <thead>
                                                <tr>
                                                    <th>Measured Hose O.D</th>
                                                    <th>Hose I.D.</th>
                                                    <th>Fitting O.D.</th>
                                                    <th>Ferrule Wall</th>
                                                    <th>Hose Compression</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{ crimp?.MeasuredHoseOD_2 | number : '1.3-3'}}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.Hose?.InsideDiameter | number : '1.3-3' }}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.Fitting2?.OutsideDiameter | number : '1.3-3' }}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.Ferrule2?.WallThickness | number : '1.3-3' }}
                                                    </td>
                                                    <td>
                                                        {{ crimp?.CompressionStringValue_2 }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <p  *ngIf="showCrimpCaluclation(isCrimp, crimp.Compression_2, crimp.OverrideCompression_2)" style="font-size:120%;">
                                        <b>
                                            Crimp Diameter: {{ crimp?.CalculatedInch_2 | number : '1.3-3' }} inches and {{
                                            crimp?.CalculatedMM_2 | number : '1.2-2' }} mm
                                        </b>
                                    </p>

                                    <p  *ngIf="!showCrimpCaluclation(isCrimp, crimp.Compression_2, crimp.OverrideCompression_2)" style="font-size:120%; color: red;">
                                        <b> Error, can not crimp, compression or override compression must be 10 or greater.  Or, this is not a crimpable hose.
                                        </b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
