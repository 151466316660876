
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-module';
import { AverylabelsComponent } from './averylabels/averylabels.component';
import { CustomaverylabelsComponent } from './customaverylabels/customaverylabels.component';
import { CustomeLabelComponent } from './customlabelcreator/customlabel.component';
import { LabelnextstepsComponent } from './labelnextsteps/labelnextsteps.component';
import { LabelsmenuService } from './labelsmenu-service';
import { LabelsmenuComponent } from './labelsmenu/labelsmenu.component';

@NgModule({
	declarations: [
		LabelsmenuComponent
		, LabelnextstepsComponent
		, AverylabelsComponent
		, CustomaverylabelsComponent
		,CustomeLabelComponent
	],
	imports: [
		BrowserModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild([
			{ path: 'labelsmenu', component: LabelsmenuComponent },
			{ path: 'labelnextsteps', component: LabelnextstepsComponent },
			{ path: 'averylabels', component: AverylabelsComponent },
			{ path: 'customaverylabels', component: CustomaverylabelsComponent },
			{ path: 'customerlabelscreator', component: CustomeLabelComponent },

		])
	],
	providers: [
		LabelsmenuService
	]
})
export class LabelsmenuModule {}
