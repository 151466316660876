<h3>
    HYDROSTATIC TEST CERTIFICATE</h3>
<hr />
<div *ngIf="hydroTest">
    <table class='htTable'>
        <tbody>
            <tr>
                <td width="20%">
                    <label>Contact:</label>
                </td>
                <td width="30%">
                    <input [(ngModel)]="hydroTest.contact" (change)="ValueChanged('contact')"
                        [ngModelOptions]="{standalone: true}" placeholder="(enter contact)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
                <td width="20%">
                    <label>Test Date</label>
                </td>
                <td width="30%">
                    <input type="date" [(ngModel)]="hydroTest.testDate" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" placeholder="(test date)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
            </tr>
            <tr>
                <td>
                    <label>Customer:</label>
                </td>
                <td>
                    <form [formGroup]="CustomerSearchForm">
                        <input placeholder="(Customer name)" class='form-control' type='text' formControlName="Customer"
                        [matAutocomplete]="auto" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectCustomerAutocomplete($event.option.value)">
                        <mat-option *ngFor="let item of customerfilteredOptions | async"
                            [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>
                    </form>
                </td>
                <td>
                    <label>Purchase Order:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.purhaseOrder" (change)="ValueChanged('purhaseOrder')"
                        [ngModelOptions]="{standalone: true}" placeholder="(purchase order)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
            </tr>
            <tr>
                <td>
                    <label>Address:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.address" (change)="ValueChanged('address')"
                        [ngModelOptions]="{standalone: true}" placeholder="(address)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
                <td>
                    <label>Test Location:</label>
                </td>
                <td>
                    <select [(ngModel)]="hydroTest.testLocation" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" class="form-control  input-list" [disabled]="loading">
                        <option *ngFor="let o of appPropertiesService.testLocations" [value]="o.name">{{o.name}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <label>City:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.city" (change)="ValueChanged('city')"
                        [ngModelOptions]="{standalone: true}" placeholder="(city)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
                <td>
                    <label>Sales Order:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.salesOrder"
                        (change)="ValueChanged('salesOrder'); isSalesOrderNumberValid()"
                        [ngModelOptions]="{standalone: true}" placeholder="(sales order)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
            </tr>
            <tr>
                <td>
                    <label>Province:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.province" (change)="ValueChanged('province')"
                        [ngModelOptions]="{standalone: true}" placeholder="(province)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
                <td>
                    <label>Postal Code:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.postalCode" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" placeholder="(postal code)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
            </tr>
            <tr>
                <td>
                    <label>Telephone:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.telephone" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" placeholder="(telephone)" [disabled]="loading"
                        class="form-control  input-list">
                </td>
                <td>
                    <label>Fax:</label>
                </td>
                <td>
                    <input [(ngModel)]="hydroTest.fax" (change)="ValueChanged()" [ngModelOptions]="{standalone: true}"
                        placeholder="(fax)" [disabled]="loading" class="form-control  input-list">
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <hr />
                </td>
            </tr>
            <tr>
                <td>
                    <label>Continuity Test Required:</label>
                </td>
                <td>
                    <select [(ngModel)]="hydroTest.elecTestReq" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" class="form-control  input-list" [disabled]="loading">
                        <option *ngFor="let o of yesNo" [value]="o.value">{{o.name}}</option>
                    </select>
                </td>
                <td rowspan="1">
                    <label>Internal Notes:</label>
                </td>
                <td rowspan="3" class="col-md-9">
                    <textarea [(ngModel)]="hydroTest.internalNotes" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" rows="5" class="form-control"
                        [disabled]="loading"></textarea>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Tester:</label>
                </td>
                <td>
                    <select [(ngModel)]="hydroTest.tester" (change)="ValueChanged()"
                        [ngModelOptions]="{standalone: true}" class="form-control input-list" [disabled]="loading">
                        <option *ngFor="let o of staffList" [value]="o.userId">{{o.userId}}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Test Machine:</label>
                </td>
                <td>
                    <table>
                        <tbody>
                            <td>

                                <select [(ngModel)]="hydroTest.testMachine" (change)="ValueChanged('testMachine')"
                                    [ngModelOptions]="{standalone: true}" class="form-control  input-list"
                                    [disabled]="loading">
                                    <option *ngFor="let o of testingMachineOptions" [value]="o.value">{{o.name}}
                                    </option>
                                </select>

                            </td>

                            <td>
                                <select [(ngModel)]="hydroTest.PLC" (change)="ValueChanged('PLC')"
                                    [ngModelOptions]="{standalone: true}" *ngIf="hydroTest.testMachine!='File'"
                                    class="form-control  input-list" [disabled]="loading">
                                    <option *ngFor="let i of plcOptions" [value]="i.value">{{i.name}}</option>
                                </select>
                            </td>
                        </tbody>
                    </table>
                </td>

            </tr>
        </tbody>
    </table>

    <button class="btn btn-primary" [disabled]="loading" (click)="saveRecord()">
        <span *ngIf="loading" class="fa fa-spinner fa-pulse" style="margin-right: 5px;"></span>
        Save Record
    </button>
    <button *ngIf="valueChanged" class="btn btn-primary" (click)="Cancel()">
        Cancel Changes
    </button>
</div>
<mat-spinner color="primary" class="spinner" *ngIf="loadingCustomer"></mat-spinner>
<div *ngIf="valueChanged">If you navigate off the tab your changes will be discarded.</div>
<br>
<label style="color:red">{{errorMessage}}</label>

