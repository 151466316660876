import { ApplicationSettings } from './../../shared/application-settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ICrimps } from '../crimp-model/ICrimps';
import { ICrimpSearch } from '../crimp-model/IcrimpSearch';

@Injectable()
export class CrimpGridService {
	private baseUrl;
	pageTitle = 'Crimp Management List';
	public message = { MessageType: '', Message: '' };

	constructor(
		private authHttp: HttpClient,
		public userSearchService: UsersearchService,
		private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/crimp/getcrimps';
	}

	private handleError(error: any): Observable<any> {
		return throwError(error.error.ErrorDescription || 'Server error');
	}

	public getRecordSet(search: ICrimpSearch): Observable<ICrimps> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('hoseName', search.HoseName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ferruleName', search.FerruleName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('fittingName', search.FittingName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderNumber', search.OrderNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('user', search.CreatedBy, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('branch', search.Branch, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('take', search.take.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('hoseName', search.HoseName.toString())
			.set('ferruleName', search.FerruleName.toString())
			.set('fittingName', search.FittingName.toString())
			.set('orderNumber', search.OrderNumber.toString())
			.set('user', search.CreatedBy ? search.CreatedBy : '')
			.set('createdDate', search.CreatedDate ? search.CreatedDate : '')
			.set('branch', search.Branch)
			.set('sort', search.orderByString)
		};

		return this.authHttp.get<ICrimps>(this.baseUrl, options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICrimps>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	public getFreshSearch() : ICrimpSearch { 
		let search: ICrimpSearch = {
			Id: 0,
			HoseName: '',
			TestRequired: 0,
			OrderNumber: '',
			TestResults: '',
			End2Same: 0,
			HoseOutsideDiameter: 0,
			FerruleName: '',
			FittingName: '',
			Compression: 0,
			Measurement1: null,
			Measurement2: null,
			Measurement3: null,
			MeasuredHoseOD: 0,
			OverrideCompression: 0,
			CalculatedInch: 0,
			CalculatedMM: 0,
			FerruleName_2: '',
			FittingName_2: '',
			Compression_2: 0,
			Measurement1_2: null,
			Measurement2_2: null,
			Measurement3_2: null,
			MeasuredHoseOD_2: 0,
			OverrideCompression_2: 0,
			CalculatedInch_2: 0,
			CalculatedMM_2: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			crimpDate:0,
			Branch:'',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	public addHydroTest(id: number): Observable<any> {
		const url = `${this.baseUrl}` + '/CreateHydroTest/' + `${id}`;
		return this.authHttp.post(url, id)
			.catch(this.handleError);
	}

	public toExcel(search: ICrimpSearch): Observable<string> {
		const options = {
		params: new HttpParams()
		.set('skip', search.skip.toString())
		.set('take', search.take.toString())
		.set('hoseName', search.HoseName.toString())
		.set('ferruleName', search.FerruleName.toString())
		.set('fittingName', search.FittingName.toString())
		.set('orderNumber', search.OrderNumber.toString())
		.set('user', search.CreatedBy ? search.CreatedBy : '')
		.set('createdDate', search.CreatedDate ? search.CreatedDate : '')
		.set('branch', search.Branch)
		.set('sort', search.orderByString)
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

}

