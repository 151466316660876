import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard  {
	constructor(private auth: AuthService, private router: Router
		,private Location:Location) {}

	/**
	 *  User route guard. If user is not
	 *  logged in redirection to login page is performed.
	 */
	canActivate() {
		if (this.auth.loggedIn()) {
			return true;
		} else {		
			this.auth.redirectUrl=this.Location.path();
			this.router.navigateByUrl('/login');
			return false;
		}
	}
}
