import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	selector: 'customerplanrecord-help',
	templateUrl: './customerplanrecord-help.component.html',
	styleUrls: ['./customerplanrecord-help.component.css']
})

export class CustomerPlanrecordHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'CustomerPlanRecords Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}