<div class='panel panel-primary animaPlaceholder'>
	<div class='col-md-12'>
		<h2>Test page to send emails</h2>
	</div>
	<div class='col-md-3 text-right'>
        <button class='btn btn-primary btn-sm' style='width: 200px;margin:20px;' (click)='sendEmailForOpenIssues()' [disabled]="emailBtnPushed">Send Email</button>
    </div>
	<div class='col-md-3 text-right'>
        <button class='btn btn-primary btn-sm' style='width: 200px;margin:20px;' (click)='sendEmailForOpenIssuesToCraig()' [disabled]="emailBtnPushed">Send Email to Craig</button>
    </div>
   
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort>
		<ng-container matColumnDef='Id'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>Id</th>
			<td mat-cell *matCellDef='let e'>
				{{e.Id}}
			</td>
		</ng-container>
        <ng-container matColumnDef='Customer'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'>Customer</th>
			<td mat-cell *matCellDef='let e'>{{e.Customer}}</td>
		</ng-container>
		<ng-container matColumnDef='NCRCode'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'>NCRCode</th>
			<td mat-cell *matCellDef='let e'>{{e.NCRCode}}</td>
		</ng-container>
		<ng-container matColumnDef='Problem'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'>Problem</th>
			<td mat-cell *matCellDef='let e'>{{(e.Problem.length >68 )?(e.Problem | slice: 0: 68) + '..' : e.Problem}}
			</td>
		</ng-container>		
		<ng-container matColumnDef='EmployeeAssigned'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'>Employee Assigned</th>
			<td mat-cell *matCellDef='let e'>{{e.EmployeeAssigned}}</td>
		</ng-container>
	
		<ng-container matColumnDef='Owner'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'>Owner</th>
			<td mat-cell *matCellDef='let e'>{{e.Owner}}</td>
		</ng-container>
	
		<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
		<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
	</table>
</div>
