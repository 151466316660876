import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { PrinterIpService } from '../../shared/printer-ip.service';
import { PackageLabelService } from '../package-label-service';

@Component({
	templateUrl: './package-label.component.html',
	styleUrls: ['package-label.component.css']
})

export class PackageLabelComponent implements OnInit, OnDestroy, AfterViewInit {
	public page = 'Stock Parts 2 X 4';
	errorMessage: string;
	boxlabelForm: UntypedFormGroup;
	partsData = [];
	qtyTypes = ['ea', 'ft'];
	public Branch: string;

	constructor(
		private authService: AuthService,
		private fb: UntypedFormBuilder,
		private packageLabelService: PackageLabelService,
		private printerIpService: PrinterIpService) { }

	ngOnInit(): void {
		this.Branch = this.authService.getBranchId();

		this.boxlabelForm = this.fb.group({
			Id: '',
			PartNumber: ['', Validators.required],
			PartDescription: [{value: '', disabled: true}, Validators.required],
			Upc: [{value: '', disabled: true}, Validators.required],
			Quantity: ['', Validators.required],
			QtyType: ['', Validators.required],
			EclipseId: ['', Validators.required],
			LabelType: ['Package', Validators.required],
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: [1, Validators.required]
		});
	}

	public ngAfterViewInit(): void {

	}

	print(): void {
		if (this.boxlabelForm.valid) {
			const obj = Object.assign({}, this.boxlabelForm.getRawValue());
			this.packageLabelService.createPackagelabel(obj)
				.subscribe(_ => this.onSaveComplete()
					, (_: any) => this.errorMessage = 'Error while printing the label');

			this.packageLabelService.createPackagelabelGlPrinting(obj, this.Branch)
				.subscribe(_ => this.onSaveComplete()
					, (_: any) => this.errorMessage = 'Error while printing the label (Gl Printing)');
		}
	}

	onSaveComplete(): void {
		//this.errorMessage = '';
		this.boxlabelForm.reset();
		this.boxlabelForm.patchValue({
			LabelType: 'Package',
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: 1
		});
	}

	ngOnDestroy(): void { }

	// tslint:disable-next-line:no-shadowed-variable
	public valueNormalizer = (text: Observable<string>) => text.map(_ => null);

	partChanged(partNumber: any) {
		const part = this.partsData.find(p => p.PartNumber === partNumber);

		if (part) {
			this.boxlabelForm.patchValue({
				Upc: part.Upc,
				EclipseId: part.EclipseId,
				PartDescription: part.PartDescription
			});
		}
	}
	
	printerIp(): string {
		return this.printerIpService.getIp(this.Branch);
	}

	resetError() : void {
		this.errorMessage = '';
	}
}
