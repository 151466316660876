import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DdlService } from './../../../shared/ddlService/ddl.service';
import { IHose } from './../hose.model/hose';
import { HoseService } from './../hose.service';

@Component({
	selector: 'app-hose-edit',
	templateUrl: './hose-edit.component.html',
	styleUrls: ['./hose-edit.component.css']
})

export class HoseEditComponent implements OnInit {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit ';
	errorMessage: string;
	hoseForm: UntypedFormGroup;
	hose: IHose;
	private sub: Subscription;
	public buttonPushed = false;
	public message;
	hideInsideOutsideDiameters = false;

	// load ddls
	public producttypesddl: any = [];
	public hyddimsddl: any = [];
	public inddimsddl: any = [];

	public dimsddl: any = [];

	public editPermission = false;

	constructor(private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private hoseService: HoseService,
		public ddlService: DdlService,
		private auth: AuthService) {
	}

	ngOnInit(): void {
		this.auth.getRoles().includes('Update Parts')?this.editPermission=true:this.editPermission=false;
		this.hoseForm = this.fb.group({
			Id: 0,
			Hose: '',
			InsideDiameter: '',
			OutsideDiameter: '',
			HydraulicInsideDiameter: '',
			DiameterValue: '',
			Compression: '',
			ContinuityTestRequired: '',
			CrimpInstructions: '',
			Notes: '',
			Pressure: '',
			PressureTest: '',
			ProductTypeId: '',
			ProductType: '',
			ProductTypeName: '',
			Description: '',
			IsCrimp: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.ddlService.ddl('PRODUCTTYPE,Hydraulic Lookup,Industrial Lookup')
			.subscribe(
				(data) => {
					this.producttypesddl = data.filter(obj => obj.LookupType === 'PRODUCTTYPE');
					this.hyddimsddl = data.filter(obj => obj.LookupType === 'Hydraulic Lookup');
					this.inddimsddl = data.filter(obj => obj.LookupType === 'Industrial Lookup');

					this.dimsddl = this.hyddimsddl;

					this.sub = this.route.params.subscribe(
						params => {
							const id = +params['Id'];
							this.gethose(id);
						}
					);
				});
	}

	saveHose(con: boolean): void {
		if(this.editPermission){
		this.buttonPushed = true;
		if (this.hoseForm.valid) {
			this.errorMessage = '';
			const obj = Object.assign({}, this.hose, this.hoseForm.value);
			this.hoseService.saveHose(obj)
				.subscribe((data) => this.onSaveComplete(con, data)
					, (error: any) => {
						this.errorMessage = <any>error;
						this.message = 'The data has been updated';
					});
		} else {
			// console.log(this.hoseForm)
			this.errorMessage = 'Please fill the required fields of form';
		}
	}else{
		this.errorMessage = 'You dont have permission to edit this element';
	}
	}

	onChange(value) {
		// When Hydraulic is selected, hide the 'Inside Diameter' and 'Outside Diameter' fields.
		this.hideInsideOutsideDiameters = value === 'Hydraulic' ? true : false;

		if (value === 'Industrial') {
			this.dimsddl = this.inddimsddl;
		} else {
			this.dimsddl = this.hyddimsddl;
		}

		this.hoseForm.patchValue({ ProductTypeName: value });
		this.hose.ProductType = value;
	}

	onSaveComplete(con: boolean, data: any): void {
		if (con) {
			this.hoseForm.reset();
			this.router.navigate(['/hoses']);
		} else {
			if (data !== null && this.hose.Id === 0) {
				this.router.navigate(['/hose-details', data.Id]);
			}
		}
		this.message = 'The data has been Updated';
		this.buttonPushed = false;
	}

	gethose(id: number): void {
		this.hoseService.getHosebyId(id)
			.subscribe((obj: IHose) => this.onhoseRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onhoseRetrieved(hose: IHose): void {
		if (this.hoseForm) {
			this.hoseForm.reset();
		}

		this.hose = hose;

		if (this.hose.Id === 0) {
			this.page = 'Add hose';
		} else {
			this.page = 'Edit hose';
		}

		if (this.hose.ProductType === 'Industrial') {
			this.dimsddl = this.inddimsddl;
		}

		if (this.hose.ProductType === 'Hydraulic') {
			this.hideInsideOutsideDiameters = true;
		}

		this.hoseForm.patchValue({
			Id: this.hose.Id,
			Hose: this.hose.Hose,
			InsideDiameter: this.hose.InsideDiameter,
			OutsideDiameter: this.hose.OutsideDiameter,
			HydraulicInsideDiameter: this.hose.HydraulicInsideDiameter,
			DiameterValue: this.hose.DiameterValue,
			Compression: this.hose.Compression,
			ContinuityTestRequired: this.hose.ContinuityTestRequired,
			CrimpInstructions: this.hose.CrimpInstructions,

			Notes: this.hose.Notes,
			Pressure: this.hose.Pressure,
			PressureTest: this.hose.PressureTest,
			ProductTypeId: this.hose.ProductTypeId,
			ProductTypeName: this.hose.ProductType,
			ProductType: this.hose.ProductType,
			Description: this.hose.Description,
			IsCrimp: this.hose.IsCrimp,

			CreatedDate: this.hose.CreatedDate,
			CreatedBy: this.hose.CreatedBy,
			UpdatedDate: this.hose.UpdatedDate,
			UpdatedBy: this.hose.UpdatedBy,
		});
	}

	deleteHose(): void {
		if(this.editPermission){	
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.hose.Hose}?`)) {
			this.hoseService.deleteHose(this.hose.Id)
				.subscribe(() => this.onSaveComplete(true, null)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}else{
		this.errorMessage = 'You dont have permission to edit this element';
	}
	}

	cancel(): void {
		this.router.navigate(['/hoses']);
	}

	// Load DDL data
}
