import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { PartListComponent } from './part-list/part-list.component';
import { PartEditComponent } from './part-edit/part-edit.component';
import { PartService } from './part-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		PartListComponent
		, PartEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'parts', component: PartListComponent },
			{ path: 'part-details/:Id', component: PartEditComponent },
		])
	],
	providers: [
		PartService
	]
})
export class PartModule { }
