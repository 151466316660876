import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ApplicationSettings } from '../shared/application-settings';

@Injectable()
export class LabelsmenuService {
	private baseUrl;
	public pageTitle = 'vBinLabels List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Labelsmenu';
	}


	

	

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}


	getVendorsList(): Observable<any> {
		const url = `${ this.baseUrl}/getvendorslist`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabelByProductCategory(value,type,count,lastrec): Observable<any> {
		let val = value.replace(/&/g, '%26');
		const url = `${ this.baseUrl}/createlabelbyproductcategory?value=`+val+`&type=`+type+`&count=`+count+`&lastrec=`+lastrec;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createBinLabelReport(): Observable<any> {
		const url = `${ this.baseUrl}/createbinlabel`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabelByPLXOver(pline,vendor): Observable<any> {
		const url = `${ this.baseUrl}/createlabelbyplxover?pline=`+pline+`&vendor=`+vendor;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createRackLabelPartNumberOnly(pline): Observable<any> {
		const url = `${ this.baseUrl}/racklabelpartnumberonly?pline=`+pline;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createRackLabelCode39(pline): Observable<any> {
		const url = `${ this.baseUrl}/racklabelcode39?pline=`+pline;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createRackLabelByPlineCode39(pline): Observable<any> {
		const url = `${ this.baseUrl}/racklabelbyplinecode39?pline=`+pline;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	addFileShowroomLabel(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadshowroomlabel`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	insertFileShowroomLabel(data){
		console.log(data);
		const url = `${this.baseUrl}/insertshowroomlabel`;
		return this.authHttp.post<any>(url,data)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createLabelByProductGroup(pline,type,count,lastrec): Observable<any> {
		let val = pline.replace(/&/g, '%26');
		const url = `${ this.baseUrl}/createlabelbyproductgroup?pline=`+val+`&type=`+type+`&count=`+count+`&lastrec=`+lastrec;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	generateAllProdCatRpt(): Observable<any> {
		const url = `${ this.baseUrl}/generateallprodcatrpt`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	getProdCatPdfLinkList(): Observable<any> {
		const url = `${ this.baseUrl}/getprodcatpdflinklist`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	generateAllProdGroupRpt(): Observable<any> {
		const url = `${ this.baseUrl}/generateallprodgrouprpt`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	getProdGroupPdfLinkList(): Observable<any> {
		const url = `${ this.baseUrl}/getprodgrouppdflinklist`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabelByProductGroupCustomer(value,type,count,lastrec,customerid,partsfilter): Observable<any> {
		let val = value.replace(/&/g, '%26');
		const url = `${ this.baseUrl}/createlabelbyproductgroupcustomer?value=`+val+`&type=`+type+`&count=`+count+`&lastrec=`+lastrec+`&customerid=`+customerid+`&partsfilter=`+partsfilter;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabelByProductCategoryCustomer(value,type,count,lastrec,customerid,partsfilter): Observable<any> {
		let val = value.replace(/&/g, '%26');
		const url = `${ this.baseUrl}/createlabelbyproductcategorycustomer?value=`+val+`&type=`+type+`&count=`+count+`&lastrec=`+lastrec+`&customerid=`+customerid+`&partsfilter=`+partsfilter;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabelAllCustomerParts(value,customerid,averytype): Observable<any> {
		let val = value.replace(/&/g, '%26');
		const url = `${ this.baseUrl}/createlabelsbycustomer?value=`+val+`&customerid=`+customerid+`&averytype=`+averytype;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	
}