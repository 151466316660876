import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApplicationSettings } from '../../shared/application-settings';
import { Calibration } from './calibration.model';
import { DataFromPLC } from './datafromPLC.model';
import { AuthService } from 'src/app/auth/auth.service';



@Injectable()
export class CalibrationServiceService {

	constructor(
		private http: HttpClient
		, private appPropertiesService: ApplicationSettings
		, private auth: AuthService) { }

	getCalibrationData(pressureSwitch: string, macAddress: string): Observable<Calibration[]> {
		const par: string = '?pressureSwitch=' + pressureSwitch + '&macAddress=' + macAddress;
		const url: string = this.appPropertiesService.baseUrl + '/api/Calibration/List';

		return this.http.get<any>(url + par)
			.catch(this.handleCalibrationsError);
	}

	private handleCalibrationsError(error: HttpErrorResponse) {
		let message = '';
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			message =  error.message;
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			message = error.error.ExceptionMessage;
		}

		return throwError(message);
	}

	private handleError(error: any) {
		console.log('handle error', error);
		return throwError(error.error.ErrorDescription || 'Error while trying to fetch data');
	}
	

	saveCalibrationData(obj: Calibration): Observable<any> {
		const url: string = this.appPropertiesService.baseUrl + '/api/Calibration';

		let par: string = '?id=' + obj.Id + '&macAddress=' + obj.MacAddress + '&plcValue=' + obj.PLCValue;
		par = par + '&pressure=' + obj.Pressure + '&pressureSwitch=' + obj.PressureSwitch;

		return this.http.post<any>(url + par, { name })
			.catch(this.handleError);
	}

	getPlcValue(): Observable<DataFromPLC> {
		console.log('getPlcValue()');
		return this.http.get(this.appPropertiesService.PLCUrl + 'ReadPLC/ReadPLCPressure?PressureSensor=Low')
		.map((response: any) => response.json() as DataFromPLC)
			.catch(this.handleError);
	}

	getPlcValueHighPressure(): Observable<DataFromPLC> {
		console.log('getPlcValueHighPressure()::test');
		return this.http.get(this.appPropertiesService.PLCUrl + 'ReadPLC/ReadPLCPressure?PressureSensor=High')
			.map((response: any) => response.json() as DataFromPLC)
			.catch(this.handleError);
	}

	getMacAddress(): Observable<string> {

		console.log('getting the mac address')

		let branchid = this.auth.getBranchId();

		if (branchid==="0"||branchid==="18"||branchid==="21"||branchid==="3"||branchid=="27"||branchid=="23"||branchid=="5"){
			return Observable.of("Branch " + branchid);
		}
		else{
			return this.http.get(this.appPropertiesService.PLCUrl + 'ReadPLC/GetMacAddress')
			.map((response: any) => response.json() as string)
			.catch(this.handleError);
		}
		
	}

	deleteRecord(id: number): any {
		const url: string = this.appPropertiesService.baseUrl + '/api/Calibration/DeleteCalibration?id='+id;
		return this.http.delete<any>(url)
		.catch(this.handleError);
	}
	


}
