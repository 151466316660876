import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	selector: 'lookuplist-help',
	templateUrl: './lookuplist-help.component.html',
	styleUrls: ['./lookuplist-help.component.css']
})

export class LookuplistHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'LookUpLists Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}