export class ChartTime{
    public startTime: number;
    public currentTime: number;

    public SetStartAndCurrentTime(time: number)
    {
        this.startTime = time;
        this.currentTime = time;
    }
    public totalSeconds(): number{
        return this.seconds() + 60*this.minutes();
    }
    public minutes(): number{
        return Math.floor((this.currentTime - this.startTime) / 60000);
    }
    public displayTimer(): string{
        return this.minutes() + ':' + this.seconds();
    }
    public seconds(): number {
        return +(((this.currentTime - this.startTime) % 60000) / 1000).toFixed(0);
    }
    
}