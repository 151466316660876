import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDocument } from '../document-model/document';

import { Subscription } from 'rxjs/Subscription';
import { DocumentService } from '../document-service';
import { IDocuments } from '../Document-model/Documents';
import { IDocumentSearch } from '../Document-model/DocumentSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { ApplicationSettings } from 'src/app/shared/application-settings';

@Component({
	templateUrl: './document-edit.component.html',
	styleUrls: ['document-edit.component.css']
})

export class DocumentEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Documents';
	errorMessage: string;
	documentForm: UntypedFormGroup;
	document: IDocument;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public DocumentSearch: IDocumentSearch;
	public ListData: IDocuments;
	public showNextRecordButton: boolean = true;
	public CurrentFile = ''

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private documentService: DocumentService
		, private settings: ApplicationSettings
	) {
	}

	ngOnInit(): void {
		this.documentForm = this.fb.group({
			Id: '',
			Name: '',
			Description: '',
			FileName: '',
			DocumentType: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			RealFileName: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getDocument(id);
			}
		);
	}

	public getSelectedRecordSet(): void {
		let pageName = "Documents List";
		this.documentService.getRecordSet(this.DocumentSearch, pageName)
			.subscribe((obj: IDocuments) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IDocuments) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.document.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveDocument(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.documentForm.valid) {
			const obj = Object.assign({}, this.document, this.documentForm.value);
			this.documentService.saveDocument(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.documentForm.reset();
			this.router.navigate(['/documents']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.document.Id === 0) {
			this.router.navigate(['/document-details', data.Id]);
		}
		else if (this.document.Id != 0) {
			this.getDocument(this.document.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.document.Id)
				record = count;
		});

		this.getDocument(this.ListData.data[record].Id)
	}

	getDocument(id: number): void {
		this.documentService.getDocument(id)
			.subscribe((obj: IDocument) => this.onDocumentRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onDocumentRetrieved(document: IDocument): void {
		if (this.documentForm) {
			this.documentForm.reset();
		}
		this.document = document;
		if (this.document.Id === 0) {
			this.page = 'Add Documents';
		} else {
			this.page = 'Edit Documents';
		}

		this.documentForm.patchValue({
			Id: this.document.Id,
			Name: this.document.Name,
			Description: this.document.Description,
			FileName: this.document.FileName,
			DocumentType: this.document.DocumentType,
			CreatedDate: this.document.CreatedDate,
			CreatedBy: this.document.CreatedBy,
			UpdatedDate: this.document.UpdatedDate,
			UpdatedBy: this.document.UpdatedBy,
			RealFileName: this.document.RealFileName,
		});
		this.CurrentFile = this.document.RealFileName;
		console.log('current file: '+this.CurrentFile );
	}

	ngOnDestroy(): void {
	}

	deleteDocument(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.document.Name}?`)) {
			this.documentService.deleteDocument(this.document.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/documents']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Documents List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.DocumentSearch = this.documentService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.DocumentSearch.Name = i.Value;

			if (i.Item == 'Description')
				this.DocumentSearch.Description = i.Value;

			if (i.Item == 'FileName')
				this.DocumentSearch.FileName = i.Value;

			if (i.Item == 'DocumentType')
				this.DocumentSearch.DocumentType = i.Value;

			if (i.Item == 'RealFileName')
				this.DocumentSearch.RealFileName = i.Value;

			if (i.Item == 'orderByString')
				this.DocumentSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.DocumentSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	onFileSelected(event: any) {
		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
			console.log(target.files[0].name);
		}
		const formData = new FormData();
		formData.append('fileData', target.files[0]);
		formData.append('Id', this.document.Id.toString());

		console.log(formData);

		this.documentService.addFile(formData).subscribe(
			retVal => {
				console.log("File Uploaded.");
				console.log(retVal);
				this.documentForm.patchValue({
					RealFileName: retVal
				});
				this.CurrentFile = retVal;
			}
			, (error: any) => this.errorMessage = <any>error
		);
	}
	openFile(filename) {
		let url = this.settings.retrieveUrl() + '/uploadedfiles/documents/';
		window.open(url + filename, "_blank");
	}
}
