import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';

@Injectable()
export class HydrotestGridService {
private baseUrl;

	constructor(private settings: ApplicationSettings) {
	this.baseUrl = this.settings.retrieveUrl() + '/api/hydrotests/grid';
}
}
