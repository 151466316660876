import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
	private baseUrl;
	public pageTitle = 'Dashboard';

  constructor(private authHttp: HttpClient
    , private settings: ApplicationSettings) {
    this.baseUrl = this.settings.retrieveUrl() + '/api/Dashboard';
   }

  public getDailyStatsData(branchid): Observable<any> {
		console.log('branchid:' + branchid);
		const url = `${this.baseUrl}/getDailyStats?branchid=${branchid}`;
		return this.authHttp
			.get(url)
	}
	public getPickByPersonData(branchid): Observable<any> {
		console.log('branchid:' + branchid);
		const url = `${this.baseUrl}/getPickByPerson?branchid=${branchid}`;
		return this.authHttp
			.get(url)
	}
}
