import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IPartatt} from './Partatt-model/Partatt';
import { IPartatts } from './Partatt-model/Partatts';
import { IPartattSearch } from './Partatt-model/PartattSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class PartattService {
	private baseUrl;
	public pageTitle = 'PartAtts List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Partatts';
	}

	getPartatt(id: number):  Observable<IPartatt> {
		if (id === 0) {
			return of(this.initializePartatt());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IPartatt>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	savePartatt(partatt: IPartatt): Observable<any> {
		if (partatt.Id === 0) {
			return this.createPartatt(partatt);
		}
		return this.updatePartatt(partatt);
	}

	private createPartatt(partatt: IPartatt): Observable<any> {
		partatt.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/partattcreate', partatt)
			.pipe(retry(1), catchError(this.processError));
	}

	private updatePartatt(partatt: IPartatt): Observable<IPartatt> {
		return this.authHttp.put<IPartatt>(this.baseUrl, partatt)
			.pipe(retry(1), catchError(this.processError));
	}

	deletePartatt(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializePartatt(): IPartatt {
		return {
			Id: 0,
			EclipseId: 0,
			PrimaryDimension: '',
			PrimaryDimensionValue: '',
			SecondaryDimension: '',
			SecondaryDimensionValue: '',
			Var3: '',
			Series: '',
			Style: '',
			LabelTag: '',
			LabelGroup: '',
			SortOrder: 0,
			DescriptionCount: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IPartatt;
	}

	public getRecordSet(search: IPartattSearch): Observable<IPartatts> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('EclipseId', search.EclipseId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PrimaryDimension', search.PrimaryDimension, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PrimaryDimensionValue', search.PrimaryDimensionValue, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SecondaryDimension', search.SecondaryDimension, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SecondaryDimensionValue', search.SecondaryDimensionValue, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Var3', search.Var3, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Series', search.Series, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Style', search.Style, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LabelTag', search.LabelTag, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LabelGroup', search.LabelGroup, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SortOrder', search.SortOrder, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('DescriptionCount', search.DescriptionCount, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('EclipseId', search.EclipseId ? search.EclipseId : '')
			.set('PrimaryDimension', search.PrimaryDimension ? search.PrimaryDimension : '')
			.set('PrimaryDimensionValue', search.PrimaryDimensionValue ? search.PrimaryDimensionValue : '')
			.set('SecondaryDimension', search.SecondaryDimension ? search.SecondaryDimension : '')
			.set('SecondaryDimensionValue', search.SecondaryDimensionValue ? search.SecondaryDimensionValue : '')
			.set('Var3', search.Var3 ? search.Var3 : '')
			.set('Series', search.Series ? search.Series : '')
			.set('Style', search.Style ? search.Style : '')
			.set('LabelTag', search.LabelTag ? search.LabelTag : '')
			.set('LabelGroup', search.LabelGroup ? search.LabelGroup : '')
			.set('SortOrder', search.SortOrder ? search.SortOrder : '')
			.set('DescriptionCount', search.DescriptionCount ? search.DescriptionCount : '')
		};

		return this.authHttp.get<IPartatts>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IPartatts>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IPartattSearch { 
		let search: IPartattSearch = {
			Id: 1,
			EclipseId: '',
			PrimaryDimension: '',
			PrimaryDimensionValue: '',
			SecondaryDimension: '',
			SecondaryDimensionValue: '',
			Var3: '',
			Series: '',
			Style: '',
			LabelTag: '',
			LabelGroup: '',
			SortOrder: '',
			DescriptionCount: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IPartattSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('EclipseId', search.EclipseId ? search.EclipseId : '')
			.set('PrimaryDimension', search.PrimaryDimension ? search.PrimaryDimension : '')
			.set('PrimaryDimensionValue', search.PrimaryDimensionValue ? search.PrimaryDimensionValue : '')
			.set('SecondaryDimension', search.SecondaryDimension ? search.SecondaryDimension : '')
			.set('SecondaryDimensionValue', search.SecondaryDimensionValue ? search.SecondaryDimensionValue : '')
			.set('Var3', search.Var3 ? search.Var3 : '')
			.set('Series', search.Series ? search.Series : '')
			.set('Style', search.Style ? search.Style : '')
			.set('LabelTag', search.LabelTag ? search.LabelTag : '')
			.set('LabelGroup', search.LabelGroup ? search.LabelGroup : '')
			.set('SortOrder', search.SortOrder ? search.SortOrder : '')
			.set('DescriptionCount', search.DescriptionCount ? search.DescriptionCount : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadimage`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
}