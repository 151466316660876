import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';
import { IUserLogin } from './user-model/user-login';

@Injectable()
export class AuthService {
	private baseUrl;
	public redirectUrl: string;
	constructor(
		private http: HttpClient,
		private router: Router,
		private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/users';
	}

	/**
	 * Login action. User is logged in after valid JWT is returned
	 * from the server. Existence of this token in the local storage
	 * will determine whether the user is logged in or not.
	 * @param user
	 */
	login(user: IUserLogin): Observable<IUserLogin> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};

		return this.http.post<any>(this.baseUrl + '/Login', user, httpOptions)
			.do(data => {
				localStorage.setItem('token', data.AccessToken);
				localStorage.setItem('email', data.UserInfo.Email);
				localStorage.setItem('username', data.UserInfo.UserName);
				localStorage.setItem('branchId', data.UserInfo.BranchId);
				localStorage.setItem('expirationDate', data.ExpirationDate);
				const roles = JSON.stringify(data.UserInfo.Roles);
				// This removes brackets around the string
				const rolesTrimmed = roles.substring(1, roles.length - 1);
				localStorage.setItem('roles', rolesTrimmed);

				if (this.redirectUrl != null) {
					this.redirectToUrl();
				}
				else {
					this.router.navigate(['/welcome']);
				}

			})
			.catch(this.handleError);
	}

	public redirectToUrl(){
		this.router.navigate([this.redirectUrl]);
		this.redirectUrl = null;
	}
	loggedIn() {
		const expirationDate = localStorage.getItem('expirationDate');

		if (expirationDate != null && new Date(expirationDate) > new Date()) {
			return true;
		}

		return false;
	}

	/**
	 * Clear local storage for this session
	 * and redirect to welcome page.
	 */
	logout(): void {
		localStorage.removeItem('token');
		localStorage.removeItem('roles');
		localStorage.removeItem('expirationDate');
		localStorage.removeItem('email');
		localStorage.removeItem('branchId');
		localStorage.removeItem('username');
		this.router.navigate(['/login']);
	}

	/**
	 * Retrieving user email from local storage.
	 */
	getEmail(): string {
		return localStorage.getItem('email');
	}

	/**
	 * Retrieving user username from local storage.
	 */
	getUsername(): string {
		return localStorage.getItem('username');
	}

	/**
	 * Retrieving user branchId from local storage.
	 */
	getBranchId(): string {
		return localStorage.getItem('branchId');
	}
	
	branchHydrotestUpdated(): boolean {
		let branchid = this.getBranchId();

		if (branchid==="0"||branchid==="18"||branchid==="21"||branchid==="23"||branchid==="3"||branchid=="27"||branchid=="5")
			return true;
		else
			return false;

	}

	getToken() {
		return localStorage.getItem('token');
	}

	getRoles() {
		return localStorage.getItem('roles');
	}

	/**
	 * Helper method that retrieves user roles
	 * from storage, and checks if user is in
	 * Admin role.
	 */
	isAdmin(): boolean {
		// Get user roles from storage
		const roles = localStorage.getItem('roles');
		let userRoles = [];

		if (roles != null) {
			userRoles = roles.replace(/\"/g, '').replace(' ', '').split(',');

			// If role name changes this line will not work
			// TO-DO
			if (userRoles.indexOf('Admin') !== -1) {
				return true;
			}
		} else {
			return false;
		}
	}

	/**
 * Helper method that retrieves user roles
 * from storage, and checks if user is in
 * HR Admin role.
 */
	isHrAdmin(): boolean {
		// Get user roles from storage
		const roles = localStorage.getItem('roles');
		let userRoles = [];

		if (roles != null) {
			userRoles = roles.replace(/\"/g, '').replace(' ', '').split(',');
			// If role name changes this line will not work
			// TO-DO
			if (userRoles.indexOf('HRAdmin') !== -1) {
				return true;
			}
		} else {
			return false;
		}
	}
	isInRoles(rolename): boolean {
		// Get user roles from storage
		const roles = localStorage.getItem('roles');
		let userRoles = [];

		if (roles != null) {
			userRoles = roles.replace(/\"/g, '').replace(' ', '').split(',');
			// If role name changes this line will not work
			// TO-DO
			if (userRoles.indexOf(rolename) !== -1) {
				return true;
			}
		} else {
			return false;
		}
		return false;
	}
	
	/**
	/**
	 * Helper method that retrieves user roles
	 * from storage, and checks if user is in
	 * Admin role.
	 */
	isAppManagement(): boolean {
		// Get user roles from storage
		const roles = localStorage.getItem('roles');
		let userRoles = [];

		if (roles != null) {
			userRoles = roles.replace(/\"/g, '').replace(' ', '').split(',');

			// If role name changes this line will not work
			// TO-DO
			if (userRoles.indexOf('AppManagement') !== -1) {
				return true;
			}
		} else {
			return false;
		}
	}

	/**
	 * Error handler method that is being called
	 * if login http call fails at some point.
	 * @param error
	 */
	private handleError(error: any): Observable<any> {
		return throwError(error.error.ErrorDescription || 'Server error');
	}
	resetEmail(userEmail) {
		const url = `${this.baseUrl}/ResetPassword?email=${userEmail}`;
		return this.http.get<any>(url)
			.catch(this.handleError);
	}
	checkLogin(username) {
		const url = `${this.baseUrl}/checklogin?username=`+username;
		return this.http.get<any>(url)
			.catch(this.handleError);
	}
}
