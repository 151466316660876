import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/application-settings';
import { AppPropertiesService } from '../../app-properties.service';
import { DataService } from '../../data/data-service';
import { Calibration } from './models/calibration';

@Injectable()
export class CalibrationService {
	// private highPcCalibrationList: Calibration[];
	private calBottom = 0;
	private calTop = 0;
	baseUrl: string;
	
	public pcCalibrationList: Calibration[];
	

	constructor(
		private http: HttpClient,
		private appSettings: ApplicationSettings,
		private appPropertiesService: AppPropertiesService,
		private dataService: DataService) {
		this.baseUrl = this.appSettings.retrieveUrl();
	}

	getCalibrationData(pressureSwitch: string, macAddress: string): Observable<Calibration[]> {
		const par: string = '?pressureSwitch=' + pressureSwitch + '&macAddress=' + macAddress;
		const url: string = this.baseUrl + '/api/Calibration/List';

		console.log('Getting calibration data: ' + url + par);
		return this.http.get<any>(url + par);
	}

	calibrateValues(inValue: string, inSensor: string): number {
		let retVal = 0;

		console.log('calibrateValues(')
		console.log(this.pcCalibrationList)

		if (inSensor == 'Low') {
			if (this.pcCalibrationList != null) {
				this.pcCalibrationList.forEach(cal => {
					// tslint:disable-next-line:triple-equals
					if (cal.Pressure == 0) {
						this.calBottom = cal.PLCValue;
					}
					// tslint:disable-next-line:triple-equals
					if (cal.Pressure == 300) {
						this.calTop = cal.PLCValue;
					}
				});
			}

		} else {
			if (this.pcCalibrationList != null) {
				this.pcCalibrationList.forEach(cal => {
					// tslint:disable-next-line:triple-equals
					if (cal.Pressure == 0) {
						this.calBottom = cal.PLCValue;
					}
					// tslint:disable-next-line:triple-equals
					if (cal.Pressure == 10000) {
						this.calTop = cal.PLCValue;
					}
				});
			}
		}
		// console.log(this.calBottom);
		// todo this is a calibration error
		// tslint:disable-next-line:triple-equals
		if (inSensor == 'Low') {
			retVal = (+inValue - this.calBottom) / (this.calTop - this.calBottom) * (300 - 0);
		} else {
			retVal = (+inValue - this.calBottom) / (this.calTop - this.calBottom) * (10000 - 0);
		}

		if (retVal < 0) {
			retVal = 0;
		}

		return +retVal.toFixed(0);
	}

}
