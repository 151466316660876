import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PrinterIpService } from '../../shared/printer-ip.service';
import { BoxLabelService } from '../box-label-service';
import { AuthService } from './../../auth/auth.service';

@Component({
	templateUrl: './box-label.component.html',
	styleUrls: ['box-label.component.css']
})

export class BoxLabelComponent implements OnInit, OnDestroy, AfterViewInit {
	public page = 'Stock Parts 4 X 6';
	errorMessage: string;
	boxlabelForm: UntypedFormGroup;
	partsData = [];
	qtyTypes = ['ea', 'ft'];
	public Branch: string;

	constructor(
		private authService: AuthService,
		private fb: UntypedFormBuilder,
		private boxLabelService: BoxLabelService,
		private printerIpService: PrinterIpService) { }

	ngOnInit(): void {
		this.Branch = this.authService.getBranchId();

		this.boxlabelForm = this.fb.group({
			Id: '',
			PartNumber: ['', Validators.required],
			PartDescription: [{ value: '', disabled: true }, Validators.required],
			Upc: [{ value: '', disabled: true }, Validators.required],
			Quantity: ['', Validators.required],
			QtyType: ['', Validators.required],
			EclipseId: ['', Validators.required],
			LabelType: ['Box', Validators.required],
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: [1, Validators.required]
		});
	}

	public ngAfterViewInit(): void {

	}

	print(): void {
		if (this.boxlabelForm.valid) {
			const obj = Object.assign({}, this.boxlabelForm.getRawValue());
			this.boxLabelService.createBoxlabel(obj)
				.subscribe(_ => this.onSaveComplete()
					, (_: any) => this.errorMessage = 'Error while printing the label');

			this.boxLabelService.createBoxlabelGlPrinting(obj, this.Branch)
				.subscribe(_ => this.onSaveComplete()
					, (_: any) => this.errorMessage = 'Error while printing the label (GL Printing)');
		}
	}

	onSaveComplete(): void {
		//this.errorMessage = '';
		this.boxlabelForm.reset();
		this.boxlabelForm.patchValue({
			LabelType: 'Box',
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: 1
		});
	}

	ngOnDestroy(): void { }

	// tslint:disable-next-line:no-shadowed-variable
	public valueNormalizer = (text: Observable<string>) => text.map(_ => null);

	partChanged(partNumber: any) {
		const part = this.partsData.find(p => p.PartNumber === partNumber);

		if (part) {
			this.boxlabelForm.patchValue({
				Upc: part.Upc,
				EclipseId: part.EclipseId,
				PartDescription: part.PartDescription
			});
		}
	}

	printerIp(): string {
		return this.printerIpService.getIp(this.Branch);
	}

	resetError() : void {
		this.errorMessage = '';
	}
}
