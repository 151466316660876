import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { ICustomer } from '../customer-model/customer';
import { CustomerService } from '../customer-service';

@Component({
	templateUrl: './customer-edit.component.html',
	styleUrls: ['customer-edit.component.css']
})

export class CustomerEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit ';
	errorMessage: string;
	customerForm: UntypedFormGroup;
	customer: ICustomer;
	private sub: Subscription;
	public buttonPushed = false;

	constructor(private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private customerService: CustomerService) {
	}

	ngOnInit(): void {
		this.customerForm = this.fb.group({
			Id: '',
			Customer: '',
			Address1: '',
			Address2: '',
			City: '',
			Pr: '',
			PC: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getCustomer(id);
			}
		);
	}


	saveCustomer(con: boolean): void {
		this.buttonPushed = true;

		if (this.customerForm.valid) {
			const obj = Object.assign({ }, this.customer, this.customerForm.value);
			this.customerService.saveCustomer(obj)
				.subscribe((data) => this.onSaveComplete(con, data)
				, (error: any) => this.errorMessage = < any > error);
		}
	}
	onSaveComplete(con: boolean, data: any): void {
		if (con) {
			this.customerForm.reset();
			this.router.navigate(['/customers']);
		} else {
			if (data !== null && this.customer.Id === 0) {
				this.router.navigate(['/customer-details', data.Id]);
			}
		}

		this.buttonPushed = false;
	}

	getCustomer(id: number): void {
		this.customerService.getCustomer(id)
			.subscribe((obj: ICustomer) => this.onCustomerRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onCustomerRetrieved(customer: ICustomer): void {
		if (this.customerForm) {
			this.customerForm.reset();
		}
		this.customer = customer;
		if (this.customer.Id === 0) {
			this.page = 'Add Customer';
		} else {
			this.page = 'Edit Customer';
		}
		this.customerForm.patchValue({
			Id: this.customer.Id,
			Customer: this.customer.Customer,
			Address1: this.customer.Address1,
			Address2: this.customer.Address2,
			City: this.customer.City,
			Pr: this.customer.Pr,
			PC: this.customer.PC,
			CreatedDate: this.customer.CreatedDate,
			CreatedBy: this.customer.CreatedBy,
			UpdatedDate: this.customer.UpdatedDate,
			UpdatedBy: this.customer.UpdatedBy,
			});
		}

	ngOnDestroy(): void {
	}

	deleteCustomer(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.customer.Customer}?`)) {
			this.customerService.deleteCustomer(this.customer.Id)
				.subscribe(() => this.onSaveComplete(true, null)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/customers']);
	}
}
