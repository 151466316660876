import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IPartSearch } from '../Part-model/PartSearch';
import { IParts } from '../Part-model/Parts';
import { IPart } from '../part-model/part';
import { PartService } from '../part-service';

@Component({
	templateUrl: './part-edit.component.html',
	styleUrls: ['part-edit.component.css']
})

export class PartEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Parts';
	errorMessage: string;
	partForm: UntypedFormGroup;
	part: IPart;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public PartSearch: IPartSearch;
	public ListData: IParts;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private partService: PartService,
		) {
	}

	ngOnInit(): void {
		this.partForm = this.fb.group({
			Id: '',
			PartNumber: ['',[Validators.maxLength(250)]],
			PartDescription: ['',[Validators.maxLength(250)]],
			EclipseId: '',
			PartRootsId: '',
			BagQty: '',
			BoxQty: '',
			Upc: ['',[Validators.maxLength(50)]],
			BoxSize: ['',[Validators.maxLength(250)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getPart(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.partService.getRecordSet(this.PartSearch)
			.subscribe((obj: IParts) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IParts){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.part.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	savePart(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.partForm.valid) {
			const obj = Object.assign({ }, this.part, this.partForm.value);
			this.partService.savePart(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.partForm.reset();
			this.router.navigate(['/parts']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.part.Id === 0) {
			this.router.navigate(['/part-details', data.Id]);
		} 
		else if (this.part.Id != 0){
			this.getPart(this.part.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.part.Id)
				record = count;
		});

		this.getPart(this.ListData.data[record].Id)
	}

	getPart(id: number): void {
		this.partService.getPart(id)
			.subscribe((obj: IPart) => this.onPartRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onPartRetrieved(part: IPart): void {
		if (this.partForm) {
			this.partForm.reset();
		}
		this.part = part;
		if (this.part.Id === 0) {
			this.page = 'Add Parts';
		} else {
			this.page = 'Edit Parts';
		}

		this.partForm.patchValue({
			Id: this.part.Id,
			PartNumber: this.part.PartNumber,
			PartDescription: this.part.PartDescription,
			EclipseId: this.part.EclipseId,
			PartRootsId: this.part.PartRootsId,
			BagQty: this.part.BagQty,
			BoxQty: this.part.BoxQty,
			Upc: this.part.Upc,
			BoxSize: this.part.BoxSize,
			CreatedDate: this.part.CreatedDate,
			CreatedBy: this.part.CreatedBy,
			UpdatedDate: this.part.UpdatedDate,
			UpdatedBy: this.part.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deletePart(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.part.PartNumber}?`)) {
			this.partService.deletePart(this.part.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/parts']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Parts List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.PartSearch = this.partService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'PartNumber')
				this.PartSearch.PartNumber = i.Value;

			if (i.Item == 'PartDescription')
				this.PartSearch.PartDescription = i.Value;

			if (i.Item == 'EclipseId')
				this.PartSearch.EclipseId = i.Value;

			if (i.Item == 'PartRootsId')
				this.PartSearch.PartRootsId = i.Value;

			if (i.Item == 'BagQty')
				this.PartSearch.BagQty = i.Value;

			if (i.Item == 'BoxQty')
				this.PartSearch.BoxQty = i.Value;

			if (i.Item == 'Upc')
				this.PartSearch.Upc = i.Value;

			if (i.Item == 'BoxSize')
				this.PartSearch.BoxSize = i.Value;

			if (i.Item == 'orderByString')
				this.PartSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.PartSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
