import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IUsersearch } from 'src/app/admin/usersearches/usersearch-model/usersearch';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationSettings } from 'src/app/shared/application-settings';
import { LabelprintingService } from '../../labelprinting-service';
// import { PrinterIpService } from '../../shared/printer-ip.service';
import { Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';

const CHAT_URL = "ws://localhost:5001/chathub";
export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}


@Component({
	templateUrl: './non-stock.component.html',
	styleUrls: ['non-stock.component.css']
})

export class NonStockLabelComponent implements OnInit {
	public page = 'Non Stock Label';
	errorMessage: string;
	nonStockLabelForm: UntypedFormGroup;
	partsData = [];
	qtyTypes = ['ea', 'ft'];
	labelTypes = [
		{ label: '2 X 4', value: 'Package'},
		//{ label: '4 X 6', value: 'Box'},
	];
	public UserSearches: IUsersearch[];
	public Branch: string;
	// public PrinterName = '';
	public printerList=[];
	public LayoutList = ['regular', 'rotated'];
	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};
	public serverStatus = false;
	public printerAvailable = false;
	public serverStatusMessage= 'start local communications';
	public serverStatusMessageDef= 'start local communications';
	private subject: AnonymousSubject<MessageEvent>;
	public messages: Subject<Message>;
	
	public pageTitle = 'NonStock';
	constructor(
		private authService: AuthService,
		private fb: UntypedFormBuilder,
		private labelPrintingService: LabelprintingService,
		public applicationSettings: ApplicationSettings
		// private printerIpService: PrinterIpService
		) { 
			this.connectServer();
		}

	ngOnInit(): void {

		this.nonStockLabelForm = this.fb.group({
			Id: '',
			PartNumber: ['', Validators.required],
			PartDescription: ['', Validators.required],
			Upc: ['', Validators.required],
			Quantity: ['', Validators.required],
			QtyType: ['', Validators.required],
			LabelType: ['', Validators.required],
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: [1, Validators.required],
			Customer: [''],
			SalesOrder: [''],
			PrinterName: ['', Validators.required],
			Layout: ['regular']
		});
		this.getPrinterList();
	}
	public ngAfterViewInit(): void {
		// this.getUserSearchesForNonStockLabel();
	}
	getPrinterList() {
		let branchid = this.authService.getBranchId();
		this.labelPrintingService.getPrinterListData(branchid, '2x4')
			.subscribe(val => {
				if(val.length>0){
				this.printerList = val;
				this.nonStockLabelForm.patchValue({
					PrinterName: this.printerList[0].IP, 
				  });
				  this.printerAvailable = true;
				  this.serverStatusMessage = this.serverStatusMessageDef;
				}else{
					this.printerAvailable = false;
					this.serverStatusMessage = this.serverStatusMessageDef + ' Unable to find printer for 6x2 size';
				}
			});
	}
	print(): void {
		if (this.nonStockLabelForm.valid) {
			const obj = Object.assign({}, this.nonStockLabelForm.getRawValue());
			this.labelPrintingService.saveSearch(obj, this.pageTitle);
			this.labelPrintingService.createNonStockLabel(obj)
				.subscribe(val => {

					// let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
					this.trnsfrData = {
						source: 'labelprint client',
						content: [],
						message: 'Label Sent For Print',
						function: 'sendLabelForPrint'
					};
					// this.trnsfrData.content.push(url + val);
					this.trnsfrData.content.push(val);
					this.trnsfrData.content.push(obj.PrinterName);
					this.trnsfrData.content.push(obj.NumberOfLabels);
					this.messages.next(this.trnsfrData);
					this.onSaveComplete()
				}


					, (_: any) => this.errorMessage = 'Error while printing the label');

		}
	}

	onSaveComplete(): void {
		//this.errorMessage = '';
		// this.nonStockLabelForm.reset();
		// this.nonStockLabelForm.patchValue({
		// 	LabelType: '',
		// 	CreatedBy: this.authService.getUsername(),
		// 	NumberOfLabels: 1
		// });
		this.nonStockLabelForm.patchValue({
			Id: '',
			PartNumber: '',
			PartDescription: '',
			Upc: '',
			Quantity: '',
			QtyType: '',
			LabelType: 'Box',
			CreatedBy: this.authService.getUsername(),
			NumberOfLabels: 1,
			Customer: '',
			SalesOrder: ''	
		});
		// this.getUserSearchesForNonStockLabel();
	}
	// getUserSearchesForNonStockLabel() {
	// 	this.userSearchService.getUserSearchesByForm(this.pageTitle)
	// 		.subscribe((data) => {
	// 			this.UserSearches = data;
	// 			this.UserSearches.forEach(i => {
	// 				if (i.Item == 'PrinterName')
	// 					this.nonStockLabelForm.patchValue({
	// 						PrinterName: i.Value
	// 					});
	// 			});

	// 		}
	// 			, (error: any) => this.errorMessage = <any>error);
	// }

	resetError() : void {
		this.errorMessage = '';
	}
	//Websocket Communication Section Start//
	// receivePrinterList() {
	// 	this.trnsfrData = {
	// 		source: 'labelprint client',
	// 		content: [],
	// 		message: '',
	// 		function: 'requestPrinterList'
	// 	};
	// 	this.messages.next(this.trnsfrData);
	// }
	

	connectServer() {
		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			}
			)
		);
		//Response section from websocket Start//
		this.messages.subscribe(msg => {
			// if (msg.function == "receivePrinterList") {
			// 	this.printerList = msg.content
			// }
			// else
			 if (msg.function == "labelPrintResponse") {
				//alert(msg.message);
			}
			else {
				// this.received.push(msg);
				console.log("Response from websocket: " + msg.message);
			}
		});
		//Response section from websocket End//
	}
	public connect(url): AnonymousSubject<MessageEvent> {
		this.subject = this.create(url);
		return this.subject;
	}

 //Connecting websocket//
	private create(url): AnonymousSubject<MessageEvent> {
		let ws = new WebSocket(url);
		ws.onopen = openevent => {
			console.log("Successfully connected: " + url);
			this.serverStatus = true;
			this.trnsfrData = {
				source: 'labelprint client',
				content: [],
				message: 'register client',
				function: 'registerAngularClient'
			};
			this.messages.next(this.trnsfrData);
				// this.receivePrinterList();
		}

		let observable = new Observable((obs: Observer<MessageEvent>) => {
			ws.onmessage = obs.next.bind(obs);
			ws.onerror = obs.error.bind(obs);
			ws.onclose = obs.complete.bind(obs);
			return ws.close.bind(ws);
		});
		let observer = {
			error: null,
			complete: null,
			next: (data: Object) => {
				console.log('Message sent to websocket: ', data);
				if (ws.readyState === WebSocket.OPEN) {
					ws.send(JSON.stringify(data));
				}
				else {
					this.serverStatus = false;
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, observable);
	}
	reconnect() {
		this.connectServer();
	}
		//Websocket Communication Section End//
}
