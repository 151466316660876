import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IBinlabelcat} from './Binlabelcat-model/Binlabelcat';
import { IBinlabelcats } from './Binlabelcat-model/Binlabelcats';
import { IBinlabelcatSearch } from './Binlabelcat-model/BinlabelcatSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class BinlabelcatService {
	private baseUrl;
	public pageTitle = 'Binlabelcats List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Binlabelcats';
	}

	getBinlabelcat(id: number):  Observable<IBinlabelcat> {
		if (id === 0) {
			return of(this.initializeBinlabelcat());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IBinlabelcat>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveBinlabelcat(binlabelcat: IBinlabelcat): Observable<any> {
		if (binlabelcat.Id === 0) {
			return this.createBinlabelcat(binlabelcat);
		}
		return this.updateBinlabelcat(binlabelcat);
	}

	private createBinlabelcat(binlabelcat: IBinlabelcat): Observable<any> {
		binlabelcat.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/binlabelcatcreate', binlabelcat)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateBinlabelcat(binlabelcat: IBinlabelcat): Observable<IBinlabelcat> {
		return this.authHttp.put<IBinlabelcat>(this.baseUrl, binlabelcat)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteBinlabelcat(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeBinlabelcat(): IBinlabelcat {
		return {
			Id: 0,
			PLine: '',
			LabelTag: '',
			LabelGroup: '',
			NumberOfPages: 0,
			TotalPages: 0,
			Notes: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IBinlabelcat;
	}

	public getRecordSet(search: IBinlabelcatSearch): Observable<IBinlabelcats> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('PLine', search.PLine, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LabelTag', search.LabelTag, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LabelGroup', search.LabelGroup, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('NumberOfPages', search.NumberOfPages, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('TotalPages', search.TotalPages, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('PLine', search.PLine ? search.PLine : '')
			.set('LabelTag', search.LabelTag ? search.LabelTag : '')
			.set('LabelGroup', search.LabelGroup ? search.LabelGroup : '')
			.set('NumberOfPages', search.NumberOfPages ? search.NumberOfPages : '')
			.set('TotalPages', search.TotalPages ? search.TotalPages : '')
			.set('Notes', search.Notes ? search.Notes : '')
		};

		return this.authHttp.get<IBinlabelcats>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IBinlabelcats>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IBinlabelcatSearch { 
		let search: IBinlabelcatSearch = {
			Id: 1,
			PLine: '',
			LabelTag: '',
			LabelGroup: '',
			NumberOfPages: '',
			TotalPages: '',
			Notes: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IBinlabelcatSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('PLine', search.PLine ? search.PLine : '')
			.set('LabelTag', search.LabelTag ? search.LabelTag : '')
			.set('LabelGroup', search.LabelGroup ? search.LabelGroup : '')
			.set('NumberOfPages', search.NumberOfPages ? search.NumberOfPages : '')
			.set('TotalPages', search.TotalPages ? search.TotalPages : '')
			.set('Notes', search.Notes ? search.Notes : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getBinLabelCatForList(): Observable<any> {
		const url = `${ this.baseUrl}/getbinlabelcatforlist`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
}