
<div class='panel panel-primary animaPlaceholder' *ngIf = 'labelpropertyconfig'>
	<div class='panel-heading'>
		<span *ngIf = 'labelpropertyconfig' >{{ page }} :{{ labelpropertyconfig.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='labelpropertyconfigForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Header1</label>
							<div class="col-md-10 flex-container">
								<button class="btn btn-primary btn-sm" (click)="openLookUpList('Header')">A</button>
								<select class='form-control' formControlName='Header1'>
									<option *ngFor="let item of headerList" [value]="item.Value">{{item.Value}}
									</option>
								</select>
							</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Header2</label>
						<div class="col-md-10 flex-container">
							<button class="btn btn-primary btn-sm" (click)="openLookUpList('Header')">A</button>
							<select class='form-control' formControlName='Header2'>
								<option *ngFor="let item of headerList" [value]="item.Value">{{item.Value}}
								</option>
							</select>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Description</label>
						<div class="col-md-10 flex-container">
							<button class="btn btn-primary btn-sm" (click)="openLookUpList('Description')">A</button>
							<select class='form-control' formControlName='Description'>
								<option *ngFor="let item of descriptionList" [value]="item.Value">{{item.Value}}
								</option>
							</select>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Expression Like</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ExpressionLike' />
							<span *ngIf = "labelpropertyconfigForm.controls['ExpressionLike'].invalid  && labelpropertyconfigForm.controls['ExpressionLike'].errors.maxlength" class="text-danger">
									Only 256 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Expression Equals</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ExpressionEquals' />
							<span *ngIf = "labelpropertyconfigForm.controls['ExpressionEquals'].invalid  && labelpropertyconfigForm.controls['ExpressionEquals'].errors.maxlength" class="text-danger">
									Only 256 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Order</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='Order' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveLabelpropertyconfig(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!labelpropertyconfigForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveLabelpropertyconfig(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!labelpropertyconfigForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveLabelpropertyconfig(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!labelpropertyconfigForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!labelpropertyconfigForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteLabelpropertyconfig()'
								style='width: 150px; margin-right:10px'
								[disabled]='!labelpropertyconfigForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='labelpropertyconfig'>{{labelpropertyconfig.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='labelpropertyconfig'>{{labelpropertyconfig.UpdatedDate}}</label></span>
					</div>
				</div>
				<div>
					<button class="btn btn-primary" (click)="getWebPartInfo()">Get Web Part Info</button>
				</div>
				<div [innerHTML]="webPartInfo">
			</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>