
<div class='panel panel-primary animaPlaceholder' *ngIf = 'vprodinvent'>
	<div class='panel-heading'>
		<span *ngIf = 'vprodinvent' >{{ page }} :{{ vprodinvent.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='vprodinventForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Product Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='ProductId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Branch Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='BranchId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Loc Type</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LocType' />
							<span *ngIf = "vprodinventForm.controls['LocType'].invalid  && vprodinventForm.controls['LocType'].errors.maxlength" class="text-danger">
									Only 255 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Warehouse</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Warehouse' />
							<span *ngIf = "vprodinventForm.controls['Warehouse'].invalid  && vprodinventForm.controls['Warehouse'].errors.maxlength" class="text-danger">
									Only 255 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> On Hand Qty</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='OnHandQty' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveVprodinvent(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!vprodinventForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveVprodinvent(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!vprodinventForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml' 
									[disabled]='buttonPushed'
									(click)='saveVprodinvent(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!vprodinventForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!vprodinventForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteVprodinvent()'
								style='width: 150px; margin-right:10px'
								[disabled]='!vprodinventForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='vprodinvent'>{{vprodinvent.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='vprodinvent'>{{vprodinvent.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>