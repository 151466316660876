import { Component } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
//import { RoleService } from '../role-service/role.service';
import { Router } from '@angular/router';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {
	showNav = false;
	constructor(public auth: AuthService, private router: Router) {}

	toggleNav(): void {
		this.showNav = !this.showNav;
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/all-labels`])
		);
		window.open(url, '_blank');
	}
}
