﻿<h1>Elongation Calculation</h1>
<table>
    <tr>
        <td>Elongation Under Test Pressure</td>
        <td><input [(ngModel)]="hoseDetail.ECPressure"  (ngModelChange)="changeECPressure($event)" placeholder="" class="form-control  input-list"></td>
        <td>&nbsp;Pass / Fail&nbsp;</td>
        <td>
            <select [(ngModel)]="hoseDetail.ECPressureResults" class="form-control  input-list">
                <option *ngFor="let o of passFail" [value]="o.value">{{o.name}}</option>
            </select>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorECPressure == false">
                {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>Elongation On Immediate Release</td>
        <td><input [(ngModel)]="hoseDetail.ECImmediateRelease" (ngModelChange)="changeImmediateRelease($event)"  placeholder="" class="form-control  input-list"></td>
        <td>&nbsp;Pass / Fail&nbsp;</td>
        <td>
            <select [(ngModel)]="hoseDetail.ECImmediateReleaseResult" class="form-control  input-list">
                <option *ngFor="let o of passFail" [value]="o.value">{{o.name}}</option>
            </select>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorImmediateRelease == false">
                {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
    <tr>
        <td>Permanent Elongation</td>
        <td><input [(ngModel)]="hoseDetail.ECPermanentElongation"  (ngModelChange)="changePermanentElongation($event)" placeholder="" class="form-control  input-list"></td>
        <td>&nbsp;Pass / Fail&nbsp;</td>
        <td>
            <select [(ngModel)]="hoseDetail.ECPermanentElongationResult" class="form-control  input-list">
                <option *ngFor="let o of passFail" [value]="o.value">{{o.name}}</option>
            </select>
        </td>
        <td>
            <span class="alert alert-warning" *ngIf="errorPermanentElongation == false">
                {{appPropertiesService.errorMessageInteger}}
            </span>
        </td>
    </tr>
</table>
