import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IHose } from '../hose-model/hose';


@Component({
	selector: 'http-visual-inspection'
	, templateUrl: './visual-inspection.html'
})

export class VisualInspection implements OnInit {
	@Input() hoseDetail: IHose;
	@Input()
	public set triggerUpdate(val: number) {
		this._triggerUpdate = val;
		if (this._triggerUpdate > 1) {
			this.setUpValues();
		}
	}
	@Output() updateHoseDetail: EventEmitter<IHose> = new EventEmitter<IHose>();

	visualInspectionCover: Options;
	visualInspectionCoverOptions: Options[] = this.setRBList();

	visualInspectionEnd1: Options;
	visualInspectionEnd1Options: Options[] = this.setRBList();

	visualInspectionEnd2: Options;
	visualInspectionEnd2Options: Options[] = this.setRBList();

	visualInspectionTube: Options;
	visualInspectionTubeOptions: Options[] = this.setRBList();

	visualInspectionResult: Options;
	visualInspectionResultOptions: Options[] = this.setRBList();

	visualInspectionOverall: Options;
	visualInspectionOverallOptions: Options[] = this.setRBList();

	private _triggerUpdate: number;

	getViCover(optionid: number): void {
		this.visualInspectionCover = this.visualInspectionCoverOptions.filter((item) => item.id === optionid)[0];
		this.hoseDetail.VICover = this.visualInspectionCover.name;
		this.updateHoseDetail.emit(this.hoseDetail);

		// console.log('VI Cover: ' + this.visualInspectionCover.name);
	}

	getViEnd1(optionid: number): void {
		this.visualInspectionEnd1 = this.visualInspectionEnd1Options.filter((item) => item.id === optionid)[0];
		this.hoseDetail.VIEnd1 = this.visualInspectionEnd1.name;
		this.updateHoseDetail.emit(this.hoseDetail);
	}

	getViEnd2(optionid: number): void {
		this.visualInspectionEnd2 = this.visualInspectionEnd2Options.filter((item) => item.id === optionid)[0];
		this.hoseDetail.VIEnd2 = this.visualInspectionEnd2.name;
		this.updateHoseDetail.emit(this.hoseDetail);
	}

	getViTube(optionid: number): void {
		this.visualInspectionTube = this.visualInspectionTubeOptions.filter((item) => item.id === optionid)[0];
		this.hoseDetail.VITube = this.visualInspectionTube.name;
		this.updateHoseDetail.emit(this.hoseDetail);
	}

	getViResult(optionid: number): void {
		this.visualInspectionResult = this.visualInspectionResultOptions.filter((item) => item.id === optionid)[0];
		this.hoseDetail.VIResult = this.visualInspectionResult.name;
		this.updateHoseDetail.emit(this.hoseDetail);
	}

	getViOverall(optionid: number): void {
		this.visualInspectionOverall = this.visualInspectionOverallOptions.filter((item) => item.id === optionid)[0];
		this.hoseDetail.OverallResult = this.visualInspectionOverall.name;
		this.updateHoseDetail.emit(this.hoseDetail);
	}

	constructor() {
		// console.log('VisualInspection::constructor');

	}

	setUpValues(): void {
		// console.log('Set up values called: triggerUpdate: ' + this._triggerUpdate.toString());
		this.visualInspectionCover = new Options(-1, '');
		this.visualInspectionEnd1 = new Options(-1, '');
		this.visualInspectionEnd2 = new Options(-1, '');
		this.visualInspectionTube = new Options(-1, '');
		this.visualInspectionResult = new Options(-1, '');
		this.visualInspectionOverall = new Options(-1, '');

		if (this.hoseDetail === undefined) {
			// console.log('VisualInspection::setUpValues  hoseDetail is undefined.');
		} else {
			// console.log('VisualInspection::setUpValues  hoseDetail has values.');
			this.visualInspectionCover = this.setOptionValue(this.hoseDetail.VICover);
			this.visualInspectionEnd1 = this.setOptionValue(this.hoseDetail.VIEnd1);
			this.visualInspectionEnd2 = this.setOptionValue(this.hoseDetail.VIEnd2);
			this.visualInspectionTube = this.setOptionValue(this.hoseDetail.VITube);
			this.visualInspectionResult = this.setOptionValue(this.hoseDetail.VIResult);
			this.visualInspectionOverall = this.setOptionValue(this.hoseDetail.OverallResult);
		}
	}

	ngOnInit(): void {
		// console.log('VisualInspection::ngOnInit');
		this.setUpValues();
	}

	setOptionValue(str: string): Options {
		let op: Options;
		if (str === 'Pass') {
			op = new Options(1, 'Pass');
		} else if (str === 'Fail') {
			op = new Options(0, 'Fail');
		} else {
			op = new Options(-1, '');
		}
		return op;
	}

	setRBList(): Options[] {
		let op: Options[] = [
			new Options(1, 'Pass'),
			new Options(0, 'Fail')
		];
		return op;
	}

	onblur(): void {
		this.updateHoseDetail.emit(this.hoseDetail);
	}
}

export class Options {
	constructor(public id: number, public name: string) { }
}
