
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/branche-details/0']" style='width: auto;'>Add New Branch</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='showHideSearchClick()'>{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: auto;' (click)='toExcel()'>To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize'  style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Branch Name</label>
				<input type='text' class='form-control block-element' placeholder=' Branch Name' [(ngModel)]='BranchName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> City</label>
				<input type='text' class='form-control block-element' placeholder=' City' [(ngModel)]='City' />
			</div>
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Branch Number</label>
				<input type='text' class='form-control block-element' placeholder=' Branch Number' [(ngModel)]='BranchNumber' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Business Number</label>
				<input type='text' class='form-control block-element' placeholder=' Business Number' [(ngModel)]='BusinessNumber' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Toll Free Number</label>
				<input type='text' class='form-control block-element' placeholder=' Toll Free Number' [(ngModel)]='TollFreeNumber' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Address Line</label>
				<input type='text' class='form-control block-element' placeholder=' Address Line' [(ngModel)]='AddressLine' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Address Line2</label>
				<input type='text' class='form-control block-element' placeholder=' Address Line2' [(ngModel)]='AddressLine2' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Postal Code</label>
				<input type='text' class='form-control block-element' placeholder=' Postal Code' [(ngModel)]='PostalCode' />
			</div> -->
		
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Province</label>
				<input type='text' class='form-control block-element' placeholder=' Province' [(ngModel)]='Province' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Country</label>
				<input type='text' class='form-control block-element' placeholder=' Country' [(ngModel)]='Country' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div> -->
			
			<!-- <div class='col-md-4'>
				<label class='inline-label'>fax</label>
				<input type='text' class='form-control block-element' placeholder='fax' [(ngModel)]='fax' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> U P C</label>
				<input type='text' class='form-control block-element' placeholder=' U P C' [(ngModel)]='UPC' />
			</div> -->
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm ' style='width: 110px' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm ' style='width: 110px' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/branche-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='BranchName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Branch Name</th>
		<td mat-cell *matCellDef='let e'>{{e.BranchName}}</td>
	</ng-container>
	<ng-container matColumnDef='BranchNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Branch Number</th>
		<td mat-cell *matCellDef='let e'>{{e.BranchNumber}}</td>
	</ng-container>
	<ng-container matColumnDef='BusinessNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Business Number</th>
		<td mat-cell *matCellDef='let e'>{{e.BusinessNumber}}</td>
	</ng-container>
	<ng-container matColumnDef='TollFreeNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Toll Free Number</th>
		<td mat-cell *matCellDef='let e'>{{e.TollFreeNumber}}</td>
	</ng-container>
	<ng-container matColumnDef='City'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> City</th>
		<td mat-cell *matCellDef='let e'>{{e.City}}</td>
	</ng-container>
	<ng-container matColumnDef='Province'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Province</th>
		<td mat-cell *matCellDef='let e'>{{e.Province}}</td>
	</ng-container>
	
	<ng-container matColumnDef='fax'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'>fax</th>
		<td mat-cell *matCellDef='let e'>{{e.fax}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>