import { Injectable } from '@angular/core';

@Injectable()
export class PrinterIpService {
	constructor() {
    }
    
    getIp(branch: string): string {
        let computer = '200';

        if (branch=='1') {
            //Delta printers 103, 104
            //computer='50';
            computer='107';
        }
        else if (branch=='5'){
            //Saskatoon  printer 200
            computer='135';
        }
        else if (branch=='21'){
            //foot hills  printer 91, 92
            computer='90';
        }
        else if (branch=='31'){
            //foot hills  printer 91, 92
            computer='90';
        }
        else if (branch=='27'){
            //Montreal
            //computer='89';
            computer='90';
        }
        else if (branch=='36'){
            //foot hills  printer 91, 92
            computer='90';
        }
        
        return '192.168.' + branch + '.' + computer;
    }


}
