import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { CustomerPlanrecordListComponent } from './customerplanrecord-list/customerplanrecord-list.component';
import { CustomerPlanrecordEditComponent } from './customerplanrecord-edit/customerplanrecord-edit.component';
import { CustomerPlanrecordHelpComponent } from './customerplanrecord-help/customerplanrecord-help.component';
import { CustomerPlanrecordService } from './customerplanrecord-service';
import { CustomerPlanrecordUploadComponent } from './customerplanrecord-upload/customerplanrecord-upload.component';
import { CustomerPlanrecordExportComponent } from './customerplanrecord-export/customerplanrecord-export.component';

@NgModule({
	declarations: [
		CustomerPlanrecordListComponent
		, CustomerPlanrecordEditComponent
		, CustomerPlanrecordHelpComponent
		,CustomerPlanrecordUploadComponent
		,CustomerPlanrecordExportComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'customerplanrecords', component: CustomerPlanrecordListComponent },
			{ path: 'customerplanrecord-details/:Id', component: CustomerPlanrecordEditComponent },
			{ path: 'customerplanrecord-help', component: CustomerPlanrecordHelpComponent },
			{ path: 'customerplanrecord-upload', component: CustomerPlanrecordUploadComponent },
			{ path: 'customerplanrecord-export', component: CustomerPlanrecordExportComponent },
		])
	],
	providers: [
		CustomerPlanrecordService
	]
})
export class CustomerPlanrecordModule { }
