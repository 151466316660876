import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { LabelpropertyconfigService } from '../labelpropertyconfig-service';

@Component({
  selector: 'app-labelpropertyresult',
  templateUrl: './labelpropertyresult.component.html',
  styleUrls: ['./labelpropertyresult.component.css']
})
export class LabelpropertyresultComponent implements OnInit {

  public errorMessage = '';
  displayedColumns: string[] = ['PrimaryDimension', 'SecondaryDimension', 'PrimaryDimensionValue', 'SecondaryDimensionValue', 'PartNumber','EclipseId'];
	public dataSource: any;
	public progressStatus=false;
	private sub: Subscription;

  constructor(
    private labelpropertyconfigService:LabelpropertyconfigService
    , private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  	this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];

				console.log(id);
        this.getResultExecutePropertys(id);
			}
		);
  }
	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
  getResultExecutePropertys(id) {
		this.labelpropertyconfigService.getResultExecutePropertys(id).subscribe(response => {
			this.dataSource = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
}
