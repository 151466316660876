import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ILabelpropertyconfig } from '../labelpropertyconfig-model/labelpropertyconfig'; 

import { Subscription } from 'rxjs/Subscription';
import { LabelpropertyconfigService } from '../labelpropertyconfig-service';
import { ILabelpropertyconfigs } from '../labelpropertyconfig-model/labelpropertyconfigs';
import { ILabelpropertyconfigSearch } from '../labelpropertyconfig-model/labelpropertyconfigSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { LookuplistService} from '../../lookuplists/lookuplist-service' 

@Component({
	templateUrl: './labelpropertyconfig-edit.component.html',
	styleUrls: ['labelpropertyconfig-edit.component.css']
})

export class LabelpropertyconfigEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Label Property Configs';
	errorMessage: string;
	labelpropertyconfigForm: UntypedFormGroup;
	labelpropertyconfig: ILabelpropertyconfig;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public LabelpropertyconfigSearch: ILabelpropertyconfigSearch;
	public ListData: ILabelpropertyconfigs;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;
    public headerList:any;
	public descriptionList: any;
    webPartInfo:any;
	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private labelpropertyconfigService: LabelpropertyconfigService
		, private lookuplistService: LookuplistService
		) {
	}

	ngOnInit(): void {
		this.labelpropertyconfigForm = this.fb.group({
			Id: '',
			Header1: ['',[Validators.maxLength(256),Validators.required]],
			Header2: ['',[Validators.maxLength(256),Validators.required]],
			ExpressionLike: ['',[Validators.maxLength(256)]],
			ExpressionEquals: ['',[Validators.maxLength(256)]],
			Order: '',
			Description: ['',[Validators.maxLength(256),Validators.required]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getLabelpropertyconfig(id);
				this.progressStatus = true;
			}
		);
		this.getHeaderList();
		this.getDescriptionList();
	}

	public getSelectedRecordSet():void{
		this.labelpropertyconfigService.getRecordSet(this.LabelpropertyconfigSearch)
			.subscribe((obj: ILabelpropertyconfigs) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ILabelpropertyconfigs){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.labelpropertyconfig.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveLabelpropertyconfig(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.labelpropertyconfigForm.valid) {
			const obj = Object.assign({ }, this.labelpropertyconfig, this.labelpropertyconfigForm.value);
			this.labelpropertyconfigService.saveLabelpropertyconfig(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.labelpropertyconfigForm.reset();
			this.router.navigate(['/labelpropertyconfigs']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.labelpropertyconfig.Id === 0) {
			this.router.navigate(['/labelpropertyconfig-details', data.Id]);
		} 
		else if (this.labelpropertyconfig.Id != 0){
			this.getLabelpropertyconfig(this.labelpropertyconfig.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.labelpropertyconfig.Id)
				record = count;
		});

		this.getLabelpropertyconfig(this.ListData.data[record].Id)
	}

	getLabelpropertyconfig(id: number): void {
		this.labelpropertyconfigService.getLabelpropertyconfig(id)
			.subscribe((obj: ILabelpropertyconfig) => this.onLabelpropertyconfigRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onLabelpropertyconfigRetrieved(labelpropertyconfig: ILabelpropertyconfig): void {
		if (this.labelpropertyconfigForm) {
			this.labelpropertyconfigForm.reset();
		}
		this.labelpropertyconfig = labelpropertyconfig;
		if (this.labelpropertyconfig.Id === 0) {
			this.page = 'Add Label Property Configs';
		} else {
			this.page = 'Edit Label Property Configs';
		}

		this.labelpropertyconfigForm.patchValue({
			Id: this.labelpropertyconfig.Id,
			Header1: this.labelpropertyconfig.Header1,
			Header2: this.labelpropertyconfig.Header2,
			ExpressionLike: this.labelpropertyconfig.ExpressionLike,
			ExpressionEquals: this.labelpropertyconfig.ExpressionEquals,
			Order: this.labelpropertyconfig.Order,
			Description:this.labelpropertyconfig.Description,
			CreatedDate: this.labelpropertyconfig.CreatedDate,
			CreatedBy: this.labelpropertyconfig.CreatedBy,
			UpdatedDate: this.labelpropertyconfig.UpdatedDate,
			UpdatedBy: this.labelpropertyconfig.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteLabelpropertyconfig(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.labelpropertyconfig.Header1}?`)) {
			this.labelpropertyconfigService.deleteLabelpropertyconfig(this.labelpropertyconfig.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/labelpropertyconfigs']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Label Property Configs List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.LabelpropertyconfigSearch = this.labelpropertyconfigService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Header1')
				this.LabelpropertyconfigSearch.Header1 = i.Value;

			if (i.Item == 'Header2')
				this.LabelpropertyconfigSearch.Header2 = i.Value;

			if (i.Item == 'ExpressionLike')
				this.LabelpropertyconfigSearch.ExpressionLike = i.Value;

			if (i.Item == 'ExpressionEquals')
				this.LabelpropertyconfigSearch.ExpressionEquals = i.Value;

			if (i.Item == 'Order')
				this.LabelpropertyconfigSearch.Order = i.Value;

			if (i.Item == 'orderByString')
				this.LabelpropertyconfigSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.LabelpropertyconfigSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	getHeaderList() {
		this.lookuplistService.getLookupListByForm('labelpropertyconfigs', 'Header').subscribe(response => {
			this.headerList = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
	getDescriptionList() {
		this.lookuplistService.getLookupListByForm('labelpropertyconfigs', 'Description').subscribe(response => {
			this.descriptionList = response;
		}
			, (error: any) => this.errorMessage = <any>error);
	}
	
	public openLookUpList(value)
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/lookuplist-details`,0,'labelpropertyconfigs',value])
		);
		  window.open(url,'_blank');
	 }
	 getWebPartInfo(){
		if(this.labelpropertyconfigForm.value.ExpressionLike!='' || this.labelpropertyconfigForm.value.ExpressionEquals!=''){
			let exprval='';
			if(this.labelpropertyconfigForm.value.ExpressionLike!=''){
				exprval=this.labelpropertyconfigForm.value.ExpressionLike;
			}
			else{
				exprval=this.labelpropertyconfigForm.value.ExpressionEquals;
			}
			this.labelpropertyconfigService.getWebPartInfo(exprval).subscribe(response => {
				this.webPartInfo=response;
			}
				, (error: any) => this.errorMessage = <any>error);
		}
		else{
			alert("Need to have a value in ExpressionLike or ExpressionEquals!");
		}

	 }
}
