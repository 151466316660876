import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { DocumentService } from '../document-service';
import { IDocumentSearch } from '../document-model/documentsearch';
import { IDocuments } from '../document-model/documents';

@Component({
	templateUrl: './whmis-document-list.component.html',
	styleUrls: ['./whmis-document-list.component.css']
})

export class WhmisDocumentListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Name', 'Description','RealFileName'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public Name = '';
	public Description = '';
	public FileName = '';
	public DocumentType = '';
	public RealFileName = '';

	public pageTitle = 'WHMIS Document List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;

	public progressStatus=true;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private _appService: DocumentService) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x = +localStorage.getItem('rows');
		if (x > 0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10'; }
		if (localStorage.getItem('showHideSearch') === 'true') {
			this.showHideSearch = true;
			this.SearchText = 'Hide Filters';
		}
		else {
			this.showHideSearch = false;
			this.SearchText = 'Show Filters';
		}
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm("Whmis Documents List")
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);

				
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.Name = i.Value;

			if (i.Item == 'Description')
				this.Description = i.Value;

			if (i.Item == 'FileName')
				this.FileName = i.Value;

			if (i.Item == 'DocumentType')
				this.DocumentType = i.Value;

			if (i.Item == 'RealFileName')
				this.RealFileName = i.Value;

			if (i.Item == 'orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value) === 'asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(() => this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
		this.dataSource = null;
		this.recordTotal = 0;
		let search: IDocumentSearch = this._appService.getFreshSearch();

		search.Name = this.Name;
		search.Description = this.Description;
		search.FileName = this.FileName;
		search.DocumentType = "WHMIS";
		search.RealFileName = this.RealFileName;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';
		let pageName="Whmis Documents List";
		this._appService.getRecordSet(search,pageName).subscribe((obj: IDocuments) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = <any>error;
			});
			this.progressStatus = false;
	}

	private onSelectedRecordSetReceived(obj: IDocuments) {
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.Name = '';
		this.Description = '';
		this.FileName = '';
		this.DocumentType = '';
		this.RealFileName = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError(): void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		if (this.showHideSearch) {
			this.showHideSearch = false;
			localStorage.setItem('showHideSearch', 'false');
			this.SearchText = 'Show Filters';
		}
		else {
			this.showHideSearch = true;
			localStorage.setItem('showHideSearch', 'true');
			this.SearchText = 'Hide Filters';
		}
	}
	searchAndHideClick() {
		this.skip = 0;
		this.getData();
		setTimeout(() => {
			this.showHideSearchClick();
		}, 500);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/whims-document`])
		);
		window.open(url, '_blank');
	}
	public toExcel() {
		let search: IDocumentSearch = this._appService.getFreshSearch();

		search.Name = this.Name;
		search.Description = this.Description;
		search.FileName = this.FileName;
		search.DocumentType = "WHMIS";
		search.RealFileName = this.RealFileName;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.toExcel(search).subscribe(
			retVal => {
				this.excelFile = retVal;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}
	public hideFileLink() {
		this.excelFile = "";
	}
	public openExcelFile() {
		window.open(`${this.applicationSettings.retrieveUrl()}${this.excelFile}`);
	}
	openFile(filename) {
		console.log('report:' + filename);
		let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/documents/';

		window.open(url + filename, "_blank");
		console.log('pdf created:' + filename)
		//this.buttonPushed=false;

	}
}