
<div class='panel panel-primary animaPlaceholder' *ngIf = 'part'>
	<div class='panel-heading'>
		<span *ngIf = 'part' >{{ page }} :{{ part.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='partForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Part Number</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='PartNumber' />
							<span *ngIf = "partForm.controls['PartNumber'].invalid  && partForm.controls['PartNumber'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Part Description</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='PartDescription' />
							<span *ngIf = "partForm.controls['PartDescription'].invalid  && partForm.controls['PartDescription'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Eclipse Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='EclipseId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Part Roots Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='PartRootsId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Bag Qty</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='BagQty' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Box Qty</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='BoxQty' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Upc</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Upc' />
							<span *ngIf = "partForm.controls['Upc'].invalid  && partForm.controls['Upc'].errors.maxlength" class="text-danger">
									Only 50 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Box Size</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='BoxSize' />
							<span *ngIf = "partForm.controls['BoxSize'].invalid  && partForm.controls['BoxSize'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='savePart(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!partForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='savePart(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!partForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='savePart(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!partForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!partForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deletePart()'
								style='width: 150px; margin-right:10px'
								[disabled]='!partForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='part'>{{part.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='part'>{{part.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>