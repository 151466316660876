import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IVprodinvent } from '../vprodinvent-model/vprodinvent';
import { Subscription } from 'rxjs/Subscription';
import { IVprodinventSearch } from '../Vprodinvent-model/VprodinventSearch';
import { IVprodinvents } from '../Vprodinvent-model/Vprodinvents';
import { VprodinventService } from '../vprodinvent-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Component({
	templateUrl: './vprodinvent-edit.component.html',
	styleUrls: ['vprodinvent-edit.component.css']
})

export class VprodinventEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Product Inventory';
	errorMessage: string;
	vprodinventForm: UntypedFormGroup;
	vprodinvent: IVprodinvent;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public VprodinventSearch: IVprodinventSearch;
	public ListData: IVprodinvents;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private vprodinventService: VprodinventService,
		) {
	}

	ngOnInit(): void {
		this.vprodinventForm = this.fb.group({
			Id: '',
			ProductId: '',
			BranchId: '',
			LocType: ['',[Validators.maxLength(255)]],
			OnHandQty: '',
			Warehouse:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getVprodinvent(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.vprodinventService.getRecordSet(this.VprodinventSearch)
			.subscribe((obj: IVprodinvents) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IVprodinvents){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.vprodinvent.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveVprodinvent(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.vprodinventForm.valid) {
			const obj = Object.assign({ }, this.vprodinvent, this.vprodinventForm.value);
			this.vprodinventService.saveVprodinvent(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.vprodinventForm.reset();
			this.router.navigate(['/vprodinvents']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.vprodinvent.Id === 0) {
			this.router.navigate(['/vprodinvent-details', data.Id]);
		} 
		else if (this.vprodinvent.Id != 0){
			this.getVprodinvent(this.vprodinvent.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.vprodinvent.Id)
				record = count;
		});

		this.getVprodinvent(this.ListData.data[record].Id)
	}

	getVprodinvent(id: number): void {
		this.vprodinventService.getVprodinvent(id)
			.subscribe((obj: IVprodinvent) => this.onVprodinventRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onVprodinventRetrieved(vprodinvent: IVprodinvent): void {
		if (this.vprodinventForm) {
			this.vprodinventForm.reset();
		}
		this.vprodinvent = vprodinvent;
		if (this.vprodinvent.Id === 0) {
			this.page = 'Add Product Inventory';
		} else {
			this.page = 'Edit Product Inventory';
		}

		this.vprodinventForm.patchValue({
			Id: this.vprodinvent.Id,
			ProductId: this.vprodinvent.ProductId,
			BranchId: this.vprodinvent.BranchId,
			LocType: this.vprodinvent.LocType,
			OnHandQty: this.vprodinvent.OnHandQty,
			Warehouse:this.vprodinvent.Warehouse,
			CreatedDate: this.vprodinvent.CreatedDate,
			CreatedBy: this.vprodinvent.CreatedBy,
			UpdatedDate: this.vprodinvent.UpdatedDate,
			UpdatedBy: this.vprodinvent.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteVprodinvent(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.vprodinvent.ProductId}?`)) {
			this.vprodinventService.deleteVprodinvent(this.vprodinvent.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/vprodinvents']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Product Inventory List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.VprodinventSearch = this.vprodinventService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'ProductId')
				this.VprodinventSearch.ProductId = i.Value;

			if (i.Item == 'BranchId')
				this.VprodinventSearch.BranchId = i.Value;

			if (i.Item == 'LocType')
				this.VprodinventSearch.LocType = i.Value;

			if (i.Item == 'OnHandQty')
				this.VprodinventSearch.OnHandQty = i.Value;

			if (i.Item == 'orderByString')
				this.VprodinventSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.VprodinventSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
