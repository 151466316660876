<div class="panel panel-primary">
    <div class="panel-heading">
        Custom Avery Labels
    </div>
    <div class="panel-body">
        <div class='row'>
            <div class='col-md-12'>
                <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                    <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input (change)="onFileSelected($event)" type="file" [(ngModel)]="fileNmShowrmlbl" />
            </div>
            <div class="col-md-3" *ngIf="shwrmExcelLink">
                <a href="javascript:void(0)" (click)="openExcelFile()">Download excel</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="margin-top: 20px;" *ngIf="uploadstatus">
                <div class="col-md-1"><button class="btn btn-primary" (click)="insertFileData()" [disabled]="progressStatus">Proceed</button></div>
                <div class="col-md-1"><button class="btn btn-primary" (click)="reupload()" [disabled]="progressStatus" >Re-upload</button></div>
            </div>
        </div>
        <div class="row" *ngIf="uploadstatus">
            <div class="col-md-12" style="margin-top: 20px;">
                <table>
                    <tr>
                        <th>Part Number</th>
                        <th>ECID</th>
                    </tr>
                    <tr *ngFor="let i of uploaddata">
                        <td>{{i.PartNumber}}</td>
                        <td>{{i.ECID}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>