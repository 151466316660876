import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hydraulicfittingscategorie-menu',
  templateUrl: './hydraulicfittingscategorie-menu.component.html',
  styleUrls: ['./hydraulicfittingscategorie-menu.component.css']
})
export class HydraulicfittingscategoriemenuComponent implements OnInit {

  public errorMessage = '';

  constructor() { }

  ngOnInit(): void {
  }
  
    resetError() : void {
      this.errorMessage = '';
    }
}
