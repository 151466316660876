import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { ICustomerconcern } from "./Customerconcern-model/Customerconcern";
import { ICustomerconcerns } from "./Customerconcern-model/Customerconcerns";
import { ICustomerconcernSearch } from "./Customerconcern-model/CustomerconcernSearch";
import { IUsersearch } from "../admin/usersearches/usersearch-model/usersearch";
import { UsersearchService } from "../admin/usersearches/usersearch-service";
import { ApplicationSettings } from "../shared/application-settings";
import { ColumnManagement } from "./customerconcern-model/columnmanagement";
import { CustomerConcernsHistoryDto } from "./customerconcern-model/customerconcernHistoryDto";
import { CustomerConcernsFileDto } from "./customerconcern-model/customerconcernsFileDto";
import { CustomerConcernsNotesDto } from "./customerconcern-model/customerconcernsNotesDto";
import { ICustomerConcernsFailureMode } from "./customerconcern-model/customerConcernsFailureMode";
import { CustomerConcernsFiveWhysDto } from "./customerconcern-model/customerConcernsFiveWhysDto";

@Injectable()
export class CustomerconcernService {
	private baseUrl;
	private baseNoteUrl;
	private massEmailUrl;
	public pageTitle = "NCR List";

	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings,
		private userSearchService: UsersearchService
	) {
		this.baseUrl = this.settings.retrieveUrl() + "/api/Customerconcerns";
		this.baseNoteUrl =
			this.settings.retrieveUrl() + "/api/CustomerConcernsNotes";
		this.massEmailUrl =
			this.settings.retrieveUrl() + "/api/customerconcernsemail";
	}

	getCustomerconcern(id: number): Observable<ICustomerconcern> {
		if (id === 0) {
			return of(this.initializeCustomerconcern());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp
			.get<ICustomerconcern>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	getCustomerConcernsStatus(): Observable<any> {
		const url = `${this.baseUrl}/getCustomerConcernsStatus`;
		return this.authHttp
			.get(url)
			.pipe(retry(1), catchError(this.processError));
	}

	saveCustomerconcern(customerconcern: ICustomerconcern): Observable<any> {
		if (customerconcern.Id === 0) {
			return this.createCustomerconcern(customerconcern);
		}
		return this.updateCustomerconcern(customerconcern);
	}

	private createCustomerconcern(
		customerconcern: ICustomerconcern
	): Observable<any> {
		customerconcern.Id = undefined;

		return this.authHttp
			.post<number>(this.baseUrl + "/Create", customerconcern)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateCustomerconcern(
		customerconcern: ICustomerconcern
	): Observable<ICustomerconcern> {
		return this.authHttp
			.put<ICustomerconcern>(this.baseUrl, customerconcern)
			.pipe(retry(1), catchError(this.processError));
	}

	getAllFilesCustomerConcern(customerConcernsId: number) {
		const url = `${this.baseUrl}/files?id=${customerConcernsId}`;
		return this.authHttp
			.get<CustomerConcernsFileDto[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	getCustomerConcernHistory(id: any) {
		const url = `${this.baseUrl}/history?id=${id}`;
		return this.authHttp
			.get<CustomerConcernsHistoryDto[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	createFileCustomerConcern(file: any) {
		//const url = this.settings.retrieveUrl() + '/api/FileSave/filecreate';

		const url = `${this.baseUrl}/filecreate`;
		//return this.authHttp.post<CustomerConcernsFileDto>(url,file)

		return this.authHttp
			.post<any>(url, file)
			.pipe(retry(1), catchError(this.processError));
	}

	updateFileCustomerConcern(file: any) {
		const url = `${this.baseUrl}/fileupdate`;
		return this.authHttp
			.put<CustomerConcernsFileDto>(url, file)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteFileCustomConcern(id: number) {
		const url = `${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp
			.delete<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	// Function to Get NotesByCustomerConcernsId create by ameer 1/12/2021
	getNotesByCustomerConcernsId(customerConcernsId: number) {
		const url = `${this.baseNoteUrl}/GetNotesByCustomerConcernsId?id=${customerConcernsId}`;
		return this.authHttp
			.get<CustomerConcernsNotesDto[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	// Function to save notes create by ameer 1/12/2021
	createCustomerConcernsNotes(notes: any) {
		const url = `${this.baseNoteUrl}/CreateCustomerConcernsNotes`;
		return this.authHttp
			.post<any>(url, notes)
			.pipe(retry(1), catchError(this.processError));
	}

	// Function to Delete customerconcernsnotes create by ameer 1/12/2021
	deleteCustomerConcernsNotes(noteId: number) {
		const url = `${this.baseNoteUrl}/DeleteCustomerConcernsNotes?id=${noteId}`;
		return this.authHttp
			.delete<CustomerConcernsNotesDto[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteCustomerconcern(id: number): Observable<Response> {
		const url = `${this.baseUrl}/${id}`;
		return this.authHttp
			.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeCustomerconcern(): ICustomerconcern {
		return {
			EmployeeId: 0,
			Id: 0,
			NCRDate: "",
			NCRNumber: "",
			Customer: "",
			OriginalSalesOrderNumber: "",
			Problem: "",
			investigationResults: "",
			EmployeerResponsible: "",
			Disposition: "",
			NCRCode: "",
			BranchResponsible: 0,
			InternalExternal: "",
			LastUpdate: "",
			UserId: "",
			EmployeeAssigned: "",
			SignedOff: "",
			QTY: 0,
			UOM: "",
			CustomerPartNumber: "",
			CorrectiveAction: 0,
			RootCause: "",
			Containment: "",
			HyflexPartNo: "",
			HoseLotNumbers: "",
			TravelerNumber: "",
			COQ: "",
			CustomerContact: "",
			ReplacementCreditNumber: "",
			CustomerComplaintNumber: "",
			ResponsibleFor: "",
			Hose: "",
			Fitting1: "",
			Fitting2: "",
			Ferrule: "",
			Accessory: "",
			CorrectiveActionText: "",
			Division: "",
			Owner: "",
			CreatedDate: "",
			CreatedBy: "",
			UpdatedDate: "",
			UpdatedBy: "",
			StatusID: 1,
		} as ICustomerconcern;
	}

	public getRecordSet(
		search: ICustomerconcernSearch
	): Observable<ICustomerconcerns> {
		var uSearch: IUsersearch[] = [];
		uSearch.push(
			this.userSearchService.setSearch(
				"NCRFromDate",
				search.NCRFromDate,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"NCRToDate",
				search.NCRToDate,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"NCRNumber",
				search.NCRNumber,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Customer",
				search.Customer,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"OriginalSalesOrderNumber",
				search.OriginalSalesOrderNumber,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Problem",
				search.Problem,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"BranchResponsible",
				search.BranchResponsible,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"EmployeeAssigned",
				search.EmployeeAssigned,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"CustomerComplaintNumber",
				search.CustomerComplaintNumber,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"ResponsibleFor",
				search.ResponsibleFor,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Division",
				search.Division,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Owner",
				search.Owner,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"StatusID",
				search.StatusID,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"FailureModeLevel1",
				search.FailureModeLevel1,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"FailureModeLevel2",
				search.FailureModeLevel2,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"orderByString",
				search.orderByString,
				this.pageTitle
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Skip",
				search.skip.toString(),
				this.pageTitle
			)
		);

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => {});

		const options = {
			params: new HttpParams()
				.set("skip", search.skip.toString())
				.set("take", search.take.toString())
				.set("orderByString", search.orderByString)
				.set(
					"NCRFromDate",
					search.NCRFromDate ? search.NCRFromDate : ""
				)
				.set("NCRToDate", search.NCRToDate ? search.NCRToDate : "")
				.set("NCRNumber", search.NCRNumber ? search.NCRNumber : "")
				.set("Customer", search.Customer ? search.Customer : "")
				.set(
					"OriginalSalesOrderNumber",
					search.OriginalSalesOrderNumber
						? search.OriginalSalesOrderNumber
						: ""
				)
				.set("Problem", search.Problem ? search.Problem : "")
				.set(
					"BranchResponsible",
					search.BranchResponsible ? search.BranchResponsible : ""
				)
				.set(
					"EmployeeAssigned",
					search.EmployeeAssigned ? search.EmployeeAssigned : ""
				)
				.set(
					"CustomerComplaintNumber",
					search.CustomerComplaintNumber
						? search.CustomerComplaintNumber
						: ""
				)
				.set(
					"ResponsibleFor",
					search.ResponsibleFor ? search.ResponsibleFor : ""
				)
				.set("Division", search.Division ? search.Division : "")
				.set("Owner", search.Owner ? search.Owner : "")
				.set("StatusID", search.StatusID ? search.StatusID : "")
				.set(
					"FailureModeLevel1",
					search.FailureModeLevel1 ? search.FailureModeLevel1 : ""
				)
				.set(
					"FailureModeLevel2",
					search.FailureModeLevel2 ? search.FailureModeLevel2 : ""
				),
		};

		return this.authHttp
			.get<ICustomerconcerns>(this.baseUrl + "/grid", options)
			.pipe(
				retry(1),
				catchError(this.processError),
				map(
					(response) =>
						<ICustomerconcerns>{
							data: response.data,
							total: response.total,
							skip: search.skip,
						}
				)
			);
	}

	public getFreshSearch(): ICustomerconcernSearch {
		let search: ICustomerconcernSearch = {
			Id: 1,
			NCRFromDate: "",
			NCRToDate: "",
			NCRNumber: "",
			FailureModeLevel1: "",
			FailureModeLevel2: "",
			Customer: "",
			OriginalSalesOrderNumber: "",
			Problem: "",
			BranchResponsible: "",
			EmployeeAssigned: "",
			CustomerComplaintNumber: "",
			ResponsibleFor: "",
			Division: "",
			Owner: "",
			StatusID: "",
			skip: 0,
			orderByString: "",
			take: 10,
		};
		return search;
	}

	processError(err: any) {
		let message = "";
		if (err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
			message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}

		if (message.includes("Error Code: 401")) {
			message =
				"Security or authentication issue. If you are seeing this you either do not have permission";
			message +=
				" to access this page or your account has timed out and you need to log off and back on.";
		}
		console.error("this message: " + message);
		return throwError(message);
	}

	public getReport(fileId: number, reportType: number): Observable<any> {
		const url = `${this.baseUrl}/getReport?id=${fileId}&reportType=${reportType}`;

		return this.authHttp.get(url);
	}

	public saveColumns(search: ColumnManagement) {
		let p = "CustomerConcernColumns";

		var uSearch: IUsersearch[] = [];

		uSearch.push(
			this.userSearchService.setSearch(
				"NCRDate",
				search.NCRDateCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"NCRCode",
				search.NCRCodeCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Customer",
				search.CustomerCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Problem",
				search.ProblemCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"OriginalSalesOrderNumber",
				search.OriginalSalesOrderNumberCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"EmployeeAssigned",
				search.EmployeeAssignedCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"CustomerComplaintNumber",
				search.CustomerComplaintNumberCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"ResponsibleFor",
				search.ResponsibleForCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"BranchResponsible",
				search.BranchResponsibleCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Division",
				search.DivisionCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"StatusID",
				search.StatusCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Owner",
				search.OwnerCheck.toString(),
				p
			)
		);
		uSearch.push(
			this.userSearchService.setSearch(
				"Report",
				search.ReportCheck.toString(),
				p
			)
		);

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => {});
	}
	sendEmailToEmp(customerconcern, EmailRpt): Observable<any> {
		const url = `${this.baseUrl}/sendEmail?EmailRpt=${EmailRpt}`;
		return this.authHttp
			.post<any>(url, customerconcern)
			.pipe(retry(1), catchError(this.processError));
	}

	getOpenCustomerConcerns() {
		return this.authHttp
			.get<any[]>(this.baseUrl + "/opencustomerconcerns")
			.pipe(retry(1), catchError(this.processError));
	}
	sendEmailForOpenIssues(customerconcern) {
		return this.authHttp
			.post<any>(
				this.massEmailUrl + "/emailforopenissues",
				customerconcern
			)
			.pipe(retry(1), catchError(this.processError));
	}

	sendEmailForOpenIssuesToCraig(customerconcern) {
		return this.authHttp
			.post<any>(
				this.massEmailUrl + "/emailforopenissuestocraig",
				customerconcern
			)
			.pipe(retry(1), catchError(this.processError));
	}
	public toExcel(search: ICustomerconcernSearch): Observable<string> {
		const options = {
			params: new HttpParams()
				.set("orderByString", search.orderByString)
				.set(
					"NCRFromDate",
					search.NCRFromDate ? search.NCRFromDate : ""
				)
				.set("NCRToDate", search.NCRToDate ? search.NCRToDate : "")
				.set("NCRNumber", search.NCRNumber ? search.NCRNumber : "")
				.set("Customer", search.Customer ? search.Customer : "")
				.set(
					"OriginalSalesOrderNumber",
					search.OriginalSalesOrderNumber
						? search.OriginalSalesOrderNumber
						: ""
				)
				.set("Problem", search.Problem ? search.Problem : "")
				.set(
					"BranchResponsible",
					search.BranchResponsible ? search.BranchResponsible : ""
				)
				.set(
					"EmployeeAssigned",
					search.EmployeeAssigned ? search.EmployeeAssigned : ""
				)
				.set(
					"CustomerComplaintNumber",
					search.CustomerComplaintNumber
						? search.CustomerComplaintNumber
						: ""
				)
				.set(
					"ResponsibleFor",
					search.ResponsibleFor ? search.ResponsibleFor : ""
				)
				.set("Division", search.Division ? search.Division : "")
				.set("Owner", search.Owner ? search.Owner : "")
				.set("StatusID", search.StatusID ? search.StatusID : "")
				.set(
					"FailureModeLevel1",
					search.FailureModeLevel1 ? search.FailureModeLevel1 : ""
				)
				.set(
					"FailureModeLevel2",
					search.FailureModeLevel2 ? search.FailureModeLevel2 : ""
				),
		};

		return this.authHttp
			.get<string>(this.baseUrl + "/toexcel", options)
			.pipe(retry(1), catchError(this.processError));
	}

	getFailureModes(): Observable<ICustomerConcernsFailureMode[]> {
		const url = `${this.baseUrl}/getAllFailureModes`;
		return this.authHttp
			.get<ICustomerConcernsFailureMode[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	searchGLMCustomers(text: string): Observable<any> {
		const url = this.baseUrl + "/searchGLMCustomers?text=" + text;
		return this.authHttp.get(url).catch(this.processError);
	}

	searchGLMCustomerByID(custID: string): Observable<any> {
		const url = this.baseUrl + "/searchGLMCustomerByID?custID=" + custID;
		return this.authHttp.get(url).catch(this.processError);
	}

	getAllCustomers(): Observable<any> {
		const url = `${this.baseUrl}/getAllGLMCustomer`;
		return this.authHttp
			.get(url)
			.pipe(retry(1), catchError(this.processError));
	}

	getAllVendors(): Observable<any> {
		const url = `${this.baseUrl}/getAllGLMVendor`;
		return this.authHttp
			.get(url)
			.pipe(retry(1), catchError(this.processError));
	}

	getHelpFile(): Observable<any> {
		const url = `${this.baseUrl}/getHelpFileName`;
		return this.authHttp
			.get(url)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteFiveWhysRecords(id: number): Observable<any> {
		const url = `${this.baseUrl}/fivewhys/deletefivewhys?id=${id}`;
		return this.authHttp
			.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	createFiveWhysRecords(fiveWhyObj: any): Observable<any> {
		return this.authHttp
			.post<number>(this.baseUrl + "/fivewhys/createfivewhys", fiveWhyObj)
			.pipe(retry(1), catchError(this.processError));
	}

	updateFiveWhysRecords(fiveWhyObj: any): Observable<any> {
		return this.authHttp
			.put<CustomerConcernsFiveWhysDto>(
				this.baseUrl + "/fivewhys/updatefivewhys",
				fiveWhyObj
			)
			.pipe(retry(1), catchError(this.processError));
	}

	getFiveWhysData(customerConcernsId: number): Observable<any> {
		const url = `${this.baseUrl}/fivewhys/getfivewhysdata?customerConcernsId=${customerConcernsId}`;
		return this.authHttp
			.get<any[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	createIshikawaDiagramRecords(ishikawaDiagramObj: any): Observable<any> {
		return this.authHttp
			.post<number>(
				this.baseUrl + "/ishikawadiagram/createishikawadiagram",
				ishikawaDiagramObj
			)
			.pipe(retry(1), catchError(this.processError));
	}

	updateIshikawaDiagramRecords(ishikawaDiagramObj: any): Observable<any> {
		return this.authHttp
			.put<CustomerConcernsFiveWhysDto>(
				this.baseUrl + "/ishikawadiagram/updateishikawadiagram",
				ishikawaDiagramObj
			)
			.pipe(retry(1), catchError(this.processError));
	}

	getIshikawaDiagramData(customerConcernsId: number): Observable<any> {
		const url = `${this.baseUrl}/ishikawadiagram/getishikawadiagramdata?customerConcernsId=${customerConcernsId}`;
		return this.authHttp
			.get<any[]>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteIshikawaDiagram(customerConcernsId: number): Observable<any> {
		const url = `${this.baseUrl}/ishikawadiagram/deleteishikawadiagram?id=${customerConcernsId}`;
		return this.authHttp
			.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	sendEmailToAuditor(customerConcernId: number) {
		const url = `${this.massEmailUrl}/auditor-assigned?CustomerConcernId=${customerConcernId}`;
		return this.authHttp
			.post<any>(url, {})
			.pipe(retry(1), catchError(this.processError));
	}
}
