import { Component, OnInit } from '@angular/core';
import { BrancheService } from 'src/app/admin/branches/branche-service';
import { DashboardService } from '../dashboard-service';

@Component({
  selector: 'app-dailystats',
  templateUrl: './dailystats.component.html',
  styleUrls: ['./dailystats.component.css']
})
export class DailystatsComponent implements OnInit {
  public BranchId = '';
  public branches;
  public dailyStatsData: any;
  public time = { leftTime: 0, format: 'm:s' };
	public intervalTime = null;
  public month1dt=new Date();
  public month2dt=new Date();
  public month3dt=new Date();
  public pickByPersonData: any;

  constructor(
    public branchService: BrancheService,
    public dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
   
    let crntdate=new Date();
    this.month3dt=crntdate;
    this.month2dt.setMonth(this.month2dt.getMonth()-1);
    this.month1dt.setMonth(this.month1dt.getMonth()-2);
    this.getAllBranches();
    this.time = {
      leftTime: 300, format: 'mm:ss'
    }
    this.intervalTime = setInterval(() => {

      this.time = {
        leftTime: 300, format: 'mm:ss'
      }
    }, 300000);
   
  }

  getAllBranches() {
    this.branchService.getAllBranchesForDropdown().subscribe(response => {
      console.log(response);
      this.branches = response;
    });
  }

  getDailyStats() {
    this.dashboardService.getDailyStatsData(this.BranchId).subscribe(resp => {
      console.log(resp);
      this.dailyStatsData=resp;
    })
   
  }
  onFinishedTimer() {
    console.log('onFinishedTimer');
    this.getAllFunctions();
  }
  getPickByPerson() {
    console.log("1");
    this.dashboardService.getPickByPersonData(0).subscribe(resp => {
      console.log(resp);
      // this.dailyStatsData=resp;
      this.pickByPersonData=resp;
    })
  }
  getAllFunctions(){
    this.getDailyStats();
    this.getPickByPerson();
  }
}
