
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/create-user']" style='width:auto'>Add New User</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: 80px' (click)='showHideSearchClick()'>{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: auto' (click)='toExcel()'>To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> First Name</label>
				<input type='text' class='form-control block-element' placeholder=' First Name' [(ngModel)]='FirstName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Last Name</label>
				<input type='text' class='form-control block-element' placeholder=' Last Name' [(ngModel)]='LastName' />
			</div>
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Initials</label>
				<input type='text' class='form-control block-element' placeholder=' Initials' [(ngModel)]='Initials' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Title</label>
				<input type='text' class='form-control block-element' placeholder=' Title' [(ngModel)]='Title' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Address</label>
				<input type='text' class='form-control block-element' placeholder=' Address' [(ngModel)]='Address' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> City</label>
				<input type='text' class='form-control block-element' placeholder=' City' [(ngModel)]='City' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Region</label>
				<input type='text' class='form-control block-element' placeholder=' Region' [(ngModel)]='Region' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Postal Code</label>
				<input type='text' class='form-control block-element' placeholder=' Postal Code' [(ngModel)]='PostalCode' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Home Phone</label>
				<input type='text' class='form-control block-element' placeholder=' Home Phone' [(ngModel)]='HomePhone' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Cell Phone</label>
				<input type='text' class='form-control block-element' placeholder=' Cell Phone' [(ngModel)]='CellPhone' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Birth Date</label>
				<input type='text' class='form-control block-element' placeholder=' Birth Date' [(ngModel)]='BirthDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Hire Date</label>
				<input type='text' class='form-control block-element' placeholder=' Hire Date' [(ngModel)]='HireDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Emergency Name</label>
				<input type='text' class='form-control block-element' placeholder=' Emergency Name' [(ngModel)]='EmergencyName' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Emergency Phone</label>
				<input type='text' class='form-control block-element' placeholder=' Emergency Phone' [(ngModel)]='EmergencyPhone' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div> -->
			
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Image</label>
				<input type='text' class='form-control block-element' placeholder=' Image' [(ngModel)]='Image' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Signature</label>
				<input type='text' class='form-control block-element' placeholder=' Signature' [(ngModel)]='Signature' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Company Id</label>
				<input type='text' class='form-control block-element' placeholder=' Company Id' [(ngModel)]='CompanyId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Company Name</label>
				<input type='text' class='form-control block-element' placeholder=' Company Name' [(ngModel)]='CompanyName' />
			</div> -->
			<div class='col-md-4'>
				<label class='inline-label'> Email</label>
				<input type='text' class='form-control block-element' placeholder=' Email' [(ngModel)]='Email' />
			</div>
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Email Confirmed</label>
				<input type='text' class='form-control block-element' placeholder=' Email Confirmed' [(ngModel)]='EmailConfirmed' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Password Hash</label>
				<input type='text' class='form-control block-element' placeholder=' Password Hash' [(ngModel)]='PasswordHash' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Security Stamp</label>
				<input type='text' class='form-control block-element' placeholder=' Security Stamp' [(ngModel)]='SecurityStamp' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Phone Number</label>
				<input type='text' class='form-control block-element' placeholder=' Phone Number' [(ngModel)]='PhoneNumber' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Phone Number Confirmed</label>
				<input type='text' class='form-control block-element' placeholder=' Phone Number Confirmed' [(ngModel)]='PhoneNumberConfirmed' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Two Factor Enabled</label>
				<input type='text' class='form-control block-element' placeholder=' Two Factor Enabled' [(ngModel)]='TwoFactorEnabled' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lockout End Date Utc</label>
				<input type='text' class='form-control block-element' placeholder=' Lockout End Date Utc' [(ngModel)]='LockoutEndDateUtc' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Lockout Enabled</label>
				<input type='text' class='form-control block-element' placeholder=' Lockout Enabled' [(ngModel)]='LockoutEnabled' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Access Failed Count</label>
				<input type='text' class='form-control block-element' placeholder=' Access Failed Count' [(ngModel)]='AccessFailedCount' />
			</div> -->
			<div class='col-md-4'>
				<label class='inline-label'> User Name</label>
				<input type='text' class='form-control block-element' placeholder=' User Name' [(ngModel)]='UserName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Branch </label>
				<!-- <input type='text' class='form-control block-element' placeholder=' Branch Id' [(ngModel)]='BranchId' /> -->
                <select [(ngModel)]='BranchId' class='form-control block-element'>
					<option value="" disabled selected hidden>Select Branch</option>
					<option value= ""> </option>
					<option *ngFor='let branch of branches' [value]="branch.Id">{{branch.BranchName}} {{branch.BranchNumber}}
					</option>
				</select>		
			</div>
			<div class='col-md-4'>
				<!-- <label class='inline-label'> Active</label>
				<input type='text' class='form-control block-element' placeholder=' Active' [(ngModel)]='Active' /> -->
				<input type='checkbox' [(ngModel)]='Active' style="margin-top: 20px;" />&nbsp;
					<label> Active Users</label>
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/edit-user', e.Id]">Edit</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='FirstName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> First Name</th>
		<td mat-cell *matCellDef='let e'>{{e.FirstName}}</td>
	</ng-container>
	<ng-container matColumnDef='LastName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Last Name</th>
		<td mat-cell *matCellDef='let e'>{{e.LastName}}</td>
	</ng-container>
	<ng-container matColumnDef='Initials'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Initials</th>
		<td mat-cell *matCellDef='let e'>{{e.Initials}}</td>
	</ng-container>
	<ng-container matColumnDef='Title'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Title</th>
		<td mat-cell *matCellDef='let e'>{{e.Title}}</td>
	</ng-container>
	<ng-container matColumnDef='Address'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Address</th>
		<td mat-cell *matCellDef='let e'>{{e.Address}}</td>
	</ng-container>
	<ng-container matColumnDef='City'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> City</th>
		<td mat-cell *matCellDef='let e'>{{e.City}}</td>
	</ng-container>
	<ng-container matColumnDef='Region'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Region</th>
		<td mat-cell *matCellDef='let e'>{{e.Region}}</td>
	</ng-container>
	<ng-container matColumnDef='PostalCode'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Postal Code</th>
		<td mat-cell *matCellDef='let e'>{{e.PostalCode}}</td>
	</ng-container>
	<ng-container matColumnDef='HomePhone'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Home Phone</th>
		<td mat-cell *matCellDef='let e'>{{e.HomePhone}}</td>
	</ng-container>
	<ng-container matColumnDef='CellPhone'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Cell Phone</th>
		<td mat-cell *matCellDef='let e'>{{e.CellPhone}}</td>
	</ng-container>
	<ng-container matColumnDef='BirthDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'> Birth Date</th>
		<td mat-cell *matCellDef='let e'>{{e.BirthDate}}</td>
	</ng-container>
	<ng-container matColumnDef='HireDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col13'> Hire Date</th>
		<td mat-cell *matCellDef='let e'>{{e.HireDate}}</td>
	</ng-container>
	<ng-container matColumnDef='EmergencyName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col14'> Emergency Name</th>
		<td mat-cell *matCellDef='let e'>{{e.EmergencyName}}</td>
	</ng-container>
	<ng-container matColumnDef='EmergencyPhone'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col15'> Emergency Phone</th>
		<td mat-cell *matCellDef='let e'>{{e.EmergencyPhone}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col16'> Notes</th>
		<td mat-cell *matCellDef='let e'>{{e.Notes}}</td>
	</ng-container>
	<ng-container matColumnDef='Active'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col17'> Active</th>
		<td mat-cell *matCellDef='let e'>{{e.Active}}</td>
	</ng-container>
	<ng-container matColumnDef='Image'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col18'> Image</th>
		<td mat-cell *matCellDef='let e'>{{e.Image}}</td>
	</ng-container>
	<ng-container matColumnDef='Signature'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col19'> Signature</th>
		<td mat-cell *matCellDef='let e'>{{e.Signature}}</td>
	</ng-container>
	<ng-container matColumnDef='CompanyId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col20'> Company Id</th>
		<td mat-cell *matCellDef='let e'>{{e.CompanyId}}</td>
	</ng-container>
	<ng-container matColumnDef='CompanyName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col21'> Company Name</th>
		<td mat-cell *matCellDef='let e'>{{e.CompanyName}}</td>
	</ng-container>
	<ng-container matColumnDef='Email'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col22'> Email</th>
		<td mat-cell *matCellDef='let e'>{{e.Email}}</td>
	</ng-container>
	<ng-container matColumnDef='EmailConfirmed'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col23'> Email Confirmed</th>
		<td mat-cell *matCellDef='let e'>{{e.EmailConfirmed}}</td>
	</ng-container>
	<ng-container matColumnDef='PasswordHash'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col24'> Password Hash</th>
		<td mat-cell *matCellDef='let e'>{{e.PasswordHash}}</td>
	</ng-container>
	<ng-container matColumnDef='SecurityStamp'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col25'> Security Stamp</th>
		<td mat-cell *matCellDef='let e'>{{e.SecurityStamp}}</td>
	</ng-container>
	<ng-container matColumnDef='PhoneNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col26'> Phone Number</th>
		<td mat-cell *matCellDef='let e'>{{e.PhoneNumber}}</td>
	</ng-container>
	<ng-container matColumnDef='PhoneNumberConfirmed'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col27'> Phone Number Confirmed</th>
		<td mat-cell *matCellDef='let e'>{{e.PhoneNumberConfirmed}}</td>
	</ng-container> 
	<ng-container matColumnDef='TwoFactorEnabled'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col28'> Two Factor Enabled</th>
		<td mat-cell *matCellDef='let e'>{{e.TwoFactorEnabled}}</td>
	</ng-container>
	<ng-container matColumnDef='LockoutEndDateUtc'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col29'> Lockout End Date Utc</th>
		<td mat-cell *matCellDef='let e'>{{e.LockoutEndDateUtc}}</td>
	</ng-container>
	<ng-container matColumnDef='LockoutEnabled'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col30'> Lockout Enabled</th>
		<td mat-cell *matCellDef='let e'>{{e.LockoutEnabled}}</td>
	</ng-container>
	<ng-container matColumnDef='AccessFailedCount'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col31'> Access Failed Count</th>
		<td mat-cell *matCellDef='let e'>{{e.AccessFailedCount}}</td>
	</ng-container>
	<ng-container matColumnDef='UserName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col32'> User Name</th>
		<td mat-cell *matCellDef='let e'>{{e.UserName}}</td>
	</ng-container>
	<ng-container matColumnDef='BranchNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> BranchNumber</th>
		<td mat-cell *matCellDef='let e'>{{e.BranchNumber}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>