import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IHose } from '../hose-model/hose';
@Component({
	selector: 'http-fileupload'
	, templateUrl: './file-upload.html'
})

export class FileUploadComponent implements OnChanges {
	@Input() hoseDetail: IHose;
	@ViewChild('fileInput', { static: true }) fileInput: any;

	uploadForm: UntypedFormGroup;
	baseUrl: string;
	errorMessage = '';
	urldataHose:any;
	constructor(
		private appSettings: ApplicationSettings,private authHttp: HttpClient,private fb: UntypedFormBuilder) {
			this.baseUrl = this.appSettings.retrieveUrl();
	}

	ngOnInit(): void {
		this.uploadForm = this.fb.group({
			Image: ''
		});
		this.hoseDetail.fileNameAndPath = `${this.baseUrl}/uploadedfiles/HydroTestFiles/${this.hoseDetail.fileName}`;
	}
	onFileSelected(event: any) {
		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
		  console.log(target.files[0].name);
	   
		const formData = new FormData();
		formData.append('fileName', target.files[0]);
		formData.append('Id', this.hoseDetail.Id.toString());
		formData.append('Location', 'HydroTestFiles');
		
		this.addFile(formData).subscribe(
		  retVal => {
			console.log("File Uploaded: "+retVal);	
			this.hoseDetail.fileNameAndPath = `${this.baseUrl}/uploadedfiles/HydroTestFiles/${retVal}`;
			this.hoseDetail.fileName = retVal;
			}
		  ,(error: any) => this.errorMessage = <any>error
		);
	  }	
	}

	addFile(file){
		console.log(file);
		//const url = `${this.baseUrl}/api/HydroTestHose/uploadimage`;
		const url = this.baseUrl + '/api/hydrotesthose/ReportImage?id=' + this.hoseDetail.Id;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}

	ngOnChanges() {
		this.hoseDetail.fileNameAndPath = `${this.baseUrl}/uploadedfiles/HydroTestFiles/${this.hoseDetail.fileName}`;
		console.log(this.hoseDetail.fileNameAndPath);
	}

	
	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	/*uploadEventHandler(e: UploadEvent) {
		e.data = {
			id: this.hoseDetail.Id
		};
	}*/

	/**
	 * After successful file upload, server returns filename
	 * of the saved file.
	 * @param e
	 */
	/*successEventHandler(e: SuccessEvent) {
		const fileName = e.response.body;
		this.hoseDetail.fileName = fileName;
		this.hoseDetail.fileNameAndPath = `${this.baseUrl}/uploadedfiles/HydroTestFiles/${fileName}`;
	}*/
}

