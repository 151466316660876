﻿
<h3>ASSEMBLY DETAILS </h3>
<div *ngIf="hydroTest">
    <table class='htTable'>
        <tbody>
            <tr>
                <td class="col-md-2 bottom">
                    <label>Assembly Type</label>
                </td>
                <td class="col-md-2 bottom">
                    <select [(ngModel)]="hydroTest.testType" (change)="ValueChanged()" style="width: 95%" [ngModelOptions]="{standalone: true}" (change)="setHAWP(); changeTestPressureMessage()"
                        class="form-control" [disabled]="loading">
                        <option *ngFor="let o of refData" [value]="o.value">{{o.name}}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td colspan="6" class="col-md-12">
                    <hr />
                </td>
            </tr>
            <tr>
                <td class="col-md-2">
                    <label>Hose</label>
                </td>
                <td class="col-md-4">
                    <form [formGroup]="HoseSearchForm">
                        <input placeholder="(Hose)" class='form-control' type='text' formControlName="Hose"
                        [matAutocomplete]="autoHose" />
                    <mat-autocomplete autoActiveFirstOption #autoHose="matAutocomplete" (optionSelected)="selectHoseAutocomplete($event.option.value)">
                        <mat-option *ngFor="let item of hosefilteredOptions | async"
                            [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>
                    </form>
                </td>
                <td class="col-md-2">
                    <label>End 1</label>
                </td>
                <td class="col-md-4">
                    <form [formGroup]="End1SearchForm">
                        <input placeholder="(End1)" class='form-control' type='text' formControlName="End1"
                        [matAutocomplete]="autoEnd1" />
                    <mat-autocomplete autoActiveFirstOption #autoEnd1="matAutocomplete" (optionSelected)="selectEndOneAutocomplete($event.option.value)">
                        <mat-option *ngFor="let item of endonefilteredOptions | async"
                            [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>
                    </form>

                </td>
            </tr>
            <tr>
                <td class="col-md-2">
                    <label>Hose Length</label>
                </td>
                <td class="col-md-4">
                    <input [(ngModel)]="hydroTest.feet" (change)="ValueChanged()" (ngModelChange)="changeFeet($event)" [ngModelOptions]="{standalone: true}"
                        [disabled]="loading" class="limitWidth ">
                    <span class="alert alert-warning" *ngIf="errorFeet == false">
                        {{appPropertiesService.errorMessageInteger}}
                    </span>
                    <input [(ngModel)]="hydroTest.inch" (change)="ValueChanged()" (ngModelChange)="changeInch($event)" [ngModelOptions]="{standalone: true}"
                        [disabled]="loading" class="limitWidth ">
                    <label>ft | in</label>
                    <span class="alert alert-warning" *ngIf="errorInch == false">
                        {{appPropertiesService.errorMessageInteger}}
                    </span>
                </td>
                <td class="col-md-2">
                    <label style="margin-bottom:24px">End 1 WP</label>
                </td>
                <td class="col-md-4">
                    <div>
                        <input [(ngModel)]="hydroTest.endOneWp" (change)="ValueChanged()" (ngModelChange)="mychange($event)" (blur)="setHAWP()" [ngModelOptions]="{standalone: true}"
                            [disabled]="true" class="form-control input-list" style="width: 95%">
                        <br>
                        <span class="alert alert-warning" *ngIf="errorNatural == false">
                            {{appPropertiesService.errorMessageInteger}}
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="col-md-2">
                    <label>Inside Diameter</label>
                </td>
                <td class="col-md-4">
                    <input [(ngModel)]="hydroTest.insideDiameter" (change)="ValueChanged()" [ngModelOptions]="{standalone: true}" [disabled]="loading"
                        class="form-control input-list" style="width: 95%">
                </td>
                <td class="col-md-2">
                    <label >End 2</label>
                </td>
                <td class="col-md-4" >
                    <form [formGroup]="End2SearchForm">
                        <input placeholder="(End2)" class='form-control' type='text' formControlName="End2"
                        [matAutocomplete]="autoEnd2" />
                    <mat-autocomplete autoActiveFirstOption #autoEnd2="matAutocomplete" (optionSelected)="selectEndTwoAutocomplete($event.option.value)">
                        <mat-option *ngFor="let item of endtwofilteredOptions | async"
                            [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>
                    </form>


                </td>
            </tr>
            <tr>
                <td class="col-md-2">
                    <label style="margin-bottom:24px" >Hose WP</label>
                </td>
                <td class="col-md-4">
                    <div>
                        <input [(ngModel)]="hydroTest.hoseWp" (change)="ValueChanged()" (ngModelChange)="changeHoseWp($event)" (blur)="setHAWP()"
                            [ngModelOptions]="{standalone: true}" [disabled]="true" class="form-control input-list" style="width: 95%">
                        <br>
                        <span class="alert alert-warning" *ngIf="errorHoseWp == false">
                            {{appPropertiesService.errorMessageInteger}}
                        </span>
                    </div>
                </td>
                <td class="col-md-2">
                    <label style="margin-bottom:24px">End 2 WP</label>
                </td>
                <td class="col-md-4">
                    <div>
                        <input [(ngModel)]="hydroTest.endTwoWp" (change)="ValueChanged()" (ngModelChange)="changeEndTwoWp($event)" (blur)="setHAWP()"
                            [ngModelOptions]="{standalone: true}" [disabled]="true" class="form-control input-list" style="width: 95%">
                        <br>
                        <span class="alert alert-warning" *ngIf="errorEndTwoWp == false">
                            {{appPropertiesService.errorMessageInteger}}
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6" class="col-md-12">
                    <hr />
                </td>
            </tr>
            <tr *ngIf="!IsHydraulic()">
                <td class="col-md-2">
                    <label style="margin-bottom:24px">HAWP</label>
                </td>
                <td class="col-md-4">
                    <div>
                        <input [(ngModel)]="hydroTest.hawp" (change)="ValueChanged()" (ngModelChange)="changeHawp($event)" [ngModelOptions]="{standalone: true}"
                            [disabled]="true" class="form-control input-list" style="width: 95%">
                        <br>
                        <span class="alert alert-warning" *ngIf="errorHawp == false">
                            {{appPropertiesService.errorMessageInteger}}
                        </span>
                    </div>
                </td>
            </tr>
            <tr *ngIf="IsCustomerSpecified()">
                <td class="col-md-2">
                    <label style="margin-bottom:24px">Customer Specified Test Pressure</label>
                </td>
                <td class="col-md-4">
                    <div>
                        <input [(ngModel)]="hydroTest.customerSpecifiedTestPressure" (change)="ValueChanged()" (ngModelChange)="setHAWP()" [ngModelOptions]="{standalone: true}"
                            [disabled]="loading" class="form-control input-list" style="width: 95%">
                        <br>
                        <span class="alert alert-warning" *ngIf="errorHawp == false">
                            {{appPropertiesService.errorMessageInteger}}
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="col-md-2">
                    <label style="margin-bottom:24px">Test Pressure</label>
                </td>
                <td class="col-md-4">
                    <div>
                        <input [disabled]="true" [(ngModel)]="hydroTest.testPressure" (ngModelChange)="changeTestPressure($event)"
                            [ngModelOptions]="{standalone: true}"  class="form-control input-list" style="width: 95%">

                        <br>
                        <span class="alert alert-warning" *ngIf="errorTestPressure == false">
                            {{appPropertiesService.errorMessageInteger}}
                        </span>
                    </div>
                </td>
                <td [hidden]="showTestPressure" colspan="2" class="col-md-6">
                    <button type="button" (click)="ToggleTestPressureVisability()" class="btn btn-primary btn-xs">
                        <span class="glyphicon glyphicon-question-sign"></span>
                    </button>

                    <div [hidden]="showTestPressure">
                        <b>Test Pressures, Hose:</b>{{testPressureHose}}
                        <b> Fitting 1:</b>{{testPressureFitting1}}
                        <b> Fitting 2:</b>{{testPressureFitting2}}
                        <b> Test Pressure:</b>{{hydroTest.testPressure}}
                    </div>

                </td>
            </tr>

            <tr>
                <td class="col-md-2">
                </td>
                <td colspan="2" class="col-md-4">

                </td>
            </tr>

            <tr>
                <td colspan="4">
                    <div style="display: inline-block">
                        <button class="btn btn-primary" [disabled]="loading || isValid == false" (click)="save()">
                            <span *ngIf="loading" class="fa fa-spinner fa-pulse" style="margin-right: 5px;"></span>
                            Save
                        </button>
                        <button *ngIf="valueChanged" class="btn btn-danger" (click)="Cancel()">
                            Cancel Changes
                        </button>
                    </div>
                    <span *ngIf="valueChanged">If you navigate off the tab your changes will be discarded.</span>
                </td>
            </tr>
            <tr>
                <td colspan="4" style="font-size: 14pt;">
                    <strong > HAWP - Hose assembly working pressure is determined by the weakest component in the hose assembly.</strong>
                    <br /> {{testPressureMessage}}
                    <br> {{testPressureMessageDuration}}
                    <br> {{testPressureMessageLine3}}
                </td>
            </tr>
        </tbody>
    </table>
</div>