import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { BoxLabelService } from './box-label-service';
import { BoxLabelComponent } from './box-label/box-label.component';

@NgModule({
	declarations: [
		BoxLabelComponent
	],
	imports: [
		MatAutocompleteModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'box-label', component: BoxLabelComponent },
		])
	],
	providers: [
		BoxLabelService
	]
})
export class BoxLabelModule { }
