import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'
import { DevelopmenttaskListComponent } from './developmenttask-list/developmenttask-list.component';
import { DevelopmenttaskEditComponent } from './developmenttask-edit/developmenttask-edit.component';
import { DevelopmenttaskHelpComponent } from './developmenttask-help/developmenttask-help.component';
import { DevelopmenttaskService } from './developmenttask-service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	declarations: [
		DevelopmenttaskListComponent
		, DevelopmenttaskEditComponent
		, DevelopmenttaskHelpComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		NgSelectModule,
		RouterModule.forChild([
			{ path: 'developmenttasks', component: DevelopmenttaskListComponent },
			{ path: 'developmenttask-details/:Id', component: DevelopmenttaskEditComponent },
			{ path: 'developmenttask-help', component: DevelopmenttaskHelpComponent },
		])
	],
	providers: [
		DevelopmenttaskService
	]
})
export class DevelopmenttaskModule { }
