import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { ICrimps } from '../crimp-model/ICrimps';
import { ICrimpSearch } from '../crimp-model/IcrimpSearch';
import { ICrimp } from '../crimp-model/crimp';
import { CrimpService } from '../crimp.service';
import { NewFromCrimpService } from './new-from-crimp.service';

@Component({
	templateUrl: './new-from-crimp.component.html',
	styleUrls: ['./new-from-crimp.component.css']
})

export class NewFromCrimpComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	public pageSize: number = +localStorage.getItem('rows');
	public pageSizeList: any[];
	public UserSearches: IUsersearch[];
	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';
	displayedColumns: string[] = ['Id','OrderNumber','HoseName', 'FerruleName','FittingName', 'CreatedDate', 'TestRequired'];

	isLoadingResults = true;
	public recordTotal: number = 0;

	public skip = 0;
	public sub: Subscription;
	public OrderNumber = '';
	public crimpDate = '0';
	public TestRequired = '';

	public crimpDates = [
		{
			name: '',
			value: '0'
		},
		{
			name: 'Today',
			value: CrimpDate.Today
		},
		{
			name: 'Today and yesterday',
			value: CrimpDate.TodayYesterday
		},
		{
			name: 'Last 5 days',
			value: CrimpDate.Last5Days
		}
	];

	pageTitle = 'New From Crimp';
	public errorMessage = '';
	public dataSource: any;
	listFilter: string;
	public listOfcrimps: ICrimp[];
	public allowUnsort = true;
	public multiple = false;

	constructor(
		private router: Router,
		private appSettings: ApplicationSettings,
		public service: NewFromCrimpService,
		private route: ActivatedRoute,
		//private configurationService: ConfigurationService,
		private serviceCrimp: CrimpService,
		private userSearchService: UsersearchService) {
	
		//this.pageSizeList = this.configurationService.pageSizeList;

		if (this.pageSize === 0) {
			this.pageSize = 10;
			localStorage.setItem('rows', '10');
		}
	}

	refreshGrid(): void {
		this.skip = 0;
		this.getData();
	}

	ngOnInit(): void {
		this.skip = 0;
		this.crimpDate='0';
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x; }
		else { this.pageSize = 10;}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='OrderNumber')
				this.OrderNumber = i.Value;

				if(i.Item=='TestRequired')
				this.TestRequired = i.Value;

			if(i.Item=='crimpDate')
				this.crimpDate = i.Value.toString();

			if(i.Item=='orderByString') {
				if (this.appSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.appSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	valueChanged(): boolean {
		localStorage.setItem('rows', this.pageSize.toString());
		return true;
	}

	onFilter() {
		this.refreshGrid();
	}

	getData(): any {
		this.isLoadingResults = true;
		this.dataSource = null; 
		this.recordTotal = 0; 
		   let search: ICrimpSearch = this.serviceCrimp.getFreshSearch();
   
		   search.OrderNumber = this.OrderNumber;
		   search.TestRequired = Number(this.TestRequired);
		   search.crimpDate = Number(this.crimpDate);
		   
		   
		   search.skip = this.paginator.pageIndex * this.pageSize;
		   search.take = this.pageSize;
		   search.orderByString = this.sort.direction + '::' + this.sort.active + '::';
   
		   this.serviceCrimp.getRecordSet(search).subscribe((obj: ICrimps) => this.onSelectedRecordSetReceived(obj),
		   (error: any) => {
			   this.errorMessage = <any> error;
		   });
	   }

	   private onSelectedRecordSetReceived(obj: ICrimps){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.isLoadingResults = false;
	}

	createTraveler(id: number): void {
		this.serviceCrimp.addHydroTest(id)
			.subscribe((hydrotestId) => this.onHydrotestAdded(hydrotestId),
				(error: any) => this.errorMessage = <any>error);
	}

	onHydrotestAdded(hydrotestId: number): void {
		this.router.navigate(['/hydro-test', hydrotestId]);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/new-from-crimp`])
		);
		window.open(url, '_blank');
	}
}

export enum CrimpDate {
	Blank = '0',
	Today = '1',
	TodayYesterday = '2',
	Last5Days = '3'
}
