export class ColumnManagement {
	constructor() { }
	public NCRDateCheck = false;
	public NCRCodeCheck = false;
	public StatusCheck = false;
	public CustomerCheck = false;
	public ProblemCheck = false;
	public OriginalSalesOrderNumberCheck = false;
	public EmployeeAssignedCheck = false;
	public CustomerComplaintNumberCheck = false;
	public ResponsibleForCheck = false;

	public BranchResponsibleCheck = false;
	public DivisionCheck = false;
	public OwnerCheck = false;
	public ReportCheck = false;
	public TableSize = "500";


	public tableHeights = [
		'300'
		, '400'
		, '500'
		, '600'
		, '650'
		, '700'
		, '750'
		, '800'
		, '900'
	];

	public setStringValues(item: string, value: string) {
		if (item == 'TableSize')
			this.TableSize = value;
	}

	public convertToBool(val: string): boolean {
		if (val == 'true')
			return true;
		else
			return false;
	}

	public setValues(item: string, val: string) {

		if (item == 'TableSize') {
			this.TableSize = val;
			return;
		}

		let value = this.convertToBool(val);

		if (item == 'NCRDate')
			this.NCRDateCheck = value;
		if (item == 'NCRCode')
			this.NCRCodeCheck = value;


		if (item == 'StatusID')
			this.StatusCheck = value;


		if (item == 'Customer')
			this.CustomerCheck = value;

		if (item == 'Problem')
			this.ProblemCheck = value;

		if (item == 'OriginalSalesOrderNumber')
			this.OriginalSalesOrderNumberCheck = value;

		if (item == 'EmployeeAssigned')
			this.EmployeeAssignedCheck = value;

		if (item == 'CustomerComplaintNumber')
			this.CustomerComplaintNumberCheck = value;

		if (item == 'ResponsibleFor')
			this.ResponsibleForCheck = value;

		if (item == 'BranchResponsible')
			this.BranchResponsibleCheck = value;

		if (item == 'Division')
			this.DivisionCheck = value;

		if (item == 'Owner')
			this.OwnerCheck = value;

		if (item == 'Report')
			this.ReportCheck = value;

	}

	public GetColumns(): string[] {
		let retval: string[] = [];;
		retval.push('Id');

		if (this.NCRDateCheck)
			retval.push('NCRDate');

		if (this.NCRCodeCheck)
			retval.push('NCRCode');

		if (this.StatusCheck)
			retval.push('StatusID');

		if (this.CustomerCheck)
			retval.push('Customer');

		if (this.ProblemCheck)
			retval.push('Problem');

		if (this.OriginalSalesOrderNumberCheck)
			retval.push('OriginalSalesOrderNumber');

		if (this.EmployeeAssignedCheck)
			retval.push('EmployeeAssigned');

		if (this.CustomerComplaintNumberCheck)
			retval.push('CustomerComplaintNumber');

		if (this.ResponsibleForCheck)
			retval.push('ResponsibleFor');

		if (this.BranchResponsibleCheck)
			retval.push('BranchResponsible');

		if (this.DivisionCheck)
			retval.push('Division');

		if (this.OwnerCheck)
			retval.push('Owner');

		if (this.ReportCheck)
			retval.push('Report');

		return retval;
	}
}