import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth-guard.service';
import { BoxLabelModule } from './box-labels/box-label-module';
import { CrimpModule } from './crimp-management/crimp-module';
import { CustomerModule } from './customers/customer-module';
import { DocumentModule } from './documents/document-module';
import { HydrotestModule } from './hydrotests/hydrotest-module';
import { LoginComponent } from './login/login.component';
import { AuthorizationModule } from './modules/authorization.module';
import { GreenLineLegacyModule } from './modules/greenline-legacy.module';
import { PackageLabelModule } from './package-labels/package-label-module';
import { FerruleModule } from './part-management/ferrule/ferrule.module';
import { FittingModule } from './part-management/fitting/fitting.module';
import { HoseModule } from './part-management/hose/hose.module';
import { ApplicationSettings } from './shared/application-settings';
import { DdlService } from './shared/ddlService/ddl.service';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { ReportSettings } from './shared/report-settings';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { PartService } from './parts/part-service';
import { NonStockLabelModule } from './non-stock/non-stock-module';
import { PrinterIpService } from './shared/printer-ip.service';
//import { PartImportComponent } from './parts/part-import/part-import.component';
import { AdminModule } from './admin/admin.module';
import { CustomerconcernModule } from './customerconcerns/customerconcern-module';
import { MaterialModule } from './material-module'; 
import { HelpModule } from './helps/help-module';
import { WalkinModule } from './walkins/walkin-module';
import { LabelprintingModule } from './labelprinting/labelprinting-module';
import { DevelopmenttaskModule } from './developmenttasks/developmenttask-module';
import { BranchlabelprinterModule } from './branchlabelprinters/branchlabelprinter-module';
import { CustomerPlanrecordModule } from './customerplanrecords/customerplanrecord-module';
import { LookuplistModule } from './lookuplists/lookuplist-module';
import { HydrotestcalibrationModule } from './hydrotestcalibrations/hydrotestcalibration-module';
import { DashboardModule } from './dashboard/dashboard-module';
import { PartattModule } from './partatts/partatt-module';
import { PartModule } from './parts/part-module';
import { MillcertModule } from './millcerts/millcert-module';
import { AutomationModule } from './automations/automation-module';
import { BinlabelcatModule } from './binlabelcats/binlabelcat-module';
import { LabelsmenuModule } from './labelsmenu/labelsmenu.module';
import { ProductdataModule } from './productdatas/productdata-module';
import { PartcatModule } from './partcats/partcat-module';
import { HydraulicfittingscategorieModule } from './hydraulicfittingscategories/hydraulicfittingscategorie-module';
import { VprodinventModule } from './vprodinvents/vprodinvent-module';
import { LabelpropertyconfigModule } from './labelpropertyconfigs/labelpropertyconfig-module';
import { VideoModule } from './videos/video-module';
import { UnauthorizedpageComponent } from './unauthorizedpage/unauthorizedpage/unauthorizedpage.component';

import { HeaderVersionComponent } from './shared/header-version/header-version.component';
import { HeaderVersionModule } from './shared/header-version/header-version-module';

export function start(config: ApplicationSettings) {
	function result() {
		return config.getUrl();
	}
	return result;
}

@NgModule({
	declarations: [
		UnauthorizedpageComponent,
		AppComponent,
		WelcomeComponent,
		NavigationComponent,
		LoginComponent,
		SpinnerComponent,
		//PartImportComponent	,
		HeaderVersionComponent
	],
	imports: [
		LabelpropertyconfigModule,
		VprodinventModule,
		HydraulicfittingscategorieModule,
		PartcatModule,
		ProductdataModule,
		LabelsmenuModule,
		BinlabelcatModule,
		AutomationModule,
		MillcertModule,
		PartModule,
		PartattModule,
		DashboardModule,
		HydrotestcalibrationModule,
		LookuplistModule,
		CustomerPlanrecordModule,
		BranchlabelprinterModule,
		DevelopmenttaskModule,
		LabelprintingModule,
		WalkinModule,
		HelpModule,
		MaterialModule,
		MatTabsModule,
		CustomerconcernModule,
		AdminModule,
		HydrotestModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AuthorizationModule,
		CrimpModule,
		GreenLineLegacyModule,
		FerruleModule,
		CustomerModule,
		HoseModule,
		VideoModule,
		FittingModule,
		DocumentModule,
		BoxLabelModule,
		PackageLabelModule,
		NonStockLabelModule,
		HeaderVersionModule,
		RouterModule.forRoot([
    {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [AuthGuard]
    },
	{ path: 'unauthorized', component: UnauthorizedpageComponent },
    //{ path: 'partimport', component: PartImportComponent },
    { path: 'login', component: LoginComponent },
    { path: '', redirectTo: 'welcome', pathMatch: 'full' },
    { path: '**', redirectTo: 'welcome', pathMatch: 'full' }
], {})
	],
	providers: [
		//ConfigurationService,
		ApplicationSettings,
		ReportSettings,
		PartService,
		DdlService,
		PrinterIpService,
		{
			provide: APP_INITIALIZER,
			useFactory: start,
			deps: [ApplicationSettings],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
