﻿<div *ngIf="hydroTest">
    <div *ngIf="hydroTestDataState()">
        <span class="line-breaker">
            {{hydroTestDataState()}}
        </span>
    </div>

    <div class="row" *ngIf="!hydroTestDataState()">
        <div class="col-md-2">
            <http-hose-list [hydrotestId]="hydroTest.hydroTestId" (hoseSelected)="onHoseSelected($event)"
                [(triggerHoseListRefresh)]="triggerHoseListRefresh">
            </http-hose-list>
        </div>
        <div class="col-md-10">
            <label>Current Action:&nbsp;&nbsp;&nbsp;</label>
            <label>{{formState}}</label>

            <div class="controls mb-20">
                <button *ngIf="createNewHoseVisible" class="btn btn-primary" (click)="addNewHose()">
                    Add a New Hose
                </button>&nbsp;
                <button *ngIf="hoseDetail" class="btn btn-primary" (click)="deleteThisHose()">
                    Delete This Hose
                </button>
                <label style="color: red; font-weight: bold">{{errorMessage}}</label>
            </div>

            <div class="row mt-20" *ngIf="showStuff">
                <div class="col-md-12">
                    <button class="btn btn-primary" (click)="saveHoseDetail(hoseDetail)">Save Current Hose</button>
                    <button class="btn btn-default" 
                            (click)="duplicateHoses_click(); modal1.show();" 
                            *ngIf="showDuplicateHoseButton">
                        Duplicate Hose
                    </button>
                </div>
                <div class="col-md-12">
                    <form class="form-inline mb-20 mt-20">
                        <div class="form-group">
                            <label>Serial Number</label>
                            <input [(ngModel)]="hoseDetail.SerialNumber" placeholder="(enter s/n)" (blur)="onSerialBlur()"
                                class="form-control input-list" name="serialNumber">
                        </div>

                        <div class="form-group">
                            <label>Customer Serial Number</label>
                            <input [(ngModel)]="hoseDetail.SerialNumberCustomer" placeholder="(customer s/n)" 
                                class="form-control input-list" name="serialNumberCustomer">
                        </div>
                    </form>
                </div>
            </div>

            <div class="row" *ngIf="showTabs">
                <ul class="nav nav-tabs">
                    <li class="{{tabClass[0]}}"><a href="javascript:void(0)" (click)="selectTab(0)">Visual Inspection</a></li>
                    <li class="{{tabClass[1]}}"><a href="javascript:void(0)" (click)="selectTab(1)">Continuity</a></li>
                    <li *ngIf="testMachineType=='File'" class="{{tabClass[2]}}"><a href="javascript:void(0)" (click)="selectTab(2)">Files</a></li>
                    <li *ngIf="testMachineType!='File'" class="{{tabClass[3]}}"><a href="javascript:void(0)" (click)="selectTab(3)">Chart</a></li>
                    <li class="{{tabClass[4]}}"><a href="javascript:void(0)" (click)="selectTab(4)">Elongation (2 page only)</a></li>
                    <li class="{{tabClass[5]}}"><a href="javascript:void(0)" (click)="selectTab(5)">Hydrostatic (2 page only)</a></li>
                </ul>
                <div *ngIf="tabVisible[0]">
                    <http-visual-inspection [(hoseDetail)]="hoseDetail" [(triggerUpdate)]="triggerUpdate"></http-visual-inspection>
                </div>
                <div *ngIf="tabVisible[1]">
                    <http-continuity [(hoseDetail)]="hoseDetail"></http-continuity>
                </div>
                <div *ngIf="tabVisible[2]">
                    <http-fileupload [(hoseDetail)]="hoseDetail"></http-fileupload>
                </div>
                <div *ngIf="tabVisible[3]">
                    <http-chart [(hoseDetail)]="hoseDetail"></http-chart>
                </div>
                <div *ngIf="tabVisible[4]">
                    <http-elongation [(hoseDetail)]="hoseDetail"></http-elongation>
                </div>
                <div *ngIf="tabVisible[5]">
                    <http-hydrostatic [(hoseDetail)]="hoseDetail"></http-hydrostatic>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal #modal1>
    <div class="app-modal-header">
        Duplicate Hoses
    </div>
    <div class="app-modal-body">
        How many hoses do you want to copy?
        <input type="number" [(ngModel)]="hosesToDuplicate" style="margin-left: 17px; width: 66px">
    </div>
    <div class="app-modal-footer">
        <button type="button" [disabled]="hosesToDuplicate <= 0 || duplicateDisabled" class="btn btn-primary" (click)="duplicateHose()"
            data-dismiss="myModal">Save</button>
        <button id="modal1Delete" type="button" class="btn btn-default" (click)="modal1.hide()">Close</button>
    </div>
</app-modal>