import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-customer-concerns',
	templateUrl: './customer-concerns.component.html',
	styleUrls: ['./customer-concerns.component.css']
})
export class CustomerConcernsComponent implements OnInit {

	url: SafeResourceUrl;
	legacyUrl = '/DataEntry/CustomerConcerns/CustomerConcernsList.aspx';

	constructor(
		public sanitizer: DomSanitizer,
		private settings: ApplicationSettings) {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.retrieveLegacyAppUrl() + this.legacyUrl);
	}

	ngOnInit() {
	}

}
