<div class="col-md-12 nopadding form-my mb-40">
    <div class="card-form">
        <div class="form-title">{{ page }}</div>
        <form class="form-horizontal" novalidate [formGroup]="customerForm">
            <div class="form-body">
                <div class="col-md-12">
                    <a class='btn btn-default btn-sm' [routerLink]="['/customers']">
                        <span class=" glyphicon glyphicon-chevron-left"></span>Back
                    </a>
                </div>

                <div class="mt-40">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label for="Customer" class="col-md-3 control-label">Customer Name <span class="required-star">*</span></label>
                            <div class="col-md-9">
                                <input formControlName="Customer" type="text" id="Customer" placeholder="Customer Name*" name="Customer" required class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="City" class="col-md-3 control-label">City <span class="required-star">*</span></label>
                            <div class="col-md-9">
                                <input formControlName="City" type="text" id="City" placeholder="City" name="City" required class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="PostalCode" class="col-md-3 control-label">Postal Code <span class="required-star">*</span></label>
                            <div class="col-md-9">
                                <input formControlName="PC" type="text" id="PostalCode" placeholder="Postal Code" name="PostalCode" required class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="Province" class="col-md-3 control-label">Province</label>
                            <div class="col-md-9">
                                <input formControlName="Pr" type="text" class="form-control" id="Pr" placeholder="Province.." name="Pr" />
                            </div>
                        </div>

                    </div>

                    <div class="col-md-6 col-sm-6 col-xs-12">

                        <div class="form-group">
                            <label for="Address1" class="col-md-3 control-label">Address 1 <span class="required-star">*</span></label>
                            <div class="col-md-9">
                                <textarea class="form-control" formControlName="Address1" type="text" id="Address1" placeholder="Address1" name="Address1" required></textarea>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="Address2" class="col-md-3 control-label">Address 2</label>
                            <div class="col-md-9">
                                <textarea formControlName="Address2" type="text" id="Address2" placeholder="Address2" name="Address2" class="form-control"></textarea>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div class="form-footer">

                <div class="col-md-12 alert alert-danger mt-20" *ngIf='errorMessage'>
                    <strong>Error !</strong> {{ errorMessage }}.
                </div>

                <div class="col-md-12 buttons-row text-center">
                    <button class="btn btn-flat btn-primary" [disabled]="buttonPushed" (click)="saveCustomer(true)" style="width: 150px; margin-right:10px" [disabled]='!customerForm.valid'>
                        <span class="glyphicon glyphicon-floppy-save"></span> Save & Return &nbsp;
                </button>
                    <span>
                        <button class="btn btn-flat btn-primary"
                            [disabled]="buttonPushed"
                            (click)="saveCustomer(false)"
                            style="width: 150px; margin-right:10px"
                            [disabled]='!customerForm.valid'>
                            <span class="glyphicon glyphicon-floppy-save"></span> Save & Continue &nbsp;
                    </button>
                    </span>
                    <span>
                        <button class="btn btn-flat btn-warning"
                            [disabled]="buttonPushed"
                            (click)="cancel()"
                            style="width: 150px; margin-right:10px"
                            [disabled]='!customerForm.valid'>
                            <span class=""></span> Cancel &nbsp;
                    </button>
                    </span>
                    <span>
                        <button class="btn btn-flat btn-danger"
                            [disabled]="buttonPushed"
                            (click)="deleteCustomer()"
                            style="width: 150px; margin-right:10px"
                            [disabled]='!customerForm.valid'>
                            <span class="glyphicon glyphicon-remove-circle"></span> Delete &nbsp;
                    </button>
                    </span>
                </div>

                <div class="col-md-12 footer-info">
                    <div class="text-left col-sm-6">
                        Updated By: <span *ngIf="customer" class="label label-default">{{customer.UpdatedBy}}</span>

                    </div>
                    <div class="text-right col-sm-6">
                        Time: <span *ngIf="customer" class="label label-success">{{customer.UpdatedDate}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>