import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IHydrotestcalibration} from './Hydrotestcalibration-model/Hydrotestcalibration';
import { IHydrotestcalibrations } from './Hydrotestcalibration-model/Hydrotestcalibrations';
import { IHydrotestcalibrationSearch } from './Hydrotestcalibration-model/HydrotestcalibrationSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class HydrotestcalibrationService {
	private baseUrl;
	public pageTitle = 'HydroTestCalibrations List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Hydrotestcalibrations';
	}

	getHydrotestcalibration(id: number):  Observable<IHydrotestcalibration> {
		if (id === 0) {
			return of(this.initializeHydrotestcalibration());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IHydrotestcalibration>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveHydrotestcalibration(hydrotestcalibration: IHydrotestcalibration): Observable<any> {
		if (hydrotestcalibration.Id === 0) {
			return this.createHydrotestcalibration(hydrotestcalibration);
		}
		return this.updateHydrotestcalibration(hydrotestcalibration);
	}

	private createHydrotestcalibration(hydrotestcalibration: IHydrotestcalibration): Observable<any> {
		hydrotestcalibration.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/hydrotestcalibrationcreate', hydrotestcalibration)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateHydrotestcalibration(hydrotestcalibration: IHydrotestcalibration): Observable<IHydrotestcalibration> {
		return this.authHttp.put<IHydrotestcalibration>(this.baseUrl, hydrotestcalibration)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteHydrotestcalibration(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeHydrotestcalibration(): IHydrotestcalibration {
		return {
			Id: 0,
			machineip: '',
			PressureSwitch: '',
			Pressure: 0,
			PLCValue: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IHydrotestcalibration;
	}

	public getRecordSet(search: IHydrotestcalibrationSearch): Observable<IHydrotestcalibrations> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('machineip', search.machineip, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PressureSwitch', search.PressureSwitch, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Pressure', search.Pressure, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PLCValue', search.PLCValue, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('machineip', search.machineip ? search.machineip : '')
			.set('PressureSwitch', search.PressureSwitch ? search.PressureSwitch : '')
			.set('Pressure', search.Pressure ? search.Pressure : '')
			.set('PLCValue', search.PLCValue ? search.PLCValue : '')
		};

		return this.authHttp.get<IHydrotestcalibrations>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IHydrotestcalibrations>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IHydrotestcalibrationSearch { 
		let search: IHydrotestcalibrationSearch = {
			Id: 1,
			machineip: '',
			PressureSwitch: '',
			Pressure: '',
			PLCValue: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IHydrotestcalibrationSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('machineip', search.machineip ? search.machineip : '')
			.set('PressureSwitch', search.PressureSwitch ? search.PressureSwitch : '')
			.set('Pressure', search.Pressure ? search.Pressure : '')
			.set('PLCValue', search.PLCValue ? search.PLCValue : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}