<div class="panel panel-primary">
    <div class="panel-heading">
        Label Next Steps
    </div>
    <div class="panel-body">
        <div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
        <div class="row">
            <div class="col-4 col-md-4">                    
                <div [ngClass]="{'active': menutab==='tab1'}">
                    <button class="btntab" (click)="mainmenufunt('tab1')"  [ngClass]="{'active': menutab==='tab1'}" [disabled]='progressStatus'>Print
                        Single Bin Label 4x2</button>
                </div>              
                <div>
                    <div [ngClass]="{'active': menutab==='tab2'}">
                        <button class="btntab" (click)="mainmenufunt('tab2')" [ngClass]="{'active': menutab==='tab2'}" [disabled]='progressStatus'>Rack
                            Bin
                            Labels</button>
                    </div>
                    <div *ngIf="menutab==='tab2'">
                        <div class="divsubmenu">
                            <div> <button class="btnsubmenutab" (click)="submenutab2funt('tb2submenu1')"
                                    [ngClass]="{'submenuactive': submenutab2==='tb2submenu1'}" [disabled]='progressStatus'><!-- On button click submenutab2funt set tb2submenu1 to submenutab2 so tb2submenu1 becomes active -->
                                4X2 Part Number Only
                                </button></div>
                            <div> <button class="btnsubmenutab" (click)="submenutab2funt('tb2submenu2')"
                                    [ngClass]="{'submenuactive': submenutab2==='tb2submenu2'}" [disabled]='progressStatus'>
                                    4X2 Rack Label Code 39
                                </button></div>
                            <div> <button class="btnsubmenutab" (click)="submenutab2funt('tb2submenu3')"
                                    [ngClass]="{'submenuactive': submenutab2==='tb2submenu3'}" [disabled]='progressStatus'>
                                    Label By Product Group Code 39 2X1.63
                                    </button></div>
                        </div>
                    </div>
                </div>   
                <div [ngClass]="{'active': menutab==='tab3'}">
                    <button class="btntab" (click)="mainmenufunt('tab3')" [ngClass]="{'active': menutab==='tab3'}" [disabled]='progressStatus'>Find a
                        Label Tab</button>
                </div>        
            </div>
            <div class="col-8 col-md-8">
                <div *ngIf="menutab=='tab1'" class="divdatatab">
                    No Data Available
                </div>
                <div *ngIf="menutab=='tab2'" class="divdatatab">
                    <div class="row form-group">
                        <div>
                            <div class="col-md-3">
                                Select a Product Group
                            </div>
                            <div class="col-md-2">
                                <select class="form-control" [(ngModel)]="rackBinPLine">
                                    <option></option>
                                    <option *ngFor='let data of pLineList;' [value]="data.PLine">
                                        {{data.PLine}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-2 margin-right" *ngIf="rackBinPLine">
                            <button class="btn btn-primary" (click)="createRackBinLabels()" [disabled]='progressStatus'>Go</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="menutab=='tab3'" class="divdatatab">
                    No Data Available
                </div>
            </div>
        </div>

    </div>
</div>
<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
    </div>