import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ILookuplistSearch } from '../Lookuplist-model/LookuplistSearch';
import { ILookuplists } from '../Lookuplist-model/Lookuplists';
import { ILookuplist } from '../lookuplist-model/lookuplist';
import { LookuplistService } from '../lookuplist-service';

@Component({
	templateUrl: './lookuplist-edit.component.html',
	styleUrls: ['lookuplist-edit.component.css']
})

export class LookuplistEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit LookUpLists';
	errorMessage: string;
	lookuplistForm: UntypedFormGroup;
	lookuplist: ILookuplist;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public LookuplistSearch: ILookuplistSearch;
	public ListData: ILookuplists;
	public showNextRecordButton: boolean = true;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private lookuplistService: LookuplistService,
		) {
	}

	ngOnInit(): void {
		this.lookuplistForm = this.fb.group({
			Id: '',
			Form: '',
			Field: '',
			Value: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getLookuplist(id);
			}
		);
	}

	public getSelectedRecordSet():void{
		this.lookuplistService.getRecordSet(this.LookuplistSearch)
			.subscribe((obj: ILookuplists) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ILookuplists){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.lookuplist.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveLookuplist(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.lookuplistForm.valid) {
			const obj = Object.assign({ }, this.lookuplist, this.lookuplistForm.value);
			this.lookuplistService.saveLookuplist(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.lookuplistForm.reset();
			this.router.navigate(['/lookuplists']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.lookuplist.Id === 0) {
			this.router.navigate(['/lookuplist-details', data.Id]);
		} 
		else if (this.lookuplist.Id != 0){
			this.getLookuplist(this.lookuplist.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.lookuplist.Id)
				record = count;
		});

		this.getLookuplist(this.ListData.data[record].Id)
	}

	getLookuplist(id: number): void {
		this.lookuplistService.getLookuplist(id)
			.subscribe((obj: ILookuplist) => this.onLookuplistRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onLookuplistRetrieved(lookuplist: ILookuplist): void {
		if (this.lookuplistForm) {
			this.lookuplistForm.reset();
		}
		this.lookuplist = lookuplist;
		if (this.lookuplist.Id === 0) {
			this.page = 'Add LookUpLists';
		} else {
			this.page = 'Edit LookUpLists';
		}

		this.lookuplistForm.patchValue({
			Id: this.lookuplist.Id,
			Form: this.lookuplist.Form,
			Field: this.lookuplist.Field,
			Value: this.lookuplist.Value,
			CreatedDate: this.lookuplist.CreatedDate,
			CreatedBy: this.lookuplist.CreatedBy,
			UpdatedDate: this.lookuplist.UpdatedDate,
			UpdatedBy: this.lookuplist.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteLookuplist(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.lookuplist.Form}?`)) {
			this.lookuplistService.deleteLookuplist(this.lookuplist.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/lookuplists']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('LookUpLists List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.LookuplistSearch = this.lookuplistService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Form')
				this.LookuplistSearch.Form = i.Value;

			if (i.Item == 'Field')
				this.LookuplistSearch.Field = i.Value;

			if (i.Item == 'Value')
				this.LookuplistSearch.Value = i.Value;

			if (i.Item == 'orderByString')
				this.LookuplistSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.LookuplistSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
