import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'
import { LookuplistListComponent } from './lookuplist-list/lookuplist-list.component';
import { LookuplistEditComponent } from './lookuplist-edit/lookuplist-edit.component';
import { LookuplistHelpComponent } from './lookuplist-help/lookuplist-help.component';
import { LookuplistService } from './lookuplist-service';

@NgModule({
	declarations: [
		LookuplistListComponent
		, LookuplistEditComponent
		, LookuplistHelpComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'lookuplists', component: LookuplistListComponent },
			{ path: 'lookuplist-details/:Id', component: LookuplistEditComponent },
			{ path: 'lookuplist-help', component: LookuplistHelpComponent },
		])
	],
	providers: [
		LookuplistService
	]
})
export class LookuplistModule { }
