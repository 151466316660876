import { HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';

import {IRole} from '../../../admin/roles/role-model/role';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IUser } from '../user-model/user';
import { UserChangePassword } from '../user-model/user-change-password';
import { UserCreateModel } from '../user-model/user-create';
import { throwError } from 'rxjs'
import { IUsersearch } from 'src/app/admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from 'src/app/admin/usersearches/usersearch-service';
import { IAspnetuser } from '../aspnetuser-model/Aspnetuser';
import { IAspnetusers } from '../aspnetuser-model/Aspnetusers';
import { IAspnetuserSearch } from '../aspnetuser-model/AspnetuserSearch';
import { retry, catchError, map } from 'rxjs/operators';

@Injectable()
export class UserService {
	private baseUrl;
	public pageTitle = 'Users List';
	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings,
	    private userSearchService: UsersearchService
	) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/users';
	}

	getUsers(): Observable<IUser[]> {
		return this.authHttp.get<IUser[]>(this.baseUrl).catch(this.handleError);
	}

	getUsersForList(
		status: boolean,
		firstName: string,
		lastName: string
	): Observable<IUser[]> {
		return this.authHttp
			.get<IUser[]>(
				this.baseUrl +
					`?status=${status}&firstName=${firstName}&lastName=${lastName}`
			)
			.catch(this.handleError);
	}

	getUsersDropdown(email: string): Observable<IUser[]> {
		return this.authHttp
			.get<IUser[]>(this.baseUrl + `?email=${email}`)
			.catch(this.handleError);
	}

	saveUser(user: UserCreateModel): Observable<IUser> {
		const url = this.baseUrl + '/create';

		return this.authHttp.post<IUser>(url, user).catch(this.handleError);
	}

	getUser(id: string): Observable<IUser> {
		const url = this.baseUrl + '?id=' + id;
		return this.authHttp.get<IUser>(url).catch(this.handleError);
	}

	updateUser(user: IUser): Observable<IUser> {
		return this.authHttp
			.put(this.baseUrl + '/edit', user)
			.catch(this.handleError);
	}

	changePassword(
		changedPassword: UserChangePassword
	): Observable<UserChangePassword> {
		return this.authHttp
			.put(this.baseUrl + '/changepassword', changedPassword)
			.catch(this.handleError);
	}

	getRoles(userId: string): Observable<string[]> {
		return this.authHttp
			.get<string[]>(this.baseUrl + '/Roles/Filter?userId=' + userId)
			.catch(this.handleError);
	}
	getUserRoles(userId: string): Observable<string[]> {
		return this.authHttp
			.get<string[]>(this.baseUrl + '/Roles/UserRoles?userId=' + userId)
			.catch(this.handleError);
	}
	removeFromRole(roleToDeleteFrom: IRole): Observable<Response> {
		return this.authHttp
			.put(this.baseUrl + '/Roles/Remove', roleToDeleteFrom)
			.catch(this.handleError);
	}

	addToRole(roleToAdd: IRole): Observable<Response> {
		return this.authHttp
			.put(this.baseUrl + '/Roles/Add', roleToAdd)
			.catch(this.handleError);
	}

	private handleError(error: any): Observable<any> {
		return throwError(error.error.ErrorDescription || 'Server error');
	}
	public getRecordSet(search: IAspnetuserSearch): Observable<IAspnetusers> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('FirstName', search.FirstName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LastName', search.LastName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Initials', search.Initials, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Title', search.Title, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Address', search.Address, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('City', search.City, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Region', search.Region, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PostalCode', search.PostalCode, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('HomePhone', search.HomePhone, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CellPhone', search.CellPhone, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BirthDate', search.BirthDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('HireDate', search.HireDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EmergencyName', search.EmergencyName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EmergencyPhone', search.EmergencyPhone, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Active', search.Active.toString(), this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Image', search.Image, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Signature', search.Signature, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CompanyId', search.CompanyId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CompanyName', search.CompanyName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Email', search.Email, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EmailConfirmed', search.EmailConfirmed, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PasswordHash', search.PasswordHash, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SecurityStamp', search.SecurityStamp, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PhoneNumber', search.PhoneNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PhoneNumberConfirmed', search.PhoneNumberConfirmed, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('TwoFactorEnabled', search.TwoFactorEnabled, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LockoutEndDateUtc', search.LockoutEndDateUtc, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LockoutEnabled', search.LockoutEnabled, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('AccessFailedCount', search.AccessFailedCount, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('UserName', search.UserName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BranchId', search.BranchId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			
			.set('FirstName', search.FirstName ? search.FirstName : '')
			.set('LastName', search.LastName ? search.LastName : '')
			.set('Initials', search.Initials ? search.Initials : '')
			.set('Title', search.Title ? search.Title : '')
			.set('Address', search.Address ? search.Address : '')
			.set('City', search.City ? search.City : '')
			.set('Region', search.Region ? search.Region : '')
			.set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('HomePhone', search.HomePhone ? search.HomePhone : '')
			.set('CellPhone', search.CellPhone ? search.CellPhone : '')
			.set('BirthDate', search.BirthDate ? search.BirthDate : '')
			.set('HireDate', search.HireDate ? search.HireDate : '')
			.set('EmergencyName', search.EmergencyName ? search.EmergencyName : '')
			.set('EmergencyPhone', search.EmergencyPhone ? search.EmergencyPhone : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Active', search.Active ? search.Active.toString() : 'false')
			.set('Image', search.Image ? search.Image : '')
			.set('Signature', search.Signature ? search.Signature : '')
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('CompanyName', search.CompanyName ? search.CompanyName : '')
			.set('Email', search.Email ? search.Email : '')
			.set('EmailConfirmed', search.EmailConfirmed ? search.EmailConfirmed : 'false')
			.set('PasswordHash', search.PasswordHash ? search.PasswordHash : '')
			.set('SecurityStamp', search.SecurityStamp ? search.SecurityStamp : '')
			.set('PhoneNumber', search.PhoneNumber ? search.PhoneNumber : '')
			.set('PhoneNumberConfirmed', search.PhoneNumberConfirmed ? search.PhoneNumberConfirmed : 'false')
			.set('TwoFactorEnabled', search.TwoFactorEnabled ? search.TwoFactorEnabled : 'false')
			.set('LockoutEndDateUtc', search.LockoutEndDateUtc ? search.LockoutEndDateUtc : '')
			.set('LockoutEnabled', search.LockoutEnabled ? search.LockoutEnabled : 'false')
			.set('AccessFailedCount', search.AccessFailedCount ? search.AccessFailedCount : '')
			.set('UserName', search.UserName ? search.UserName : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
		};
       
		return this.authHttp.get<IAspnetusers>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IAspnetusers>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IAspnetuserSearch { 
		let search: IAspnetuserSearch = {
			Id: 1,
			FirstName: '',
			LastName: '',
			Initials: '',
			Title: '',
			Address: '',
			City: '',
			Region: '',
			PostalCode: '',
			HomePhone: '',
			CellPhone: '',
			BirthDate: '',
			HireDate: '',
			EmergencyName: '',
			EmergencyPhone: '',
			Notes: '',
			Active: false,
			Image: '',
			Signature: '',
			CompanyId: '',
			CompanyName: '',
			Email: '',
			EmailConfirmed: '',
			PasswordHash: '',
			SecurityStamp: '',
			PhoneNumber: '',
			PhoneNumberConfirmed: '',
			TwoFactorEnabled: '',
			LockoutEndDateUtc: '',
			LockoutEnabled: '',
			AccessFailedCount: '',
			UserName: '',
			BranchId: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IAspnetuserSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('FirstName', search.FirstName ? search.FirstName : '')
			.set('LastName', search.LastName ? search.LastName : '')
			.set('Initials', search.Initials ? search.Initials : '')
			.set('Title', search.Title ? search.Title : '')
			.set('Address', search.Address ? search.Address : '')
			.set('City', search.City ? search.City : '')
			.set('Region', search.Region ? search.Region : '')
			.set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('HomePhone', search.HomePhone ? search.HomePhone : '')
			.set('CellPhone', search.CellPhone ? search.CellPhone : '')
			.set('BirthDate', search.BirthDate ? search.BirthDate : '')
			.set('HireDate', search.HireDate ? search.HireDate : '')
			.set('EmergencyName', search.EmergencyName ? search.EmergencyName : '')
			.set('EmergencyPhone', search.EmergencyPhone ? search.EmergencyPhone : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Active', search.Active ? search.Active.toString() : 'false')
			.set('Image', search.Image ? search.Image : '')
			.set('Signature', search.Signature ? search.Signature : '')
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('CompanyName', search.CompanyName ? search.CompanyName : '')
			.set('Email', search.Email ? search.Email : '')
			.set('EmailConfirmed', search.EmailConfirmed ? search.EmailConfirmed : 'false')
			.set('PasswordHash', search.PasswordHash ? search.PasswordHash : '')
			.set('SecurityStamp', search.SecurityStamp ? search.SecurityStamp : '')
			.set('PhoneNumber', search.PhoneNumber ? search.PhoneNumber : '')
			.set('PhoneNumberConfirmed', search.PhoneNumberConfirmed ? search.PhoneNumberConfirmed : 'false')
			.set('TwoFactorEnabled', search.TwoFactorEnabled ? search.TwoFactorEnabled : 'false')
			.set('LockoutEndDateUtc', search.LockoutEndDateUtc ? search.LockoutEndDateUtc : '')
			.set('LockoutEnabled', search.LockoutEnabled ? search.LockoutEnabled : 'false')
			.set('AccessFailedCount', search.AccessFailedCount ? search.AccessFailedCount : '')
			.set('UserName', search.UserName ? search.UserName : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getLinkUserData(key: string): Observable<any> {
		return this.authHttp
			.get<any>(this.baseUrl + '/CheckLinkResetPassword?key=' + key)
			.catch(this.handleError);
	}
	
	resetUserPassword(
		resetPasswordData,userId
	) : any{
		return this.authHttp
			.post<any>(this.baseUrl + '/ResetPasswordSubmit?userId='+userId, resetPasswordData)
			.catch(this.handleError);
	}
	getAllUsers(idE: string): Observable<IAspnetuser[]> {
		return this.authHttp
		.get<IAspnetuser[]>( `${this.baseUrl}/GetAllUsers?aspnetuserText=${idE}`)
		.catch(this.handleError);;

		
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadimage`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllUsersCustomerConcerns(idE: string): Observable<IAspnetuser[]> {
		var localUrl = this.settings.retrieveUrl() + '/api/Customerconcerns';

	   return this.authHttp
	   .get<IAspnetuser[]>( `${localUrl}?aspnetuserText=${idE}`)
	   .catch(this.handleError);;

	   
   }

}
