import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { CustomerconcernListComponent } from './customerconcern-list/customerconcern-list.component';
import { CustomerconcernEditComponent } from './customerconcern-edit/customerconcern-edit.component';
import { CustomerconcernService } from './customerconcern-service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';
import { CustomerconcernMgmtComponent } from './customerconcern-mgmt/customerconcern-mgmt.component';
import { AuthGuard } from '../auth/auth-guard.service';

@NgModule({
	declarations: [
		CustomerconcernListComponent
		, CustomerconcernEditComponent
		, CustomerconcernMgmtComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		MatPaginatorModule,
		NgSelectModule,
		RouterModule.forChild([
			{ path: 'customerconcerns', component: CustomerconcernListComponent },
			{ path: 'customerconcern-details/:Id', component: CustomerconcernEditComponent, canActivate: [AuthGuard] },
			{ path: 'customerconcern-mgmt', component: CustomerconcernMgmtComponent },
		])
	],
	providers: [
		CustomerconcernService,
		DatePipe
	]
})
export class CustomerconcernModule { }
