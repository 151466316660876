import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { IFerrule } from '../ferrule.model/ferrule';
import { FerruleService } from '../ferrule.service';

@Component({
	selector: 'app-ferrule-edit',
	templateUrl: './ferrule-edit.component.html',
	styleUrls: ['./ferrule-edit.component.css']
})
export class FerruleEditComponent implements OnInit {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit Ferrule';
	errorMessage: string;
	ferrule: IFerrule;
	private sub: Subscription;
	public buttonPushed = false;
	public message;
	ferruleForm: UntypedFormGroup;
	editPermission = false;
	
	constructor(private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private ferruleService: FerruleService,
		private auth: AuthService) {
	}

	ngOnInit(): void {

		this.auth.getRoles().includes('Update Parts')?this.editPermission=true:this.editPermission=false;
		this.ferruleForm = this.fb.group({
			Id: 0,
			Ferrule: '',
			InsideDiameter: '',
			WallThickness: '',

			CrimpInstructions: '',
			Notes: '',
			Pressure: '',
			PressureTest: '',

			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getferrule(id);
			}
		);

	}

	saveFerrule(con: boolean): void {
		if(this.editPermission){
		this.buttonPushed = true;
		if (this.ferruleForm.valid) {
			this.errorMessage = '';
			const obj = Object.assign({}, this.ferrule, this.ferruleForm.value);
			this.ferruleService.saveFerrule(obj)
				.subscribe((data) => this.onSaveComplete(con, data)
					, (error: any) => {
						this.errorMessage = <any>error;
						this.message = 'The data has been updated';
					});
		} else {
			this.errorMessage = 'Please fill the required fields of form';
		}
	}else{
		this.errorMessage = 'You dont have permission to edit this element';
	}
	}


	onSaveComplete(con: boolean, data: any): void {
		if (con) {
			this.ferruleForm.reset();
			this.router.navigate(['/ferrules']);
		} else {
			if (data !== null && this.ferrule.Id === 0) {
				this.router.navigate(['/ferrule-details', data.Id]);
			}
		}
		this.message = 'The data has been Updated';
		this.buttonPushed = false;
	}

	getferrule(id: number): void {
		this.ferruleService.getFerrulebyId(id)
			.subscribe((obj: IFerrule) => this.onferruleRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onferruleRetrieved(ferrule: IFerrule): void {
		if (this.ferruleForm) {
			this.ferruleForm.reset();
		}
		this.ferrule = ferrule;
		if (this.ferrule.Id === 0) {
			this.page = 'Add ferrule';
		} else {
			this.page = 'Edit ferrule';
		}

		this.ferruleForm.patchValue({

			Id: this.ferrule.Id,
			Ferrule: this.ferrule.Ferrule,
			InsideDiameter: this.ferrule.InsideDiameter,
			WallThickness: this.ferrule.WallThickness,

			CrimpInstructions: this.ferrule.CrimpInstructions,
			Notes: this.ferrule.Notes,
			Pressure: this.ferrule.Pressure,

			PressureTest: this.ferrule.PressureTest,

			CreatedDate: this.ferrule.CreatedDate,
			CreatedBy: this.ferrule.CreatedBy,
			UpdatedDate: this.ferrule.UpdatedDate,
			UpdatedBy: this.ferrule.UpdatedBy,
		});
	}

	deleteFerrule(): void {
		if(this.editPermission){
		this.buttonPushed = true;
		if (confirm(`Are you sure you want to delete the item: ${this.ferrule.Ferrule}?`)) {
			this.ferruleService.deleteFerrule(this.ferrule.Id)
				.subscribe(() => this.onSaveComplete(true, null)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}else{
		this.errorMessage = 'You dont have permission to edit this element';
	}
	}

	cancel(): void {
		this.router.navigate(['/ferrules']);
	}
}
