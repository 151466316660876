<div class="panel panel-primary">
    <div class="panel-heading">
        Label Creator
    </div>
    <div class="panel-body">
        <div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
        <!-- <div class="row">
            <div class= 'col-md-12'>
                <label class="text-danger">
                    Label generation temporarily disabled at this location due to server load.
                </label>
            </div>
        </div> -->
        <div class="row">
            <div class="col-4 col-md-4">
                <div [ngClass]="{'active': menutab==='tab1'}">
                    <button class="btntab" (click)="mainmenufunt('tab1')"  [ngClass]="{'active': menutab==='tab1'}" [disabled]='progressStatus'>Labels by
                        Product Group</button>
                </div>
                <div [ngClass]="{'active': menutab==='tab2'}" class="divtab">  <!-- On button click mainmenufunt set tab1 to menutab so tab1 becomes active -->
                    <button class="btntab" (click)="mainmenufunt('tab2')"  [ngClass]="{'active': menutab==='tab2'}" [disabled]='progressStatus'>Labels by
                        Product Category </button>
                </div>                  
                 <div [ngClass]="{'active': menutab==='tab3'}">
                    <button class="btntab" (click)="mainmenufunt('tab3')"  [ngClass]="{'active': menutab==='tab3'}" [disabled]='progressStatus'>All Labels
                        For Customer</button>
                </div> 
            </div>
            <div class="col-8 col-md-8">
                <div *ngIf="menutab=='tab1'" class="divdatatab">
                    <div class="row form-group">
                        <div>
                            <div class="col-md-3">
                                Select a Customer
                            </div>
                            <div class="col-md-6">
                                <select class="form-control" [(ngModel)]="selCustomer">
                                    <option></option>
                                    <option *ngFor='let data of customerList;' [value]="data.Id">
                                        {{data.Customer}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div>
                            <div class="col-md-3">
                                Select a Product Group
                            </div>
                            <div class="col-md-6">
                                <select class="form-control" [(ngModel)]="pLine">
                                    <option></option>
                                    <option *ngFor='let data of priceLineList;' [value]="data.PriceLine">
                                        {{data.PriceLine}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div>
                            <div class="col-md-8">
                                <input type='radio' value="all-parts" [(ngModel)]='parts_filter' style="margin-top: 20px;" />&nbsp;
					            <label> All Parts</label>
                                <br>
                                <input type='radio' value="custom" [(ngModel)]='parts_filter' style="margin-top: 20px;" />&nbsp;
					            <label> Only Parts with Customer Part Number</label>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-md-2 margin-right" *ngIf="pLine">
                            <button class="btn btn-primary" (click)="createLabelByProductGroupCustomer()" [disabled]='progressStatus'>Go</button>
                        </div>
                     
                        <!-- <div class="col-md-8" style="color: blue;">
                            {{prodGroupFileStatus}}
                        </div> -->
                    </div>
                </div>
                <div *ngIf="menutab=='tab2'" class="divdatatab"><!-- Second section displays on basis of menutab value  -->
                    <div class="row form-group">
                        <div>
                            <div class="col-md-3">
                                Select a Customer
                            </div>
                            <div class="col-md-6">
                                <select class="form-control" [(ngModel)]="selCustomer">
                                    <option></option>
                                    <option *ngFor='let data of customerList;' [value]="data.Id">
                                        {{data.Customer}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-3">
                            Select a Product Category
                        </div>
                        <div class="col-md-6">
                            <select class="form-control" [(ngModel)]="labelTag">
                                <option></option>
                                <option *ngFor='let data of tagList;' [value]="data.Tag">
                                    {{data.Tag}}</option>
                            </select>
                        </div>

                    </div>

                    <div class="row form-group">
                        <div>
                            <div class="col-md-8">
                                <input type='radio' value="all-parts" [(ngModel)]='parts_filter' style="margin-top: 20px;" />&nbsp;
					            <label> All Parts</label>
                                <br>
                                <input type='radio' value="custom" [(ngModel)]='parts_filter' style="margin-top: 20px;" />&nbsp;
					            <label> Only Parts with Customer Part Number</label>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-md-2" *ngIf="labelTag">
                            <button class="btn btn-primary" (click)="createLabelByProductCategoryCustomer()" [disabled]='progressStatus'>Go</button>
                            <!-- <button class="btn btn-primary" (click)="createLabelByTag()" [disabled]=true>Go</button> -->
                        </div>

                    </div>
                </div>


                <div *ngIf="menutab=='tab3'" class="divdatatab"><!-- Third section displays on basis of menutab value  -->
                    <div class="row form-group">
                        <div>
                            <div class="col-md-3">
                                Select a Customer
                            </div>
                            <div class="col-md-6">
                                <select class="form-control" [(ngModel)]="selCustomer">
                                    <option></option>
                                    <option *ngFor='let data of customerList;' [value]="data.Id">
                                        {{data.Customer + ' ('+data.Qty+' part'+(data.Qty>1?'s':'')+')'}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
    
                    <div class="row form-group">
                        <div>
                            <div class="col-md-8">
                                <input disabled type='radio' value="all-parts" [(ngModel)]='parts_filterAll' style="margin-top: 20px;" />&nbsp;
					            <label> All Customer Part Number</label>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-md-2" *ngIf="selCustomer">
                            <button class="btn btn-primary" (click)="createLabelAllCustomerParts('5160')" [disabled]='progressStatus'>Avery 5160 Labels</button>
                        </div>

                        <div class="col-md-2" *ngIf="selCustomer">
                            <button class="btn btn-primary" (click)="createLabelAllCustomerParts('6578')" [disabled]='progressStatus'>Avery 6578 (barcode)</button>
                        </div>

                    </div>
                </div>
            
            </div>
        </div>
    </div>
</div>
<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
    </div>