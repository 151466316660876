
<div class='panel panel-primary animaPlaceholder' *ngIf = 'customerplanrecord'>
	<div class='panel-heading'>
		<span *ngIf = 'customerplanrecord' >{{ page }} :{{ customerplanrecord.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='customerplanrecordForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> File Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='FileName' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Customer</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Customer' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Notes</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Notes' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> File Month</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='FileMonth' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary'
							[disabled]='buttonPushed'
								(click)='saveCustomerPlanrecord(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!customerplanrecordForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveCustomerPlanrecord(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!customerplanrecordForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveCustomerPlanrecord(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!customerplanrecordForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right'>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!customerplanrecordForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='deleteCustomerPlanrecord()'
								style='width: 150px; margin-right:10px'
								[disabled]='!customerplanrecordForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='customerplanrecord'>{{customerplanrecord.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='customerplanrecord'>{{customerplanrecord.UpdatedDate}}</label></span>
					</div>
				</div>
				<div class='table-container' *ngIf = 'customerplanrecord.Id'>
					<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
						<ng-container matColumnDef='Id'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>Id</th>
							<td mat-cell *matCellDef='let e'>
								<!-- <button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/customerplandetail-details', e.Id]">{{e.Id}}</button> -->
							{{e.Id}}
							</td>
						</ng-container>
						<ng-container matColumnDef='CustomerPlanRecordId'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Customer Plan Record Id</th>
							<td mat-cell *matCellDef='let e'>{{e.CustomerPlanRecordId}}</td>
						</ng-container>
						<ng-container matColumnDef='PartNumber'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Part Number</th>
							<td mat-cell *matCellDef='let e'>{{e.PartNumber}}</td>
						</ng-container>
						<ng-container matColumnDef='Revision'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Revision</th>
							<td mat-cell *matCellDef='let e'>{{e.Revision}}</td>
						</ng-container>
						<ng-container matColumnDef='CustomerPlanMonth'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Customer Plan Month</th>
							<td mat-cell *matCellDef='let e'>{{e.CustomerPlanMonth}}</td>
						</ng-container>
						<ng-container matColumnDef='Quantity'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Quantity</th>
							<td mat-cell *matCellDef='let e'>{{e.Quantity}}</td>
						</ng-container>
						<ng-container matColumnDef='EclipseId'>
							<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> EclipseId</th>
							<td mat-cell *matCellDef='let e'>{{e.EclipseId}}</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
						<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
					</table>
					</div>
	</div>
	
</div>