
<div class="panel panel-primary animaPlaceholder">
<div class="container-fluid">
	<div class="panel-heading">
		{{page}}
		<div class='has-error' class="text-danger" *ngIf='errorMessage'>
			<button class="btn btn-primary btn-sm" (click)="resetError()">Reset Error</button>
			{{ errorMessage }}
		</div>
	</div>
	<div class="panel-body">
		<form class="form-horizontal" novalidate [formGroup]="nonStockLabelForm">
			<fieldset>
				<div class="row">
					<div class="col-md-5">
						<div class="form-group">
							<label class="col-md-4 control-label">Part Number</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="PartNumber" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Part Description</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="PartDescription" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Upc / Part Number</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="Upc" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Quantity</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="Quantity" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Number Of Labels</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="NumberOfLabels" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Qty Type</label>
							<div class="col-md-8">
								<select formControlName='QtyType' class='form-control block-element'>
									<option *ngFor='let qtytype of qtyTypes' [value]="qtytype">{{qtytype}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Label Type</label>
							<div class="col-md-8">
								<select formControlName='LabelType' class='form-control block-element'>
									<option *ngFor='let item of labelTypes' [value]="item.value">{{item.label}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Customer</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="Customer" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Sales Order</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="SalesOrder" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Printers</label>
							<div class="col-md-8">
								<select formControlName='PrinterName' class='form-control block-element'>					
									<option *ngFor='let item of printerList' [value]="item.IP">{{item.Name+'('+item.IP+')'}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Layout</label>
							<div class="col-md-8">
								<select formControlName='Layout' class='form-control block-element'>
									<option *ngFor='let item of LayoutList' [value]="item">{{item}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<div class="col-md-offset-2 col-md-4 col-xs-4">
								<button class="btn btn-primary btn-sm" (click)="print()" [disabled]="!nonStockLabelForm.valid || !serverStatus || !printerAvailable">Print</button>
							</div>
							<div class="col-md-offset-2 col-md-4 col-xs-4" *ngIf="!serverStatus || !printerAvailable">
								<button class="btn btn-primary btn-sm" (click)="reconnect()"
									>Reconnect</button>
							</div>
						</div>
						<div   *ngIf="serverStatus && printerAvailable" >
							<label style="color: green;font-size: 18px;">local communications Working</label>
						</div>
						<div  *ngIf="!serverStatus || !printerAvailable" >
							<label style="color: red;font-size: 18px;">{{serverStatusMessage}}</label>
						</div>
					</div>
				
					<div class="col-md-7">

						<div class="container" style="height: 300px; width: 500px;">
							<img src="../../../assets/packagelabel.jpg" style="width:500px">
							<div class="bottom-left">{{nonStockLabelForm.get('Quantity').value}}</div>
							<div class="top-left">{{nonStockLabelForm.get('PartNumber').value}}</div>
							<div class="top-right">{{nonStockLabelForm.get('Upc').value}}</div>
							<div class="bottom-right">{{nonStockLabelForm.get('Quantity').value}}</div>
							<div class="centered">{{nonStockLabelForm.get('QtyType').value}}</div>
							<div class="customer">{{nonStockLabelForm.get('Customer').value}}</div>
							<div class="salesorder">{{nonStockLabelForm.get('SalesOrder').value}}</div>
							(*print will be slightly different)
						</div>

						<!-- <img src="../../../assets/packagelabel.jpg" alt="" class="img-responsive"> -->
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
