import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { ApplicationSettings } from '../shared/application-settings';


import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IPart} from './Part-model/Part';
import { IParts } from './Part-model/Parts';
import { IPartSearch } from './Part-model/PartSearch';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class PartService {
	private baseUrl: string;
	public pageTitle = 'Parts List';
	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Parts';
	}

	getParts(text: string): Observable<any> {
		const url = this.baseUrl + '?text=' + text;
		return this.authHttp.get(url)
			.catch(this.handleError);
	}

	getPartsByUpc(text: string): Observable<any> {
		const url = this.baseUrl + '/upc?upc=' + text;
		return this.authHttp.get(url)
			.catch(this.handleError);
	}

	private handleError(error: any): Observable<any> {
		return Observable.throw(error.message || 'Server error');
	}
	getPart(id: number):  Observable<IPart> {
		if (id === 0) {
			return of(this.initializePart());
		}

		const url = `${this.baseUrl}/getbyid?id=${id}`;

		return this.authHttp.get<IPart>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	savePart(part: IPart): Observable<any> {
		if (part.Id === 0) {
			return this.createPart(part);
		}
		return this.updatePart(part);
	}

	private createPart(part: IPart): Observable<any> {
		part.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/partcreate', part)
			.pipe(retry(1), catchError(this.processError));
	}

	private updatePart(part: IPart): Observable<IPart> {
		return this.authHttp.put<IPart>(this.baseUrl, part)
			.pipe(retry(1), catchError(this.processError));
	}

	deletePart(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializePart(): IPart {
		return {
			Id: 0,
			PartNumber: '',
			PartDescription: '',
			EclipseId: 0,
			PartRootsId: 0,
			BagQty: 0,
			BoxQty: 0,
			Upc: '',
			BoxSize: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IPart;
	}

	public getRecordSet(search: IPartSearch): Observable<IParts> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('PartNumber', search.PartNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PartDescription', search.PartDescription, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EclipseId', search.EclipseId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PartRootsId', search.PartRootsId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BagQty', search.BagQty, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BoxQty', search.BoxQty, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Upc', search.Upc, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BoxSize', search.BoxSize, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('PartNumber', search.PartNumber ? search.PartNumber : '')
			.set('PartDescription', search.PartDescription ? search.PartDescription : '')
			.set('EclipseId', search.EclipseId ? search.EclipseId : '')
			.set('PartRootsId', search.PartRootsId ? search.PartRootsId : '')
			.set('BagQty', search.BagQty ? search.BagQty : '')
			.set('BoxQty', search.BoxQty ? search.BoxQty : '')
			.set('Upc', search.Upc ? search.Upc : '')
			.set('BoxSize', search.BoxSize ? search.BoxSize : '')
		};

		return this.authHttp.get<IParts>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IParts>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IPartSearch { 
		let search: IPartSearch = {
			Id: 1,
			PartNumber: '',
			PartDescription: '',
			EclipseId: '',
			PartRootsId: '',
			BagQty: '',
			BoxQty: '',
			Upc: '',
			BoxSize: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IPartSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('PartNumber', search.PartNumber ? search.PartNumber : '')
			.set('PartDescription', search.PartDescription ? search.PartDescription : '')
			.set('EclipseId', search.EclipseId ? search.EclipseId : '')
			.set('PartRootsId', search.PartRootsId ? search.PartRootsId : '')
			.set('BagQty', search.BagQty ? search.BagQty : '')
			.set('BoxQty', search.BoxQty ? search.BoxQty : '')
			.set('Upc', search.Upc ? search.Upc : '')
			.set('BoxSize', search.BoxSize ? search.BoxSize : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	
}
