import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, FormControlName, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { GenericValidator } from '../../../shared/generic-validator';
import { UserCreateModel } from '../user-model/user-create';
import { UserService } from '../user-service/user.service';


@Component({
	templateUrl: './user-create.component.html'
})
export class UserCreateComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef })
	formInputElements: ElementRef[];

	createUserForm: UntypedFormGroup;
	pageTitle = 'Create User';
	errorMessage = '';
	user: UserCreateModel;

	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator: GenericValidator;
	

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService		
	) {
		this.validationMessages = {
			Email: {
				required: 'Email is required.'
			},
			Password: {
				required: 'Password is required.'
			},
			ConfirmPassword: {
				required: 'Confirm Password is required.'
			},
			FirstName: {
				required: 'First Name is required.'
			},
			LastName: {
				required: 'Last Name is required.'
			}
		};
	}

	ngOnInit(): void {

		this.createUserForm = this.fb.group({
			Username: ['', [Validators.required]],
			FirstName: ['', [Validators.required]],
			LastName: ['', [Validators.required]],
			Email: ['', [Validators.required]],
			Password: ['', [Validators.required]],
			ConfirmPassword: ['', [Validators.required]],
			Initials: '',
			Title: '',
			Address: '',
			City: '',
			Region: '',
			PostalCode: '',
			HomePhone: '',
			CellPhone: '',
			BirthDate: '',
			HireDate: '',
			EmergencyName: '',
			EmergencyPhone: '',
			Notes: '',
			Active: false,
			Image: '',
		});
		this.genericValidator = new GenericValidator(this.validationMessages);
	}

	ngAfterViewInit(): void {
		// Watch for the blur event from any input element on the form.
		const controlBlurs: Observable<any>[] = this.formInputElements.map(
			(formControl: ElementRef) =>
				Observable.fromEvent(formControl.nativeElement, 'blur')
		);

		// Merge the blur event observable with the valueChanges observable
		Observable.merge(this.createUserForm.valueChanges, ...controlBlurs)
			.debounceTime(800)
			.subscribe(value => {
				this.displayMessage = this.genericValidator.processMessages(
					this.createUserForm
				);
			});
	}
	
	saveUser(): void {
		if (this.createUserForm.dirty && this.createUserForm.valid) {
			const userCreated = Object.assign(
				{},
				this.user,
				this.createUserForm.value
			);
			this.userService
				.saveUser(userCreated)
				.subscribe(
					() => this.onSaveComplete(),
					(error: any) => (this.errorMessage = error)
				);
		} else if (!this.createUserForm.dirty) {
			this.onSaveComplete();
		}
	}

	onSaveComplete(): void {
		this.createUserForm.reset();
		this.router.navigate(['/aspnetusers']);
	}
}
