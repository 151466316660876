import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
//import { D3Service } from 'd3-ng2-service';
import { NgxD3Service } from '@katze/ngx-d3';

import { AppPropertiesService } from './app-properties.service';
import { AssemblyEditor } from './assembly-detail/assembly-editor';
import { DataService } from './data/data-service';
import { CalibrationService } from './hoses/chart/calibration.service';
import { ChartComponent } from './hoses/chart/chart.component';
import { ChartService } from './hoses/chart/chart.service';
import { ReadLocalService } from './hoses/chart/readlocal.service';
import { ContinuityComponent } from './hoses/continuity/continuity.component';
import { ElongationComponent } from './hoses/elongation/elongation.component';
import { FileUploadComponent } from './hoses/file-upload/file-upload';
import { FileUploadService } from './hoses/file-upload/file-upload-service/file-upload.service';
import { HoseList } from './hoses/hose-list/hose-list';
import { HosesService } from './hoses/hoses-service/hoses.service';
import { HosesComponent } from './hoses/hoses.component';
import { HydrostaticComponent } from './hoses/hydrostatic/hydrostatic.component';
import { VisualInspection } from './hoses/visual-inspection/visual-inspection';
import { HydroTestLegacyApp } from './hydro-test-legacy.component';
import { HydroTestCertificate } from './hydro-test/hydro-test-certificate/hydro-test-certificate';
import { ModalComponent } from './shared/modal.component';
import { MaterialModule } from '../material-module'

@NgModule({
	imports: [BrowserModule
		// , HttpModule
		, ReactiveFormsModule
		, FormsModule
		, CommonModule
		//, ChartsModule
		//, Ng2CompleterModule
		,MaterialModule
		, RouterModule.forChild([
		])
	],
	declarations: [
		HydroTestLegacyApp
		, HydroTestCertificate
		, AssemblyEditor
		, VisualInspection
		, HoseList
		, ContinuityComponent
		, ElongationComponent
		, HydrostaticComponent
		, FileUploadComponent
		, HosesComponent
		, ChartComponent
		, ModalComponent
	],
	exports: [
		HydroTestLegacyApp
	],
	providers: [
		FileUploadService
		, HosesService
		, AppPropertiesService
		, ChartService
		//, CompleterService
		//, D3Service
		, NgxD3Service
		, DataService
		, ReadLocalService
		, CalibrationService]
})
export class HydroTestLegacyModule { }
