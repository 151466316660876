<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
			<div class='col-md-1'>
				{{pageTitle}}
			</div>
			<div class='col-md-2 text-right'>
				<label>Rows</label>&nbsp;&nbsp;
				<select [(ngModel)]='pageSize'
					style='width: 100px; color: black;background-color: #fff;border-color: #ccc;border-radius: 3px;line-height: 1.5;padding: 4px 10px;'
					(change)='pageSizeValueChanged()'>
					<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
				</select>
			</div>
			<div class='col-md-2 text-right'>
				<button class='btn btn-default btn-sm' [routerLink]="['/customerconcern-details/0']"
					style='width:160px'>Add New NCR</button>
			</div>
			<div class='col-md-2 text-right'>
				<button class='btn btn-default btn-sm' style='width: 140px' (click)='showHideColumnsClick()'>
					{{ShowColumnsText}}</button>
			</div>
			<div class='col-md-1 text-right'>
				<button class='btn btn-default btn-sm' style='width: 100px' (click)='showHideSearchClick()'>
					{{SearchText}}</button>
			</div>
			<div class='col-md-1 text-right'>
				<button class='btn btn-default btn-sm' style='width: 80px' (click)='searchClick()'>Search</button>
			</div>
			<div class='col-md-1 text-right'>
				<button class='btn btn-default btn-sm' style='width: 100px' (click)='toExcel()'>To Excel</button>
			</div>
			<div class='col-md-1 text-right'>
				<button class='btn btn-default btn-sm' style='width: 100px'
					(click)='searchClearClick()'>Clear-Search</button>
			</div>
			<div class='col-md-1 text-right'>
				<button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
			</div>
		</div>
	</div>

	<div class='panel-body'>
		<div class='row' *ngIf='excelFile'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' >
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row' *ngIf='errorMessage'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' >
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf="showHideSearch">
			<div class="col-md-6">
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Customer</label>
						<input type='text' class='form-control block-element' placeholder='' [(ngModel)]='Customer' />
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>NCR Number</label>
						<input type='text' class='form-control block-element' placeholder='' [(ngModel)]='NCRNumber' />
					</div>
				</div>
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Status</label>
						<select [(ngModel)]='StatusID' class='form-control block-element'>
							<option value=""> </option>
							<option *ngFor='let ccStatus of allCustomerConcernsStatus'
								[value]="ccStatus.StatusID">{{ccStatus.StatusDescription}}</option>
						</select>
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>Original Sales Order#</label>
						<input type='text' class='form-control block-element' placeholder=''
							[(ngModel)]='OriginalSalesOrderNumber' />
					</div>
				</div>
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Start Date</label>
						<input class='form-control' style="height: 40px;" type='date' [(ngModel)]='NCRFromDate' />
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>End Date</label>
						<input class='form-control' style="height: 40px;" type='date' [(ngModel)]='NCRToDate' />
					</div>
				</div>
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Customer Complaint #</label>
						<input type='text' class='form-control block-element' placeholder=''
							[(ngModel)]='CustomerComplaintNumber' />
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>Responsible For</label>
						<input type='text' class='form-control block-element' placeholder=''
							[(ngModel)]='ResponsibleFor' />
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Failure Mode</label>
						<select [(ngModel)]='Level1FailureMode' 
						        class='form-control block-element'
								(change)="onLevel1FailureModeSelect($event.target.value)">
							<option value=""> </option>
							<option *ngFor='let failureMode of level1FailureModes' 
							    [value]="failureMode.ID">{{failureMode.Description}}
							</option>
						</select>
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>Sub Failure Mode</label>
						<select [(ngModel)]='Level2FailureMode' class='form-control block-element'>
							<option value=""> </option>
							<option *ngFor='let failureMode of level2FailureModes' 
							    [value]="failureMode.ID">{{failureMode.Description}}
							</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Branch</label>
						<select [(ngModel)]='BranchResponsible' class='form-control block-element'>
							<option value=""> </option>
							<option *ngFor='let branch of branches' [value]="branch.BranchNumber">{{branch.BranchName}}
								{{branch.BranchNumber}}</option>
						</select>
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>Division</label>
						<select [(ngModel)]='Division' class='form-control block-element'>
							<option value=""></option>		
							<option value="Green Line HF">Green Line HF</option>		
							<option value="Pulsar">Pulsar</option>		
							<option value="GLM - Adapters">GLM - Adapters</option>		
							<option value="GLM - HAL">GLM - HAL</option>		
							<option value="GLM - IAL">GLM - IAL</option>		
							<option value="GLM - Office">GLM - Office</option>		
							<option value="GLM - TAL">GLM - TAL</option>		
							<option value="GLM - Shipping">GLM - Shipping</option>		
						</select>
					</div>
				</div>
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Owner</label>
						<ng-select [(ngModel)]="Owner">
							<ng-option *ngFor="let employee of employees" [value]="employee.Id">{{employee.LastName
								+ ', ' + employee.FirstName}}</ng-option>
						</ng-select>
					</div>
					<div class='col-md-6'>
						<label class='inline-label'>Assigned Employee</label>
						<ng-select [(ngModel)]="EmployeeAssigned">
							<ng-option *ngFor="let employee of employees" [value]="employee.Id">{{employee.LastName
								+ ', ' + employee.FirstName}}</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="row">
					<div class='col-md-6'>
						<label class='inline-label'>Problem</label>
						<input type='text' class='form-control block-element' placeholder='' [(ngModel)]='Problem' />
					</div>
				</div>
			</div>
		</div>
		<div class='filters' *ngIf='showHideColumns'>
			<div class="row">
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.NCRDateCheck' />&nbsp;
					<label> NCRDate</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.NCRCodeCheck' />&nbsp;
					<label> NCRCode</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.StatusCheck' />&nbsp;
					<label> Status</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.CustomerCheck' />&nbsp;
					<label> Customer</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.ProblemCheck' />&nbsp;
					<label> Problem</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox'
						[(ngModel)]='columnManagementCheckboxes.OriginalSalesOrderNumberCheck' />&nbsp;
					<label> Original Sales Order Number</label>
				</div>
			</div>

			<div class="row">
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.EmployeeAssignedCheck' />&nbsp;
					<label> Employee Assigned</label>
				</div>

				<div class='col-md-2'>
					<input type='checkbox'
						[(ngModel)]='columnManagementCheckboxes.CustomerComplaintNumberCheck' />&nbsp;
					<label> Customer Complaint Number</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.ResponsibleForCheck' />&nbsp;
					<label> Responsible For</label>
				</div>

				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.BranchResponsibleCheck' />&nbsp;
					<label> Branch Responsible</label>
				</div>
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.DivisionCheck' />&nbsp;
					<label> Division</label>
				</div>


				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.ReportCheck' />&nbsp;
					<label> Report</label>
				</div>
			</div>
			<div class="row">
				<div class='col-md-2'>
					<input type='checkbox' [(ngModel)]='columnManagementCheckboxes.OwnerCheck' />&nbsp;
					<label> Owner</label>
				</div>
			</div>
			<div class="row" style="margin-top: 10px;">
				<div class='col-md-3'>
					<button class='btn btn-primary btn-sm' style='width: 160px'
						(click)='updateColumnsClick("false")'>Update
						Columns</button>
				</div>
				<div class='col-md-3'>
					<button class='btn btn-primary btn-sm' style='width: 200px'
						(click)='updateColumnsClick("true")'>Update
						Columns and Close</button>
				</div>
			</div>


		</div>
	</div>
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort>
		<ng-container matColumnDef='Id'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
			<td mat-cell *matCellDef='let e'>
				<button class='btn btn-default' style='min-width: 80px;'
					[routerLink]="['/customerconcern-details', e.Id]">{{e.Id}}</button>
			</td>
		</ng-container>
		<ng-container matColumnDef='NCRDate'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'>NCR Date</th>
			<td mat-cell *matCellDef='let e'>{{e.NCRDate | date:'dd/MM/YYYY'}}</td>
		</ng-container>
		<ng-container matColumnDef='NCRCode'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'>Failure Mode</th>
			<td mat-cell *matCellDef='let e'>{{e.FailureModeLevel1Disp + " / " + e.FailureModeLevel2Disp}}</td>
		</ng-container>
		<ng-container matColumnDef='StatusID'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'>Status</th>
			<td mat-cell *matCellDef='let e'>{{e.StatusDescription}}</td>
		</ng-container>
		<ng-container matColumnDef='Customer'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'>Customer</th>
			<td mat-cell *matCellDef='let e'>{{ e.CustID != null ? e.CustomerDisplay : e.Customer}}</td>
		</ng-container>
		<ng-container matColumnDef='Problem'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'>Problem</th>
			<td mat-cell *matCellDef='let e'>{{(e.Problem.length >68 )?(e.Problem | slice: 0: 68) + '..' : e.Problem}}
			</td>
		</ng-container>
		<ng-container matColumnDef='OriginalSalesOrderNumber'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'>Original SalesOrder Number</th>
			<td mat-cell *matCellDef='let e'>{{e.OriginalSalesOrderNumber}}</td>
		</ng-container>
		<ng-container matColumnDef='EmployeeAssigned'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'>Employee Assigned</th>
			<td mat-cell *matCellDef='let e'>{{e.EmployeeAssigned}}</td>
		</ng-container>
		<ng-container matColumnDef='CustomerComplaintNumber'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'>Customer Complaint Number</th>
			<td mat-cell *matCellDef='let e'>{{e.CustomerComplaintNumber}}</td>
		</ng-container>
		<ng-container matColumnDef='ResponsibleFor'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'>Responsible For</th>
			<td mat-cell *matCellDef='let e'>{{ e.VendorID != null ? e.VendorDisplay : e.ResponsibleFor}}</td>
		</ng-container>
		<ng-container matColumnDef='BranchResponsible'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'>Br</th>
			<td mat-cell *matCellDef='let e'>{{e.BranchResponsible}}</td>
		</ng-container>
		<ng-container matColumnDef='Division'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'>Division</th>
			<td mat-cell *matCellDef='let e'>{{e.Division}}</td>
		</ng-container>
		<ng-container matColumnDef='Owner'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'>Owner</th>
			<td mat-cell *matCellDef='let e'>{{e.Owner}}</td>
		</ng-container>
		<!-- <ng-container matColumnDef='Report'>
			<th mat-header-cell *matHeaderCellDef  class='col1'>Report</th>
			<td mat-cell *matCellDef='let e'>
				<div class="dropdown">
					<button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style='min-width: 50px;'>
						<span class='glyphicon glyphicon-floppy-save'></span> Report&nbsp;
					</button>
					<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<li><a class="dropdown-item" style="cursor: pointer;" (click)="report(e.Id, 1)">Internal</a></li>
						<li><a class="dropdown-item" style="cursor: pointer;" (click)="report(e.Id, 2)">Customer</a></li>
						<li><a class="dropdown-item" style="cursor: pointer;" (click)="report(e.Id, 3)">Supplier</a></li>
					</ul>
				</div>


			</td>
		</ng-container> -->
		<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
		<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
	</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>