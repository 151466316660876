import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';
import { IBoxLabel } from './box-label-model/box-label';
import { PrinterIpService } from '../shared/printer-ip.service';

@Injectable()
export class BoxLabelService {
	private baseUrl: string;
	private glPrintingUrl: string;

	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings,
		private printerIpService: PrinterIpService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/boxlabels';
		//this.glPrintingUrl = this.settings.retrieveGlPrintingUrl() + '/api/boxlabels';
	}

	createBoxlabel(boxLabel: IBoxLabel): Observable <any> {
		return this.authHttp.post(this.baseUrl, boxLabel)
			.catch (this.handleError);
	}

	createBoxlabelGlPrinting(boxLabel: IBoxLabel, branch: string): Observable <any> {
		this.glPrintingUrl = 'https://' + this.printerIpService.getIp(branch) +'/GLPrinting' + '/api/boxlabels';
		return this.authHttp.post(this.glPrintingUrl, boxLabel)
			.catch (this.handleError);
	}

	private handleError(error: any): Observable <any> {
		return Observable.throw (error.message || 'Server error');
	}
}
