import { AfterViewInit, Component, OnInit } from '@angular/core';


@Component({
	selector: 'document-help',
	templateUrl: './document-help.component.html',
	styleUrls: ['./document-help.component.css']
})

export class DocumentHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'Documents Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}