import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { HydraulicfittingscategorieService } from './hydraulicfittingscategorie-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { HydraulicfittingscategoriemenuComponent } from './hydraulicfittingscategorie-menu/hydraulicfittingscategorie-menu.component';
import { HydraulicfittingscategorieUploadComponent } from './hydraulicfittingscategorie-upload/hydraulicfittingscategorie-upload.component';

@NgModule({
	declarations: [
		HydraulicfittingscategoriemenuComponent
		, HydraulicfittingscategorieUploadComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'hydraulicfittingscategorie-upload', component: HydraulicfittingscategorieUploadComponent },
			{ path: 'hydraulicfittingscategorie-menu', component: HydraulicfittingscategoriemenuComponent },
		])
	],
	providers: [
		HydraulicfittingscategorieService
	]
})
export class HydraulicfittingscategorieModule { }
