import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	selector: 'hydrotestcalibration-help',
	templateUrl: './hydrotestcalibration-help.component.html',
	styleUrls: ['./hydrotestcalibration-help.component.css']
})

export class HydrotestcalibrationHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'HydroTestCalibrations Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}