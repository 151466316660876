﻿<div class='panel panel-primary animated fadeIn'>
        <div class='panel-heading'>
            <div *ngIf="hydroTestDetail">
                <span>Hydro Test Id:{{hydroTestDetail.hydroTestId}}</span>
                <div class="report-buttons">

                    <a (click)='createReport("HydroAllOnePage")' class="btn btn-default btn-xs">
                        One Page Report 
                    </a>
                    <a (click)='createReport("HydroTestOnePageNoImage")' class="btn btn-default btn-xs">
                        One Page Report No Image
                    </a>
                    <a (click)='createReport("HydroTestTwoPage")' class="btn btn-default btn-xs">
                        Two Page Report
                    </a>   
                </div>
                <div class="report-buttons">
                    <a (click)='createNewReport("HydroAllOnePage")' class="btn btn-default btn-xs">
                       New One Page Report 
                    </a>
                    <a (click)='createNewReport("HydroTestOnePageNoImage")' class="btn btn-default btn-xs">
                        New One Page Report No Image
                     </a>
                     <a (click)='createNewReport("HydroTestTwoPage")' class="btn btn-default btn-xs">
                        New Two Page Report
                     </a>
                </div>
                <div class="report-buttons">
                   
                </div>
                <div class="report-buttons">
                   
                </div>
            </div>
        </div>
        <div class='panel-body'>
            <!--<material-datepicker [(date)]="today"></material-datepicker>-->
            <br />
            <ul class="nav nav-tabs">
                <li class="{{tabClass[0]}}"><a href="javascript:void(0)" (click)="selectTab(0)">Hydro Test</a></li>
                <li class="{{tabClass[1]}}"><a href="javascript:void(0)" (click)="selectTab(1)">Assembly Details</a></li>
                <li class="{{tabClass[2]}}"><a href="javascript:void(0)" (click)="selectTab(2)">Hoses</a></li>
            </ul>
            <div  *ngIf="tabVisible[0]">
                <http-hydrotestcertificate (hydroTestIdUpdated)="hydroTestIdUpdatedEvent($event)">
                </http-hydrotestcertificate>
            </div>
            <div *ngIf="tabVisible[1]">
                <assembly-editor></assembly-editor>
            </div>
            <div *ngIf="tabVisible[2]">
                <http-hoses ></http-hoses>
            </div>
        </div>
    </div>
    <div>
        <mat-spinner color="primary" class="spinner" *ngIf="loadingResult"></mat-spinner>
    </div>
