import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DdlService } from './../../../shared/ddlService/ddl.service';
import { IFitting } from './../fitting-model/fitting';
import { FittingService } from './../fitting.service';

@Component({
	selector: 'app-fitting-edit',
	templateUrl: './fitting-edit.component.html',
	styleUrls: ['./fitting-edit.component.css']
})

export class FittingEditComponent implements OnInit {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit ';
	errorMessage: string;
	fittingForm: UntypedFormGroup;
	fitting: IFitting;
	private sub: Subscription;
	public buttonPushed = false;
	public message;
	editPermission = false;
	// load ddls
	public producttypesddl: any = [];

	constructor(private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private fittingService: FittingService,
		public ddlService: DdlService,
		private auth: AuthService) {
	}

	ngOnInit(): void {
		this.auth.getRoles().includes('Update Parts')?this.editPermission=true:this.editPermission=false;
		this.fittingForm = this.fb.group({
			FittingId: 0,

			Fitting: '',
			OutsideDiameter: '',
			ArchiveDate: '',

			CrimpInstructions: '',
			Notes: '',

			Pressure: '',
			PressureTest: '',
			ProductTypeId: '',
			ProductTypeName: '',
			ProductType: '',
			Description: '',

			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.ddlService.ddl("PRODUCTTYPE")
			.subscribe(
				(data) => {
					this.producttypesddl = data;

					this.sub = this.route.params.subscribe(
						params => {
							const id = +params['Id'];
							this.getfitting(id);
						}
					);

				});

	}

	saveFitting(con: boolean): void {
		if(this.editPermission){
		this.buttonPushed = true;
		if (this.fittingForm.valid) {
			this.errorMessage = '';

			const obj = Object.assign({}, this.fitting, this.fittingForm.value);
			this.fittingService.saveFitting(obj)
				.subscribe((data) => this.onSaveComplete(con, data)
					, (error: any) => {
						this.errorMessage = <any>error;
						this.message = 'The data has been updated';
					});
		} else {
			this.errorMessage = 'Please fill the required fields.';
		}
	}else{
		this.errorMessage = 'You dont have permission to edit this element';
	}
	}

	onSaveComplete(con: boolean, data: any): void {
		if (con) {
			this.fittingForm.reset();
			this.router.navigate(['/fittings']);
		} else {
			if (data !== null && this.fitting.Id === 0) {
				this.router.navigate(['/fitting-details', data.Id]);
			}
		}
		this.message = 'The data has been Updated';
		this.buttonPushed = false;
	}

	getfitting(id: number): void {
		this.fittingService.getFittingbyId(id)
			.subscribe((obj: IFitting) => this.onfittingRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onfittingRetrieved(fitting: IFitting): void {
		if (this.fittingForm) {
			this.fittingForm.reset();
		}
		this.fitting = fitting;
		if (this.fitting.Id === 0) {
			this.page = 'Add fitting';
		} else {
			this.page = 'Edit fitting';
		}

		this.fittingForm.patchValue({
			Id: this.fitting.Id,
			Fitting: this.fitting.Fitting,
			OutsideDiameter: this.fitting.OutsideDiameter,
			ProductTypeId: this.fitting.ProductTypeId,
			Description: this.fitting.Description,
			ProductType: this.fitting.ProductType,
			CrimpInstructions: this.fitting.CrimpInstructions,
			Notes: this.fitting.Notes,
			Pressure: this.fitting.Pressure,
			PressureTest: this.fitting.PressureTest,

			ArchiveDate: this.fitting.ArchiveDate,

			CreatedDate: this.fitting.CreatedDate,
			CreatedBy: this.fitting.CreatedBy,
			UpdatedDate: this.fitting.UpdatedDate,
			UpdatedBy: this.fitting.UpdatedBy,
		});
	}

	deleteFitting(): void {
		if(this.editPermission){
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.fitting.Fitting}?`)) {
			this.fittingService.deleteFitting(this.fitting.Id)
				.subscribe(() => this.onSaveComplete(true, null)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}else{
		this.errorMessage = 'You dont have permission to edit this element';
	}
	}

	cancel(): void {
		this.router.navigate(['/fittings']);
	}
}
