import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IRole } from '../role-model/role';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class RoleService {
	private baseUrl;

	constructor(
		private authHttp: HttpClient,
		private settings: ApplicationSettings
	) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/users/roles';
	}

	getRoles(): Observable<IRole[]> {
		return this.authHttp.get<IRole[]>(this.baseUrl)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	saveRole(role: IRole): Observable<IRole> {
		return this.authHttp.post<IRole>(this.baseUrl, role)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	getRole(id: string): Observable<IRole> {
		const url = this.baseUrl + '?id=' + id;
		return this.authHttp.get<IRole>(url).pipe(
			retry(1),
			catchError(this.processError));
	}

	updateRole(role: IRole): Observable<IRole> {
		return this.authHttp.put<IRole>(this.baseUrl, role).pipe(
			retry(1),
			catchError(this.processError));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }
}
