import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {

	title = 'app';
	deviceInfo = null;
	browser = '';
	notChrome = true;

	constructor(private deviceService: DeviceDetectorService
	) {
		this.epicFunction();
		const test = 1;
	}
	epicFunction() {
		this.deviceInfo = this.deviceService.getDeviceInfo();

		if (this.deviceInfo.browser === 'Chrome') {
			this.notChrome = false;
		}

		console.log(this.deviceInfo);
	}

}
