import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IDocument} from './Document-model/Document';
import { IDocuments } from './Document-model/Documents';
import { IDocumentSearch } from './Document-model/DocumentSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class DocumentService {
	private baseUrl;
	public pageTitle = 'Documents List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Documents';
	}

	getDocument(id: number):  Observable<IDocument> {
		if (id === 0) {
			return of(this.initializeDocument());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IDocument>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveDocument(document: IDocument): Observable<any> {
		if (document.Id === 0) {
			return this.createDocument(document);
		}
		return this.updateDocument(document);
	}

	private createDocument(document: IDocument): Observable<any> {
		document.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/documentcreate', document)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateDocument(document: IDocument): Observable<IDocument> {
		return this.authHttp.put<IDocument>(this.baseUrl, document)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteDocument(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeDocument(): IDocument {
		return {
			Id: 0,
			Name: '',
			Description: '',
			FileName: '',
			DocumentType: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			RealFileName: '',
		} as IDocument;
	}

	public getRecordSet(search: IDocumentSearch,pageName: string): Observable<IDocuments> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, pageName));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, pageName));
		uSearch.push(this.userSearchService.setSearch('FileName', search.FileName, pageName));
		uSearch.push(this.userSearchService.setSearch('DocumentType', search.DocumentType, pageName));
		uSearch.push(this.userSearchService.setSearch('RealFileName', search.RealFileName, pageName));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, pageName));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(),pageName));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
			.set('FileName', search.FileName ? search.FileName : '')
			.set('DocumentType', search.DocumentType ? search.DocumentType : '')
			.set('RealFileName', search.RealFileName ? search.RealFileName : '')
		};

		return this.authHttp.get<IDocuments>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IDocuments>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}
	public getFreshSearch() : IDocumentSearch { 
		let search: IDocumentSearch = {
			Id: 1,
			Name: '',
			Description: '',
			FileName: '',
			DocumentType: '',
			RealFileName: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IDocumentSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
			.set('FileName', search.FileName ? search.FileName : '')
			.set('DocumentType', search.DocumentType ? search.DocumentType : '')
			.set('RealFileName', search.RealFileName ? search.RealFileName : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadfile`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
}