import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { VideoListComponent } from './video-list/video-list.component';
import { VideoEditComponent } from './video-edit/video-edit.component';
import { VideoService } from './video-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { VimeoComponent } from './vimeo/vimeo.component';
import { TrainingVideoEditComponent } from './training-video-edit/training-video-edit.component';
import { TrainingVideoListComponent } from './training-video-list/training-video-list.component';

@NgModule({
	declarations: [
		VideoListComponent
		, VideoEditComponent
		,VimeoComponent
		,TrainingVideoEditComponent
		,TrainingVideoListComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'videos', component: VideoListComponent },
			{ path: 'video-details/:Id', component: VideoEditComponent },
			{ path: 'training-videos', component: TrainingVideoListComponent },
			{ path: 'training-video-details/:Id', component: TrainingVideoEditComponent },


		])
	],
	providers: [
		VideoService
	]
})
export class VideoModule { }
