import { HoseGridService } from './hose-list/hose-list.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HoseListComponent } from './hose-list/hose-list.component';
import { HoseEditComponent } from './hose-edit/hose-edit.component';
import { HoseService } from './hose.service';
import { MaterialModule } from 'src/app/material-module';

@NgModule({
	declarations: [
		HoseListComponent
		, HoseEditComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		
		CommonModule,
		RouterModule.forChild([
			{ path: 'hoses', component: HoseListComponent },
			{ path: 'hose-details/:Id', component: HoseEditComponent },
		])
	],
	providers: [
		HoseGridService
		, HoseService
	]
})
export class HoseModule { }
