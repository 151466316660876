import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ICustomerPlanrecord} from './CustomerPlanrecord-model/CustomerPlanrecord';
import { ICustomerPlanrecords } from './CustomerPlanrecord-model/CustomerPlanrecords';
import { ICustomerPlanrecordSearch } from './CustomerPlanrecord-model/CustomerPlanrecordSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class CustomerPlanrecordService {
	private baseUrl;
	public pageTitle = 'Customer Plan Records List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/CustomerPlanrecords';
	}

	getCustomerPlanrecord(id: number):  Observable<ICustomerPlanrecord> {
		if (id === 0) {
			return of(this.initializeCustomerPlanrecord());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ICustomerPlanrecord>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveCustomerPlanrecord(customerplanrecord: ICustomerPlanrecord): Observable<any> {
		if (customerplanrecord.Id === 0) {
			return this.createCustomerPlanrecord(customerplanrecord);
		}
		return this.updateCustomerPlanrecord(customerplanrecord);
	}

	private createCustomerPlanrecord(customerplanrecord: ICustomerPlanrecord): Observable<any> {
		customerplanrecord.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/customerplanrecordcreate', customerplanrecord)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateCustomerPlanrecord(customerplanrecord: ICustomerPlanrecord): Observable<ICustomerPlanrecord> {
		return this.authHttp.put<ICustomerPlanrecord>(this.baseUrl, customerplanrecord)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteCustomerPlanrecord(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeCustomerPlanrecord(): ICustomerPlanrecord {
		return {
			Id: 0,
			FileName: '',
			Customer: '',
			Notes: '',
			FileMonth: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICustomerPlanrecord;
	}

	public getRecordSet(search: ICustomerPlanrecordSearch): Observable<ICustomerPlanrecords> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('FileName', search.FileName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Customer', search.Customer, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('FileMonth', search.FileMonth, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('FileName', search.FileName ? search.FileName : '')
			.set('Customer', search.Customer ? search.Customer : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('FileMonth', search.FileMonth ? search.FileMonth : '')
		};

		return this.authHttp.get<ICustomerPlanrecords>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICustomerPlanrecords>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ICustomerPlanrecordSearch { 
		let search: ICustomerPlanrecordSearch = {
			Id: 1,
			FileName: '',
			Customer: '',
			Notes: '',
			FileMonth: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ICustomerPlanrecordSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('FileName', search.FileName ? search.FileName : '')
			.set('Customer', search.Customer ? search.Customer : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('FileMonth', search.FileMonth ? search.FileMonth : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getCustomerPlanDetailsData(Id){
		return this.authHttp.get<any>(this.baseUrl + '/getcustomerplandetails?Id='+Id)
			.pipe(retry(1), catchError(this.processError));
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadfile`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	exportDataToCSV(){
		const url = `${this.baseUrl}/exporttocsv`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getPartNumbers(){
		const url = `${this.baseUrl}/getpartnumbers`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getPartKits(){
		const url = `${this.baseUrl}/getpartkits`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}
