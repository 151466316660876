
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml'  [routerLink]="['/binlabelcat-details/0']" style='width:auto;' [disabled]="progressStatus">Add New Binlabelcat</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: auto' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> P Line</label>
				<input type='text' class='form-control block-element' placeholder=' P Line' [(ngModel)]='PLine' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Label Tag</label>
				<input type='text' class='form-control block-element' placeholder=' Label Tag' [(ngModel)]='LabelTag' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Label Group</label>
				<input type='text' class='form-control block-element' placeholder=' Label Group' [(ngModel)]='LabelGroup' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Number Of Pages</label>
				<input type='text' class='form-control block-element' placeholder=' Number Of Pages' [(ngModel)]='NumberOfPages' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Total Pages</label>
				<input type='text' class='form-control block-element' placeholder=' Total Pages' [(ngModel)]='TotalPages' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/binlabelcat-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='PLine'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> P Line</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.PLine}}</td>
	</ng-container>
	<ng-container matColumnDef='LabelTag'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Label Tag</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.LabelTag}}</td>
	</ng-container>
	<ng-container matColumnDef='LabelGroup'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Label Group</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.LabelGroup}}</td>
	</ng-container>
	<ng-container matColumnDef='NumberOfPages'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Number Of Pages</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.NumberOfPages}}</td>
	</ng-container>
	<ng-container matColumnDef='TotalPages'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Total Pages</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.TotalPages}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Notes</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.Notes}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>