import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { LabelprintingService } from './labelprinting-service';
import { NonStockLabelComponent } from './non-stock/non-stock/non-stock.component';
import { BoxLabelComponent } from './box-labels/box-label/box-label.component';
import { PackageLabelComponent } from './package-labels/package-label/package-label.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Label4By1Component } from './label4by1/label4by1/label4by1.component';
import { Label6By2Component } from './label6by2/label6by2/label6by2.component';
import { InventoryLabelComponent } from './inventory-labels/inventory-label/inventory-label.component';

@NgModule({
  declarations: [
		NonStockLabelComponent,
		BoxLabelComponent,
		PackageLabelComponent,
		Label4By1Component,
		Label6By2Component,
		InventoryLabelComponent
	],
	imports: [
		MatAutocompleteModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'new-non-stock', component: NonStockLabelComponent },
			{ path: 'new-box-label', component: BoxLabelComponent },
			{ path: 'new-package-label', component: PackageLabelComponent },
			{ path: 'label4by1', component: Label4By1Component },
			{ path: 'label6by2', component: Label6By2Component },
			{ path: 'inventory-label', component: InventoryLabelComponent },
				])
	],
	providers: [
		LabelprintingService
	]
})
export class LabelprintingModule { }
