<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/crimp-details/0']" style='width:auto;' [disabled]="isLoadingResults">Add Crimp Details</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='showHideSearchClick()' [disabled]="isLoadingResults">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: 100px' (click)='toExcel()' [disabled]="isLoadingResults">To Excel</button>
		</div>
		<div class='col-md-3 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize'  style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of appSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Hose</label>
				<input type='text' class='form-control block-element' placeholder=' HoseName' [(ngModel)]='HoseName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Ferrule</label>
				<input type='text' class='form-control block-element' placeholder=' FerruleName' [(ngModel)]='FerruleName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Fitting</label>
				<input type='text' class='form-control block-element' placeholder=' FittingName' [(ngModel)]='FittingName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Order Number</label>
				<input type='text' class='form-control block-element' placeholder=' OrderNumber' [(ngModel)]='OrderNumber' />
			</div>
            <div class='col-md-4'>
				<label class='inline-label'> User</label>
				<input type='text' class='form-control block-element' placeholder=' User' [(ngModel)]='User' />
			</div>
            <div class='col-md-4'>
				<label class='inline-label'> Created Date</label>
				<input type='date' class='form-control block-element' placeholder=' CreatedDate' [(ngModel)]='CreatedDate' />
			</div>

            <div class='col-md-4'>
				<label class='inline-label'> Branch</label>
				<select [(ngModel)]='Branch' class='form-control block-element'>
					<option value="" disabled selected hidden>Select Branch</option>
					<option value= ""> </option>
					<option *ngFor='let branch of branches' [value]="branch.BranchName">{{branch.BranchName}}
					</option>
				</select>	
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

            <div class='table-container'>
                <table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
                    <ng-container matColumnDef='Id'>
                        <th mat-header-cell *matHeaderCellDef class='col1'>#</th>
                        <td mat-cell *matCellDef='let e'>
						<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/crimp-details', e.Id]">{{e.Id}}</button></td>
                    </ng-container>
                    <ng-container matColumnDef='OrderNumber'>
                        <th mat-header-cell *matHeaderCellDef class='col2'> Sales Order</th>
                        <td mat-cell *matCellDef='let e'>{{e.OrderNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef='HoseName'>
                        <th mat-header-cell *matHeaderCellDef class='col3'> Hose</th>
                        <td mat-cell *matCellDef='let e'>{{e.HoseName}}</td>
                    </ng-container>
                    <ng-container matColumnDef='FerruleName'>
                        <th mat-header-cell *matHeaderCellDef class='col4'> Ferrule</th>
                        <td mat-cell *matCellDef='let e'>{{e.FerruleName}}</td>
                    </ng-container>
                    <ng-container matColumnDef='FittingName'>
                        <th mat-header-cell *matHeaderCellDef class='col5'> Fitting</th>
                        <td mat-cell *matCellDef='let e'>{{e.FittingName}}</td>
                    </ng-container>
                    <ng-container matColumnDef='CreatedDate'>
                        <th mat-header-cell *matHeaderCellDef class='col6'> Created Date</th>
                        <td mat-cell *matCellDef='let e'>{{e.CreatedDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef='Branch'>
                        <th mat-header-cell *matHeaderCellDef class='col7'> Branch</th>
                        <td mat-cell *matCellDef='let e'>{{e.Branch}}</td>
                    </ng-container>
                    <ng-container matColumnDef='HT'>
                        <th mat-header-cell *matHeaderCellDef class='col8'>Hydro Test</th>
                        <td mat-cell *matCellDef='let e'>
                            <button class='btn btn-default' style='min-width: 80px;' (click)="createTraveler(e.Id)">Hydro Test</button>
                        </td>
                    </ng-container>
					
                    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
                    <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
                </table>
                </div>
                <mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
                <div>
                    <mat-spinner color="primary" class="spinner" *ngIf="isLoadingResults"></mat-spinner>
                </div>
