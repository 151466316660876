import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-industrial-assembly',
	templateUrl: './industrial-assembly.component.html',
	styleUrls: ['./industrial-assembly.component.css']
})
export class IndustrialAssemblyComponent implements OnInit {

	url: SafeResourceUrl;
	legacyUrl = '/IndustrialAssemblies/Menu.aspx';

	constructor(
		public sanitizer: DomSanitizer,
		private settings: ApplicationSettings) {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.retrieveLegacyAppUrl() + this.legacyUrl);
	}

	ngOnInit() {
	}

}
