import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CrimpListComponent } from './crimp-list/crimp-list.component';
import { CrimpGridService } from './crimp-list/crimp-list.service';
import { CrimpService } from './crimp.service';
import { NewFromCrimpComponent } from './new-from-crimp/new-from-crimp.component';
import { NewFromCrimpService } from './new-from-crimp/new-from-crimp.service';
import { MaterialModule } from '../material-module';
import { CrimpEditComponent  } from './crimp-edit/crimp-edit.component';

@NgModule({
	declarations: [
		CrimpListComponent,
		NewFromCrimpComponent,
		CrimpEditComponent 
	],
	imports: [
		MaterialModule,
		BrowserModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		RouterModule.forChild([
		  { path: 'crimp-list', component: CrimpListComponent },
		  { path: 'new-from-crimp', component: NewFromCrimpComponent },
		  { path: 'crimp-details/:Id', component: CrimpEditComponent }
		])
	  ],
	providers: [
		CrimpGridService,
		CrimpService,
		NewFromCrimpService
	]
})
export class CrimpModule { }
