<div class="panel panel-primary animaPlaceholder">
	<div class="container-fluid">
		<div class="panel-heading">
			{{page}}
			<div class='has-error' class="text-danger" *ngIf='errorMessage'>
				<button class="btn btn-primary btn-sm" (click)="resetError()">Reset Error</button>
				{{ errorMessage }}
			</div>
		</div>
		<div class="panel-body">
			<div class="row">
				<div class="col-md-5">
					<form class="form-horizontal" novalidate [formGroup]="labelForm">
						<fieldset>
							<div class="form-group">
								<label class="col-md-4 control-label">Area</label>
								<div class="col-md-8">
									<input type="text" placeholder="Area" class="form-control" formControlName="Area">
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Row</label>
								<div class="col-md-8">
									<input class="form-control" type="text" formControlName="Row" placeholder="Row" />
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Rack</label>
								<div class="col-md-8">
									<input class="form-control" type="text" formControlName="Rack" placeholder="Rack" />
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Shelf</label>
								<div class="col-md-8">
									<input class="form-control" type="text" formControlName="Shelf"
										placeholder="Shelf" />
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Bin Location</label>
								<div class="col-md-8">
									<input class="form-control" type="text" formControlName="Location"
										placeholder="Bin Location" />
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Replace Part</label>
								<div class="col-md-8 flex-container">
									<input type="checkbox" formControlName="ReplacePartStatus" style="width:10%" />
									<input class='form-control' type="text" formControlName="ReplacePartText"
										placeholder="Replace Part" />
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Printers</label>
								<div class="col-md-8">
								
										<select formControlName='PrinterName' class='form-control block-element'>
											<option *ngFor='let item of printerList' [value]="item.IP">{{item.Name+'('+item.IP+')'}}
											</option>
										</select>
								
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Layout</label>
								<div class="col-md-8">
									<select formControlName='Layout' class='form-control block-element'>
										<option *ngFor='let item of LayoutList' [value]="item">{{item}}</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Printer Resolution</label>
								<div class="col-md-8">
									<select formControlName='PrinterResolution' class='form-control block-element'>
										<option *ngFor='let item of PrinterResolutionList' [value]="item">{{item}}</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Version</label>
								<div class="col-md-8">
									<select  readonly formControlName='Version' class='form-control block-element'>
										<option>1</option>
										<option>2</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label">Position</label>
								<div class="col-md-8">
									<select  readonly formControlName='Position' class='form-control block-element'>
										<option>Center</option>
										<option>Not Center</option>
									</select>
								</div>
							</div>
							<div class="form-group">
								<div class="col-md-offset-2 col-md-3 col-xs-4">
									<button class="btn btn-primary btn-sm" (click)="fetchData()"
										[disabled]="!labelForm.valid">Fetch Data</button>
								</div> 
								<div class="col-md-3 col-xs-4">
									<button class="btn btn-primary btn-sm" (click)="print()"
										[disabled]="!labelForm.valid || !labelDataStatus || !serverStatus || !printerAvailable">Print</button>
								</div>
								<div class="col-md-3 col-xs-4" *ngIf="!serverStatus || !printerAvailable">
									<button class="btn btn-primary btn-sm" (click)="reconnect()">Reconnect</button>
								</div>
							</div>
							<div   *ngIf="serverStatus && printerAvailable" >
								<label style="color: green;font-size: 18px;">local communications Working</label>
							</div>
							<div  *ngIf="!serverStatus || !printerAvailable" >
								<label style="color: red;font-size: 18px;">{{serverStatusMessage}}</label>
							</div>
							<div style="overflow-x:auto;">
								<table>
									<tr>
										<th>Area</th>
										<th>Row</th>
										<th>Rack</th>
										<th>Shelf</th>
										<th>Location</th>
										<th>PartNumber</th>
									</tr>
									<tr *ngFor="let i of labelData">
										<td>{{i.Area}}</td>
										<td>{{i.Row}}</td>
										<td>{{i.Rack}}</td>
										<td>{{i.Shelf}}</td>
										<td>{{i.Location}}</td>
										<td>{{i.PartNumber}}</td>
									</tr>
								</table>
							</div>
						</fieldset>
					</form>
				</div>
				<div class="col-md-7">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group  col-md-6">
								<label class="col-md-4 control-label">Direct Print</label>
								<div class="col-md-8">
									<input type="text" placeholder="Direct Print" class="form-control"
										[(ngModel)]="directPrintData">
								</div>
							</div>
							<div class="form-group  col-md-4">
								<div class='col-md-10'>
									<button class="btn btn-primary" (click)="directPrint()" [disabled]="labelForm.get('PrinterName').value=='' || directPrintData=='' || directPrintData.length < 8 || directPrintData.length>9 || !serverStatus">Direct Print</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="col-md-6">
								<label class="col-md-4 control-label">Upload</label>
								<div class="col-md-8">
									<input type="file" class="form-control" #inputFile
										(change)="onFileSelected($event)" />
								</div>
							</div>
							<div class="form-group  col-md-4">
								<div class='col-md-10'>
									<button class="btn btn-primary" (click)="filePrint()"
										[disabled]="labelForm.get('PrinterName').value=='' || fileUpload=='' || !serverStatus">Print</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="container" style="height: 300px; width: 500px;margin-top: 50px;">
							<img src="../../../assets/4x1label.png" style="width:500px">
							<div class="top-left">
								{{labelForm.get('Area').value}}{{labelForm.get('Row').value}}{{labelForm.get('Rack').value}}{{labelForm.get('Shelf').value}}{{labelForm.get('Location').value}}
							</div><br />
							<div class="partnumber">{{partNumber}}</div>
							(*print will be slightly different)
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>