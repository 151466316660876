import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../shared/application-settings';

@Injectable({
	providedIn: 'root'
})
export class LabelprintingService {
	private baseUrl;
	public pageTitle = 'Label Printing';
	received = [];


	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Labelprinting';

	}

	processError(err: any) {
		let message = '';
		if (err.error instanceof ErrorEvent) {
			message = err.error.message;
		} 
		else if(err.error.ErrorDescription!=null){
			message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		else {
			message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	getTwoByFourPartsPdfFile() {
		console.log(this.baseUrl + '/gettwobyfourpartspdf');
		return this.authHttp.get<any>(this.baseUrl + '/gettwobyfourpartspdf')
			.pipe(retry(1), catchError(this.processError));
	}
	

	createBoxlabel(obj) {
		console.log(this.baseUrl + '/boxlabels');
		return this.authHttp.post<any>(this.baseUrl + '/boxlabels', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	createPackagelabel(obj) {
		console.log(obj);
		
		return this.authHttp.post<any>(this.baseUrl + '/packagelabels', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	saveSearch(obj,pageTitle) {
		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('PrinterName', obj.PrinterName, pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });
	}
	createNonStockLabel(obj) {
		console.log(this.baseUrl + '/nonstocklabels');
		return this.authHttp.post<any>(this.baseUrl + '/nonstocklabels', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	getPrinterListData(branchid,printertype) {
		
		const url=`${this.baseUrl}` + '/getprinterlist?branchid='+`${branchid}`+'&printertype='+`${printertype}`;
		console.log(url);
		return this.authHttp.get<any>(url)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabel4By1(obj) {
		console.log(this.baseUrl + '/labels4by1');
		return this.authHttp.post<any>(this.baseUrl + '/labels4by1', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	createLabel6By2(obj) {
		console.log(this.baseUrl + '/labels6by2');
		return this.authHttp.post<any>(this.baseUrl + '/labels6by2', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	fetchDataForRackLabels(obj){
		console.log(this.baseUrl + '/fetchdataforracklabels');
		return this.authHttp.post<any>(this.baseUrl + '/fetchdataforracklabels', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	directPrintLabels4by1(data,resolution,layout,branchid,version,position){
		console.log(this.baseUrl + '/directprintlabels4by1');
		return this.authHttp.get<any>(this.baseUrl + '/directprintlabels4by1?data='+data+'&resolution='+resolution+'&layout='+layout+'&branchid='+branchid+'&version='+version+'&position='+position)
			.pipe(retry(1), catchError(this.processError));
	}
	directPrintLabels6by2(data,resolution,layout,branchid){
		console.log(this.baseUrl + '/directprintlabels6by2');
		return this.authHttp.get<any>(this.baseUrl + '/directprintlabels6by2?data='+data+'&resolution='+resolution+'&layout='+layout+'&branchid='+branchid)
			.pipe(retry(1), catchError(this.processError));
	}

	filePrintlabels4by1(file){
		console.log(this.baseUrl + '/fileprintlabels4by1');
		return this.authHttp.post<any>(this.baseUrl + '/fileprintlabels4by1', file)
			.pipe(retry(1), catchError(this.processError));
	}
	filePrintlabels6by2(file){
		console.log(this.baseUrl + '/fileprintlabels6by2');
		return this.authHttp.post<any>(this.baseUrl + '/fileprintlabels6by2', file)
			.pipe(retry(1), catchError(this.processError));
	}
	filePrintInventorylabels(file){
		console.log(this.baseUrl + '/fileprintinventorylabels');
		return this.authHttp.post<any>(this.baseUrl + '/fileprintinventorylabels', file)
			.pipe(retry(1), catchError(this.processError));
	}
}
