import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { IAudit } from './Audit-model/Audit';
import { IAuditSearch } from './Audit-model/AuditSearch';
import { IAudits } from './Audit-model/Audits';

@Injectable()
export class AuditService {
	private baseUrl;
	public pageTitle = 'Audits List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Audits';
	}

	getAudit(id: number):  Observable<IAudit> {
		if (id === 0) {
			return of(this.initializeAudit());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IAudit>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveAudit(audit: IAudit): Observable<any> {
		if (audit.Id === 0) {
			return this.createAudit(audit);
		}
		return this.updateAudit(audit);
	}

	private createAudit(audit: IAudit): Observable<any> {
		audit.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/auditcreate', audit)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateAudit(audit: IAudit): Observable<IAudit> {
		return this.authHttp.put<IAudit>(this.baseUrl, audit)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteAudit(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeAudit(): IAudit {
		return {
			Id: 0,
			TableChanged: '',
			ColumnChanged: '',
			KeyId: '',
			WhatHappened: '',
			OldValue: '',
			NewValue: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IAudit;
	}

	public getRecordSet(search: IAuditSearch): Observable<IAudits> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('TableChanged', search.TableChanged, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ColumnChanged', search.ColumnChanged, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('KeyId', search.KeyId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('WhatHappened', search.WhatHappened, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('OldValue', search.OldValue, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('NewValue', search.NewValue, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('TableChanged', search.TableChanged ? search.TableChanged : '')
			.set('ColumnChanged', search.ColumnChanged ? search.ColumnChanged : '')
			.set('KeyId', search.KeyId ? search.KeyId : '')
			.set('WhatHappened', search.WhatHappened ? search.WhatHappened : '')
			.set('OldValue', search.OldValue ? search.OldValue : '')
			.set('NewValue', search.NewValue ? search.NewValue : '')
		};

		return this.authHttp.get<IAudits>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IAudits>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IAuditSearch { 
		let search: IAuditSearch = {
			Id: 1,
			TableChanged: '',
			ColumnChanged: '',
			KeyId: '',
			WhatHappened: '',
			OldValue: '',
			NewValue: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IAuditSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('TableChanged', search.TableChanged ? search.TableChanged : '')
			.set('ColumnChanged', search.ColumnChanged ? search.ColumnChanged : '')
			.set('KeyId', search.KeyId ? search.KeyId : '')
			.set('WhatHappened', search.WhatHappened ? search.WhatHappened : '')
			.set('OldValue', search.OldValue ? search.OldValue : '')
			.set('NewValue', search.NewValue ? search.NewValue : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getAuditDataByKeyId(keyid, tablename) {
		const options = {
			params: new HttpParams()
				.set('keyid', keyid ? keyid : 0)
				.set('tablename', tablename ? tablename : '')
		};
		return this.authHttp.get<any>(this.baseUrl + '/getauditdatabykeyid', options)
		.pipe(retry(1), catchError(this.processError));
	}
	getAuditRecordData(data){
		const options = {
			params: new HttpParams()										
				.set('fromdate', data.FromDate ? data.FromDate : '')
				.set('todate', data.ToDate ? data.ToDate : '')
				.set('tablename', data.TableName ? data.TableName : '')		
				.set('oldvalue', data.OldValue ? data.OldValue : '')				
				.set('newvalue', data.NewValue ? data.NewValue : '')								
		};

		return this.authHttp.get<any>(this.baseUrl + '/getauditrecord',options)
		.pipe(retry(1), catchError(this.processError));
	}
}