import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { BinlabelcatListComponent } from './binlabelcat-list/binlabelcat-list.component';
import { BinlabelcatEditComponent } from './binlabelcat-edit/binlabelcat-edit.component';
import { BinlabelcatService } from './binlabelcat-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		BinlabelcatListComponent
		, BinlabelcatEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'binlabelcats', component: BinlabelcatListComponent },
			{ path: 'binlabelcat-details/:Id', component: BinlabelcatEditComponent },
		])
	],
	providers: [
		BinlabelcatService
	]
})
export class BinlabelcatModule { }
