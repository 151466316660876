
<div class="panel panel-primary animaPlaceholder">
<div class="container-fluid">
	<div class="panel-heading">
		{{page}}
		<div class='has-error' class="text-danger" *ngIf='errorMessage'>
				<button class="btn btn-primary btn-sm" (click)="resetError()">Reset Error</button>
				{{ errorMessage }}
		</div>
	</div>
	<div class="panel-body">
		<form class="form-horizontal" novalidate [formGroup]="boxlabelForm">
			<fieldset>
				<div class="row">
					<div class="col-md-5">
						<div class="form-group">
							<label class="col-md-4 control-label">Part Number</label>
							<div class="col-md-8">
								
								<input type="text" placeholder="Part Number" class="form-control" matInput
									formControlName="PartNumber" [matAutocomplete]="auto" >
								<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
									<mat-option *ngFor="let item of partsData" [value]="item.PartNumber" (onSelectionChange)="partChanged(item.PartNumber)">
										{{item.PartNumber}}
									</mat-option>
								</mat-autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Part Description</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="PartDescription" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Upc</label>
							<div class="col-md-8">
								<input class="form-control" type="text" formControlName="Upc" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Quantity</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="Quantity" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Number Of Labels</label>
							<div class="col-md-8">
								<input class="form-control" type="number" formControlName="NumberOfLabels" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Qty Type</label>
							<div class="col-md-8">
								<select formControlName='QtyType' class='form-control block-element'>
									<option *ngFor='let qtytype of qtyTypes' [value]="qtytype">{{qtytype}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Printers</label>
							<div class="col-md-8">
								<select formControlName='PrinterName' class='form-control block-element'>					
									<option *ngFor='let item of printerList' [value]="item.IP">{{item.Name+'('+item.IP+')'}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label class="col-md-4 control-label">Printer Resolution</label>
							<div class="col-md-8">
								<select formControlName='PrinterResolution' class='form-control block-element'>
									<option *ngFor='let item of PrinterResolutionList' [value]="item">{{item}}</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<div class="col-md-offset-2 col-md-4 col-xs-4">
								<button class="btn btn-primary btn-sm" (click)="print()" [disabled]="!boxlabelForm.valid || !printerAvailable">Print</button>
							</div>
							<div class="col-md-offset-2 col-md-4 col-xs-4" *ngIf="!serverStatus || !printerAvailable">
								<button class="btn btn-primary btn-sm" (click)="reconnect()"
									>Reconnect</button>
							</div>
						</div>
						<div   *ngIf="serverStatus && printerAvailable" >
							<label style="color: green;font-size: 18px;">local communications Working</label>
						</div>
						<div  *ngIf="!serverStatus || !printerAvailable" >
							<label style="color: red;font-size: 18px;">{{serverStatusMessage}}</label>
						</div>
					</div>
					<div class="col-md-7">

						<div class="container" style="height: 400px; width: 500px;">
							<img src="../../../assets/boxlabel.jpg" style="width:500px">
							<div class="bottom-left">{{boxlabelForm.get('Quantity').value}}</div>
							  <div class="top-left">{{boxlabelForm.get('PartNumber').value}}</div>
							  <div class="part-desc">{{boxlabelForm.get('PartDescription').value}}</div>
							 <div class="top-right">{{boxlabelForm.get('Upc').value}}</div>
							  <div class="bottom-right">{{boxlabelForm.get('Quantity').value}}</div>
						   <div class="centered">{{boxlabelForm.get('QtyType').value}}</div>
							  (*print will be slightly different)
						</div>
						<!-- <img src="../../../assets/boxlabel.jpg" alt="" class="img-responsive"> -->
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
