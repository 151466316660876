import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IVprodinvent} from './Vprodinvent-model/Vprodinvent';
import { IVprodinvents } from './Vprodinvent-model/Vprodinvents';
import { IVprodinventSearch } from './Vprodinvent-model/VprodinventSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class VprodinventService {
	private baseUrl;
	public pageTitle = 'Product Inventory List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Vprodinvents';
	}

	getVprodinvent(id: number):  Observable<IVprodinvent> {
		if (id === 0) {
			return of(this.initializeVprodinvent());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IVprodinvent>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveVprodinvent(vprodinvent: IVprodinvent): Observable<any> {
		if (vprodinvent.Id === 0) {
			return this.createVprodinvent(vprodinvent);
		}
		return this.updateVprodinvent(vprodinvent);
	}

	private createVprodinvent(vprodinvent: IVprodinvent): Observable<any> {
		vprodinvent.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/vprodinventcreate', vprodinvent)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateVprodinvent(vprodinvent: IVprodinvent): Observable<IVprodinvent> {
		return this.authHttp.put<IVprodinvent>(this.baseUrl, vprodinvent)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteVprodinvent(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeVprodinvent(): IVprodinvent {
		return {
			Id: 0,
			ProductId: 0,
			BranchId: 0,
			LocType: '',
			OnHandQty: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IVprodinvent;
	}

	public getRecordSet(search: IVprodinventSearch): Observable<IVprodinvents> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('ProductId', search.ProductId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BranchId', search.BranchId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LocType', search.LocType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('OnHandQty', search.OnHandQty, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('ProductId', search.ProductId ? search.ProductId : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('LocType', search.LocType ? search.LocType : '')
			.set('OnHandQty', search.OnHandQty ? search.OnHandQty : '')
		};

		return this.authHttp.get<IVprodinvents>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IVprodinvents>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IVprodinventSearch { 
		let search: IVprodinventSearch = {
			Id: 1,
			ProductId: '',
			BranchId: '',
			LocType: '',
			OnHandQty: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IVprodinventSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('ProductId', search.ProductId ? search.ProductId : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('LocType', search.LocType ? search.LocType : '')
			.set('OnHandQty', search.OnHandQty ? search.OnHandQty : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}