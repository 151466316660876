
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/partatt-details/0']" style='width:auto;' [disabled]="progressStatus">Add New PartAtt</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: 100px' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Eclipse Id</label>
				<input type='text' class='form-control block-element' placeholder=' Eclipse Id' [(ngModel)]='EclipseId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Primary Dimension</label>
				<select class="form-control" [(ngModel)]='PrimaryDimension'>
					<option *ngFor='let i of primaryDimList;' [value]="i.Value">
						{{i.Value}}</option>
				</select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Primary Dimension Value</label>
				<input type='text' class='form-control block-element' placeholder=' Primary Dimension Value' [(ngModel)]='PrimaryDimensionValue' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Secondary Dimension</label>
				<select class="form-control" [(ngModel)]='SecondaryDimension'>
					<option *ngFor='let i of secondaryDimList;' [value]="i.Value">
						{{i.Value}}</option>
				</select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Secondary Dimension Value</label>
				<input type='text' class='form-control block-element' placeholder=' Secondary Dimension Value' [(ngModel)]='SecondaryDimensionValue' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Var3</label>
				<input type='text' class='form-control block-element' placeholder=' Var3' [(ngModel)]='Var3' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Series</label>
				<input type='text' class='form-control block-element' placeholder=' Series' [(ngModel)]='Series' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Style</label>
				<input type='text' class='form-control block-element' placeholder=' Style' [(ngModel)]='Style' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Label Tag</label>
				<input type='text' class='form-control block-element' placeholder=' Label Tag' [(ngModel)]='LabelTag' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Label Group</label>
				<input type='text' class='form-control block-element' placeholder=' Label Group' [(ngModel)]='LabelGroup' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Sort Order</label>
				<input type='text' class='form-control block-element' placeholder=' Sort Order' [(ngModel)]='SortOrder' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Description Count</label>
				<input type='text' class='form-control block-element' placeholder=' Description Count' [(ngModel)]='DescriptionCount' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/partatt-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='EclipseId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Eclipse Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.EclipseId}}</td>
	</ng-container>
	<ng-container matColumnDef='PrimaryDimension'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Primary Dimension</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.PrimaryDimension}}</td>
	</ng-container>
	<ng-container matColumnDef='PrimaryDimensionValue'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Primary Dimension Value</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.PrimaryDimensionValue}}</td>
	</ng-container>
	<ng-container matColumnDef='SecondaryDimension'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Secondary Dimension</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.SecondaryDimension}}</td>
	</ng-container>
	<ng-container matColumnDef='SecondaryDimensionValue'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Secondary Dimension Value</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.SecondaryDimensionValue}}</td>
	</ng-container>
	<ng-container matColumnDef='Var3'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Var3</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.Var3}}</td>
	</ng-container>
	<ng-container matColumnDef='Series'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Series</th>
		<td mat-cell *matCellDef='let e' class='col8' >{{e.Series}}</td>
	</ng-container>
	<ng-container matColumnDef='Style'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Style</th>
		<td mat-cell *matCellDef='let e' class='col9' >{{e.Style}}</td>
	</ng-container>
	<ng-container matColumnDef='LabelTag'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Label Tag</th>
		<td mat-cell *matCellDef='let e' class='col10' >{{e.LabelTag}}</td>
	</ng-container>
	<ng-container matColumnDef='LabelGroup'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Label Group</th>
		<td mat-cell *matCellDef='let e' class='col11' >{{e.LabelGroup}}</td>
	</ng-container>
	<ng-container matColumnDef='SortOrder'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'> Sort Order</th>
		<td mat-cell *matCellDef='let e' class='col12' >{{e.SortOrder}}</td>
	</ng-container>
	<ng-container matColumnDef='DescriptionCount'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col13'> Description Count</th>
		<td mat-cell *matCellDef='let e' class='col13' >{{e.DescriptionCount}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>