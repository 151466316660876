import { DatePipe } from "@angular/common";
import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ViewChildren,
	ViewEncapsulation,
} from "@angular/core";
import {
	FormBuilder,
	FormControlName,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { finalize } from "rxjs/operators";
import { BrancheService } from "../../admin/branches/branche-service";
import { UserService } from "../../admin/users/user-service/user.service";
import { IUsersearch } from "../../admin/usersearches/usersearch-model/usersearch";
import { UsersearchService } from "../../admin/usersearches/usersearch-service";
import { AuthService } from "../../auth/auth.service";
import { ApplicationSettings } from "../../shared/application-settings";
import { ICustomerconcernSearch } from "../Customerconcern-model/CustomerconcernSearch";
import { ICustomerconcerns } from "../Customerconcern-model/Customerconcerns";
import { ICustomerConcernsFailureMode } from "../customerconcern-model/customerConcernsFailureMode";
import { CustomerConcernsFiveWhysDto } from "../customerconcern-model/customerConcernsFiveWhysDto";
import { CustomerConcernsIshikawaDiagramDto } from "../customerconcern-model/customerConcernsIshikawaDiagramDto";
import { ICustomerconcern } from "../customerconcern-model/customerconcern";
import { CustomerConcernsFileDto } from "../customerconcern-model/customerconcernsFileDto";
import { CustomerconcernService } from "../customerconcern-service";

@Component({
	templateUrl: "./customerconcern-edit.component.html",
	styleUrls: ["./customerconcern-edit.component.css"],
	encapsulation: ViewEncapsulation.None,
})
export class CustomerconcernEditComponent implements OnInit {
	@ViewChildren(FormControlName, { read: ElementRef })
	formInputElements: ElementRef[];
	public page = "Edit NCR";
	errorMessage: string;
	customerconcernForm: FormGroup;
	customerconcern: ICustomerconcern;
	@ViewChild("historyPaginator", { static: true })
	historyPaginator: MatPaginator;
	@ViewChild("firstPaginator", { static: true }) firstPaginator: MatPaginator;
	@ViewChild("notesPaginator", { static: true }) notesPaginator: MatPaginator;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public fiveWHYsRecordTotal: number = 0;
	public recordTotalHistory: number = 0;
	public UserSearches: IUsersearch[];
	public pageSize = "10";
	public CustomerconcernSearch: ICustomerconcernSearch;
	public allFailureModes: ICustomerConcernsFailureMode[] = [];
	public level1FailureModes: ICustomerConcernsFailureMode[] = [];
	public level2FailureModes: ICustomerConcernsFailureMode[] = [];
	public ListData: ICustomerconcerns;
	public showNextRecordButton: boolean = true;
	public descriptionsForm: FormGroup;
	public filesForm: FormGroup;
	public notesForm: FormGroup;
	public COQForm: FormGroup;
	public StatusForm: FormGroup;
	public FiveWHYsForm: FormGroup;
	public IshikawaDiagramForm: FormGroup;
	public ncrNumber: number;
	public ncrDate: any;
	public dataSource: any;
	public fiveWHYsDataSource: any;
	public ishikawaDiagramDataSource: any;
	public dataSourceHistory: any;
	public dataSourceNotes: any;
	public notesTotalRecord: any;
	public employees;
	public branches;
	public notesListArray = [];
	public OwnerName;
	public progressStatus: number = 0;
	public allCustomerConcernsStatus: any;
	public fiveWHYsButton: string = "Show Five WHYs Table";
	public ishikawaDiagramButton: string = "Show Ishikawa Diagram";

	public disableOpen: boolean = true;
	public disableClosed: boolean = true;
	public disableCancelled: boolean = true;
	public notesFormSubmitted: boolean = false;
	public emailStatus: string;
	public emailBtnPushed = false;
	public customersDatalength: number = -1;
	public vendorsDatalength: number = -1;

	displayedColumns: string[] = [
		"Edit",
		"FileName",
		"Description",
		"SortOrder",
		"IncludeInReport",
		"FileType",
		"Delete",
	];

	displayedColumnsA: string[] = [
		"Edit",
		"Effect",
		"FirstWhy",
		"SecondWhy",
		"ThirdWhy",
		"FourthWhy",
		"FifthWhy",
		"ActionWhy",
		"Delete",
	];
	historyDisplayedColumns: string[] = [
		"ColumnChanged",
		"WhatHappened",
		"OldValue",
		"NewValue",
		"CreatedBy",
		"CreatedDate",
	];
	notesColumns: string[] = ["Delete", "Notes"];
	fileToEdit: CustomerConcernsFileDto;
	fiveWHYsToEdit: CustomerConcernsFiveWhysDto;
	ishikawaDiagramToEdit: CustomerConcernsIshikawaDiagramDto;
	customerConcernsId: number;
	fileFormSubmitted: boolean = false;
	@ViewChild("inputFile") myInputVariable: ElementRef;
	@ViewChild("effectField") effectField: ElementRef<HTMLTextAreaElement>;

	customersData: any[] = []; //used in customer combo box.
	vendorsData: any[] = []; //used in vendor combo box.
	public allCustomers;
	public allVendors;
	@ViewChild("customerInput") customerInput: ElementRef;
	@ViewChild("vendorInput") vendorInput: ElementRef;
	@ViewChild("customerAutoTrigger")
	customerAutoTrigger: MatAutocompleteTrigger;
	@ViewChild("vendorAutoTrigger") vendorAutoTrigger: MatAutocompleteTrigger;
	customerPanelOpen: boolean = false;
	selectedCustomerName: string = "";
	vendorPanelOpen: boolean = false;
	selectedVendorName: string = "";
	public vendorFieldVisible: boolean = false;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private userSearchService: UsersearchService,
		private router: Router,
		private customerconcernService: CustomerconcernService,
		public applicationSettings: ApplicationSettings,
		public userService: UserService,
		public branchService: BrancheService,
		private el: ElementRef,
		public datepipe: DatePipe,
		public auth: AuthService
	) {}

	ngOnInit(): void {
		this.ncrDate = new Date();
		this.ncrDate = this.datepipe.transform(this.ncrDate, "yyyy-MM-dd");

		this.progressStatus++;
		this.branchService
			.getAllBranchesForDropdown()
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((response) => {
				this.branches = response;
			});

		this.progressStatus++;
		this.customerconcernService
			.getFailureModes()
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((response) => {
				this.allFailureModes = response;
				this.level1FailureModes = this.allFailureModes
					.filter(
						(failureMode) =>
							failureMode.Level1 > 0 &&
							failureMode.Level2 == 0 &&
							failureMode.Level3 == 0
					)
					.sort((a, b) => a.ID - b.ID);
				this.level2FailureModes = [];
			});

		this.progressStatus++;
		this.customerconcernService
			.getCustomerConcernsStatus()
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((response) => {
				this.allCustomerConcernsStatus = response;
			});

		this.initializeCustomerConcernForm();
		this.initializeDescriptionsForm();
		this.COQForm = this.fb.group({
			COQProducts: "",
			COQAdminFee: "",
			COQLabour: "",
			COQFreight: "",
			COQExtra: "",
			COQNotes: "",
			COQ: ["", [Validators.maxLength(250)]],
		});
		this.StatusForm = this.fb.group({
			StatusID: "",
		});
		this.initializeFilesForm();
		this.FiveWHYsForm = this.fb.group({
			ID: "",
			CustomerConcernsId: "",
			Effect: ["", [Validators.maxLength(140)]],
			FirstWhy: ["", [Validators.maxLength(140)]],
			SecondWhy: ["", [Validators.maxLength(140)]],
			ThirdWhy: ["", [Validators.maxLength(140)]],
			FourthWhy: ["", [Validators.maxLength(140)]],
			FifthWhy: ["", [Validators.maxLength(140)]],
			ActionWhy: ["", [Validators.maxLength(140)]],
		});
		this.IshikawaDiagramForm = this.fb.group({
			ID: "",
			CustomerConcernsId: "",
			IshikawaMaterial1: ["", [Validators.maxLength(70)]],
			IshikawaMaterial2: ["", [Validators.maxLength(70)]],
			IshikawaMaterial3: ["", [Validators.maxLength(70)]],
			IshikawaMaterial4: ["", [Validators.maxLength(70)]],
			IshikawaMaterial5: ["", [Validators.maxLength(70)]],
			IshikawaMachine1: ["", [Validators.maxLength(70)]],
			IshikawaMachine2: ["", [Validators.maxLength(70)]],
			IshikawaMachine3: ["", [Validators.maxLength(70)]],
			IshikawaMachine4: ["", [Validators.maxLength(70)]],
			IshikawaMachine5: ["", [Validators.maxLength(70)]],
			IshikawaMethod1: ["", [Validators.maxLength(70)]],
			IshikawaMethod2: ["", [Validators.maxLength(70)]],
			IshikawaMethod3: ["", [Validators.maxLength(70)]],
			IshikawaMethod4: ["", [Validators.maxLength(70)]],
			IshikawaMethod5: ["", [Validators.maxLength(70)]],
			IshikawaMan1: ["", [Validators.maxLength(70)]],
			IshikawaMan2: ["", [Validators.maxLength(70)]],
			IshikawaMan3: ["", [Validators.maxLength(70)]],
			IshikawaMan4: ["", [Validators.maxLength(70)]],
			IshikawaMan5: ["", [Validators.maxLength(70)]],
			IssueIshikawaDiagram: ["", [Validators.maxLength(140)]],
		});
		this.notesForm = this.fb.group({
			notes: ["", [Validators.required, Validators.maxLength(1000)]],
		});
		this.sub = this.route.params.subscribe((params) => {
			const id = +params["Id"];
			this.customerConcernsId = id;

			if (this.employees == null) {
				this.getAllEmployees();
			}

			if (this.allVendors == null) {
				this.progressStatus++;

				this.customerconcernService
					.getAllVendors()
					.pipe(
						finalize(() => {
							this.progressStatus--;
						})
					)
					.subscribe((response) => {
						this.allVendors = response;
					});
			}

			if (this.allCustomers == null) {
				this.progressStatus++;

				this.customerconcernService
					.getAllCustomers()
					.pipe(
						finalize(() => {
							this.progressStatus--;
						})
					)
					.subscribe((response) => {
						this.allCustomers = response;
						this.getCustomerconcern(id);
					});
			} else {
				this.getCustomerconcern(id);
			}
		});
		this.getFileCustomerConcernData();
		this.getNotes();
		this.getFiveWhysRecords();
		this.getIshikawaDiagramRecords();

		this.customerconcernForm
			.get("FailureModeLevel1")
			.valueChanges.subscribe((selectedValue) => {
				// Filter the level2FailureMode based on the selection in level1FailureMode
				this.level2FailureModes = this.allFailureModes.filter(
					(failureMode) =>
						failureMode.Level1 == selectedValue &&
						failureMode.Level2 > 0 &&
						failureMode.Level3 == 0
				);
				if (this.level2FailureModes.length > 0) {
					this.customerconcernForm
						.get("FailureModeLevel2")
						.setValue(this.level2FailureModes[0].ID);
				} else {
					this.customerconcernForm
						.get("FailureModeLevel2")
						.setValue(null);
				}

				// supplier defect or other
				if (selectedValue == 2 || selectedValue == 5) {
					this.vendorFieldVisible = true;
				} else {
					this.vendorFieldVisible = false;
					this.customerconcernForm.get("VendorID").setValue(null);
				}
			});
	}

	getAllEmployees() {
		this.progressStatus++;

		this.userService
			.getAllUsersCustomerConcerns("")
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((response) => {
				this.employees = response;
			});
	}

	getFileCustomerConcernData() {
		this.progressStatus++;

		this.customerconcernService
			.getAllFilesCustomerConcern(this.customerConcernsId)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((response) => {
				this.dataSource = new MatTableDataSource(response);
				this.dataSource.paginator = this.firstPaginator;
				this.recordTotal = response.length;
			});
	}

	public getSelectedRecordSet(): void {
		this.progressStatus++;

		this.customerconcernService
			.getRecordSet(this.CustomerconcernSearch)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(obj: ICustomerconcerns) =>
					this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				}
			);
	}

	private onSelectedRecordSetReceived(obj: ICustomerconcerns) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach((el) => {
			count += 1;
			if (el.Id == this.customerconcern.Id) record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total) this.showNextRecordButton = false;
		else this.showNextRecordButton = true;
	}

	saveCustomerconcern(returnToList: boolean): void {
		this.buttonPushed = true;
		const currentAuditor: string = this.customerconcern.AuditorAssignedId;

		if (this.validRequiredFieldsCustomerConcerns() != true) {
			this.buttonPushed = false;
			return;
		}

		if (
			this.customerconcernForm.valid &&
			this.descriptionsForm.valid &&
			this.COQForm.valid
		) {
			const customerConcernObject: ICustomerconcern = {
				...this.customerconcern,
				...this.customerconcernForm.value,
			};

			const descriptionForm: ICustomerconcern = {
				...this.customerconcern,
				...this.descriptionsForm.value,
			};

			var COQForm: ICustomerconcern = Object.assign(
				{},
				this.customerconcern,
				this.COQForm.value
			);
			var StatusForm: ICustomerconcern = Object.assign(
				{},
				this.customerconcern,
				this.StatusForm.value
			);

			customerConcernObject.NCRDate = this.ncrDate;
			customerConcernObject.Problem = descriptionForm.Problem;
			customerConcernObject.Containment = descriptionForm.Containment;
			customerConcernObject.RiskLevel = descriptionForm.RiskLevel;
			customerConcernObject.investigationResults =
				descriptionForm.investigationResults;
			customerConcernObject.RootCause = descriptionForm.RootCause;
			customerConcernObject.Disposition = descriptionForm.Disposition;

			// Corrective Action
			customerConcernObject.CorrectiveAction =
				descriptionForm.CorrectiveAction == "yes" ? 1 : 0;
			customerConcernObject.ActionPlan =
				descriptionForm.CorrectiveAction == "yes"
					? descriptionForm.ActionPlan
					: "";
			customerConcernObject.ImplementationDate =
				descriptionForm.CorrectiveAction == "yes"
					? descriptionForm.ImplementationDate
					: "";
			customerConcernObject.ImplementationNotes =
				descriptionForm.CorrectiveAction == "yes"
					? descriptionForm.ImplementationNotes
					: "";
			customerConcernObject.AuditorAssignedId =
				descriptionForm.CorrectiveAction == "yes"
					? descriptionForm.AuditorAssignedId
					: null;
			customerConcernObject.AuditCA =
				descriptionForm.CorrectiveAction == "yes"
					? descriptionForm.AuditCA
					: "";
			customerConcernObject.CorrectiveActionText =
				descriptionForm.CorrectiveActionText;

			customerConcernObject.StatusID = StatusForm.StatusID;

			customerConcernObject.COQAdminFee = COQForm.COQAdminFee;
			customerConcernObject.COQExtra = COQForm.COQExtra;
			customerConcernObject.COQFreight = COQForm.COQFreight;
			customerConcernObject.COQLabour = COQForm.COQLabour;
			customerConcernObject.COQNotes = COQForm.COQNotes;
			customerConcernObject.COQProducts = COQForm.COQProducts;

			// Corrective Action Check
			const requiredFields = [
				{
					field: customerConcernObject.ClosedDate,
					name: "NCR Detail - Closed Date",
				},
				{
					field: customerConcernObject.ActionPlan,
					name: "Descriptions - Action Plan",
				},
				{
					field: customerConcernObject.ImplementationDate,
					name: "Descriptions - Date of Implementation",
				},
				{
					field: customerConcernObject.ImplementationNotes,
					name: "Descriptions - Implementation Notes",
				},
				{
					field: customerConcernObject.AuditorAssignedId,
					name: "Descriptions - Responsible of Audit",
				},
				{
					field: customerConcernObject.AuditCA,
					name: "Descriptions - Feedback from the Process Owner",
				},
			];

			const missingFields = requiredFields
				.filter((f) => !f.field || f.field === "")
				.map((f) => f.name);

			if (
				customerConcernObject.Id != 0 &&
				customerConcernObject.Id != null &&
				customerConcernObject.StatusID == 3 &&
				customerConcernObject.CorrectiveAction == 1 &&
				missingFields.length > 0
			) {
				alert(
					`Since Corrective Action is set to "YES", please fill in the following required fields before closing this NCR:\n${missingFields
						.map((field, index) => `${index + 1}. ${field}`)
						.join("\n")}`
				);
				this.buttonPushed = false;
			} else {
				this.progressStatus++;
				this.customerconcernService
					.saveCustomerconcern(customerConcernObject)
					.pipe(
						finalize(() => {
							this.progressStatus--;
						})
					)
					.subscribe(
						(data) => {
							if (
								currentAuditor !=
								customerConcernObject.AuditorAssignedId
							) {
								this.progressStatus++;
								this.customerconcernService
									.sendEmailToAuditor(
										customerConcernObject.Id
									)
									.pipe(
										finalize(() => {
											this.progressStatus--;
										})
									)
									.subscribe((response) => {
										this.onSaveComplete(returnToList, data);
									});
							} else {
								this.onSaveComplete(returnToList, data);
							}
						},
						(error: any) => (this.errorMessage = <any>error)
					);
			}
		}
	}

	validRequiredFieldsCustomerConcerns(): boolean {
		if (this.customerconcernForm.get("FailureModeLevel1").value == null) {
			alert("Failure Mode not found");
			return false;
		}

		if (this.customerconcernForm.get("FailureModeLevel2").value == null) {
			alert("Sub Failure Mode not found");
			return false;
		}

		if (
			!this.validCustomerId(this.customerconcernForm.get("CustID").value)
		) {
			alert("Customer not found");
			return false;
		}

		if (
			!this.validVendorId(
				this.customerconcernForm.get("VendorID").value,
				this.customerconcernForm.get("FailureModeLevel1").value
			)
		) {
			alert("Vendor not found");
			return false;
		}

		if (
			this.customerconcernForm.get("BranchResponsible").value == null ||
			this.customerconcernForm.get("BranchResponsible").value == ""
		) {
			alert("Branch not found");
			return false;
		}

		if (this.customerconcernForm.get("Division").value == null) {
			alert("Division not found");
			return false;
		}

		if (this.customerconcernForm.get("EmployeeAssigned").value == null) {
			alert("Assign employee not found");
			return false;
		}

		if (
			this.descriptionsForm.get("Problem").value == null ||
			this.descriptionsForm.get("Problem").value == ""
		) {
			alert("Problem not found");
			return false;
		}

		return true;
	}

	validVendorId(
		vendorEntered: string,
		failureModeLevel1Entered: number
	): boolean {
		if (this.allVendors == null) return false;

		if (vendorEntered == null || vendorEntered == "") {
			if (failureModeLevel1Entered != 2) return true;
		} else {
			var vendor = this.allVendors.find(
				(item) => item.VendorID == vendorEntered
			);
			if (vendor != null) return true;
		}

		this.vendorsData = [];
		this.vendorsDatalength = 0;

		return false;
	}

	validCustomerId(customerEntered: string): boolean {
		if (this.allCustomers == null) return false;

		var customer = this.allCustomers.find(
			(item) => item.CustID == customerEntered
		);
		if (customer != null) return true;

		this.customersData = [];
		this.customersDatalength = 0;

		return false;
	}

	onSaveComplete(returnToList: boolean, data: any): void {
		if (returnToList) {
			this.customerconcernForm.reset();
			this.router.navigate(["/customerconcerns"]);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.customerconcern.Id === 0) {
				this.router.navigate(["/customerconcern-details", data.Id]);
			}
		}
		this.buttonPushed = false;
	}

	getCustomerconcern(id: number): void {
		this.progressStatus++;

		this.customerconcernService
			.getCustomerconcern(id)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(obj: ICustomerconcern) => this.onCustomerconcernRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				}
			);
	}

	onCustomerconcernRetrieved(customerconcern: ICustomerconcern): void {
		if (this.customerconcernForm) {
			this.customerconcernForm.reset();
		}
		this.customerconcern = customerconcern;
		if (this.customerconcern.Id === 0) {
			this.page = "Add NCR";
		} else {
			this.page = "Edit NCR";
			this.ncrDate = this.customerconcern.NCRDate;
			this.OwnerName = this.customerconcern.Owner;
		}
		this.loadCustomerSavedNCR(this.customerconcern.CustID);
		this.loadVendorSavedNCR(this.customerconcern.VendorID);

		this.customerconcernForm.patchValue({
			Id: this.customerconcern.Id,
			NCRDate: this.customerconcern.NCRDate,
			Customer: this.customerconcern.Customer,
			CustID: this.customerconcern.CustID,
			VendorID: this.customerconcern.VendorID,
			OriginalSalesOrderNumber:
				this.customerconcern.OriginalSalesOrderNumber,
			EmployeerResponsible: this.customerconcern.EmployeerResponsible,
			NCRCode: this.customerconcern.NCRCode,
			FailureModeLevel1: this.customerconcern.FailureModeLevel1,
			FailureModeLevel2: this.customerconcern.FailureModeLevel2,
			BranchResponsible: this.customerconcern.BranchResponsible,
			InternalExternal: this.customerconcern.InternalExternal,
			LastUpdate: this.customerconcern.LastUpdate,
			UserId: this.customerconcern.UserId,
			EmployeeAssigned: this.customerconcern.EmployeeId,
			SignedOff: this.customerconcern.SignedOff,
			QTY: this.customerconcern.QTY,
			UOM: this.customerconcern.UOM,
			CustomerPartNumber: this.customerconcern.CustomerPartNumber,

			HyflexPartNo: this.customerconcern.HyflexPartNo,
			HoseLotNumbers: this.customerconcern.HoseLotNumbers,
			TravelerNumber: this.customerconcern.TravelerNumber,
			CustomerContact: this.customerconcern.CustomerContact,
			ReplacementCreditNumber:
				this.customerconcern.ReplacementCreditNumber,
			CustomerComplaintNumber:
				this.customerconcern.CustomerComplaintNumber,
			ResponsibleFor: this.customerconcern.ResponsibleFor,
			Hose: this.customerconcern.Hose,
			Fitting1: this.customerconcern.Fitting1,
			Fitting2: this.customerconcern.Fitting2,
			Ferrule: this.customerconcern.Ferrule,
			Accessory: this.customerconcern.Accessory,

			Division: this.customerconcern.Division,
			CreatedDate: this.customerconcern.CreatedDate,
			CreatedBy: this.customerconcern.CreatedBy,
			UpdatedDate: this.customerconcern.UpdatedDate,
			UpdatedBy: this.customerconcern.UpdatedBy,
			ClosedDate: this.customerconcern.ClosedDate
				? new Date(this.customerconcern.ClosedDate)
						.toISOString()
						.slice(0, 10)
				: "",
		});

		let correctiveaction: any;
		if (this.customerconcern.CorrectiveAction == 1) {
			correctiveaction = "yes";
		} else {
			correctiveaction = "no";
		}
		this.descriptionsForm.patchValue({
			Problem: this.customerconcern.Problem,
			Containment: this.customerconcern.Containment,
			RiskLevel: this.customerconcern.RiskLevel,
			investigationResults: this.customerconcern.investigationResults,
			RootCause: this.customerconcern.RootCause,
			Disposition: this.customerconcern.Disposition,
			CorrectiveAction: correctiveaction,
			CorrectiveActionText: this.customerconcern.CorrectiveActionText,
			ActionPlan: this.customerconcern.ActionPlan,
			ImplementationDate: this.customerconcern.ImplementationDate
				? new Date(this.customerconcern.ImplementationDate)
						.toISOString()
						.slice(0, 10)
				: "",
			ImplementationNotes: this.customerconcern.ImplementationNotes,
			AuditorAssignedId: this.customerconcern.AuditorAssignedId,
			AuditCA: this.customerconcern.AuditCA,
		});
		this.COQForm.patchValue({
			COQProducts: this.customerconcern.COQProducts,
			COQAdminFee: this.customerconcern.COQAdminFee,
			COQFreight: this.customerconcern.COQFreight,
			COQLabour: this.customerconcern.COQLabour,
			COQExtra: this.customerconcern.COQExtra,
			COQNotes: this.customerconcern.COQNotes,
			COQ: this.customerconcern.COQ,
		});

		this.StatusForm.patchValue({
			StatusID: this.customerconcern.StatusID,
		});
	}

	deleteCustomerconcern(): void {
		this.buttonPushed = true;

		if (
			confirm(
				`Are you sure you want to delete the item: ${this.customerconcern.NCRDate}?`
			)
		) {
			this.progressStatus++;

			this.customerconcernService
				.deleteCustomerconcern(this.customerconcern.Id)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe(
					() => this.onSaveComplete(true, null),
					(error: any) => (this.errorMessage = <any>error)
				);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(["/customerconcerns"]);
	}

	public getUserSearchData(): void {
		this.progressStatus++;

		this.userSearchService
			.getUserSearchesByForm("NCR List")
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(data) => {
					this.UserSearches = data;
					this.setUserFilters();
				},
				(error: any) => (this.errorMessage = <any>error)
			);
	}

	setUserFilters(): void {
		this.CustomerconcernSearch =
			this.customerconcernService.getFreshSearch();
		this.UserSearches.forEach((i) => {
			if (i.Item == "NCRFromDate")
				this.CustomerconcernSearch.NCRFromDate = i.Value;

			if (i.Item == "NCRToDate")
				this.CustomerconcernSearch.NCRToDate = i.Value;

			if (i.Item == "Customer")
				this.CustomerconcernSearch.Customer = i.Value;

			if (i.Item == "OriginalSalesOrderNumber")
				this.CustomerconcernSearch.OriginalSalesOrderNumber = i.Value;

			if (i.Item == "Problem")
				this.CustomerconcernSearch.Problem = i.Value;

			if (i.Item == "FailureModeLevel1")
				this.CustomerconcernSearch.FailureModeLevel1 = i.Value;

			if (i.Item == "FailureModeLevel2")
				this.CustomerconcernSearch.FailureModeLevel2 = i.Value;

			if (i.Item == "BranchResponsible")
				this.CustomerconcernSearch.BranchResponsible = i.Value;

			if (i.Item == "EmployeeAssigned")
				this.CustomerconcernSearch.EmployeeAssigned = i.Value;

			if (i.Item == "CustomerComplaintNumber")
				this.CustomerconcernSearch.CustomerComplaintNumber = i.Value;

			if (i.Item == "ResponsibleFor")
				this.CustomerconcernSearch.ResponsibleFor = i.Value;

			if (i.Item == "Division")
				this.CustomerconcernSearch.Division = i.Value;

			if (i.Item == "Owner") this.CustomerconcernSearch.Owner = i.Value;

			if (i.Item == "StatusID")
				this.CustomerconcernSearch.StatusID = i.Value;

			if (i.Item == "orderByString")
				this.CustomerconcernSearch.orderByString = i.Value;

			if (i.Item == "Skip") this.CustomerconcernSearch.skip = i.Value;
		});
		this.getSelectedRecordSet();
	}

	onFileSelected(event) {
		if (event.target.files.length > 0) {
			this.filesForm.patchValue({
				fileUpload: event.target.files[0],
			});
		}
	}

	onRootCauseFileSelected(event) {
		if (event.target.files.length > 0) {
			this.FiveWHYsForm.patchValue({
				fileUpload: event.target.files[0],
			});
		}
	}

	onSaveFile() {
		this.fileFormSubmitted = true;
		if (this.filesForm.invalid) return;
		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append(
			"CustomerConcernsId",
			this.customerConcernsId.toString()
		);
		formData.append("Description", fileForm.fileDescription || 0);
		formData.append(
			"includeinreport",
			!!fileForm.includeReport ? "1" : "0"
		);
		formData.append("FileName", fileForm.fileUpload);
		formData.append("FileType", fileForm.fileType || "General");
		formData.append("sortorder", fileForm.sortOrder || 0);

		if (this.fileToEdit && this.fileToEdit.Id) {
			formData.append("Id", this.fileToEdit.Id.toString());
			this.progressStatus++;

			this.customerconcernService
				.updateFileCustomerConcern(formData)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((file) => {
					this.fileToEdit = null;
					this.clearForm();
					this.myInputVariable.nativeElement.value = "";
					this.getFileCustomerConcernData();
				});
		} else {
			this.progressStatus++;

			this.customerconcernService
				.createFileCustomerConcern(formData)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((file) => {
					this.clearForm();
					this.myInputVariable.nativeElement.value = "";
					this.getFileCustomerConcernData();
				});
		}
	}

	editFileData(file) {
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			sortOrder: this.fileToEdit.sortorder,
			fileType: this.fileToEdit.FileType,
			includeReport: this.fileToEdit.includeinreport === 1 ? true : false,
			fileDescription: this.fileToEdit.Description,
		});
	}

	editFiveWHYsTableData(fiveWhyRecord) {
		this.fiveWHYsToEdit = fiveWhyRecord;
		this.FiveWHYsForm.patchValue({
			ID: this.fiveWHYsToEdit.ID,
			Effect: this.fiveWHYsToEdit.Effect,
			FirstWhy: this.fiveWHYsToEdit.FirstWhy,
			SecondWhy: this.fiveWHYsToEdit.SecondWhy,
			ThirdWhy: this.fiveWHYsToEdit.ThirdWhy,
			FourthWhy: this.fiveWHYsToEdit.FourthWhy,
			FifthWhy: this.fiveWHYsToEdit.FifthWhy,
			ActionWhy: this.fiveWHYsToEdit.ActionWhy,
		});

		this.effectField.nativeElement.focus();
	}

	clearForm() {
		this.filesForm.reset({
			fileType: "General",
		});
		this.fileFormSubmitted = false;
	}

	clearFiveWHYsForm() {
		this.FiveWHYsForm.reset();
		this.fiveWHYsToEdit = null;
	}

	clearIshikawaDiagramForm() {
		this.getIshikawaDiagramRecords();
	}

	open(row) {
		window.open(
			`${this.applicationSettings.retrieveUrl()}${row.Path}${
				row.FileName
			}`
		);
	}

	deleteFileData(id) {
		this.progressStatus++;

		this.customerconcernService
			.deleteFileCustomConcern(id)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((file) => this.getFileCustomerConcernData());
	}

	tabClick(tab) {
		if (tab.tab.textLabel === "Change Log") {
			this.progressStatus++;

			this.customerconcernService
				.getCustomerConcernHistory(this.customerConcernsId)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((history) => {
					this.dataSourceHistory = new MatTableDataSource(history);
					this.dataSourceHistory.paginator = this.historyPaginator;
					this.recordTotalHistory = history.length;
				});
		}
	}

	resetError(): void {
		this.errorMessage = "";
		this.buttonPushed = false;
		this.emailBtnPushed = false;
	}

	// Function for get notes create by ameer 1/12/2021
	getNotes() {
		this.progressStatus++;

		this.customerconcernService
			.getNotesByCustomerConcernsId(this.customerConcernsId)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((notes) => {
				this.notesListArray = notes;
				this.dataSourceNotes = new MatTableDataSource(notes);
				this.dataSourceNotes.paginator = this.notesPaginator;
				this.notesTotalRecord = notes.length;
			});
	}

	// Function for save notes create by ameer 1/12/2021
	saveNotes() {
		this.notesFormSubmitted = true;
		if (this.notesForm.invalid) {
			this.setFocusOnError();
		} else {
			const reqObj = {
				CustomerConcernsId: this.customerConcernsId,
				Note: this.notesForm.value.notes,
			};

			this.progressStatus++;

			this.customerconcernService
				.createCustomerConcernsNotes(reqObj)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((notes) => {
					this.notesFormSubmitted = false;
					this.notesForm.reset();
					this.getNotes();
				});
		}
	}

	// Function for get notes create by ameer 1/12/2021
	deleteNotes(noteId) {
		this.progressStatus++;

		this.customerconcernService
			.deleteCustomerConcernsNotes(noteId)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe((notes) => {
				this.getNotes();
			});
	}

	// Function to set focus on first invalid field on form submit create by ameer 1/12/2021
	public setFocusOnError() {
		let target;
		target = this.el.nativeElement.querySelectorAll(
			".form-control.ng-invalid"
		);
		if (target.length) {
			target[0].focus();
		}
	}

	openReport(reportType: number) {
		let url =
			this.applicationSettings.retrieveUrl() +
			"/uploadedfiles/tempReports/";

		this.progressStatus++;

		this.customerconcernService
			.getReport(this.customerConcernsId, reportType)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(val: string) => {
					window.open(url + val, "_blank");
				},
				(error: any) => {
					this.errorMessage = <any>error;
				}
			);
	}

	onNCRDateChange(event) {
		this.ncrDate = new Date(event);
		this.ncrDate = this.datepipe.transform(this.ncrDate, "yyyy-MM-dd");
	}

	sendEmail() {
		this.emailStatus = "Email is Sending...";
		let emprptstatus = false;
		this.emailBtnPushed = true;
		if (this.customerconcernForm.value.EmailRpt) {
			emprptstatus = true;
		}
		var obj: ICustomerconcern = Object.assign(
			{},
			this.customerconcernForm.value
		);
		obj.Problem = this.descriptionsForm.value.Problem;
		this.progressStatus++;

		this.customerconcernService
			.sendEmailToEmp(obj, emprptstatus)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(val: any) => {
					this.emailStatus = val;
					this.emailBtnPushed = false;
				},
				(error: any) => {
					this.errorMessage = <any>error;
				}
			);
	}

	searchCustomers() {
		var customerValue = this.customerconcernForm.get("CustID").value;
		this.customerPanelOpen = true;

		if (customerValue != null && customerValue.length >= 3) {
			//this.customerconcernService.searchGLMCustomers(customerValue).subscribe(resp => {
			//	  this.customersData = resp;
			this.customersData = this.allCustomers.filter((item) =>
				item.Name.toUpperCase().includes(
					customerValue.trim().toUpperCase()
				)
			);
			this.customersDatalength = this.customersData.length;
			if (this.customersDatalength > 0) {
				setTimeout(() => this.customerAutoTrigger.openPanel(), 0);
			}
			//}, _ => this.errorMessage = 'Error loading customers from the server');
		} else {
			this.customersData = [];
			this.customersDatalength = 0;
		}
	}

	loadCustomerSavedNCR(custId: any): void {
		if (custId) {
			this.customersData = this.allCustomers.filter(
				(item) => item.CustID == custId
			);
			this.customersDatalength = this.customersData.length;
			if (this.customersDatalength > 0) {
				this.customerconcernForm.get("CustID").setValue(custId);
			}
		}
	}

	onCustomerSelectionMade() {
		// Reset the flag when a selection is made
		//this.selectedCustomerName = event.option.viewValue;
		//this.customersData = [];
		this.customerPanelOpen = false;
		this.customerInput.nativeElement.blur();
	}

	onCustomerInputKeyDown(event: any) {
		// Reset the flag when a selection is made
		//this.selectedCustomerName = event.option.viewValue;
		if (
			event.key == "Enter" &&
			this.customersData.length < 1 &&
			this.customerPanelOpen == false
		) {
			this.searchCustomers();
		} else if (event.key == "ArrowDown" || event.key == "ArrowUp") {
			//do nothing
		} else {
			this.customersData = [];
			this.customerPanelOpen = false;
		}
	}

	displayCustomerFn(customerId: number): string {
		// Find the customer in `customersData` by `CustID` and return the `Name`
		if (this.customersData) {
			const customer = this.customersData.find(
				(c) => c.CustID === customerId
			);
			//return customer ? customerId + " - " + customer.Name : '';
			return customer ? customer.Name : "";
		}
		return "";
	}

	searchVendors() {
		var vendorValue = this.customerconcernForm.get("VendorID").value;
		this.vendorPanelOpen = true;

		if (vendorValue != null && vendorValue.length >= 3) {
			this.vendorsData = this.allVendors.filter((item) =>
				item.Name.toUpperCase().includes(
					vendorValue.trim().toUpperCase()
				)
			);
			this.vendorsDatalength = this.vendorsData.length;
			if (this.vendorsDatalength > 0) {
				setTimeout(() => this.vendorAutoTrigger.openPanel(), 0);
			}
		} else {
			this.vendorsData = [];
			this.vendorsDatalength = 0;
		}
	}

	loadVendorSavedNCR(vendorId: any): void {
		if (vendorId) {
			this.vendorsData = this.allVendors.filter(
				(item) => item.VendorID == vendorId
			);
			this.vendorsDatalength = this.vendorsData.length;
			if (this.vendorsDatalength > 0) {
				this.customerconcernForm.get("VendorID").setValue(vendorId);
			}
		}
	}

	onVendorSelectionMade() {
		// Reset the flag when a selection is made
		//this.selectedCustomerName = event.option.viewValue;
		//this.customersData = [];
		this.vendorPanelOpen = false;
		this.vendorInput.nativeElement.blur();
	}

	onVendorInputKeyDown(event: any) {
		// Reset the flag when a selection is made
		//this.selectedCustomerName = event.option.viewValue;
		if (
			event.key == "Enter" &&
			this.vendorsData.length < 1 &&
			this.vendorPanelOpen == false
		) {
			this.searchVendors();
		} else if (event.key == "ArrowDown" || event.key == "ArrowUp") {
			//do nothing
		} else {
			this.vendorsData = [];
			this.vendorPanelOpen = false;
		}
	}

	displayVendorFn(vendorId: number): string {
		if (this.vendorsData) {
			const vendor = this.vendorsData.find(
				(c) => c.VendorID === vendorId
			);
			//return vendor ? vendorId + " - " + vendor.Name : '';
			return vendor ? vendor.Name : "";
		}
		return "";
	}

	openHelp() {
		this.customerconcernService.getHelpFile().subscribe((response) => {
			const directory =
				this.applicationSettings.retrieveUrl() +
				"/uploadedfiles/documents/";
			window.open(directory + response, "_blank");
		});
	}

	public getFiveWhysRecords() {
		this.progressStatus++;

		this.customerconcernService
			.getFiveWhysData(this.customerConcernsId)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(response) => {
					this.fiveWHYsDataSource = new MatTableDataSource(response);
					this.fiveWHYsRecordTotal = response.length;

					if (this.fiveWHYsRecordTotal > 0)
						this.fiveWHYsButton = "Hide Five WHYs Table";
					else this.fiveWHYsButton = "Show Five WHYs Table";
				},
				(error: any) => (this.errorMessage = <any>error)
			);
	}

	public getIshikawaDiagramRecords() {
		this.progressStatus++;

		this.customerconcernService
			.getIshikawaDiagramData(this.customerConcernsId)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(obj: CustomerConcernsIshikawaDiagramDto) =>
					this.onIshikawaDiagramRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				}
			);
	}

	onIshikawaDiagramRetrieved(obj: CustomerConcernsIshikawaDiagramDto) {
		if (this.IshikawaDiagramForm) {
			this.IshikawaDiagramForm.reset();
		}

		this.ishikawaDiagramToEdit = obj;

		if (this.ishikawaDiagramToEdit == null) {
			this.IshikawaDiagramForm.reset();
			this.ishikawaDiagramButton = "Show Ishikawa Diagram";
			this.ishikawaDiagramToEdit = null;
		} else {
			this.IshikawaDiagramForm.patchValue({
				ID: this.ishikawaDiagramToEdit.ID,
				CustomerOrderId: this.ishikawaDiagramToEdit.CustomerConcernsId,
				IshikawaMaterial1: this.ishikawaDiagramToEdit.IshikawaMaterial1,
				IshikawaMaterial2: this.ishikawaDiagramToEdit.IshikawaMaterial2,
				IshikawaMaterial3: this.ishikawaDiagramToEdit.IshikawaMaterial3,
				IshikawaMaterial4: this.ishikawaDiagramToEdit.IshikawaMaterial4,
				IshikawaMaterial5: this.ishikawaDiagramToEdit.IshikawaMaterial5,
				IshikawaMachine1: this.ishikawaDiagramToEdit.IshikawaMachine1,
				IshikawaMachine2: this.ishikawaDiagramToEdit.IshikawaMachine2,
				IshikawaMachine3: this.ishikawaDiagramToEdit.IshikawaMachine3,
				IshikawaMachine4: this.ishikawaDiagramToEdit.IshikawaMachine4,
				IshikawaMachine5: this.ishikawaDiagramToEdit.IshikawaMachine5,
				IshikawaMethod1: this.ishikawaDiagramToEdit.IshikawaMethod1,
				IshikawaMethod2: this.ishikawaDiagramToEdit.IshikawaMethod2,
				IshikawaMethod3: this.ishikawaDiagramToEdit.IshikawaMethod3,
				IshikawaMethod4: this.ishikawaDiagramToEdit.IshikawaMethod4,
				IshikawaMethod5: this.ishikawaDiagramToEdit.IshikawaMethod5,
				IshikawaMan1: this.ishikawaDiagramToEdit.IshikawaMan1,
				IshikawaMan2: this.ishikawaDiagramToEdit.IshikawaMan2,
				IshikawaMan3: this.ishikawaDiagramToEdit.IshikawaMan3,
				IshikawaMan4: this.ishikawaDiagramToEdit.IshikawaMan4,
				IshikawaMan5: this.ishikawaDiagramToEdit.IshikawaMan5,
				IssueIshikawaDiagram:
					this.ishikawaDiagramToEdit.IssueIshikawaDiagram,
			});
			this.ishikawaDiagramButton = "Hide Ishikawa Diagram";
		}
	}

	showFiveWHYsTable() {
		if (this.fiveWHYsButton == "Hide Five WHYs Table") {
			this.fiveWHYsButton = "Show Five WHYs Table";
		} else {
			this.fiveWHYsButton = "Hide Five WHYs Table";
		}
	}

	showIshikawaDiagram() {
		if (this.ishikawaDiagramButton == "Hide Ishikawa Diagram") {
			this.ishikawaDiagramButton = "Show Ishikawa Diagram";
		} else {
			this.ishikawaDiagramButton = "Hide Ishikawa Diagram";
		}
	}

	public deleteFiveWhysRecord(data) {
		this.clearFiveWHYsForm();
		if (data.ID) {
			if (confirm("Are you sure you want to delete this item?")) {
				this.progressStatus++;

				this.customerconcernService
					.deleteFiveWhysRecords(data.ID)
					.pipe(
						finalize(() => {
							this.progressStatus--;
						})
					)
					.subscribe(
						(response) => {
							if (response.Status === "Failed") {
								alert("Record cannot be deleted!!!");
							} else {
								this.getFiveWhysRecords();
							}
						},
						(error: any) => (this.errorMessage = <any>error)
					);
			}
		} else {
			this.getFiveWhysRecords();
		}
	}

	public deleteIshikawaDiagram() {
		if (this.ishikawaDiagramToEdit == null) {
			return;
		}

		this.progressStatus++;

		this.customerconcernService
			.deleteIshikawaDiagram(this.customerConcernsId)
			.pipe(
				finalize(() => {
					this.progressStatus--;
				})
			)
			.subscribe(
				(response) => {
					if (response.Status === "Failed") {
						alert("Record cannot be deleted!!!");
					} else {
						this.getIshikawaDiagramRecords();
					}
				},
				(error: any) => (this.errorMessage = <any>error)
			);
	}

	onSaveFiveWHYsTable() {
		if (this.FiveWHYsForm.invalid) return;

		if (
			this.FiveWHYsForm.value.Effect == "" &&
			this.FiveWHYsForm.value.FirstWhy == "" &&
			this.FiveWHYsForm.value.SecondWhy == "" &&
			this.FiveWHYsForm.value.ThirdWhy == "" &&
			this.FiveWHYsForm.value.FourthWhy == "" &&
			this.FiveWHYsForm.value.FifthWhy == "" &&
			this.FiveWHYsForm.value.ActionWhy == ""
		) {
			alert("Information not entered.");
			return;
		}

		var obj: CustomerConcernsFiveWhysDto = Object.assign(
			{},
			this.fiveWHYsToEdit,
			this.FiveWHYsForm.value
		);
		obj.CustomerConcernsId = this.customerConcernsId;

		if (this.fiveWHYsToEdit && this.fiveWHYsToEdit.ID) {
			this.progressStatus++;

			this.customerconcernService
				.updateFiveWhysRecords(obj)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((file) => {
					this.fiveWHYsToEdit = null;
					this.clearFiveWHYsForm();
					this.getFiveWhysRecords();
				});
		} else {
			this.progressStatus++;

			this.customerconcernService
				.createFiveWhysRecords(obj)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((file) => {
					this.fiveWHYsToEdit = null;
					this.clearFiveWHYsForm();
					this.getFiveWhysRecords();
				});
		}
	}

	onSaveIshikawaDiagram() {
		if (this.IshikawaDiagramForm.invalid) return;

		if (
			this.IshikawaDiagramForm.value.IshikawaMaterial1 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMaterial2 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMaterial3 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMaterial4 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMaterial5 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMachine1 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMachine2 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMachine3 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMachine4 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMachine5 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMethod1 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMethod2 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMethod3 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMethod4 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMethod5 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMan1 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMan2 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMan3 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMan4 == "" &&
			this.IshikawaDiagramForm.value.IshikawaMan5 == "" &&
			this.IshikawaDiagramForm.value.IssueIshikawaDiagram == ""
		) {
			alert("Information not entered.");
			return;
		}

		var obj: CustomerConcernsIshikawaDiagramDto = Object.assign(
			{},
			this.ishikawaDiagramToEdit,
			this.IshikawaDiagramForm.value
		);
		obj.CustomerConcernsId = this.customerConcernsId;

		if (this.ishikawaDiagramToEdit && this.ishikawaDiagramToEdit.ID) {
			this.progressStatus++;

			this.customerconcernService
				.updateIshikawaDiagramRecords(obj)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((file) => {
					this.ishikawaDiagramToEdit = null;
					this.clearIshikawaDiagramForm();
					alert("Ishikawa diagram saved");
				});
		} else {
			this.progressStatus++;

			this.customerconcernService
				.createIshikawaDiagramRecords(obj)
				.pipe(
					finalize(() => {
						this.progressStatus--;
					})
				)
				.subscribe((file) => {
					this.ishikawaDiagramToEdit = null;
					this.clearIshikawaDiagramForm();
					alert("Ishikawa diagram saved");
				});
		}
	}

	private initializeCustomerConcernForm(): void {
		this.customerconcernForm = this.fb.group({
			Id: "",
			NCRDate: "",
			Customer: ["", [Validators.maxLength(250)]],
			OriginalSalesOrderNumber: ["", [Validators.maxLength(250)]],
			EmployeerResponsible: "",
			NCRCode: "",
			FailureModeLevel1: ["", Validators.required],
			FailureModeLevel2: ["", Validators.required],
			BranchResponsible: ["", Validators.required],
			InternalExternal: "",
			LastUpdate: "",
			UserId: "",
			EmployeeAssigned: ["", Validators.required],
			SignedOff: "",
			QTY: "",
			UOM: "",
			CustomerPartNumber: ["", [Validators.maxLength(250)]],
			HyflexPartNo: ["", [Validators.maxLength(250)]],
			HoseLotNumbers: ["", [Validators.maxLength(250)]],
			TravelerNumber: ["", [Validators.maxLength(250)]],
			CustomerContact: ["", [Validators.maxLength(250)]],
			ReplacementCreditNumber: ["", [Validators.maxLength(250)]],
			CustomerComplaintNumber: ["", [Validators.maxLength(250)]],
			ResponsibleFor: ["", [Validators.maxLength(250)]],
			Hose: ["", [Validators.maxLength(250)]],
			Fitting1: ["", [Validators.maxLength(250)]],
			Fitting2: ["", [Validators.maxLength(250)]],
			Ferrule: ["", [Validators.maxLength(250)]],
			Accessory: ["", [Validators.maxLength(250)]],
			Division: ["", Validators.required],
			CreatedDate: "",
			CreatedBy: "",
			UpdatedDate: "",
			UpdatedBy: "",
			EmailRpt: false,
			CustID: ["", Validators.required],
			VendorID: "",
			ClosedDate: "",
		});
	}

	private initializeDescriptionsForm(): void {
		this.descriptionsForm = this.fb.group({
			Problem: ["", [Validators.maxLength(4000)]],
			Containment: ["", [Validators.maxLength(4000)]],
			RiskLevel: "",
			investigationResults: ["", [Validators.maxLength(4000)]],
			RootCause: ["", [Validators.maxLength(4000)]],
			Disposition: ["", [Validators.maxLength(4000)]],
			CorrectiveAction: [""],
			CorrectiveActionText: ["", [Validators.maxLength(4000)]],
			ActionPlan: ["", [Validators.maxLength(4000)]],
			ImplementationDate: "",
			ImplementationNotes: ["", [Validators.maxLength(4000)]],
			AuditorAssignedId: null,
			AuditCA: ["", [Validators.maxLength(4000)]],
		});
	}

	private initializeFilesForm(): void {
		this.filesForm = this.fb.group({
			fileUpload: ["", Validators.required],
			fileDescription: ["", [Validators.maxLength(500)]],
			fileType: [
				"General",
				[
					Validators.required,
					Validators.pattern(/^(General|Root Cause|NCR Trend)$/),
				],
			],
			sortOrder: [""],
			includeReport: [""],
		});
	}
}
