import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppPropertiesService } from '../../app-properties.service';
import { IHose } from '../hose-model/hose';

@Component({
	selector: 'http-elongation'
	, templateUrl: './Elongation.html',
	  styles: [`
	 .alert {
		 padding: 8px;
	 }
	`]
})

export class ElongationComponent implements OnInit {
	@Input() hoseDetail: IHose;
	@Output() updateHoseDetail: EventEmitter<IHose> = new EventEmitter<IHose>();

	public passFail: any[];
	public errorECPressure: boolean = true;
	public errorImmediateRelease: boolean = true;
	public errorPermanentElongation: boolean = true;

	constructor() {
		this.passFail = [
			{ name: '', value: '' },
			{ name: 'Pass', value: 'Pass' },
			{ name: 'Fail', value: 'Fail' }
		];
	}

	ngOnInit(): void {
		// console.log('Elongation::ngOnInit');
	}

	onblur(): void {
		// console.log('Elongation::onblur');
		this.updateHoseDetail.emit(this.hoseDetail);
	}
	changeECPressure (val) {
	   if (val != "") {
		this.errorECPressure = this.isNormalInteger(val);
		 }
	   else {
		this.errorECPressure = true;  
	   }
	}
	changeImmediateRelease (val) {
	   if (val != "") {
		this.errorImmediateRelease = this.isNormalInteger(val);
		 }
	   else {
		this.errorImmediateRelease = true;  
	   }
	}
	changePermanentElongation (val) {
	   if (val != "") {
		this.errorPermanentElongation = this.isNormalInteger(val);
		 }
	   else {
		this.errorPermanentElongation = true;  
	   }
	}
   isNormalInteger(str) : boolean  {
	var n = Math.floor(Number(str));
	return String(n) === str && n >= 0;
   }
   
}
