import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IBinlabelcat } from '../binlabelcat-model/binlabelcat';

import { Subscription } from 'rxjs/Subscription';
import { BinlabelcatService } from '../binlabelcat-service';
import { IBinlabelcats } from '../Binlabelcat-model/Binlabelcats';
import { IBinlabelcatSearch } from '../Binlabelcat-model/BinlabelcatSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './binlabelcat-edit.component.html',
	styleUrls: ['binlabelcat-edit.component.css']
})

export class BinlabelcatEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Binlabelcats';
	errorMessage: string;
	binlabelcatForm: UntypedFormGroup;
	binlabelcat: IBinlabelcat;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public BinlabelcatSearch: IBinlabelcatSearch;
	public ListData: IBinlabelcats;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private binlabelcatService: BinlabelcatService,
		) {
	}

	ngOnInit(): void {
		this.binlabelcatForm = this.fb.group({
			Id: '',
			PLine: ['',[Validators.maxLength(300)]],
			LabelTag: ['',[Validators.maxLength(300)]],
			LabelGroup: ['',[Validators.maxLength(300)]],
			NumberOfPages: '',
			TotalPages: '',
			Notes: ['',[Validators.maxLength(4000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getBinlabelcat(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.binlabelcatService.getRecordSet(this.BinlabelcatSearch)
			.subscribe((obj: IBinlabelcats) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IBinlabelcats){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.binlabelcat.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveBinlabelcat(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.binlabelcatForm.valid) {
			const obj = Object.assign({ }, this.binlabelcat, this.binlabelcatForm.value);
			this.binlabelcatService.saveBinlabelcat(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.binlabelcatForm.reset();
			this.router.navigate(['/binlabelcats']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.binlabelcat.Id === 0) {
			this.router.navigate(['/binlabelcat-details', data.Id]);
		} 
		else if (this.binlabelcat.Id != 0){
			this.getBinlabelcat(this.binlabelcat.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.binlabelcat.Id)
				record = count;
		});

		this.getBinlabelcat(this.ListData.data[record].Id)
	}

	getBinlabelcat(id: number): void {
		this.binlabelcatService.getBinlabelcat(id)
			.subscribe((obj: IBinlabelcat) => this.onBinlabelcatRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onBinlabelcatRetrieved(binlabelcat: IBinlabelcat): void {
		if (this.binlabelcatForm) {
			this.binlabelcatForm.reset();
		}
		this.binlabelcat = binlabelcat;
		if (this.binlabelcat.Id === 0) {
			this.page = 'Add Binlabelcats';
		} else {
			this.page = 'Edit Binlabelcats';
		}

		this.binlabelcatForm.patchValue({
			Id: this.binlabelcat.Id,
			PLine: this.binlabelcat.PLine,
			LabelTag: this.binlabelcat.LabelTag,
			LabelGroup: this.binlabelcat.LabelGroup,
			NumberOfPages: this.binlabelcat.NumberOfPages,
			TotalPages: this.binlabelcat.TotalPages,
			Notes: this.binlabelcat.Notes,
			CreatedDate: this.binlabelcat.CreatedDate,
			CreatedBy: this.binlabelcat.CreatedBy,
			UpdatedDate: this.binlabelcat.UpdatedDate,
			UpdatedBy: this.binlabelcat.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteBinlabelcat(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.binlabelcat.PLine}?`)) {
			this.binlabelcatService.deleteBinlabelcat(this.binlabelcat.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/binlabelcats']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Binlabelcats List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.BinlabelcatSearch = this.binlabelcatService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'PLine')
				this.BinlabelcatSearch.PLine = i.Value;

			if (i.Item == 'LabelTag')
				this.BinlabelcatSearch.LabelTag = i.Value;

			if (i.Item == 'LabelGroup')
				this.BinlabelcatSearch.LabelGroup = i.Value;

			if (i.Item == 'NumberOfPages')
				this.BinlabelcatSearch.NumberOfPages = i.Value;

			if (i.Item == 'TotalPages')
				this.BinlabelcatSearch.TotalPages = i.Value;

			if (i.Item == 'Notes')
				this.BinlabelcatSearch.Notes = i.Value;

			if (i.Item == 'orderByString')
				this.BinlabelcatSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.BinlabelcatSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
