import { Component, OnInit } from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import { AppPropertiesService } from '../app-properties.service';
import { DataService } from '../data/data-service';
import { IHydroTest } from '../hydro-test/hydro-test-model/hydro-test';
import { UntypedFormBuilder,UntypedFormGroup } from '@angular/forms';
import {Observable} from 'rxjs';
@Component({
	selector: 'assembly-editor',
	templateUrl: './assembly-editor.html',
	styleUrls: ['./assembly-editor.css']
})
export class AssemblyEditor implements OnInit {
	public dataFiltered: Array<string>;
	public dataFilteredEndOne: Array<string>;
	public dataFilteredEndTwo: Array<string>;

	limitResults = 50; 

	hosefilteredOptions: Observable<string[]>;
	endonefilteredOptions: Observable<string[]>;
	endtwofilteredOptions: Observable<string[]>;

	// thisAssembly: Assembly;
	hydroTest: IHydroTest;

	hydrotestId: number;
	public valueChanged = false;
	loading = false;

	myList: any[] = [];
	//private hosesList: any[];
	//private errorMessage: string;
	//private searchStr: string;

	refData: any[];
	returnData: string;

	testPressureMessage = '(select test type)';
	testPressureMessageDuration = '';
	testPressureMessageLine3 = '';

	testPressureFitting1 = 0;
	testPressureFitting2 = 0;
	testPressureHose = 0;
	testPressureHydraulic = 0;
	showTestPressure = true;

	public errorNatural = true;
	public errorEndTwoWp = true;
	public errorHose = true;
	public errorHoseWp = true;
	public errorHawp = true;
	public errorTestPressure = true;
	public errorFeet = true;
	public errorInch = true;
	public isValid = true;

	HoseSearchForm: UntypedFormGroup;
	End1SearchForm: UntypedFormGroup;
	End2SearchForm: UntypedFormGroup;

	constructor(
		private appPropertiesService: AppPropertiesService,
		private dataService: DataService,
		private fb: UntypedFormBuilder
	) {
		//

		this.refData = [
			{ name: 'Industrial', value: 'Industrial' },
			{ name: 'Industrial Used', value: 'Industrial Used' },
			{ name: 'Hydraulic', value: 'Hydraulic' },
			{ name: 'Hydraulic Used', value: 'Hydraulic Used' },
			{ name: 'Oilfield', value: 'Oilfield' },
			{ name: 'Oilfield Used', value: 'Oilfield Used' },
			{ name: 'Customer Specified', value: 'Customer Specified' }
		];
	}

	ngOnInit(): void {
		this.HoseSearchForm = this.fb.group({
			Hose: [null],
		});
		this.End1SearchForm = this.fb.group({
			End1: [null],
		});
		this.End2SearchForm = this.fb.group({
			End2: [null],
		});

		this.hosefilteredOptions =  this.HoseSearchForm.controls.Hose.valueChanges
		.pipe(
			startWith(''),
			map(value => this.handleFilter(value))
		  );

		  this.endonefilteredOptions =  this.End1SearchForm.controls.End1.valueChanges
		.pipe(
			startWith(''),
			map(value => this.handleFilterEndOne(value))
		  );

		  this.endtwofilteredOptions =  this.End2SearchForm.controls.End2.valueChanges
		.pipe(
			startWith(''),
			map(value => this.handleFilterEndTwo(value))
		  );

		this.dataService.evGotData.subscribe((data: string) =>
			this.GotData(data)
		);
		if (this.dataService.gotData) {
		this.GotData('');
		}

	}

	GotData(val: string) {
		this.hydroTest = Object.assign({}, this.dataService.HydroTest);
		if (this.hydroTest && this.hydroTest.hydroTestId != 0) 
		{
			this.HoseSearchForm.controls.Hose.setValue(this.hydroTest.hose);
			this.End1SearchForm.controls.End1.setValue(this.hydroTest.endOne);
			this.End2SearchForm.controls.End2.setValue(this.hydroTest.endTwo);
		}

		this.hydrotestId = this.hydroTest.hydroTestId;
		this.changeTestPressureMessage();
		this.valueChanged = false;
		this.setHAWP();

		if (this.hydroTest.testType == '0') {
			this.hydroTest.testType = '0';
		}		
	}

	selectHoseAutocomplete(value): void {
		const hose = this.appPropertiesService.listHoses.find(
			x => x.hoseName === value
		);

		if (hose) {
			this.hydroTest.hose=hose.hoseName;
			if (hose.hydraulicInsideDiameter) {
				this.hydroTest.insideDiameter = hose.hydraulicInsideDiameter;
			} else {
				this.hydroTest.insideDiameter = hose.insideDiameter.toString();
			}

			this.hydroTest.hoseWp = hose.hoseWorkingPressure;
			this.changeHawp(this.hydroTest.hawp);
		} else {
			this.hydroTest.insideDiameter = '0';
			this.hydroTest.hoseWp = 0;
		}
		this.setHAWP();
		this.changeHoseWp(this.hydroTest.hoseWp);
	}


	

	selectEndOneAutocomplete(value): void {
		let fitting = this.appPropertiesService.listFittings.find(
			x => x.fittingName == value
		);
		if (fitting) {
			this.hydroTest.endOne=fitting.fittingName;
			this.hydroTest.endOneWp = fitting.workingPressure;
		} else {
			this.hydroTest.endOneWp = 0;
		}
		this.setHAWP();
		this.mychange(this.hydroTest.endOneWp);
	}

	selectEndTwoAutocomplete(value): void {
		if (value) {
			console.log(value);
		}

		let fitting = this.appPropertiesService.listFittings.find(
			x => x.fittingName == value
		);
		if (fitting) {
			this.hydroTest.endTwo=fitting.fittingName;
			this.hydroTest.endTwoWp = fitting.workingPressure;
		} else {
			this.hydroTest.endTwoWp = 0;
		}
		this.setHAWP();
		this.changeEndTwoWp(this.hydroTest.endTwoWp);
		this.changeHawp(this.hydroTest.hawp);
	}

	/* OUTLINE OF LOGIC:
	If the hose is hydraulic:
		The test pressure is equal to the lowest test pressure of all the individual components.
		If that number is lower than the working pressure of the hose, the test pressure is set to the working pressure of the hose.
	Else:
		Find test pressure based on working pressure (using multiplier)
		Find test pressure of each individual component
		Set test pressure to smallest of those numbers
	*/
	public setHAWP(): void {
		if (this.IsHydraulic()) {
			this.setHAWP_Hydraulic();
		} else {
			this.setHAWP_NonHydraulic();
		}
	}

	private setHAWP_NonHydraulic(): void {
		
		let multiTP = 0;

		let x = this.hydroTest.endOneWp;
		if (x > this.hydroTest.endTwoWp) {
			x = this.hydroTest.endTwoWp;
		}
		if (x > this.hydroTest.hoseWp) {
			x = this.hydroTest.hoseWp;
		}
		this.hydroTest.hawp = x;

		if (this.hydroTest.testType == 'Industrial') {
			multiTP = this.hydroTest.hawp * 2;
		} else if (this.hydroTest.testType == 'Oilfield') {
			multiTP = this.hydroTest.hawp * 1.5;
		} else if (this.hydroTest.testType == 'Industrial Used') {
			multiTP = this.hydroTest.hawp * 1.5;
		} else if (this.hydroTest.testType == 'Oilfield Used') {
			multiTP = this.hydroTest.hawp * 1.5;
		} else {
			multiTP = this.hydroTest.hawp * 1;
		}

		let tpF1 = multiTP;
		let tpF2 = multiTP;
		let tpHose = multiTP;

		let hose = this.appPropertiesService.listHoses.find(x => x.hoseName == this.hydroTest.hose);
		if (hose) {
			tpHose = hose.testPressure;
		}

		let fitting1 = this.appPropertiesService.listFittings.find(x => x.fittingName == this.hydroTest.endOne);
		if (fitting1) {
			tpF1 = fitting1.testPressure;
		}

		let fitting2 = this.appPropertiesService.listFittings.find(x => x.fittingName == this.hydroTest.endTwo);
		if (fitting2) {
			tpF2 = fitting2.testPressure;
		}

		let smallest = Math.min(multiTP, tpF1, tpF2, tpHose);

		if (this.IsCustomerSpecified()){
			this.hydroTest.testPressure = this.hydroTest.customerSpecifiedTestPressure;
		} else {
			this.hydroTest.testPressure = smallest;
		}

		if ( fitting1 ) { this.testPressureFitting1 = fitting1.testPressure; }
		else { this.testPressureFitting1 = 0 }

		if ( fitting2 ) { this.testPressureFitting2 = fitting2.testPressure; }
		else { this.testPressureFitting2 = 0 }
		
		if (  hose ) { 		this.testPressureHose = hose.testPressure; }
		else { this.testPressureHose = 0 }
		
		return;
	}

	private setHAWP_Hydraulic(): void {
		let hose = this.appPropertiesService.listHoses.find(
			x => x.hoseName == this.hydroTest.hose
		);
		if (hose) {
			this.testPressureHose = hose.testPressure;
		}

		let fitting1 = this.appPropertiesService.listFittings.find(
			x => x.fittingName == this.hydroTest.endOne
		);
		if (fitting1) {
			this.testPressureFitting1 = fitting1.testPressure;
		}

		let fitting2 = this.appPropertiesService.listFittings.find(
			x => x.fittingName == this.hydroTest.endTwo
		);
		if (fitting2) {
			this.testPressureFitting2 = fitting2.testPressure;
		}

		this.testPressureHydraulic = this.testPressureHose;

		if (this.testPressureFitting1 < this.testPressureHydraulic)
			this.testPressureHydraulic = this.testPressureFitting1;

		if (this.testPressureFitting2 < this.testPressureHydraulic)
			this.testPressureHydraulic = this.testPressureFitting2;

		// added after Feb 7 meeting.
		// The minimum test pressure is the working pressure of the hose.

		if (hose) {
			if (hose.hoseWorkingPressure > this.testPressureHydraulic)
				this.testPressureHydraulic = hose.hoseWorkingPressure;
		}
		this.hydroTest.testPressure = this.testPressureHydraulic;
	}

	changeTestPressureMessage() {
		this.testPressureMessageLine3 = '';

		if (this.hydroTest.testType == 'Industrial') {
			this.testPressureMessage =
				'The test pressure for industrial hose is 2 times the HAWP.';
			this.testPressureMessageDuration =
				'Test duration: 5 minutes minimum.';
		} else if (this.hydroTest.testType == 'Oilfield') {
			this.testPressureMessage =
				'The test pressure for oilfield hose is 1.5 times the HAWP.';
			this.testPressureMessageDuration =
				'Test duration: 15 minutes minimum.';
		} else if (this.hydroTest.testType == 'Industrial Used') {
			this.testPressureMessage =
				'The test pressure for industrial used hose is 1.5 times the HAWP.';
			this.testPressureMessageDuration =
				'Test duration: 5 minutes minimum.';
		} else if (this.hydroTest.testType == 'Oilfield Used') {
			this.testPressureMessage =
				'The test pressure for oilfield used hose is 1.5 times the HAWP.';
			this.testPressureMessageDuration =
				'Test duration: 15 minutes minimum.';
		} else if (this.hydroTest.testType == 'Hydraulic') {
			this.testPressureMessage =
				'The test pressure for hydraulic hose is 2 times the HAWP.';
			this.testPressureMessageDuration =
				'Held for no longer than one minute once the test pressure has been achieved.';
			this.testPressureMessageLine3 =
				'Some exceptions may apply in larger sizes.';
		} else if (this.hydroTest.testType == 'Hydraulic Used') {
			this.testPressureMessage =
				'The test pressure for hydraulic hose is 1.5 times the HAWP.';
			this.testPressureMessageDuration = 'Test duration: 1 min ';
		} else {
			this.testPressureMessage =
				'Assembly Type: ' +
				this.hydroTest.testType +
				'. Test pressure as per customer request.';
			this.testPressureMessageDuration = 'Test duration: TBD ';
		}

		this.changeHawp(this.hydroTest.hawp);
		this.changeEndTwoWp(this.hydroTest.endTwoWp);
		this.changeHoseWp(this.hydroTest.hoseWp);
		this.changeFeet(this.hydroTest.feet);
		this.changeInch(this.hydroTest.inch);
		this.mychange(this.hydroTest.endOneWp);
	}


	private handleFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.appPropertiesService.listHoseStrings.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
	}

	  private handleFilterEndOne(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.appPropertiesService.listEndOne.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
	  }

	  private handleFilterEndTwo(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.appPropertiesService.listEndTwo.filter(option => option.toLowerCase().includes(filterValue)).slice(0,this.limitResults);
	  }

	public save(): void {
		this.loading = true;
		if (this.hydroTest.testType == '') {
			alert('Error: You must select an Assembly Type.');
			this.loading = false;
		} else {
			this.dataService
				.saveHydroTest(this.hydroTest)
				.subscribe((res) => {
					this.returnData = res;
					this.onUpdateFinished();
				});
			this.loading = false;
		}
	}

	onUpdateFinished(): void {
		this.loading = false;
		this.valueChanged = false;
	}

	mychange(val) {
		if (val != '') {
			this.errorNatural = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorNatural = true;
			this.isFormValid();
		}
	}
	changeEndTwoWp(val) {
		if (val != '') {
			this.errorEndTwoWp = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorEndTwoWp = true;
			this.isFormValid();
		}
	}
	changeHoseWp(val) {
		if (val != '') {
			this.errorHoseWp = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorHoseWp = true;
			this.isFormValid();
		}
	}
	changeHawp(val) {
		if (val != '') {
			if (this.hydroTest.testType == 'Hydraulic Used') {
				this.hydroTest.testPressure = this.hydroTest.hawp * 1.5;
			}
			this.errorHawp = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorHawp = true;
			this.isFormValid();
		}
	}
	changeFeet(val) {
		if (val != '') {
			this.errorFeet = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorFeet = true;
			this.isFormValid();
		}
	}
	changeInch(val) {
		if (val != '') {
			this.errorInch = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorInch = true;
			this.isFormValid();
		}
	}
	changeTestPressure(val) {
		if (val != '') {
			this.errorTestPressure = this.isNormalInteger(val);
			this.isFormValid();
		} else {
			this.errorTestPressure = true;
			this.isFormValid();
		}
	}
	isFormValid(): boolean {
		//debugger;
		if (
			this.errorHose &&
			this.errorEndTwoWp &&
			this.errorFeet &&
			this.errorHawp &&
			this.errorHoseWp &&
			this.errorInch &&
			this.errorNatural &&
			this.errorTestPressure
		) {
			this.isValid = true;
			return true;
		} else {
			this.isValid = false;
			return false;
		}
	}
	isNormalInteger(str): boolean {
		var n = Math.floor(Number(str));
		return String(n) == str && n >= 0;
	}

	public onHoseSelected(selected: any) {}

	goBack(): void {
		window.history.back();
	}

	ValueChanged() {
		this.valueChanged = true;
		console.log('value changed:' + this.valueChanged);
	}

	public focus(): void {
		console.log('focus');
	}
	
	Cancel(): void {
		console.log('cancelled');
		if (this.dataService.gotData) this.GotData('');

		this.valueChanged = false;
	}
	IsHydraulic(): boolean {
		if (this.hydroTest.testType == 'Hydraulic') return true;
		else return false;
	}

	IsCustomerSpecified(): boolean {
		if (this.hydroTest.testType == 'Customer Specified') return true;
		else return false;
	}
	ToggleTestPressureVisability(): void {
		if (this.showTestPressure == true) this.showTestPressure = false;
		else this.showTestPressure = true;
	}
}
