<div class="col-md-12 nopadding form-my mb-40">
    <div class="card-form">
        <div class="form-title">{{ page }}</div>
        <form class="form-horizontal row-border" novalidate [formGroup]="hoseForm">
            <div class="form-body">
                <div class="col-md-12">
                    <a class='btn btn-default btn-sm' [routerLink]="['/hoses']">
                        <span class=" glyphicon glyphicon-chevron-left"></span>Back
                    </a>

                    <div class="mt-40">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="Hose" class="col-md-3 control-label">Hose Name <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <input formControlName="Hose" type="text" id="Hose" placeholder="Hose Name*" name="Hose"
                                        required class="form-control">
                                </div>
                            </div>

                            <div class="form-group" *ngIf="!hideInsideOutsideDiameters">
                                <label for="InsideDiameter" class="col-md-3 control-label">Inside Diameter</label>
                                <div class="col-md-9">
                                    <input formControlName="InsideDiameter" type="number" id="InsideDiameter"
                                        placeholder="InsideDiameter" name="InsideDiameter" class="form-control">
                                </div>
                            </div>

                            <div class="form-group" *ngIf="!hideInsideOutsideDiameters">
                                <label for="OutsideDiameter" class="col-md-3 control-label">Outside Diameter </label>
                                <div class="col-md-9">
                                    <input formControlName="OutsideDiameter" type="number" id="OutsideDiameter"
                                        placeholder="Outside Diameter" name="OutsideDiameter" class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="ProductTypeId" class="col-md-3 control-label">Product <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <select formControlName="ProductType" class="form-control" (change)="onChange($event.target.value)">
                                        <option *ngFor="let item of producttypesddl" [value]="item.lookupText">{{item.lookupText}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="dimsddl && dimsddl.length">
                                <label for="HydraulicInsideDiameter" class="col-md-3 control-label">{{hoseForm.get('ProductTypeName').value}}
                                    Inside Diameter <span class="required-star">*</span></label>
                                <div class="col-md-9">
                                    <select formControlName="HydraulicInsideDiameter" class="form-control" required>
                                        <option *ngFor="let item of dimsddl" [value]="(item.Id*1)">{{item.lookupText}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Pressure" class="col-md-3 control-label">Working Pressure</label>
                                <div class="col-md-9">
                                    <input formControlName="Pressure" type="number" class="form-control" id="Pressure"
                                        placeholder="Pressure.." name="Pressure" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="PressureTest" class="col-md-3 control-label">Pressure Test</label>
                                <div class="col-md-9">
                                    <input formControlName="PressureTest" class="form-control" type="number" id="PressureTest"
                                        placeholder="Pressure Test.." name="PressureTest" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Province" class="col-md-3 control-label">Compression </label>
                                <div class="col-md-9">
                                    <input formControlName="Compression" class="form-control" type="number" id="Compression"
                                        placeholder="Compression" name="Compression" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="ContinuityTestRequired" class="col-md-3 control-label">Continuity Test</label>
                                <div class="col-md-9">
                                    <input formControlName="ContinuityTestRequired" type="checkbox" id="ContinuityTestRequired"
                                        placeholder="Continuity Test" name="ContinuityTestRequired" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12">

                            <div class="form-group">
                                <label for="CrimpInstructions" class="col-md-3 control-label">Crimp Instructions </label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="CrimpInstructions" type="text" id="CrimpInstructions"
                                        placeholder="Crimp Instructions" name="CrimpInstructions"></textarea>
                                    <span class="help-block">(Used on crimp calculation page.)</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Description" class="col-md-3 control-label">Can Crimp? </label>
                                <div class="col-md-9">
                                    <select formControlName="IsCrimp" class="form-control">
                                        <option [value]="true">yes</option>
                                        <option [value]="false">no</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Description" class="col-md-3 control-label">Description </label>
                                <div class="col-md-9">
                                    <textarea formControlName="Description" type="text" id="Description" placeholder="Description"
                                        name="Description" class="form-control"></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="Notes" class="col-md-3 control-label">Notes</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" formControlName="Notes" type="text" id="Notes" rows="7"
                                        placeholder="Notes.." name="Notes"></textarea>
                                    <span class="help-block">(Notes are only used here. Can be used to remind of a
                                        change.)</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="form-footer">

                <div class="col-md-12 alert alert-danger mt-20 mb-20 text-center" *ngIf='errorMessage'>
                    <strong>Error occured !</strong> {{ errorMessage }}.
                </div>

                <div class="col-md-12 alert alert-success mt-20 mb-20 text-center" *ngIf='message'>
                    {{ message }}.
                </div>

                <div class="col-md-12 buttons-row button-controls text-center">
                    <span>
                        <button [disabled]="!editPermission" class="btn btn-flat btn-primary" (click)="saveHose(true)">
                            <span class="glyphicon glyphicon-floppy-save"></span> Save & Return &nbsp;
                        </button>
                    </span>
                    <span>
                        <button [disabled]="!editPermission" class="btn btn-flat btn-primary" (click)="saveHose(false)">
                            <span class="glyphicon glyphicon-floppy-save"></span> Save & Continue &nbsp;
                        </button>
                    </span>
                    <span>
                        <button class="btn btn-flat btn-warning" (click)="cancel()">
                            <span class=""></span> Cancel &nbsp;
                        </button>
                    </span>
                    <span>
                        <button [disabled]="!editPermission||!hoseForm.valid" class="btn btn-flat btn-danger" (click)="deleteHose()">
                            <span class="glyphicon glyphicon-remove-circle"></span> Delete &nbsp;
                        </button>
                    </span>
                </div>

                <div class="col-md-12 footer-info">
                    <div class="text-left col-sm-6">
                        Updated By: <span *ngIf="hose" class="label label-default">{{hose.UpdatedBy}}</span>

                    </div>
                    <div class="text-right col-sm-6">
                        Time: <span *ngIf="hose" class="label label-success">{{hose.UpdatedDate}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>