
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'
import { DailystatsComponent } from './dailystats/dailystats.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    DailystatsComponent
  ],
  imports: [
    CountdownModule,
    NgSelectModule,
    CommonModule,
    MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
    RouterModule.forChild([
			{ path: 'dailystats', component: DailystatsComponent }
		])
  ]
})
export class DashboardModule { }
