<div class='panel panel-primary animaPlaceholder' *ngIf='developmenttask'>
	<div class='panel-heading'>
		<div class="row">
			<div class="col-md-12 col-xs-12">
				<div class="col-md-2 col-xs-7">
					<span *ngIf='developmenttask'>{{ page }} :{{ developmenttask.Id }}</span>
				</div>
				<div class="col-md-9 col-xs-2 text-right">
					<button class="btn btn-default " style="margin-right:20px;" (click)='openReport()'>Report</button>
				</div>
				<div class="col-md-1 col-xs-3  text-right">
					<span class='pull-right'>
						{{ record }} of {{ recordTotal }}
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<mat-tab-group>
			<mat-tab style="color: #101bb3;" label="Development Task">
				<form class='form-horizontal' novalidate [formGroup]='developmenttaskForm'>
					<fieldset>
						<div class='panel-body'>
							<div class="row">
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Name</label>
									<div class='col-md-10'>
										<input class='form-control' type='text' formControlName='Name' />
										<span
											*ngIf="(developmenttaskForm.controls['Name'].invalid  && developmenttaskForm.controls['Name'].errors.maxlength)"
											class="text-danger">
											Only 250 characters are allowed.
										</span>
									</div>
								</div>

								<div class='form-group col-md-6'>
									<div class="col-md-2">
										<label class='col-md-4 control-label'> Assigned User</label>
									</div>
									<div class='col-md-10 col-xs-10'>
										<div class="row">
											<button class="btn btn-primary col-md-2 col-xs-2" *ngIf="developmenttaskId"
												style="width: 59px;" type="button" (click)="sendEmpEmail()">Email
											</button>
											<div class="col-md-2 col-xs-2" *ngIf="developmenttaskId">
												<input type="checkbox" formControlName="EmpEmailRpt"> Report
											</div>
											<div class="col-md-8 col-xs-7">
												<ng-select formControlName='AssignedUser'>
													<ng-option *ngFor="let employee of employees" [value]="employee.Id">
														{{employee.LastName
														+ ', ' + employee.FirstName}}</ng-option>
												</ng-select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Description</label>
									<div class='col-md-10'>
										<textarea class='form-control' type='text' formControlName='Description'
											rows="3"></textarea>
										<span
											*ngIf="(developmenttaskForm.controls['Description'].invalid  && developmenttaskForm.controls['Description'].errors.maxlength)"
											class="text-danger">
											Only 4000 characters are allowed.
										</span>
									</div>
								</div>
								<div class='form-group col-md-6'>
									<div class="col-md-2">
										<label class='col-md-2 control-label'> Owner</label>
									</div>
									<div class='col-md-10 col-xs-10'>
										<div class="row">
											<button class="btn btn-primary col-md-2 col-xs-2" *ngIf="developmenttaskId"
												style="width: 59px;" type="button" (click)="sendOwnerEmail()">Email
											</button>
											<div class="col-md-2 col-xs-2" *ngIf="developmenttaskId">
												<input type="checkbox" formControlName="OwnerEmailRpt"> Report
											</div>
											<div class="col-md-8 col-xs-7">
												<ng-select formControlName='Owner'>
													<ng-option *ngFor="let employee of employees" [value]="employee.Id">
														{{employee.LastName
														+ ', ' + employee.FirstName}}</ng-option>
												</ng-select>
											</div>
										</div>
									</div>
								</div>
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Application</label>
									<div class='col-md-10'>
										<select class="form-control" formControlName='Application'>
											<option *ngFor='let data of applicationlist;' [value]="data.Value">
												{{data.Value}}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row">
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Priority</label>
									<div class='col-md-10'>
										<input class='form-control' type='number' formControlName='Priority' />
									</div>
								</div>
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Status</label>
									<div class='col-md-10'>
										<select class="form-control" formControlName='Status'>
											<option *ngFor='let status of statuslist;' [value]="status.Value">
												{{status.Value}}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row">
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Installed Date</label>
									<div class='col-md-10'>
										<input class='form-control' type='date' formControlName='InstalledDate' />
									</div>
								</div>
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Estimated Hours</label>
									<div class='col-md-10'>
										<input class='form-control' type='number' formControlName='EstimatedHours' />
									</div>
								</div>
							</div>
							<div class="row">

								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Branch </label>
									<div class='col-md-10'>
										<select class="form-control" formControlName='BranchId'>
											<option value=""> </option>
											<option *ngFor='let branch of branches;' [value]="branch.BranchNumber">
												{{branch.BranchName}} {{branch.BranchNumber}}</option>
										</select>
									</div>
								</div>
								<div class='form-group col-md-6'>
									<label class='col-md-2 control-label'> Notes</label>
									<div class='col-md-10'>
										<input class='form-control' type='text' formControlName='Notes' />
										<span
											*ngIf="(developmenttaskForm.controls['Notes'].invalid  && developmenttaskForm.controls['Notes'].errors.maxlength)"
											class="text-danger">
											Only 4000 characters are allowed.
										</span>
									</div>
								</div>
							</div>
							<!-- <div class="row">
								

							</div> -->
						</div>
					</fieldset>
				</form>

			</mat-tab>
			<mat-tab style="color: #101bb3;" label="Dev">
				<div class="row">
					<div class="col-md-6">
						<form class='form-horizontal' novalidate [formGroup]='developmenttaskForm'>
							<fieldset>
								<div class='panel-body'>
									<div class="row">
										<div class="col-md-12">
											<div class="row" style="display: none;">
												<div class='form-group col-md-6'>
													<label class='col-md-2 control-label'> Type1</label>
													<div class='col-md-10'>
														<input class='form-control' type='text'
															formControlName='Type1' />
													</div>
												</div>
											</div>
											<div class="row" style="display: none;">
												<div class='form-group col-md-6'>
													<label class='col-md-2 control-label'> Type2</label>
													<div class='col-md-10'>
														<input class='form-control' type='text'
															formControlName='Type2' />
													</div>
												</div>
											</div>
											<div class="row" style="display: none;">
												<div class='form-group col-md-6'>
													<label class='col-md-2 control-label'> Type3</label>
													<div class='col-md-10'>
														<input class='form-control' type='text'
															formControlName='Type3' />
													</div>
												</div>
											</div>
											<div class="row">
												<div class='form-group col-md-12'>
													<label class='col-md-2 control-label'>Next Action</label>
													<div class='col-md-10'>
														<input class='form-control' type='text'
															formControlName='nextaction' />
														<span
															*ngIf="(developmenttaskForm.controls['nextaction'].invalid  && developmenttaskForm.controls['nextaction'].errors.maxlength)"
															class="text-danger">
															Only 4000 characters are allowed.
														</span>
													</div>
												</div>
											</div>
											<div class="row">
												<div class='form-group col-md-12'>
													<label class='col-md-2 control-label'>Scheduled Date</label>
													<div class='col-md-3'>
														<input class='form-control' type='date'
															formControlName='ScheduledDate' />
													</div>
												</div>
											</div>
											<div class="row">
												<div class='form-group col-md-12'>
													<label class='col-md-2 control-label'> Files And Tables</label>
													<div class='col-md-10'>
														<textarea class='form-control' type='text'
															formControlName='FilesAndTables' rows="3"></textarea>
														<span
															*ngIf="(developmenttaskForm.controls['FilesAndTables'].invalid  && developmenttaskForm.controls['FilesAndTables'].errors.maxlength)"
															class="text-danger">
															Only 4000 characters are allowed.
														</span>
													</div>
												</div>
											</div>
											<div class="row">
												<div class='form-group col-md-12'>
													<label class='col-md-2 control-label'> Dev Notes</label>
													<div class='col-md-10'>
														<textarea class='form-control' type='text'
															formControlName='DevNotes' rows="16"></textarea>
														<span
															*ngIf="(developmenttaskForm.controls['DevNotes'].invalid  && developmenttaskForm.controls['DevNotes'].errors.maxlength)"
															class="text-danger">
															Only 4000 characters are allowed.
														</span>
													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</fieldset>
						</form>
					</div>
					<div class="col-md-6">
						<form class='form-horizontal' novalidate [formGroup]='devTaskHoursForm' *ngIf="developmenttask.Id">
							<fieldset>
								<div class='panel-body'>
									<div class="row">
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-12">
													<div class=" form-group col-md-3">
														<label class='col-md-2 control-label'> Hrs</label>
														<div class='col-md-10'>
															<input class='form-control' type='number'
																formControlName='Hours' />
														</div>
													</div>
													<div class="form-group col-md-4">
														<label class='col-md-1 control-label'> Date</label>
														<div class='col-md-11'>
															<input class='form-control' type='date'
																formControlName='Date' />
														</div>
													</div>
													<div class="form-group col-md-4">
														<label class='col-md-1 control-label'> User</label>
														<div class='col-md-11'>
															<ng-select formControlName='User'>
																<ng-option *ngFor="let employee of employees"
																	[value]="employee.Id">
																	{{employee.LastName
																	+ ', ' + employee.FirstName}}</ng-option>
															</ng-select>
														</div>
													</div>
													<div class="form-group col-md-1">
														<div class='col-md-10'>
															<button class="btn btn-primary" style="margin-top: 25px;"
																(click)="createDevTaskHours()">Save</button>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div>
													<table mat-table [dataSource]="dSDevTaskHours"
														class='mat-elevation-z8 table-wdt' matSort>
														<ng-container matColumnDef="Hours">
															<th mat-header-cell *matHeaderCellDef class='col1'> Hours
															</th>
															<td mat-cell *matCellDef="let element"> {{element.Hours}}
															</td>
														</ng-container>
														<ng-container matColumnDef="Date">
															<th mat-header-cell *matHeaderCellDef class='col1'> Date
															</th>
															<td mat-cell *matCellDef="let element"> {{element.Date |
																date:'dd/MM/yyyy'}} </td>
														</ng-container>
														<ng-container matColumnDef="User">
															<th mat-header-cell *matHeaderCellDef class='col4'> User
															</th>
															<td mat-cell *matCellDef="let element"> {{element.User}}
															</td>
														</ng-container>
														<ng-container matColumnDef="Delete">
															<th mat-header-cell *matHeaderCellDef class='col1'> Delete
															</th>
															<td mat-cell *matCellDef="let element">
																<button class='btn btn-default btn-xs'
																	style='min-width: 50px;background: none;border: none;'
																	(click)="deleteDevTaskHours(element)">
																	<span
																		class="glyphicon glyphicon-trash text-success text-dlt"></span>
																</button>
															</td>
														</ng-container>
														<tr mat-header-row *matHeaderRowDef="dispColDevTaskHrs"></tr>
														<tr mat-row *matRowDef="let row; columns: dispColDevTaskHrs;">
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>


			</mat-tab>
			<mat-tab label="Files">
				<div *ngIf="developmenttaskId">
					<div class='panel-body'>
						<form [formGroup]='filesForm' novalidate
							style="display: flex;flex-direction: column;row-gap: 10px;">
							<div>
								<div class="col-md-2">
									<label class='control-label'>File Upload:</label>
								</div>
								<div class="col-md-4">
									<input class="form-control" type="file" #inputFile
										(change)="onFileSelected($event)">
									<div *ngIf="fileToEdit && fileToEdit.Id">
										{{fileToEdit.FileName}}
									</div>
									<div *ngIf="fileFormSubmitted && filesForm.controls.fileUpload.errors?.required">
										<span style="color:red">Required</span>
									</div>
								</div>
							</div>
							<div>
								<div class="col-md-2">
									<label class=''> File Description</label>
								</div>
								<div class="col-md-4">
									<input class="form-control" type="text" formControlName='fileDescription'>
									<span
										*ngIf="(filesForm.controls['fileDescription'].invalid  && filesForm.controls['fileDescription'].errors.maxlength)"
										class="text-danger">
										Only 500 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<div class="col-md-2">
									<label class='control-label'> Sort Order</label>
								</div>
								<div class="col-md-4">
									<input class="form-control" type="number" formControlName='sortOrder'>
								</div>
							</div>
							<div class='form-group' style="display:flex;align-items: center;">
								<label class='col-md-2 control-label'> Include in Report</label>
								<div class="col-md-1 text-left">
									<input style="width:34px;" class="form-control" type="checkbox"
										formControlName='includeReport'>
								</div>
							</div>
							<div class="col-md-6 form-group">
								<button class="btn btn-primary form-control center-center remove-width-sml" 
									style="width: 10vw;float: right;" type="button" (click)="onSaveFile()">Save
									File</button>
							</div>
						</form>
					</div>
					<div>
						<table mat-table [dataSource]="dataSource" class='mat-elevation-z8 table-wdt' matSort>
							<ng-container matColumnDef="Edit">
								<th mat-header-cell *matHeaderCellDef class='col1'> EDIT </th>
								<td mat-cell *matCellDef="let element"> <button class='btn btn-default '
										style='min-width: 50px;' (click)="editFileData(element)"> <span
											style="color: rgb(4, 50, 255);"
											class="glyphicon glyphicon-edit ">EDIT</span></button></td>
							</ng-container>
							<ng-container matColumnDef="FileName">
								<th mat-header-cell *matHeaderCellDef class='col2'>File Name </th>
								<td mat-cell *matCellDef="let element">
									<a href="javascript:void(0)" (click)="open(element)"> {{element.FileName}}
									</a>

								</td>
							</ng-container>
							<ng-container matColumnDef="FileDescription">
								<th mat-header-cell *matHeaderCellDef class='col4'> Description </th>
								<td mat-cell *matCellDef="let element"> {{element.FileDescription}} </td>
							</ng-container>
							<ng-container matColumnDef="SortOrder">
								<th mat-header-cell *matHeaderCellDef class='col5'> Sort Order </th>
								<td mat-cell *matCellDef="let element"> {{element.SortOrder}} </td>
							</ng-container>
							<ng-container matColumnDef="IncludeInReport">
								<th mat-header-cell *matHeaderCellDef class='col6'> Include In Report </th>
								<td mat-cell *matCellDef="let element"> {{element.IncludeInReport === 1 ? 'True' :
									'False'}}
								</td>
							</ng-container>
							<ng-container matColumnDef="Delete">
								<th mat-header-cell *matHeaderCellDef class='col7'> Delete </th>
								<td mat-cell *matCellDef="let element">
									<button class='btn btn-default btn-xs'
										style='min-width: 50px;background: none;border: none;'
										(click)="deleteFileData(element.Id)">
										<span class="glyphicon glyphicon-trash text-success text-dlt"></span>
									</button>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
						<mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
					</div>
				</div>
			</mat-tab>
			<mat-tab style="color: #101bb3;" label="Task Costs">
				<div class="row" *ngIf="developmenttask.Id">
					<div class="col-md-12">
						<form class='form-horizontal' novalidate [formGroup]='devTaskCostsForm'>
							<fieldset>
								<div class='panel-body'>
									<div class="row">
										<div class="col-md-12">
											<div class=" form-group col-md-3">
												<label class='col-md-2 control-label'> Name</label>
												<div class='col-md-10'>
													<input class='form-control' type='text' formControlName='Name' />
												</div>
											</div>
											<div class="form-group col-md-4">
												<label class='col-md-2 control-label'> Description</label>
												<div class='col-md-10'>
													<input class='form-control' type='text'
														formControlName='Description' />
												</div>
											</div>
											<div class="form-group col-md-2">
												<label class='col-md-2 control-label'> Price</label>
												<div class='col-md-10'>
													<input class='form-control' type='number' formControlName='Price' />
												</div>
											</div>
											<div class="form-group col-md-2">
												<label class='col-md-2 control-label'> Qty</label>
												<div class='col-md-10'>
													<input class='form-control' type='number' formControlName='Qty' />
												</div>
											</div>
											<div class="form-group col-md-1">
												<div class='col-md-10'>
													<button class="btn btn-primary" 
														(click)="createDevTaskCosts()">Save</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
				<div class="row" *ngIf="developmenttask.Id">
					<div>
						<table mat-table [dataSource]="dSDevTaskCosts" class='mat-elevation-z8 table-wdt' matSort>
							<ng-container matColumnDef="Name">
								<th mat-header-cell *matHeaderCellDef class='colcost1'> Name
								</th>
								<td mat-cell *matCellDef="let element"> {{element.Name}}
								</td>
							</ng-container>
							<ng-container matColumnDef="Description">
								<th mat-header-cell *matHeaderCellDef class='colcost2'> Description
								</th>
								<td mat-cell *matCellDef="let element"> {{element.Description}} </td>
							</ng-container>
							<ng-container matColumnDef="Price">
								<th mat-header-cell *matHeaderCellDef class='colcost3'> Price
								</th>
								<td mat-cell *matCellDef="let element"> {{element.Price}}
								</td>
							</ng-container>
							<ng-container matColumnDef="Qty">
								<th mat-header-cell *matHeaderCellDef class='colcost4'> Qty
								</th>
								<td mat-cell *matCellDef="let element"> {{element.Qty}}
								</td>
							</ng-container>
							<ng-container matColumnDef="Delete">
								<th mat-header-cell *matHeaderCellDef class='colcost5'> Delete
								</th>
								<td mat-cell *matCellDef="let element">
									<button class='btn btn-default btn-xs'
										style='min-width: 50px;background: none;border: none;'
										(click)="deleteDevTaskCosts(element)">
										<span class="glyphicon glyphicon-trash text-success text-dlt"></span>
									</button>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="dispColDevTaskCosts"></tr>
							<tr mat-row *matRowDef="let row; columns: dispColDevTaskCosts;">
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<!-- <mat-tab style="color: #101bb3;" label="Files">
				<button *ngIf = 'developmenttask.Id>0' class = 'btn btn-primary' (click) = 'showHide()' id = 'bt' >
					{{ showButtonName }}
				</button>
				<ng-container *ngIf = 'show'>
					<div class= 'panel-body' *ngIf = 'developmenttask'>
						<div *ngIf = 'developmenttask.Id>0'>
							<form [formGroup] = 'filesForm' novalidate style = 'display: flex;flex-direction: column;row-gap: 10px;'>
								<div>
									<div class= 'col -md-2'>
										<label class='control-label'>File Upload:</label>
									</div>

									<div class= 'col-md-4'>
										<input type = 'file' class= 'file-input' #fileUpload (change)='onFileSelected($event)'>
										<div class= 'file-upload'>
											{{ filename || 'No file uploaded yet.' }}
											<button mat-mini-fab color = 'primary' class= 'upload-btn' (click) = 'fileUpload.click()'>
												<link href = 'https://fonts.googleapis.com/icon?family=Material+Icons' rel = 'stylesheet'>
												<mat-icon> attach_file </mat-icon>
											</button>
										</div>
									</div>
								</div>

								<div>
									<div class= 'col-md-2'>
									<label class= ''> File Description </label>
								</div>

								<div class= 'col-md-4'>
									<input class= 'form-control' type = 'text' formControlName = 'fileDescription'>
								</div>
							</div>

							<div class= 'form-group' style = 'display:flex;align-items: center;'>
								<label class= 'col-md-2 control-label'> Include in Report </label>
								<div class= 'col -md-1 text-left'>
									<input style = 'width:34px;' class= 'form-control' type = 'checkbox' formControlName = 'includeReport'>
								</div>
							</div>

							<div class= 'col-md-6 form-group'>
								<button (click) = 'onSaveFile()' class= 'btn btn-primary form-control center-center' style = 'width: 10vw;float: right;'
									[disabled] = 'fileSaved'>
									{{ buttonName }}
								</button>
							</div>
						</form>
					</div>
				</div>

			</ng-container>
			<div class= 'table-container'>
				<mat-table [dataSource] = 'dataSource' class= 'mat-elevation-z8 table-head' matSort>
					<ng-container matColumnDef = 'Id'>
						<th mat-header-cell *matHeaderCellDef class= 'col1' >#</th>
						<td mat-cell *matCellDef = 'let e'>
							<button class= 'btn btn-default' style = 'min-width: 80px;'[routerLink] = "['/glm/developmenttask-details', e.Id]" >{{ e.Id}}</button>
						</td>
					</ng-container>

					<ng-container matColumnDef = 'FileName'>
						<th mat-header-cell *matHeaderCellDef class= 'col2'> File Name </th>
						<td mat-cell *matCellDef = 'let element' > <a href = 'javascript:void(0)' (click) = 'open(element)'> {{ element.FileName }}  </a> </td>
					</ng-container>

					<ng-container matColumnDef = 'FileDescription'>
						<th mat-header-cell *matHeaderCellDef class= 'col3' > File Description </th>
						<td mat-cell *matCellDef = 'let element' >{{ element.FileDescription}}</td>
					</ng-container>

					<ng-container matColumnDef = 'Delete'>
						<th mat-header-cell *matHeaderCellDef class= 'col4' > Delete </th>
						<td mat-cell *matCellDef = 'let element'>
							<button class= 'btn btn-default btn-xs' style = 'min-width: 50px;background: none;border: none;'
								(click) = 'deleteFileData(element.Id)'>
								<span class= 'glyphicon glyphicon-trash text-success text-dlt'></span>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef = 'displayedColumns' ></tr>
					<tr mat-row *matRowDef = 'let row; columns: displayedColumns;'></tr>
				</mat-table>
			</div>
		</mat-tab> -->
		</mat-tab-group>
		<div class='row' style='padding: 15px;'>
			<div class='col-md-12 col-12'>
				<span>
					<button class='btn btn-primary col-md-2 col-12 btn-edit-sml' [disabled]='buttonPushed'
						(click)='saveDevelopmenttask(false, false)' style='width: 110px; margin-right:10px'
						[disabled]='!developmenttaskForm.valid'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary col-md-2 col-12 btn-edit-sml' [disabled]='buttonPushed'
						(click)='saveDevelopmenttask(true, false)' style='width: 150px; margin-right:10px'
						[disabled]='!developmenttaskForm.valid'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>
					<button *ngIf='showNextRecordButton' class='btn btn-primary col-md-2 col-12 btn-edit-sml'
						[disabled]='buttonPushed' (click)='saveDevelopmenttask(false, true)'
						style='width: 190px; margin-right:10px' [disabled]='!developmenttaskForm.valid'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
					</button>
				</span>

				<span class='pull-right remove-float-sml'>
					<button class='btn btn-primary col-md-2 col-12 btn-edit-sml' [disabled]='buttonPushed' (click)='cancel()'
						style='width: 150px; margin-right:10px' [disabled]='!developmenttaskForm.valid'>
						Cancel &nbsp;
					</button>
					<button class='btn btn-primary col-md-2 col-12 btn-edit-sml' [disabled]='buttonPushed'
						(click)='deleteDevelopmenttask()' style='width: 150px; margin-right:10px'
						[disabled]='!developmenttaskForm.valid'>
						<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
					</button>
				</span>
			</div>
		</div>

		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='developmenttask'>{{developmenttask.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='developmenttask'>{{developmenttask.UpdatedDate}}</label></span>
			</div>
		</div>

	</div>
</div>