import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ApplicationSettings } from '../shared/application-settings';
import { IProductdata } from './Productdata-model/Productdata';

@Injectable()
export class ProductdataService {
	private baseUrl;
	public pageTitle = 'ProductDatas List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Productdatas';
	}

	initializeProductdata(): IProductdata {
		return {
			Id: 0,
			ASSET_MANAGER_IMAGE1: '',
			_portal_id_plain_: '',
			APPLICATION_DESC: '',
			ACCURACY_DESC: '',
			ASS: '',
			CATALOG_NUMBER: '',
			FAMILIES: '',
			HOSE_OD: '',
			PC_ABRAS_RESISTANCE: '',
			PC_ACCESSORY_CATEGORY: '',
			PC_ACCESSORY_ID: '',
			PC_ACCESSORY_TYPE: '',
			PC_ADAPTER_TYPE: '',
			PC_ANGLE: '',
			PC_APPLICATION: '',
			PC_ASSEMBLY_LENGTH: '',
			PC_ASSEMBLY_TYPE: '',
			PC_ASSY_FITTINGS: '',
			PC_ASSY_FITTINGS_END1: '',
			PC_ASSY_FITTINGS_END2: '',
			PC_ASSY_FITY: '',
			PC_BAND_WIDTH: '',
			PC_BEBCAT02: '',
			PC_BLADE_DIAMETER: '',
			PC_BODY_SIZE: '',
			PC_BOLTHOLE: '',
			PC_BOLTHOLENUMBER: '',
			PC_BOWLTYPE: '',
			PC_BOWL_SIZE: '',
			PC_BRANCHSIZE: '',
			PC_BULKHEADHOLESIZE: '',
			PC_BUNDLEDIAMETER: '',
			PC_CAMLOCKSIZE: '',
			PC_CAMLOCK_PART: '',
			PC_CASEDRAIN_BODYSIZE: '',
			PC_CASEDRAIN_THREADDIMENSIONS: '',
			PC_CASEDRAIN_THREADSIZE: '',
			PC_CASEDRAIN_THREADTYPE: '',
			PC_CHANNELDIMENSIONS: '',
			PC_CHANNELS: '',
			PC_CLAMPHOLES: '',
			PC_CLAMPSIZE: '',
			PC_CLAMP_ID: '',
			PC_CLAMP_TYPE: '',
			PC_COIL_LENGTH: '',
			PC_COLOUR: '',
			PC_CONCRETEENDTYPE: '',
			PC_COUPLERSIZE: '',
			PC_COUPLING_SERIES: '',
			PC_CRIMPINGFORCE: '',
			PC_CRIMPSPERHR: '',
			PC_CROSSREF: '',
			PC_CROSSSECTION: '',
			PC_CUFF_LENGTH: '',
			PC_CUFF_SIZE: '',
			PC_CUSHCLAMPHOSESIZE: '',
			PC_CUSHCLAMPLENGTH: '',
			PC_CUSHCLAMPPIPESIZE: '',
			PC_CUSHCLAMPSIZE: '',
			PC_CUSHCLAMPTUBESIZE: '',
			PC_DASH_SIZE: '',
			PC_DEGREE_OFFSET_ALLOWED: '',
			PC_DIAMETER: '',
			PC_DIELENGTH: '',
			PC_DIESIZE: '',
			PC_DIGTUBEID: '',
			PC_DIMENSIONS: '',
			PC_DROP_LENGTH: '',
			PC_DUROMETERRATING: '',
			PC_EQUIPMENT_CATEGORY: '',
			PC_EQUIPMENT_TYPE: '',
			PC_FACE_SIZE: '',
			PC_FEMALEHOTSY: '',
			PC_FEMALEKARCHER: '',
			PC_FEMALERINGLOCKSIZE: '',
			PC_FEMALESIMONIZ: '',
			PC_FEMALETHREAD: '',
			PC_FEMALETUBESIZE: '',
			PC_FEMALE_BSPT_SIZE: '',
			PC_FEMALE_BSP_SIZE: '',
			PC_FEMALE_JIC_SIZE: '',
			PC_FEMALE_JIS30_SIZE: '',
			PC_FEMALE_KOBELCO: '',
			PC_FEMALE_KOMATSU_SIZE: '',
			PC_FEMALE_METRIC_SIZE: '',
			PC_FEMALE_ORB_SIZE: '',
			PC_FEMALE_ORFS_SIZE: '',
			PC_FEMALE_PIPE_SIZE: '',
			PC_FERRULELENGTH: '',
			PC_FERRULE_DESIGNATION: '',
			PC_FERRULE_ID: '',
			PC_FILTERELEMENT: '',
			PC_FIRETHREADSIZE: '',
			PC_FIRETHREADTYPE: '',
			PC_FITREGION: '',
			PC_FITSFLANGEOD: '',
			PC_FITSHOSEID: '',
			PC_FITSHOSEOD: '',
			PC_FITTINGOD: '',
			PC_FITTING_CATEGORY: '',
			PC_FITTING_SIZE: '',
			PC_FITTING_TYPE: '',
			PC_FLANGEOD: '',
			PC_FLANGE_SIZE: '',
			PC_FLASHPOINT: '',
			PC_FLOW_CAPACITY: '',
			PC_FLOW_RATE: '',
			PC_FUELTYPE: '',
			PC_GRIP_COLOUR: '',
			PC_GROOVEDPIPE_SIZE: '',
			PC_GUN_INLET: '',
			PC_GUN_OUTLET: '',
			PC_HAMMERLENGTH: '',
			PC_HAMMERNUTRADIUS: '',
			PC_HANDLE: '',
			PC_HEIGHT: '',
			PC_HEXSIZE: '',
			PC_HITS: '',
			PC_HOLDOPENCLIP: '',
			PC_HOLEDIAMETER: '',
			PC_HORSEPOWER: '',
			PC_HOSEAPPLICATION: '',
			PC_HOSE_CAPACITY: '',
			PC_HOSE_ID: '',
			PC_HOSE_OD: '',
			PC_HOSE_OD_MAX: '',
			PC_HOSE_OD_MIN: '',
			PC_HOSE_SIZE: '',
			PC_HOSE_TYPE: '',
			PC_IMAGEPATH: '',
			PC_IMPA: '',
			PC_INSERTIONLENGTH: '',
			PC_INSIDE_DIAMETER: '',
			PC_INVERTEDFLARESIZE: '',
			PC_JAPANESE_FLARE_SIZE: '',
			PC_KINEMATICVISCOSITY: '',
			PC_LANCE_LENGTH: '',
			PC_LEADLENGTH: '',
			PC_LENGTH: '',
			PC_LOADBEARING: '',
			PC_MALEHOTSY: '',
			PC_MALEKARCHER: '',
			PC_MALERINGLOCKSIZE: '',
			PC_MALESIMONIZ: '',
			PC_MALETUBESIZE: '',
			PC_MALE_BSPT_SIZE: '',
			PC_MALE_BSP_SIZE: '',
			PC_MALE_JIC_SIZE: '',
			PC_MALE_JIS30_SIZE: '',
			PC_MALE_KOBELCO: '',
			PC_MALE_KOMATSU_SIZE: '',
			PC_MALE_METRIC_SIZE: '',
			PC_MALE_ORB_SIZE: '',
			PC_MALE_ORFS_SIZE: '',
			PC_MALE_PIPE_SIZE: '',
			PC_MATERIAL: '',
			PC_MAXHOSECAPACITY: '',
			PC_MAXIMUM_SIZE: '',
			PC_MAXOPENING: '',
			PC_MAX_WORKING_PRESSURE: '',
			PC_METRICPORTSIZE: '',
			PC_METRIC_SERIES: '',
			PC_METRIC_SIZE: '',
			PC_METRIC_TUBE: '',
			PC_METRIC_TUBE1: '',
			PC_METRIC_TUBE2: '',
			PC_MINBURSTPRESS: '',
			PC_MINIMUM_SIZE: '',
			PC_MINITYPE: '',
			PC_MIN_BEND_RADIUS: '',
			PC_MIX_INLET: '',
			PC_MIX_OUTLET: '',
			PC_MM_RANGE: '',
			PC_MOUNTHOLENUMBER: '',
			PC_MOUNTINGHOLE: '',
			PC_MOUNTTYPE: '',
			PC_NIPPLELENGTH: '',
			PC_NOMINALTUBE: '',
			PC_NOMINAL_NUMBER: '',
			PC_NOMINAL_SIZE: '',
			PC_NOZZLE_SIZE: '',
			PC_OILCAPACITY: '',
			PC_ORIFICE: '',
			PC_ORINGID: '',
			PC_PIPE_SIZE: '',
			PC_PIPE_SWIVEL_SIZE: '',
			PC_PN: '',
			PC_PORT_SIZE: '',
			PC_POURPOINT: '',
			PC_PRESSURE_RANGE: '',
			PC_PRESSURE_RATING: '',
			PC_QCAPPLICATION: '',
			PC_QUICK_COUPLING_TYPE: '',
			PC_REDCAMSIZE: '',
			PC_REDUCEDFITTINGSIZE: '',
			PC_REDUCED_BSP_SIZE: '',
			PC_REDUCED_FLANGE_SIZE: '',
			PC_REDUCED_HOSE_SIZE: '',
			PC_REDUCED_JIC_SIZE: '',
			PC_REDUCED_METRIC_SIZE: '',
			PC_REDUCED_ORFS_SIZE: '',
			PC_REDUCED_PIPE_SIZE: '',
			PC_REDUCED_TUBE_SIZE: '',
			PC_REEL_INLET: '',
			PC_REEL_OUTLET: '',
			PC_REEL_TYPE: '',
			PC_REINFORCEMENT: '',
			PC_REPHANDLESIZE: '',
			PC_REPHANDLESIZE2: '',
			PC_REPHANDLESIZE3: '',
			PC_REVOLUTIONPM: '',
			PC_RINGLOCKSIZE: '',
			PC_RISER_SIZE: '',
			PC_RODLENGTH: '',
			PC_SAEFLARESIZE: '',
			PC_SAESIZE: '',
			PC_SAESPECIFICATION: '',
			PC_SEALID: '',
			PC_SECMETRIC_TUBE1: '',
			PC_SECMETRIC_TUBE2: '',
			PC_SECTHREAD1: '',
			PC_SECTHREAD2: '',
			PC_SECTHREADDIMENSIONS1: '',
			PC_SECTHREADDIMENSIONS2: '',
			PC_SERIES: '',
			PC_SETPRESSURE: '',
			PC_SHADY: '',
			PC_SHAPE: '',
			PC_SKIVELENGTH: '',
			PC_SLEEVELENGTH: '',
			PC_SLEEVE_ID: '',
			PC_SOCKETID: '',
			PC_SPECIFICGRAVITY: '',
			PC_SPOUTSIZE: '',
			PC_SPRAYPATTERN: '',
			PC_SPRAYRADIUS: '',
			PC_STEMLENGTH: '',
			PC_STRAINER_HOLE_SIZE: '',
			PC_SUPPORTHOLESIZE: '',
			PC_SUPPORTSPACING: '',
			PC_TAILPIPESIZE: '',
			PC_TAILPIPESTYLE: '',
			PC_TENSILESTRENGTH: '',
			PC_TESTPRESSURE: '',
			PC_THICKNESS: '',
			PC_THREAD1: '',
			PC_THREAD2: '',
			PC_THREADDIMENSIONS: '',
			PC_THREADDIMENSIONS1: '',
			PC_THREADDIMENSIONS2: '',
			PC_THREADOD: '',
			PC_THREADPITCH: '',
			PC_THREAD_SIZE: '',
			PC_THREAD_TYPE: '',
			PC_TOOLLENGTH: '',
			PC_TORQUE: '',
			PC_TUBE_ID: '',
			PC_TUBE_OD: '',
			PC_TUBE_SIZE: '',
			PC_TUBE_THICKNESS: '',
			PC_VACUUM_RATING: '',
			PC_VALVE_TYPE: '',
			PC_VISCOSITYGRADE: '',
			PC_VISCOSITYINDEX: '',
			PC_VOLTAGE: '',
			PC_WALLDIAMETER: '',
			PC_WALL_THICK: '',
			PC_WALL_THICKNESS: '',
			PC_WELDTHREADSIZE: '',
			PC_WELDTHREADTYPE: '',
			PC_WIDTH: '',
			PDW_ACCESSORY_TYPE: '',
			PDW_BODY_SIZE: '',
			PDW_CATEGORY: '',
			PDW_COUPLING_SERIES: '',
			PDW_DASH_SIZE: '',
			PDW_FITTING_SIZE: '',
			PDW_FITTING_TYPE: '',
			PDW_HOSE_ID: '',
			PDW_MALE_JIC_SIZE: '',
			PDW_MALE_ORB_SIZE: '',
			PDW_MATERIAL: '',
			PDW_PIPE_SIZE: '',
			PDW_REDUCED_BSP_SIZE: '',
			PDW_REEL_INLET: '',
			PDW_REEL_TYPE: '',
			PDW_THUM: '',
			PDW_WEBCAT01: '',
			PDW_WEBCAT02: '',
			PDW_WEBCAT03: '',
			PDW_WEBREADY: '',
			PDW_WEB_PART_NUMBER: '',
			PDW_WEB_PULSAR: '',
			PDW_WEIGHT: '',
			PDW_XREF_PULSAR_200P4ADAPTERS200PX73000JPG: '',
			PDW_XREF_PULSAR_75P4ADAPTERS75PX73000JPG: '',
			PRICELINE: '',
			PROD_ALTDESC: '',
			PROD_UPC: '',
			STATUS: '',
			TOTAL_HITS: '',
			_lsi_id_: '',
		} as IProductdata;
	}


	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadfile`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getLastExecutionData(){
		const url = `${this.baseUrl}/getlastexecutiondata`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}