import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { ICrimps } from '../crimp-model/ICrimps';
import { ICrimpSearch } from '../crimp-model/IcrimpSearch';
import { ApplicationSettings } from './../../shared/application-settings';
import { ICrimp } from './../crimp-model/crimp';
import { CrimpGridService } from './crimp-list.service';
import { BrancheService } from 'src/app/admin/branches/branche-service';
import { IBranche } from 'src/app/admin/branches/Branche-model/Branche';

@Component({
	templateUrl: './Crimp-list.component.html',
	styleUrls: ['./Crimp-list.component.css']
})
export class CrimpListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	//public view: Observable<GridDataResult>;
	public pageSize: number = +localStorage.getItem('rows');
	public pageSizeList: any[];
	public skip = 0;
	public sub: Subscription;
	public crimpDate = '0';
	public TestRequired = '';

	public HoseName = '';
	public FerruleName = '';
	public FittingName = '';
	public OrderNumber = '';
	public User = '';
	public CreatedDate = '';
	public Branch = '';

	pageTitle = 'Crimp Management List';
	public errorMessage = '';
	public dataSource: any;
	displayedColumns: string[] = ['Id','OrderNumber','HoseName', 'FerruleName','FittingName', 'CreatedDate', 'Branch','HT'];
	public UserSearches: IUsersearch[];
	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';
	listFilter: string;
	public listOfcrimps: ICrimp[];
	public allowUnsort = true;
	public multiple = false;
	public excelFile;
	isLoadingResults = true;
	public recordTotal: number = 0;
	public branches: IBranche[];

	constructor(
		private router: Router,
		public appSettings: ApplicationSettings,
		public service: CrimpGridService,
		private userSearchService: UsersearchService,
		private serviceCrimp: CrimpGridService,
		public branchService: BrancheService
	) {
		//this.pageSizeList = this.configurationService.pageSizeList;

		if (this.pageSize === 0) {
			this.pageSize = 10;
			localStorage.setItem('rows', '10');
		}
	}

	refreshGrid(): void {
		this.skip = 0;
		this.getData();
	}

	ngOnInit(): void {
		this.skip = 0;
		this.crimpDate='0';
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x; }
		else { this.pageSize = 10;}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
		 this.branchService.getAllBranchesForDropdown().subscribe(response => {
			this.branches = response.sort((a, b) => {if (a.BranchName < b.BranchName) {return -1;}if (a.BranchName > b.BranchName) {return 1;	}return 0; });
		});
	}

	public ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
		.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
	}
	, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'desc';
		this.sort.active = 'CreatedDate';

		this.UserSearches.forEach(i => {
			if(i.Item=='hoseName')
				this.HoseName = i.Value;

				if(i.Item=='ferruleName')
				this.FerruleName = i.Value;

			if(i.Item=='fittingName')
				this.FittingName = i.Value.toString();

				if(i.Item=='orderNumber')
				this.OrderNumber = i.Value.toString();

				if(i.Item=='branch')
				this.Branch = i.Value.toString();

			if(i.Item=='orderByString') {
				if (this.appSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				//this.sort.active = this.appSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	valueChanged(): boolean {
		localStorage.setItem('rows', this.pageSize.toString());
		return true;
	}

	getData(): any {
		this.isLoadingResults = true;
		this.dataSource = null; 
		this.recordTotal = 0; 
		   let search: ICrimpSearch = this.serviceCrimp.getFreshSearch();
   
		   search.OrderNumber = this.OrderNumber;
		   search.TestRequired = Number(this.TestRequired);
		   search.CreatedBy = this.CreatedDate;
		   search.HoseName = this.HoseName;
		   search.FerruleName = this.FerruleName;
		   search.FittingName = this.FittingName;
		   search.OrderNumber = this.OrderNumber;
		   search.CreatedBy = this.User;
		   search.CreatedDate = this.CreatedDate;
		   search.Branch = this.Branch;
		   
		   search.skip = this.paginator.pageIndex * this.pageSize;
		   search.take = this.pageSize;
		   search.orderByString = this.sort.direction + '::' + this.sort.active + '::';
   
		   this.serviceCrimp.getRecordSet(search).subscribe((obj: ICrimps) => this.onSelectedRecordSetReceived(obj),
		   (error: any) => {
			   this.errorMessage = <any> error;
		   });
	   }

	   private onSelectedRecordSetReceived(obj: ICrimps){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.isLoadingResults = false;
	}

	createTraveler(id: number): void {
		this.serviceCrimp.addHydroTest(id)
			.subscribe((hydrotestId) => this.onHydrotestAdded(hydrotestId),
			(error: any) => this.errorMessage = <any>error);
	}

	onHydrotestAdded(hydrotestId: number): void {
		this.router.navigate(['/hydro-test', hydrotestId]);
	}


	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/crimp`])
		);
		window.open(url, '_blank');
	}

	public searchClearClick(): void {
		this.HoseName = '';
		this.FerruleName = '';
		this.FittingName = '';
		this.OrderNumber = '';
		this.User = '';
		this.CreatedDate = '';
		this.Branch = '';
		this.skip = 0;
		this.sort.direction = 'desc';
		this.sort.active = 'CreatedDate';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	pageSizeValueChanged(): void {
		this.appSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.isLoadingResults=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }

	 searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public toExcel(){
		let search: ICrimpSearch = this.serviceCrimp.getFreshSearch();

		search.OrderNumber = this.OrderNumber;
		   search.TestRequired = Number(this.TestRequired);
		   search.CreatedBy = this.CreatedDate;
		   search.HoseName = this.HoseName;
		   search.FerruleName = this.FerruleName;
		   search.FittingName = this.FittingName;
		   search.OrderNumber = this.OrderNumber;
		   search.CreatedBy = this.User;
		   search.CreatedDate = this.CreatedDate;
		   search.Branch = this.Branch;
		   
		   search.skip = this.paginator.pageIndex * this.pageSize;
		   search.take = this.pageSize;
		   search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this.serviceCrimp.toExcel(search).subscribe(
			retVal => {
					this.excelFile = retVal;
			},
			 (error: any) => {
				 this.errorMessage = < any > error;
			});
}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.appSettings.retrieveUrl()}${ this.excelFile}`);
	}

}
