import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControlName, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IWalkin } from '../walkin-model/walkin';

import { Subscription } from 'rxjs/Subscription';
import { IWalkinSearch } from '../Walkin-model/WalkinSearch';
import { IWalkins } from '../Walkin-model/Walkins';
import { WalkinService } from '../walkin-service';

import { ApplicationSettings } from 'src/app/shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Component({
	templateUrl: './walkin-edit.component.html',
	styleUrls: ['walkin-edit.component.css']
})

export class WalkinEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Walk In';
	errorMessage: string;
	walkinForm: UntypedFormGroup;
	walkin: IWalkin;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public WalkinSearch: IWalkinSearch;
	public ListData: IWalkins;
	public showNextRecordButton: boolean = true;
    public pdfFile="";
	
	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private walkinService: WalkinService
		, public applicationSettings: ApplicationSettings
		) {
	}

	ngOnInit(): void {
		this.walkinForm = this.fb.group({
			Id: '',
			SalesOrder: '',
			DateString: '',
			CustomerName: '',
			Quantity: '',
			EclipseId: '',
			PartName: '',
			PartString: '',
			HoseType: '',
			Fitting1: '',
			Fitting2: '',
			Length: '',
			Orientation: '',
			SpecialInstructions: '',
			FileName:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getWalkin(id);
			}
		);
	}

	public getSelectedRecordSet():void{
		this.walkinService.getRecordSet(this.WalkinSearch)
			.subscribe((obj: IWalkins) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWalkins){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.walkin.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveWalkin(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.walkinForm.valid) {
			const obj = Object.assign({ }, this.walkin, this.walkinForm.value);
			this.walkinService.saveWalkin(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.walkinForm.reset();
			this.router.navigate(['/walkins']);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.walkin.Id === 0) {
				this.router.navigate(['/walkin-details', data.Id]);
			} else {
				if (advanceToNextRecord) {
					this.advanceToNextRecord();
				}
			}
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.walkin.Id)
				record = count;
		});

		this.getWalkin(this.ListData.data[record].Id)
	}

	getWalkin(id: number): void {
		this.walkinService.getWalkin(id)
			.subscribe((obj: IWalkin) => this.onWalkinRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onWalkinRetrieved(walkin: IWalkin): void {
		if (this.walkinForm) {
			this.walkinForm.reset();
		}
		this.walkin = walkin;
		if (this.walkin.Id === 0) {
			this.page = 'Add Walk In';
		} else {
			this.page = 'Edit Walk In';
		}

		this.walkinForm.patchValue({
			Id: this.walkin.Id,
			SalesOrder: this.walkin.SalesOrder,
			DateString: this.walkin.DateString,
			CustomerName: this.walkin.CustomerName,
			Quantity: this.walkin.Quantity,
			EclipseId: this.walkin.EclipseId,
			PartName: this.walkin.PartName,
			PartString: this.walkin.PartString,
			HoseType: this.walkin.HoseType,
			Fitting1: this.walkin.Fitting1,
			Fitting2: this.walkin.Fitting2,
			Length: this.walkin.Length,
			Orientation: this.walkin.Orientation,
			SpecialInstructions: this.walkin.SpecialInstructions,
			FileName:this.walkin.FileName,
			CreatedDate: this.walkin.CreatedDate,
			CreatedBy: this.walkin.CreatedBy,
			UpdatedDate: this.walkin.UpdatedDate,
			UpdatedBy: this.walkin.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteWalkin(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.walkin.SalesOrder}?`)) {
			this.walkinService.deleteWalkin(this.walkin.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/walkins']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Walk In List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.WalkinSearch = this.walkinService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'SalesOrder')
				this.WalkinSearch.SalesOrder = i.Value;

			if (i.Item == 'DateString')
				this.WalkinSearch.DateString = i.Value;

			if (i.Item == 'CustomerName')
				this.WalkinSearch.CustomerName = i.Value;

			if (i.Item == 'Quantity')
				this.WalkinSearch.Quantity = i.Value;

			if (i.Item == 'EclipseId')
				this.WalkinSearch.EclipseId = i.Value;

			if (i.Item == 'PartName')
				this.WalkinSearch.PartName = i.Value;

			if (i.Item == 'PartString')
				this.WalkinSearch.PartString = i.Value;

			if (i.Item == 'HoseType')
				this.WalkinSearch.HoseType = i.Value;

			if (i.Item == 'Fitting1')
				this.WalkinSearch.Fitting1 = i.Value;

			if (i.Item == 'Fitting2')
				this.WalkinSearch.Fitting2 = i.Value;

			if (i.Item == 'Length')
				this.WalkinSearch.Length = i.Value;

			if (i.Item == 'Orientation')
				this.WalkinSearch.Orientation = i.Value;

			if (i.Item == 'SpecialInstructions')
				this.WalkinSearch.SpecialInstructions = i.Value;

			if (i.Item == 'FileName')
				this.WalkinSearch.FileName = i.Value;

			if (i.Item == 'orderByString')
				this.WalkinSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.WalkinSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	public print() {
		this.walkinService.printWalkin(this.walkin.Id).subscribe(
			retVal => {
				console.log("fileName:" + retVal);
				this.pdfFile = retVal;
				window.open(`${this.applicationSettings.retrieveUrl()}${retVal}`);
				
			},
			(error: any) => {
				this.errorMessage = <any>error;
			});
		
	}
}
