<div class="container">
  <h1>
    {{title}}
  </h1>
  <div *ngIf="errorMessages?.length > 0">
    <ol class="errorPlaceholder">
      <li *ngFor="let error of errorMessages" class="errorMsg">
        {{ error }}
      </li>
    </ol>
  </div>
  <h3> My Mac Address is: {{ macAddress }} </h3>

  <ul class="nav nav-tabs" role="tablist">
    <li role="presentation" class="active"><a href="#lowPress" aria-controls="lowPress" role="tab" data-toggle="tab"
        (click)="tabClick('Low')">Low Pressure</a></li>
    <li role="presentation"><a href="#highPress" aria-controls="highPress" role="tab" data-toggle="tab"
        (click)="tabClick('High')">High Pressure</a></li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div role="tabpanel" class="tab-pane active" id="lowPress">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <h2>Current PLC Value</h2>
          </div>
          <div class="col-md-3">
            <h2>{{plcValue}}
              <!--<a href="javascript:void(0)" class="btn btn-xs btn-primary" (click)="refreshPlcValue()">Refresh</a>-->
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h2><button type="button" class="btn btn-default" (click)="clickSetZero('low')">Set Zero</button></h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h2><button type="button" class="btn btn-default" (click)="clickSet300('low')">Set 300 PSI</button></h2>
          </div>
        </div>
      </div>

      <div class="container">
        <h2>Calibration Table</h2>
        <p>This calibration table shows the pressure to PLC reading numbers for this computer</p>
      </div>
      <div class="container">
        <table class="calibrationTable">
          <thead>
            <tr>
              <th style="width: 200px">My Mac Address</th>
              <th style="width: 200px">Pressure Switch</th>
              <th style="width: 200px">Pressure</th>
              <th style="width: 200px">PLC Value</th>
              <th style="width: 200px"></th>
            </tr>
          </thead>
          <tr *ngFor="let h of pcCalibrationList">
            <td>{{h.MacAddress}}</td>
            <td>{{h.PressureSwitch}}</td>
            <td>{{h.Pressure}}</td>
            <td>{{h.PLCValue}}</td>
            <td><button (click)="deleteCalibrationRecord('low',h.Id)">Delete</button></td>
          </tr>
        </table>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane" id="highPress">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <h2>Current PLC Value</h2>
          </div>
          <div class="col-md-3">
            <h2>{{plcValueHighPressure}}
              <!--<a href="javascript:void(0)" class="btn btn-xs btn-primary" (click)="refreshPlcValuePressureHigh()">Refresh</a>-->
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h2><button type="button" class="btn btn-default" (click)="clickSetZero('high')">Set Zero</button></h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h2><button type="button" class="btn btn-default" (click)="clickSet300('high')">Set 10,000 PSI</button></h2>
          </div>
        </div>
      </div>

      <div class="container">
        <h2>Calibration Table</h2>
        <p>This calibration table shows the pressure to PLC reading numbers for this computer</p>
      </div>

      <div class="container">
        <table class="calibrationTable">
          <tr>
            <th style="width: 200px">My Mac Address</th>
            <th style="width: 200px">Pressure Switch</th>
            <th style="width: 200px">Pressure</th>
            <th style="width: 200px">PLC Value</th>
            <th style="width: 200px"></th>
          </tr>
          <tr *ngFor="let h of pcCalibrationListHighPressure">
            <td>{{h.MacAddress}}</td>
            <td>{{h.PressureSwitch}}</td>
            <td>{{h.Pressure}}</td>
            <td>{{h.PLCValue}}</td>
            <td><button (click)="deleteCalibrationRecord('high',h.Id)">Delete</button></td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="localGLHoseFlag">
      <div *ngIf="serverStatus">
        <label style="color: green;font-size: 18px;">local communications Working</label>
      </div>
      <div *ngIf="!serverStatus">
        <label style="color: red;font-size: 18px;">start local communications</label>
      </div>
    </div>

  </div>