import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { WalkinListComponent } from './walkin-list/walkin-list.component';
import { WalkinEditComponent } from './walkin-edit/walkin-edit.component';
import { WalkinService } from './walkin-service';
import { WalkinDropFileComponent } from './walkin-dropfile/walkin-dropfile.component';

@NgModule({
	declarations: [
		WalkinListComponent
		, WalkinEditComponent
		, WalkinDropFileComponent 
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'walkins', component: WalkinListComponent },
			{ path: 'walkin-details/:Id', component: WalkinEditComponent },
			{ path: 'walkinsfile', component: WalkinDropFileComponent },
		])
	],
	providers: [
		WalkinService
	]
})
export class WalkinModule { }
